import React, { ReactElement } from 'react';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import { IoClose } from 'react-icons/io5';
import styled from 'styled-components';
import { IconButton } from '@mui/material';
import { Button } from '../atoms/button';
import { SubTitle, SubTitle2 } from '../atoms/text';
import { errorMessage } from '../../network';

//fetch projects && company names
const style = {
  // eslint-disable-next-line @typescript-eslint/prefer-as-const
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  height: '25%',
  maxWidth: '600px',
  width: '35%',
  borderRadius: '25px'
};

const Header = styled.div`
  height: 50px;
  background-color: ${({ theme }) => theme.palette.primary.main};
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  padding: 0 20px 0 20px;
`;

const Footer = styled.div`
  height: 20%;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;

  align-items: center;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  padding: 2% 10%;
`;

const Content = styled.div`
  position: relative;
  height: 80%;
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 8px 16px;
`;

export const NotificationModal = ({
  open,
  message,
  handleConfirm,
  handleClose,
  cancelBtn
}: {
  open: boolean;
  message: string | string[];
  cancelBtn?: boolean;
  handleConfirm: () => void;
  handleClose: () => void;
}): ReactElement => {
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      closeAfterTransition
      disableEscapeKeyDown
      slots={{ backdrop: Backdrop }}
      slotProps={{ backdrop: { timeout: 500 } }}>
      <Fade in={open}>
        <Box sx={style}>
          <Header>
            <SubTitle style={{ color: 'white' }}>
              {message === errorMessage.sessionTimeout ? 'Session Timeout' : 'Error'}
            </SubTitle>
            <IconButton
              onClick={() => {
                if (message === errorMessage.sessionTimeout) {
                  handleConfirm();
                } else {
                  handleClose();
                }
              }}
              sx={{ height: 45, width: 45 }}>
              <IoClose style={{ height: 40, width: 40, color: 'white' }} />
            </IconButton>
          </Header>
          {message ? (
            <Content>
              {typeof message === 'string' ? (
                <SubTitle>{message}</SubTitle>
              ) : (
                <>
                  <SubTitle>Following error occurred:</SubTitle>
                  {message.map((m) => (
                    <SubTitle2 key={m}>{m}</SubTitle2>
                  ))}
                </>
              )}
            </Content>
          ) : (
            <Content></Content>
          )}
          <Footer
            style={{
              justifyContent: message === errorMessage.sessionTimeout ? 'center' : 'flex-end'
            }}>
            {cancelBtn && (
              <Button
                label="Cancel"
                onClick={handleClose}
                variant="text"
                color="warning"
                size="small"
                styles={{ marginRight: 8 }}
              />
            )}
            <Button
              label={message === errorMessage.sessionTimeout ? 'Log in' : 'Confirm'}
              onClick={handleConfirm}
              variant="outlined"
              color="secondary"
              size="normal"
            />
          </Footer>
        </Box>
      </Fade>
    </Modal>
  );
};
