import { Company, User } from './users';
import { ActiveLoad, DeliveryConfirmation, Load, Material, Site, Truck } from './works';
import { Project } from './Project.type';
import { LoadType } from './LoadType.type';
import { m3, userRoles } from '../util';
import { Address, Contact } from './utilityTypes';
import { DashboardProject } from '../page';

export const defaultActiveLoad: ActiveLoad = {
  id: '', //date+truckID
  truckId: '', //truck entityID
  siteId: '',
  loadTypeId: '',
  truck: '',
  loadNumber: -1, //counting value
  loadType: '',
  receivingSite: '',
  status: '',
  deliveredQte: -1,
  creation: '',
  arrive: '',
  departure: '',
  jobTicket: '',
  whoConfirmed: ''
};

export const defaultAddress: Address = {
  street1: '',
  street2: '',
  city: '',
  prov: 'Ontario'
};

export const defaultContact: Contact = {
  fname: '',
  lname: '',
  phone: '',
  email: ''
};
export const defaultTruck: Truck = {
  driverId: '',
  companyId: '',
  splId: '',
  plate: '',
  capacity: 10,
  entityId: '',
  version: -1,
  isAssigned: false,
  province: 'Ontario',
  projectId: '',
  scheduleDate: '',
  truckNumber: -1,
  driver: defaultContact
};

export const defaultCompany: Company = {
  entityId: '',
  name: '',
  version: -1
};

export const defaultLoadType: LoadType = {
  name: '',
  version: -1,
  entityId: '',
  material_name: '',
  qte: 10,
  qte_type: '',
  special_requirements: '',
  isPile: false,
  zone_name: '',
  projectId: '',
  notes: '',
  isActive: true
};

export const defaultProject: Project = {
  entityId: '',
  name: '',
  startDate: '',
  note: '',
  version: -1,
  isActive: true,
  projectNumber: -1,
  address: defaultAddress,
  contact: defaultContact,
  deliveryConfirmations: []
};

export const defaultUser: User = {
  firstName: '',
  lastName: '',
  phone: '',
  isActive: true,
  emailAddress: '',
  role: userRoles.superAdmin,
  profileId: '',
  timeZoneOffset: -5,
  projects: [],
  countryCode: 'CA',
  name: '',
  pictureUrl: ''
};

export const defaultMaterial: Material = {
  name: '',
  isActive: true,
  description: '',
  version: -1,
  entityId: ''
};

export const defaultDeliveryConf: DeliveryConfirmation = {
  name: '',
  description: '',
  isActive: true,
  version: -1,
  entityId: ''
};
export const defaultSite: Site = {
  entityId: '',
  version: -1,
  isReceiving: true,
  projects: [],
  name: '',
  summary: [],
  address: defaultAddress,
  contact: defaultContact
};

export const defaultLoad: Load = {
  entityId: '-1',
  version: -1,
  departure: '',
  arrival: '',
  creation: '',
  loadTypeId: '',
  projectId: '',
  receivingSiteId: '',
  loadNumber: 0,
  truckId: '',
  qte: 0,
  pileNumber: 0,
  operatorId: 'string',
  isLastLoad: false,
  notes: '',
  delivery_confirmation: '',
  whoConfirmed: ''
};

export const initDashboardSummary: DashboardProject = {
  name: '',
  projectId: '',
  summary: { qte: 0, total: 0, qte_type: m3 },
  details: [],
  startDate: '',
  isActive: true
};
