import React, { ReactElement } from 'react';
import { Box } from '@mui/material';

export function LocalBackdrop({
  children,
  open
}: {
  children: JSX.Element | JSX.Element[];
  open?: boolean;
}): ReactElement {
  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        bgcolor: 'rgba(0, 0, 0, 0.7)',
        zIndex: 200,
        borderRadius: '25px',
        visibility: !open ? 'hidden' : undefined
      }}>
      {children}
    </Box>
  );
}

export default LocalBackdrop;
