/* eslint-disable prettier/prettier */
import React, { ReactElement, useEffect, useState } from 'react';
import { ContentContainer } from '../component/containers';
import Box from '@mui/material/Box/Box';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { ExportBar, StyledTextField, SubTitle } from '../component/atoms';
import {
  Autocomplete,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  useTheme
} from '@mui/material';
import { Company, defaultTruck, LoadType, loadTypeFrom, Project, projectFrom, Truck } from '../types';
import styled from 'styled-components';

import { useErrors, useLoading, useService } from '../hooks';
import EditIcon from '@mui/icons-material/Edit';
import useCoreState from '../hooks/useCoreState';
import { CoreActionTypes } from '../context';
import { responseErrorHelper, reasonCode, errorMessage, fetchAll } from '../network';
import { TruckModal } from '../component/modals';
import { decontructPackedId, getProvince } from '../util';
import { lighterBlue } from '../theme';

const Header = styled.div`
  width: 100%;
  height: 145px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 15px;
`;

const FilterContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const assignmentOptions = ['All', 'Assigned', 'Unassigned'];

type TruckPageState = {
  count: number;
  trucks: Truck[];
  allTrucksDisplay: TruckDisplay[];
  trucksDisplay: TruckDisplay[];
  companies: Company[];
  selectedTruck: Truck;
  selectedProjectName: string;
  modalOpen: boolean;
  filteredCompany: Company[];
  filteredProject: string[];
  filteredAssign: string;
};

type TruckDisplay = {
  driverName: string;
  companyName: string;
  projectName: string;
  number: string;
  splId: string;
  plate: string;
  entityId: string;
  isAssigned: boolean;
};

const initTruckPageState: TruckPageState = {
  count: 0,
  trucks: [defaultTruck],
  allTrucksDisplay: [],
  trucksDisplay: [],
  companies: [],
  selectedTruck: defaultTruck,
  selectedProjectName: '',
  modalOpen: false,
  filteredCompany: [],
  filteredProject: [],
  filteredAssign: assignmentOptions[0]
};

const columns = (
  setTruckSelected: (selected: TruckDisplay, projectName: string) => void
): GridColDef[] => {
  return [
    { field: 'splId', headerName: 'Truck ID', width: 150, hideable: false },
    {
      field: 'companyName',
      headerName: 'Company name',
      filterable: false,
      hideable: false,
      width: 150
    },
    {
      field: 'driverName',
      headerName: 'Driver',
      hideable: false,
      width: 150
    },
    {
      field: 'number',
      headerName: 'Driver Phone',
      hideable: false,
      type: 'string',
      width: 150
    },
    {
      field: 'plate',
      headerName: 'Plate',
      hideable: false,
      width: 90
    },
    {
      field: 'projectName',
      headerName: 'Project',
      filterable: false,
      hideable: false,
      width: 200
    },
    {
      field: 'Edit',
      width: 50,
      sortable: false,
      hideable: false,
      disableColumnMenu: true,
      renderCell: (v) => {
        return (
          <IconButton
            onClick={() => {
              const selected = v.row as TruckDisplay;

              if (selected && 'splId' in selected) {
                setTruckSelected(selected, selected.projectName);
              }
            }}
            sx={{ height: 40, width: 40 }}>
            <EditIcon sx={{ height: 32, width: 32 }} />
          </IconButton>
        );
      }
    }
  ];
};

export const Trucks = (): ReactElement => {
  const { coreState, updateCoreState } = useCoreState();
  const { service } = useService();

  const [truckState, setTruckState] = useState<TruckPageState>(initTruckPageState);
  const { isLoading, setLoading } = useLoading();
  const { setError } = useErrors();
  const theme = useTheme();
  const initPageLoad = async () => {
    setLoading(true);
    let trucks: Truck[] = [];
    let companies: Company[] = [];
    let loadTypes: LoadType[] = [];
    let projects: Project[] = [];
    try {
      const allData = await fetchAll(service);
      const error = responseErrorHelper(allData);

      if (error && error.reason_code === reasonCode.sessionTimeout) {
        setError({ networkError: errorMessage.sessionTimeout });
        return;
      }

      if (allData && 'users' in allData) {
        companies = [
          ...allData.truckingCompanies.map((c): Company => {
            return {
              version: c.version,
              entityId: c.entityId,
              name: c.data.name,
              contactName: c.data.contactName ?? '',
              contactphone: c.data.contactphone ?? ''
            };
          })
        ];

        trucks = [
          ...allData.trucks.map((t): Truck => {
            return {
              companyId: t.data.companyId,
              driver: { ...t.data.driver },
              scheduleDate: t.data.scheduleDate,
              splId: t.data.splId,
              plate: t.data.plate,
              capacity: t.data.capacity,
              entityId: t.entityId,
              version: t.version,
              driverId: t.data.driverId,
              province: getProvince(t.data.province),
              isAssigned: t.data.isAssigned,
              projectId: t.data.projectId,
              truckNumber: t.data.truckNumber,
              note: t.data.note ? t.data.note : '',
              noteImage: t.data.noteImage ? t.data.noteImage : ''
            };
          })
        ];

        projects = [
          ...allData.projects.map((p): Project => {
            return projectFrom(p); 
          })
        ];

        loadTypes = [
          ...allData.loadTypes.map(loadTypeFrom)
        ];
      }

      updateCoreState({
        type: CoreActionTypes.SET_INIT_LOAD,
        payload: {
          ...coreState,
          trucks,
          companies,
          projects,
          loadTypes
        }
      });
    } catch (error) {
      if (error && typeof error === 'object' && 'reason_code' in error) {
        if (error.reason_code === reasonCode.sessionTimeout) {
          setError({ networkError: errorMessage.sessionTimeout });
          return;
        } else {
          if (error.reason_code === reasonCode.packactIdError) {
            const id = decontructPackedId(error);
            if (id !== 0) {
              service.setPacketID(id);
              initPageLoad();
              return;
            }
          }
        }
      }
    }
  };

  const setTurckSelected = (selected: TruckDisplay, projectName: string): void => {
    const selectedTruck = truckState.trucks.find((t) => t.entityId === selected.entityId);

    if (selectedTruck)
      setTruckState({
        ...truckState,
        selectedTruck,
        modalOpen: true,
        selectedProjectName: projectName.trim() ?? ''
      });
  };

  const handleModalClose = (): void => {
    setTruckState({ ...truckState, selectedTruck: defaultTruck, modalOpen: false });
    initPageLoad();
  };

  const handleChangeCompanyFilter = (v: Company[]): void => {
    handleFilterChange(truckState.filteredAssign,  [...v], truckState.filteredProject);
  };

  const handleChangeProjectFilter = (v: string[]): void => {
    handleFilterChange(truckState.filteredAssign, truckState.filteredCompany, [...v]);
  };

  const handleChangeAssginFilter = (event: SelectChangeEvent): void => {
    const value = event.target.value;
    handleFilterChange(value, truckState.filteredCompany, truckState.filteredProject);
  };

  // Update all filters and set state with the new truck list
  const handleFilterChange = (filteredAssign: string, filteredCompany: Company[], filteredProject: string[]) => {

    const trucksDisplay = coreState.trucks.map((t): TruckDisplay => {
      let projectName = 'Unassigned';

      const companyName = coreState.companies.find((c) => c.entityId === t.companyId)?.name ?? '';

      const driverName = `${t.driver.fname} ${t.driver.lname}`;
      const driverNumber = t.driver.phone;
      const projectFound = coreState.projects.find((p) => p.entityId === t.projectId);
      if (t.projectId && projectFound) {
        projectName = projectFound.name;
      }

      return {
        driverName,
        companyName,
        number: driverNumber,
        splId: t.splId + ' ' + t.truckNumber,
        plate: t.plate,
        entityId: t.entityId,
        isAssigned: t.projectId !== '' && t.projectId !== undefined ? true : false,
        projectName
      };
    });

    let filteredTrucks = trucksDisplay;

    if(filteredAssign != assignmentOptions[0]) {
      if(filteredAssign == assignmentOptions[1]) {
        filteredTrucks = filteredTrucks.filter((t) => t.isAssigned);
      } else if(filteredAssign == assignmentOptions[2]) {
        filteredTrucks = filteredTrucks.filter((t) => !t.isAssigned);
      }
    }

    if(filteredProject.length > 0) {
      filteredTrucks = filteredTrucks.filter(
        (t) => filteredProject.find((fp) => fp === t.projectName) !== undefined
      );
    }

    if(filteredCompany.length > 0) {
      filteredTrucks = filteredTrucks.filter(
        (t) => filteredCompany.find((fc) => t.companyName === fc.name) !== undefined
      );
    }
    
    setTruckState({
      ...truckState,
      trucksDisplay: filteredTrucks,
      allTrucksDisplay: trucksDisplay,
      count: coreState.trucks.length,
      trucks: coreState.trucks,
      companies: coreState.companies,
      filteredAssign,
      filteredCompany,
      filteredProject
    });
  };

  const loadData = (): void => {
    handleFilterChange(truckState.filteredAssign, truckState.filteredCompany, truckState.filteredProject);
  };

  useEffect(() => {
    initPageLoad();
  }, []);

  useEffect(() => {
    setLoading(true);
    loadData();
    setLoading(false);
  }, [coreState.projects, coreState.companies, coreState.trucks]);

  return (
    <ContentContainer header title="Trucks">
      <Box
        sx={{
          height: '100%',
          minHeight: '990px',
          width: '100%',
          backgroundColor: 'white',
          padding: 2,
          borderRadius: '15px',
          boxShadow: theme.shadows[1]
        }}>
        <SubTitle>total trucks: {truckState.trucksDisplay.length}</SubTitle>
        <Header>
          <FilterContainer>
            <div style={{ width: '40%' }}>
              <SubTitle style={{ alignSelf: 'flex-start', margin: 0 }}>
                Filter View By Company
              </SubTitle>
              <Autocomplete
                multiple
                sx={{
                  width: '90%',
                  overflow: 'auto',
                  '& .MuiInputBase-root': {
                    backgroundColor: 'rgba(244, 245, 253, 0.6)'
                  }
                }}
                options={ coreState.companies.map((c) => c)}
                getOptionLabel={(c) => c.name}                
                value={truckState.filteredCompany}
                onChange={(_, v) => handleChangeCompanyFilter(v)}
                renderOption={(props, c) => {
                  return (
                    <li {...props} key={c.entityId}>
                      {c.name}
                    </li>
                  );
                }}              
                renderInput={(params) => (
                  <StyledTextField {...params} label="Select company(s)" variant="filled"/>
                )}
              />
            </div>
            <div style={{ width: '30%' }}>
              <SubTitle style={{ alignSelf: 'flex-start', margin: 0 }}>
                Filter View By Project
              </SubTitle>
              <Autocomplete
                multiple
                sx={{
                  width: '90%',
                  overflow: 'auto',
                  '& .MuiInputBase-root': {
                    backgroundColor: 'rgba(244, 245, 253, 0.6)'
                  }
                }}
                options={coreState.projects.filter((p) => p.isActive).map((c) => c.name)}
                value={truckState.filteredProject}
                onChange={(_, v) => {
                  handleChangeProjectFilter(v);
                }}
                renderInput={(params) => (
                  <StyledTextField {...params} label="Select project(s)" variant="filled" />
                )}
              />
            </div>
            <div style={{ width: '30%' }}>
              <SubTitle style={{ alignSelf: 'flex-start', margin: 0 }}>Assignment Status</SubTitle>
              <FormControl
                variant="filled"
                sx={{
                  minWidth: 210,
                  '& .MuiFilledInput-root': {
                    backgroundColor: 'rgba(244, 245, 253, 0.6)',
                    height: '56px'
                  }
                }}>
                <InputLabel>Assignment Status</InputLabel>
                <Select value={truckState.filteredAssign} onChange={handleChangeAssginFilter}>
                  {assignmentOptions.map((p) => (
                    <MenuItem value={p} key={p}>
                      {p}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </FilterContainer>
        </Header>
        <Box
          sx={{
            height: '100%',
            width: '100%',
            minHeight: '950px',
            '& .data-grid-row-blue': {
              bgcolor: `${lighterBlue}`,
              '&: hover': {
                '&: .MuiDataGrid-cell': { bgcolor: `rgba(22,153,213,0.3)` },
                bgcolor: `rgba(22,153,213,0.3)`
              }
            }
          }}>
          <DataGrid
            className={`#export-trucks`} //how we get the file name for export donot delete or change format
            getRowClassName={(p) => {
              if (p.indexRelativeToCurrentPage % 2 === 1) {
                return `data-grid-row-blue`;
              } else {
                return `data-grid-row-white`;
              }
            }}
            loading={isLoading}
            columns={columns(setTurckSelected)}
            rows={truckState.trucksDisplay}
            autoHeight
            pageSize={10}
            rowsPerPageOptions={[10]}
            checkboxSelection={false}
            disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: true }}
            components={{ Toolbar: ExportBar }}
            getRowId={(row) => row.entityId}
          />
        </Box>
      </Box>
      <TruckModal
        open={truckState.modalOpen}
        handleClose={handleModalClose}
        data={truckState.selectedTruck}
        projectName={truckState.selectedProjectName}
        label="Edit Truck"
        mode="Edit"
      />
    </ContentContainer>
  );
};
