import React, { ReactElement, useState } from 'react';
import { IconButton as IB, Tooltip } from '@mui/material';

export const IconButton = ({
  label,
  children,
  height,
  width,
  onClick,
  disabled
}: {
  label: string;
  children: JSX.Element;
  height?: string;
  width?: string;
  disabled?: boolean;
  onClick: () => void;
}): ReactElement => {
  const [btnDisabled, setDisabled] = useState<boolean>(false);

  const handleClick = (): void => {
    if (btnDisabled) {
      setTimeout(() => {
        setDisabled(false);
      }, 200);
      return;
    }
    onClick();
    setDisabled(true);
    setTimeout(() => {
      setDisabled(false);
    }, 500);
  };
  return disabled ? (
    <IB
      disabled={disabled === true}
      sx={{ height: height ? height : '50px', width: width ? width : '50px' }}
      onClick={handleClick}>
      {children}
    </IB>
  ) : (
    <Tooltip title={label}>
      <IB
        sx={{ height: height ? height : '50px', width: width ? width : '50px' }}
        onClick={handleClick}>
        {children}
      </IB>
    </Tooltip>
  );
};
