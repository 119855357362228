import React, { ReactElement } from 'react';

export const Spinner = ({ width, height }: { width?: number; height?: number }): ReactElement => (
  <div
    style={{
      height: height,
      width: width,
      margin: 'auto',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }}>
    <div id="spinner"></div>
  </div>
);
