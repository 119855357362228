import React, { ReactElement, useEffect, useState } from 'react';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import { Caption, SubTitle } from '../atoms/text';
import styled, { useTheme } from 'styled-components';
import { modalBtnType } from '../containers/contentContainer';
import {
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent
} from '@mui/material';
import { IoClose } from 'react-icons/io5';
import { Button } from '../atoms/button';
import { StyledTextField } from '../atoms/styledTextField';

import {
  dataFromLoadType,
  defaultDeliveryConf,
  defaultLoadType,
  defaultMaterial,
  defaultProject,
  DeliveryConfirmation,
  LoadType,
  Material,
  Project
} from '../../types';
import { scriptOP, errorMessage, postResHelper, reasonCode, responseStatus } from '../../network';
import { Spinner } from '../atoms/spinner';
import useCoreState from '../../hooks/useCoreState';
import { CoreActionTypes } from '../../context';
import { useService } from '../../hooks';
import { AiFillCheckCircle, AiFillWarning } from 'react-icons/ai';
import { m3, numberTest } from '../../util';
import { ModalLoadingContainer } from '../containers';

const style = {
  // eslint-disable-next-line @typescript-eslint/prefer-as-const
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  minHeight: '500px',
  height: '55%',
  maxWidth: '950px',
  width: '50%',
  borderRadius: '25px'
};

type ModalErrors = {
  name: string;
  material: string;
  quantity: string;
};

const initModalState = {
  loadType: defaultLoadType,
  zone: '',
  quantity: 0,
  quantityInput: '',
  isActive: true,
  selectedProject: defaultProject,
  selectedMaterials: defaultMaterial,
  delivConf: defaultDeliveryConf,
  isLoading: false,
  projectOptions: []
};

const defaultErrors: ModalErrors = {
  name: '',
  material: '',
  quantity: ''
};

export const AddLoadTypeModal = ({
  open,
  label,
  mode,
  data,
  projectId,
  handleClose
}: {
  open: boolean;
  label: modalBtnType;
  mode: 'New' | 'Edit' | 'Offline';
  data?: LoadType;
  projectId?: string;
  handleClose: (data: LoadType) => void;
}): ReactElement => {
  const { coreState, updateCoreState } = useCoreState();
  const [modalErrors, setModalErrors] = useState<ModalErrors>(defaultErrors);
  const [notificationState, setNotifications] = useState<{
    message: string;
    isSuccess: boolean;
    isOpen: boolean;
  }>({
    message: '',
    isSuccess: true,
    isOpen: false
  });

  const theme = useTheme();
  const [ltModalState, setltModalState] = useState<{
    loadType: LoadType;
    zone: string;
    quantity: number;
    selectedProject: Project;
    isActive: boolean;
    selectedMaterials: Material;
    delivConf: DeliveryConfirmation;
    isLoading: boolean;
    quantityInput: string;
    projectOptions: Project[];
  }>(initModalState);

  const { service } = useService();

  useEffect(() => {
    if (notificationState.isOpen && notificationState.isSuccess === false) {
      setTimeout(() => {
        setNotifications({ message: '', isSuccess: true, isOpen: false });
        handleClose(ltModalState.loadType);
      }, 3000);
    }
  }, [notificationState.isOpen]);

  useEffect(() => {
    if (open) {
      setModalErrors({ ...defaultErrors });
    }
    if (mode === 'New' || mode === 'Offline') {
      setltModalState({
        ...initModalState,
        projectOptions: [],
        loadType: { ...defaultLoadType, projectId: projectId ? projectId : '' }
      });
    }

    if (!open) {
      setltModalState({ ...initModalState });
    }

    return () => {
      setltModalState({ ...initModalState });
    };
  }, [open, mode]);

  useEffect(() => {
    if (mode === 'Edit') {
      setltModalState({ ...ltModalState, isLoading: true });

      if (data) {
        const m = coreState.materials.find((mt) => mt.name === data.material_name);
        const d = coreState.deliveryConf.find((dc) => dc.name === data.special_requirements);

        setltModalState({
          ...ltModalState,
          projectOptions: [],
          selectedProject: defaultProject,
          selectedMaterials: m ?? defaultMaterial,
          delivConf: d ?? defaultDeliveryConf,
          loadType: { ...data },
          quantityInput: data.qte.toString(),
          quantity: data.qte,
          isActive: data.isActive,
          isLoading: false
        });
      }
    }
    return () => {
      setltModalState({ ...initModalState });
    };
  }, [mode, data]);

  const onClose = (): void => {
    handleClose(ltModalState.loadType);
  };

  const validation = (): boolean => {
    let hasError = false;

    // const allLoadTypes = coreState.loadTypes.filter(
    //   (l) => l.projectId === ltModalState.selectedProject.entityId
    // );
    // const hasDup = allLoadTypes.find((i) => i.name === ltModalState.loadType.name);

    // const hasEditDup = allLoadTypes
    //   .filter((l) => l.entityId !== data?.entityId)
    //   .find((i) => i.name === ltModalState.loadType.name);

    // if (hasDup && mode === 'New') {
    //   hasError = true;
    //   setModalErrors({
    //     ...modalErrors,
    //     name: `The selected project has a duplicate load type: ${ltModalState.loadType.name}`
    //   });
    // }
    // //in edit mode, check duplicate name
    // if (hasEditDup && mode === 'Edit') {
    //   hasError = true;
    //   setModalErrors({
    //     ...modalErrors,
    //     name: `The selected project has a duplicate load type: ${ltModalState.loadType.name}`
    //   });
    // }

    if (ltModalState.selectedMaterials.entityId === defaultMaterial.entityId) {
      hasError = true;
    }

    if (ltModalState.quantityInput === '') {
      hasError = true;
    }

    if (ltModalState.loadType.name === '') {
      hasError = true;
    }
    setModalErrors({
      ...modalErrors,
      name: ltModalState.loadType.name === '' ? 'This field is required' : '',
      quantity: ltModalState.quantityInput === '' ? 'This field is required' : '',
      material:
        ltModalState.selectedMaterials.entityId === defaultMaterial.entityId
          ? 'Must select a Material.'
          : ''
    });

    return hasError;
  };

  const handleInputChange = (
    input: string,
    type: 'name' | 'quantity' | 'specialR' | 'notes'
  ): void => {
    if (type === 'name') {
      setltModalState({ ...ltModalState, loadType: { ...ltModalState.loadType, name: input } });
      return;
    }

    if (type === 'specialR') {
      setltModalState({
        ...ltModalState,
        loadType: {
          ...ltModalState.loadType,
          special_requirements: input
        }
      });
      return;
    }

    if (type === 'notes') {
      setltModalState({
        ...ltModalState,
        loadType: { ...ltModalState.loadType, notes: input }
      });
      return;
    }

    if (type === 'quantity') {
      if (!numberTest.test(input) && input !== '') {
        setModalErrors({ ...modalErrors, quantity: 'Number only' });
        return;
      }

      const q = parseInt(input);
      if (input === '') {
        setltModalState({
          ...ltModalState,
          quantity: 0,
          quantityInput: input,
          loadType: { ...ltModalState.loadType, qte: 0 }
        });
      }

      setltModalState({
        ...ltModalState,
        quantity: q,
        quantityInput: input,
        loadType: { ...ltModalState.loadType, qte: q }
      });
      return;
    }
  };

  const handleSelectMaterial = (event: SelectChangeEvent) => {
    const id = event.target.value;

    const materialFound = coreState.materials.find((s) => s.entityId === id);

    if (materialFound) {
      setltModalState({ ...ltModalState, selectedMaterials: materialFound });
    }
  };

  const handleSelectDelivConf = (event: SelectChangeEvent) => {
    const id = event.target.value;
    const delivConFound = coreState.deliveryConf.find((s) => s.entityId === id);

    if (delivConFound) {
      setltModalState({
        ...ltModalState,
        delivConf: delivConFound
      });
    }
  };

  const handleSubmitCreate = async () => {
    const hasErrors = validation();
    if (hasErrors) {
      return;
    }
    setltModalState({ ...ltModalState, isLoading: true });
    const { loadType, selectedMaterials, delivConf } = ltModalState;
    const notes = loadType.notes ? loadType.notes : '';
    const specialReq = delivConf.entityId !== defaultDeliveryConf.entityId ? delivConf.name : '';
    const loadTypeData = {
      name: loadType.name,
      special_requirements: specialReq,
      material_name: selectedMaterials.name,
      qte: ltModalState.quantity,
      qte_type: m3,
      isPile: true,
      projectId: projectId ? projectId : '',
      zone_name: loadType.name,
      notes: notes,
      isActive: ltModalState.isActive
    };

    if (mode === 'Offline') {
      setltModalState({ ...ltModalState, isLoading: false });
      setNotifications({
        message: 'Success! The load type has been updated.',
        isOpen: true,
        isSuccess: true
      });

      setTimeout(() => {
        handleClose({
          ...loadTypeData,
          entityId: Date.now().toString(),
          version: 1
        });
        setNotifications({ message: '', isSuccess: true, isOpen: false });
      }, 2000);
      return;
    }

    if (mode === 'New') {
      try {
        const loadTypeRes = await service.post(scriptOP.createEntity, {
          dataJson: { ...loadTypeData },
          entityType: 'LoadType',
          acl: { other: 2 }
        });

        const entityId = postResHelper(loadTypeRes);

        const res = responseStatus(loadTypeRes);

        if (!res.success) {
          if (res.reasonCode === reasonCode.sessionTimeout) {
            setNotifications({
              message: errorMessage.sessionTimeout,
              isOpen: true,
              isSuccess: false
            });
            return;
          }
          setNotifications({
            message: errorMessage.somethingWrong,
            isOpen: true,
            isSuccess: false
          });
          return;
        }

        if (entityId) {
          setltModalState({ ...ltModalState, loadType: { ...loadTypeData, entityId, version: 1 } });

          updateCoreState({
            type: CoreActionTypes.ADD_LOADTYPE,
            payload: {
              ...coreState,
              loadTypes: [...coreState.loadTypes, { ...loadTypeData, entityId, version: 1 }]
            }
          });

          setltModalState({ ...ltModalState, isLoading: false });
          setNotifications({
            message: 'Success! The load type has been updated.',
            isOpen: true,
            isSuccess: true
          });

          setTimeout(() => {
            handleClose({ ...loadTypeData, entityId, version: 1 });
            setNotifications({ message: '', isSuccess: true, isOpen: false });
          }, 2000);
        }
      } catch (e) {
        setNotifications({
          message: 'Something went wrong, please try again.',
          isOpen: true,
          isSuccess: false
        });
      }
    }
  };

  const handleSubmitEdit = async () => {
    //edit mode
    const hasErrors = validation();
    if (hasErrors) {
      return;
    }
    setltModalState({ ...ltModalState, isLoading: true });
    const { loadType, selectedMaterials, delivConf, isActive } = ltModalState;
    const notes = loadType.notes ? loadType.notes : '';
    const specialReq = delivConf.entityId !== defaultDeliveryConf.entityId ? delivConf.name : '';

    const loadTypeData = {
      ...dataFromLoadType(loadType),
      special_requirements: specialReq,
      material_name: selectedMaterials.name,
      qte_type: m3,
      isPile: true,
      notes: notes,
      isActive
    };

    try {
      const loadTypeRes = await service.post(scriptOP.updateEntity, {
        dataJson: {
          ...loadTypeData
        },
        entityType: 'LoadType',
        acl: { other: 2 },
        entityId: loadType.entityId,
        version: loadType.version
      });

      const res = responseStatus(loadTypeRes);

      if (!res.success) {
        if (res.reasonCode === reasonCode.sessionTimeout) {
          setNotifications({
            message: errorMessage.sessionTimeout,
            isOpen: true,
            isSuccess: false
          });
          return;
        }
        setNotifications({
          message: errorMessage.somethingWrong,
          isOpen: true,
          isSuccess: false
        });
        return;
      } else {
        updateCoreState({
          type: CoreActionTypes.ADD_LOADTYPE,
          payload: {
            ...coreState,
            loadTypes: [
              ...coreState.loadTypes.map((l) =>
                l.entityId === loadType.entityId ? { ...loadType, ...loadTypeData } : l
              )
            ]
          }
        });
      }

      setltModalState({ ...ltModalState, isLoading: false });
      setNotifications({
        message: 'Success! The load type has been updated.',
        isOpen: true,
        isSuccess: true
      });
      setTimeout(() => {
        handleClose({ ...loadType, ...loadTypeData });
        setNotifications({ message: '', isSuccess: true, isOpen: false });
      }, 1500);
    } catch (e) {
      setNotifications({
        message: 'Something went wrong, please try again.',
        isOpen: true,
        isSuccess: false
      });
    }
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={onClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{ backdrop: { timeout: 500 } }}>
      <Fade in={open}>
        <Box sx={style}>
          <Header>
            <SubTitle style={{ color: 'white' }}>{label}</SubTitle>
            <IconButton onClick={onClose} sx={{ height: 45, width: 45 }}>
              <IoClose style={{ height: 40, width: 40, color: 'white' }} />
            </IconButton>
          </Header>
          <Content>
            <div style={{ height: '50%', width: '100%', display: 'flex' }}>
              <Column>
                <InputGroup>
                  <SubTitle style={{ alignSelf: 'flex-start', margin: 0 }}>Material Type*</SubTitle>
                  <FormControl
                    fullWidth
                    variant="filled"
                    sx={{
                      minWidth: 120,
                      '& .MuiFilledInput-root': {
                        backgroundColor: 'rgba(244, 245, 253, 0.6)',
                        height: '48px'
                      }
                    }}>
                    <InputLabel>Select Material Type ...</InputLabel>
                    <Select
                      onBlur={() => {
                        if (ltModalState.selectedMaterials.entityId === '') {
                          setModalErrors({ ...modalErrors, material: 'This field is requried' });
                        }
                      }}
                      value={ltModalState.selectedMaterials.entityId}
                      onChange={(e) => {
                        handleSelectMaterial(e);
                      }}
                      error={modalErrors.material !== ''}
                      fullWidth>
                      {coreState.materials
                        .filter(
                          (m) =>
                            m.isActive || m.entityId === ltModalState.selectedMaterials.entityId
                        )
                        .map((p) => (
                          <MenuItem value={p.entityId} key={p.entityId}>
                            {p.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </InputGroup>

                <InputGroup>
                  <SubTitle style={{ alignSelf: 'flex-start', margin: 0 }}>
                    Total Quantity*
                  </SubTitle>
                  <StyledTextField
                    label="Number of cubic meters"
                    id="filled-size-small"
                    required
                    value={ltModalState.quantityInput}
                    variant="filled"
                    fullWidth
                    size="small"
                    error={modalErrors.quantity !== ''}
                    helperText={modalErrors.quantity}
                    onBlur={() => {
                      if (ltModalState.quantityInput === '') {
                        setModalErrors({ ...modalErrors, quantity: 'This field is required' });
                      }
                    }}
                    onFocus={() => {
                      setModalErrors({ ...modalErrors, quantity: '' });
                    }}
                    onChange={(e) => {
                      handleInputChange(e.target.value, 'quantity');
                    }}
                  />
                </InputGroup>
              </Column>
              <Column>
                <InputGroup>
                  <SubTitle style={{ alignSelf: 'flex-start', margin: 0 }}>Area/Zone*</SubTitle>
                  <StyledTextField
                    label="Area/Zone Name"
                    id="filled-size-small"
                    required
                    error={modalErrors.name !== ''}
                    helperText={modalErrors.name}
                    onBlur={(e) => {
                      if (!e.target.value) {
                        setModalErrors({ ...modalErrors, name: 'This field is required.' });
                      }
                    }}
                    value={ltModalState.loadType.name}
                    variant="filled"
                    fullWidth
                    size="small"
                    onFocus={() => {
                      setModalErrors({ ...modalErrors, name: '' });
                    }}
                    onChange={(e) => {
                      handleInputChange(e.target.value, 'name');
                    }}
                  />
                </InputGroup>
                <InputGroup>
                  <SubTitle style={{ alignSelf: 'flex-start', margin: 0 }}>
                    Delivery Confirmation Type
                  </SubTitle>
                  <FormControl
                    fullWidth
                    variant="filled"
                    sx={{
                      minWidth: 80,

                      '& .MuiFilledInput-root': {
                        backgroundColor: 'rgba(244, 245, 253, 0.6)',
                        height: '48px'
                      }
                    }}>
                    <InputLabel>Delivery Confirmation</InputLabel>
                    <Select
                      value={ltModalState.delivConf.entityId}
                      onChange={(e) => {
                        handleSelectDelivConf(e);
                      }}
                      fullWidth>
                      {coreState.deliveryConf.map((p) => (
                        <MenuItem value={p.entityId} key={p.entityId}>
                          {p.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </InputGroup>
                <InputGroup>
                  <FormControl sx={{ width: '100%' }}>
                    <SubTitle style={{ alignSelf: 'flex-start', margin: 0 }}>Status</SubTitle>
                    <RadioGroup
                      row
                      aria-labelledby="load type active group"
                      name="status"
                      value={ltModalState.isActive}
                      sx={{
                        width: '100%',
                        '& .Mui-checked': {
                          color: theme.palette.secondary.main
                        }
                      }}
                      onChange={(e, c) => {
                        if (c === 'false') {
                          setltModalState({ ...ltModalState, isActive: false });
                        } else {
                          setltModalState({ ...ltModalState, isActive: true });
                        }
                      }}>
                      <FormControlLabel
                        // disabled={!hasPermission}
                        value={true}
                        control={<Radio />}
                        label="Active"
                      />
                      <FormControlLabel
                        value={false}
                        // disabled={!hasPermission}
                        control={<Radio />}
                        label="Disabled"
                      />
                    </RadioGroup>
                  </FormControl>
                </InputGroup>
              </Column>
            </div>
            <NoteContainer>
              <InputGroup>
                <SubTitle style={{ alignSelf: 'flex-start', margin: 0 }}>Notes</SubTitle>
                <div
                  style={{
                    height: '100%',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center'
                  }}>
                  <StyledTextField
                    id="filled-size-small"
                    value={ltModalState.loadType.notes}
                    variant="filled"
                    fullWidth
                    multiline
                    size="small"
                    rows={2}
                    onChange={(e) => {
                      handleInputChange(e.target.value, 'notes');
                    }}
                  />
                </div>
              </InputGroup>
            </NoteContainer>
          </Content>
          <Footer>
            <Caption>*Required information</Caption>
            <div>
              <Button
                variant="outlined"
                label="Cancel"
                onClick={onClose}
                styles={{ marginRight: 20 }}
                size="normal"
                color="secondary"
              />
              <Button
                variant="contained"
                label="Confirm"
                onClick={() => {
                  mode === 'New' || mode === 'Offline' ? handleSubmitCreate() : handleSubmitEdit();
                }}
                size="normal"
                color="secondary"
              />
            </div>
          </Footer>
          {ltModalState.isLoading && (
            <ModalLoadingContainer>
              <Spinner />
            </ModalLoadingContainer>
          )}

          {notificationState.isOpen && (
            <ModalLoadingContainer>
              <FeedbackContainer>
                {notificationState.isSuccess ? (
                  <AiFillCheckCircle size={50} color={theme.palette.success.main} />
                ) : (
                  <AiFillWarning size={50} color={theme.palette.error.main} />
                )}
                <SubTitle>{notificationState.message}</SubTitle>
              </FeedbackContainer>
            </ModalLoadingContainer>
          )}
        </Box>
      </Fade>
    </Modal>
  );
};

const Header = styled.div`
  height: 50px;
  background-color: ${({ theme }) => theme.palette.primary.main};
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  padding: 0 20px 0 20px;
`;

const Content = styled.div`
  position: relative;
  height: calc(100% - 100px);
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  padding: 0 20px;
`;

const Column = styled.div`
  width: 50%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
`;

const Footer = styled.div`
  height: 50px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  padding: 0 20px 0 20px;
`;

const NoteContainer = styled.div`
  height: 45%;
  width: 100%;
`;

export const InputGroup = styled.div`
  justify-content: space-evenly;
  width: 100%;
  padding: 0 10px;
  flex-direction: column;
  align-items: center;
  & > p {
    margin-bottom: 10px !important;
    margin-top: 25px !important;
  }
`;

const FeedbackContainer = styled.div`
  height: 125px;
  width: 400px;
  padding: 5px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.background.paper};
`;
