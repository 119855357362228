/* eslint-disable @typescript-eslint/ban-types */
import { HttpStatusCode } from 'axios';
import {
  Company,
  DashboardSummary,
  DeliveryConfirmation,
  Load,
  LoadType,
  Material,
  Project,
  Site,
  Truck,
  User
} from '../types';
import { getRole } from '../util';
import { scriptOP } from './property';
import NetworkService from './service';
import { AppError } from './networkProvider';

export const requestStr =
  '{"pagination":{"rowsPerPage":1000,"pageNumber":1},"searchCriteria":{},"sortCriteria":{}}';

//data type from the response of server

export enum reasonCode {
  somethingWrong = -1,
  sessionTimeout = 40303,
  packactIdError = 40566,
  logOutByLogin = 40356,
  notfound = 40571,
  badversion = 40344,
  scriptError = 40911,
  invalidData = 99999
}

export enum errorMessage {
  sessionTimeout = 'Your session expired because it was inactive for too long. Please log in again',
  somethingWrong = 'Something went wrong, please try it again later.',
  scriptError = 'Server error, please contact support.'
}

const reasonMessage = {
  40303: 'Your session expired because it was inactive for too long. Please log in again',
  0: 'Something went wrong, please try it again later.',
  40566: 'There was an error completing you request, please try refreshing this page.',
  40911: 'Server error, please try again',
  40356: 'Your account is logged in from other device.',
  40571: 'Requested resource can not be found.',
  40344: 'There was an error completing you request, please try refreshing this page.'
};
export type ResTruck = {
  acl: { other: number };
  createdAt: number;
  entityId: string;
  entityType: string;
  updatedAt: number;
  version: number;
  data: {
    capacity: number;
    companyId: string;
    driverId: string;
    splId: string;
    isAssigned: boolean;
    note: string;
    noteImage: string;
    phone: string;
    plate: string;
    province: string;
  };
};

export type ResType<T> = {
  [key: string]: string | number | T | { other: number };
  acl: { other: number };
  createdAt: number;
  entityId: string;
  entityType: string;
  version: number;
  data: T;
};

export type ReqError = {
  reason_code: number;
  severity: string;
  status: HttpStatusCode;
  status_message: string;
};

export const defaultReqError: ReqError = {
  reason_code: reasonCode.somethingWrong,
  severity: '',
  status: 500,
  status_message: ''
};

export type ResReturnList<T> = {
  [key: string]: boolean | number | T[];
  count: number;
  moreAfter: boolean;
  moreBefore: boolean;
  page: number;
  items: T[];
};

export type ResGenError = {
  response: string;
  success: boolean;
  reasonCode: number;
};

export const getErrorMessage = (code: number): string => {
  if (code === undefined) {
    return reasonMessage[0];
  }
  const keys = Object.keys(reasonMessage);
  const values = Object.values(reasonMessage);
  const index = keys.findIndex((i) => i === code.toString());

  if (index !== -1) {
    return values[index];
  }

  return reasonMessage[0];
};
export const decodeUsers = (res: unknown): User[] | ReqError => {
  if (res && typeof res === 'object') {
    if ('data' in res) {
      if (res.data && typeof res.data === 'object' && 'data' in res.data) {
        if (res.data.data && typeof res.data.data === 'object') {
          if ('reason_code' in res.data.data) {
            if (res.data.data.reason_code && typeof res.data.data.reason_code === 'number') {
              return { ...defaultReqError, reason_code: res.data.data.reason_code };
            }
          }
          if ('response' in res.data.data && Array.isArray(res.data.data.response)) {
            return [
              ...res.data.data.response.map((r) => {
                const u = r as User;
                return {
                  ...u,
                  role: getRole(u.role),
                  name: u.name ? u.name : `${u.firstName} ${u.lastName}`
                };
              })
            ];
          }
        }
      }
    }
  }
  return defaultReqError;
};

export const decodeSingleUser = (res: unknown): User | reasonCode => {
  if (res && typeof res === 'object' && 'data' in res && res.data) {
    if (typeof res.data === 'object') {
      if ('data' in res.data && res.data.data && typeof res.data.data === 'object') {
        if (
          'reasonCode' in res.data.data &&
          typeof res.data.data.reasonCode === 'string' &&
          res.data.data.reasonCode
        ) {
          const code = res.data.data.reasonCode as unknown as reasonCode;
          return code;
        }
        if (
          'response' in res.data.data &&
          res.data.data.response &&
          typeof res.data.data.response === 'object'
        ) {
          const user = res.data.data.response as User;
          return user;
        }
      }
    }
  }
  return reasonCode.invalidData;
};

export const responseErrorHelper = (res: unknown): ReqError | null => {
  if (res && typeof res === 'object') {
    if ('data' in res) {
      if (res.data && typeof res.data === 'object') {
        if ('data' in res.data) {
          if (res.data.data && typeof res.data.data === 'object') {
            if ('reasonCode' in res.data.data && typeof res.data.data.reasonCode === 'number') {
              const error: ReqError = res.data.data as unknown as ReqError;
              return { ...error, reason_code: res.data.data.reasonCode };
            }
            if ('reason_code' in res.data.data) {
              const error: ReqError = res.data.data as unknown as ReqError;
              return error;
            }
          }
        }
        if ('error' in res.data) {
          const error: ReqError = res.data.error as ReqError;
          return error;
        }
      }
    }
    if ('error' in res) {
      if (res.error && typeof res.error === 'object') {
        const error: ReqError = res.error as ReqError;
        return error;
      }
    }
  }
  return null;
};

export const responseStatus = (
  res: unknown
): { success: boolean; status: number; reasonCode: number } => {
  let r = { success: false, status: -1, reasonCode: -1 };

  if (res && typeof res === 'object') {
    //data
    if ('data' in res && res.data && typeof res.data === 'object') {
      if ('data' in res.data && res.data.data && typeof res.data.data === 'object') {
        if ('reasonCode' in res.data.data && typeof res.data.data.reasonCode === 'number') {
          r.reasonCode = res.data.data.reasonCode;
        }
        if ('reason_code' in res.data.data && typeof res.data.data.reason_code === 'number') {
          r.reasonCode = res.data.data.reason_code;
        }
        //reason code

        if (
          'success' in res.data.data &&
          res.data.data.success &&
          typeof res.data.data.success === 'boolean'
        ) {
          r.success = res.data.data.success;
        }
      }
      if ('reasonCode' in res.data && typeof res.data.reasonCode === 'number') {
        r.reasonCode = res.data.reasonCode;
      }
      if ('reason_code' in res.data && typeof res.data.reason_code === 'number') {
        r.reasonCode = res.data.reason_code;
      }
      //reason code

      if ('success' in res.data && res.data.success && typeof res.data.success === 'boolean') {
        r.success = res.data.success;
      }
    } //if data exist

    //status
    if ('status' in res && res.status && typeof res.status === 'number') {
      r.status = res.status;
    }
    //if status exist
  }

  return r;
};

export const postResHelper = (res: unknown): string | undefined => {
  let entityId;

  if (res && typeof res === 'object' && 'data' in res && res.data) {
    if (typeof res.data === 'object') {
      if ('data' in res.data && res.data.data && typeof res.data.data === 'object') {
        if (
          'reasonCode' in res.data.data &&
          typeof res.data.data.reasonCode === 'string' &&
          res.data.data.reasonCode
        ) {
          return undefined;
        }
        if (
          'response' in res.data.data &&
          res.data.data.response &&
          typeof res.data.data.response === 'object'
        ) {
          if (
            'data' in res.data.data.response &&
            res.data.data.response.data &&
            typeof res.data.data.response.data === 'object'
          ) {
            if (
              'entityId' in res.data.data.response.data &&
              typeof res.data.data.response.data.entityId === 'string'
            ) {
              entityId = res.data.data.response.data.entityId;
              return entityId;
            }
          }
          if (
            'profileId' in res.data.data.response &&
            typeof res.data.data.response.profileId === 'string'
          ) {
            entityId = res.data.data.response.profileId;
            return entityId;
          }
        }
      } else if (
        'response' in res.data &&
        typeof res.data.response === 'object' &&
        res.data.response
      ) {
        if ('entityId' in res.data.response && typeof res.data.response.entityId === 'string') {
          entityId = res.data.response.entityId;
          return entityId;
        }
        if ('profileId' in res.data.response && typeof res.data.response.profileId === 'string') {
          entityId = res.data.response.profileId;
          return entityId;
        }
      }
    }
  }

  return entityId;
};

export function responseDecoder<T>(res: unknown): ResReturnList<T> {
  const resReturn: ResReturnList<T> = {
    count: -1,
    moreAfter: false,
    moreBefore: false,
    page: -1,
    items: []
  };
  if (res && typeof res === 'object' && 'data' in res && res.data) {
    if (typeof res.data === 'object') {
      if ('data' in res.data && typeof res.data.data === 'object') {
        if (res.data.data !== null && 'results' in res.data.data) {
          if (res.data.data.results !== null && typeof res.data.data.results === 'object')
            for (const [key, value] of Object.entries(res.data.data.results)) {
              if (Object.hasOwn(resReturn, key)) {
                resReturn[key] = value;
              }
            }
        }
      }
    }
  }

  return resReturn;
}

export function entityUpdatedResult<T>(res: unknown) {
  if (res && typeof res === 'object' && 'data' in res && res.data) {
    if (typeof res.data === 'object') {
      if ('data' in res.data && typeof res.data.data === 'object' && res.data.data) {
        let response = res.data.data as object;
        if (
          'response' in response &&
          typeof response.response === 'object' &&
          response.response &&
          'data' in response.response
        ) {
          response = response.response.data as Object;
        }
        return response as ResType<T>;
      }
    }
  }
  return undefined;
}

export function readSingle<T>(res: unknown): ResType<T> | undefined {
  const keys = ['acl', 'createdAt', 'entityId', 'entityType', 'version', 'data'];
  if (res && typeof res === 'object' && 'data' in res && res.data) {
    if (typeof res.data === 'object') {
      if ('data' in res.data && typeof res.data.data === 'object' && res.data.data) {
        const response = res.data.data as object;
        const properties = Object.keys(response).filter((r) => keys.includes(r));
        if (properties.length === keys.length) {
          return response as ResType<T>;
        }
      }
    }
  }
  return undefined;
}
export type Response = {
  deliveryConfirmation: ResType<DeliveryConfirmation>[];
  materials: ResType<Material>[];
  projects: ResType<Project>[];
  sites: ResType<Site>[];
  truckingCompanies: ResType<Company>[];
  trucks: ResType<Truck>[];
  users: User[];
  summaries: DashboardSummary[];
  loadTypes: ResType<LoadType>[];
};

const fetchAllKeys = [
  'deliveryConfirmation',
  'materials',
  'projects',
  'provinces',
  'roles',
  'sites',
  'truckingCompanies',
  'trucks',
  'users',
  'summaries',
  'loadTypes'
];

export async function fetchAll(service: NetworkService): Promise<Response> {
  let result;

  try {
    result = await service.post(scriptOP.fetchAll, {});
    if (result && typeof result === 'object' && 'data' in result) {
      const res = result.data as Object;

      if ('data' in res) {
        const resData = res.data as Object;
        if ('response' in resData) {
          const response = resData.response as {};
          const keys = Object.keys(response);

          const isMatch = keys.filter((k) => !fetchAllKeys.find((fak) => fak == k)).length === 0;
          if (isMatch) {
            const data = response as Response;
            return Promise.resolve(data);
          }

          return Promise.reject({ ...defaultReqError, reason_code: -2 });
        }
      }
    }
  } catch (error) {
    const e = error as ReqError;
    if ('reason_code' in e) {
      return Promise.reject(e);
    }
    return Promise.reject({ ...defaultReqError, reason_code: -3 });
  }
  return Promise.reject({ ...defaultReqError, reason_code: -4 });
}

export const loadMapper = (res: unknown, setError: (e: Partial<AppError>) => void): Load[] => {
  if (res && typeof res === 'object' && 'data' in res && res.data) {
    if (typeof res.data === 'object') {
      if ('data' in res.data && res.data.data && typeof res.data.data === 'object') {
        if (
          'reasonCode' in res.data.data &&
          typeof res.data.data.reasonCode === 'number' &&
          res.data.data.reasonCode
        ) {
          const code = res.data.data.reasonCode as unknown as reasonCode;
          if (code === reasonCode.sessionTimeout) {
            setError({ networkError: errorMessage.sessionTimeout });

            return [];
          }
          if (res.data.data.reasonCode !== reasonCode.packactIdError) {
            setError({ networkError: getErrorMessage(res.data.data.reasonCode) });
          }

          return [];
        }
        if (
          'response' in res.data.data &&
          res.data.data.response &&
          typeof res.data.data.response === 'object' &&
          Array.isArray(res.data.data.response)
        ) {
          const loadsRes = res.data.data.response as [] as ResType<Load>[];

          return loadsRes.map((lr) => {
            return {
              entityId: lr.entityId,
              version: lr.version,
              creation: lr.data.creation ?? '',
              departure: lr.data.departure ?? '',
              arrival: lr.data.arrival ?? '',
              loadTypeId: lr.data.loadTypeId ?? '',
              projectId: lr.data.projectId ?? '',
              receivingSiteId: lr.data.receivingSiteId ?? '',
              truckId: lr.data.truckId ?? '',
              loadNumber: lr.data.loadNumber ?? -1,
              pileNumber: lr.data.pileNumber ?? -1,
              operatorId: lr.data.operatorId ?? '',
              qte: lr.data.qte ?? 0,
              isLastLoad: lr.data.isLastLoad ?? false,
              notes: lr.data.notes ?? '',
              delivery_confirmation: lr.data.delivery_confirmation ?? '',
              whoConfirmed: lr.data.whoConfirmed ?? ''
            };
          });
        }
      }
    }
  }
  return [];
};

export const csvFileResponseMapper = (
  result: unknown,
  setError: (e: Partial<AppError>) => void
) => {
  type CsvFileResponse = {
    status: number;
    data: { status: number; data: { response: { files: { name: string; content: string }[] } } };
  };
  let res = result as CsvFileResponse;
  if (
    typeof res === 'object' &&
    typeof res.data === 'object' &&
    typeof res.data.data === 'object' &&
    typeof res.data.data.response === 'object' &&
    typeof res.data.data.response.files === 'object'
  ) {
    return res.data.data.response.files;
  }
  let msg = 'Download Not available';
  console.log(result);
  if (
    typeof res === 'object' &&
    typeof res.data === 'object' &&
    typeof res.data.data === 'object' &&
    typeof res.data.data.response === 'string'
  ) {
    msg = res.data.data.response as string;
  }
  setError({ pageError: msg });
  return null;
};
