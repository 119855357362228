/* eslint-disable prettier/prettier */
import React, { ReactElement, useEffect, useState } from 'react';
import { ContentContainer } from '../component/containers';

import Box from '@mui/material/Box/Box';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import {
  ActionCell,
  Button,
  Caption,
  CellStatus,
  ExportBar,
  SubTitle,
  SubTitle2
} from '../component/atoms';
import { useTheme } from '@mui/material';
import {
  defaultDeliveryConf,
  defaultMaterial,
  DeliveryConfirmation,
  Material,
  rw,
  User
} from '../types';
import styled from 'styled-components';
import { useErrors, useLoading, useService } from '../hooks';

import useCoreState from '../hooks/useCoreState';

import {
  CEOP,
  customEntity,
  errorMessage,
  getErrorMessage,
  postResHelper,
  reasonCode,
  ReqError,
  requestStr,
  responseDecoder,
  responseErrorHelper,
  ResType,
  scriptOP
} from '../network';
import { CoreActionTypes } from '../context';
import { MaterialDeliverModal } from '../component/modals';
import { decontructPackedId } from '../util';
import { lighterBlue } from '../theme';

export enum materialDelivModalHeader {
  addMaterial = 'Add New Material Type',
  addDelivery = 'Add New Delivery Confirmation Type',
  editMaterial = 'Edit Material Type',
  editDelivery = 'Edit Delivery Confirmation Type'
}

const Header = styled.div`
  width: 100%;
  height: 130 px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

type MaterialPageState = {
  count: number;
  dcCount: number;
  materials: Material[];
  delivConfs: DeliveryConfirmation[];
  selectedMaterial: Material;
  selectedDelivConf: DeliveryConfirmation;
  modalOpen: boolean;
  modalLabel: string;
  modalData: {
    entityId: string;
    version: number;
    name: string;
    description: string;
    isActive: boolean;
  };
  modalMode: 'New' | 'Edit';
};

const initMaterialPageState: MaterialPageState = {
  count: 0,
  dcCount: 0,
  materials: [],
  delivConfs: [],
  selectedMaterial: defaultMaterial,
  selectedDelivConf: defaultDeliveryConf,
  modalOpen: false,
  modalLabel: '',
  modalData: defaultMaterial,
  modalMode: 'New'
};

const columns = (
  setMaterialSelected: (selected: Material | DeliveryConfirmation) => void,
  disableMaterial: (selected: Material | DeliveryConfirmation) => void,
  hasPermission: boolean
): GridColDef[] => {
  return [
    { field: 'name', headerName: 'Type Name', width: 150, hideable: false },
    { field: 'description', headerName: 'Description', width: 400, hideable: false },
    {
      field: 'isActive',
      headerName: 'Status',
      filterable: false,
      hideable: false,
      width: 100,
      renderCell: (v) => {
        const data = v.row as Material;
        return <CellStatus isActive={data.isActive} />;
      }
    },
    {
      field: 'Actions',
      width: 100,
      sortable: false,

      disableColumnMenu: true,
      renderCell: (v) => {
        const data = v.row as Material;
        return (
          <ActionCell
            disabled={!hasPermission}
            data={data}
            setEditSelected={setMaterialSelected}
            setDisableSelected={disableMaterial}
          />
        );
      }
    }
  ];
};

const dcColumns = (
  setDeliverConfSelected: (selected: Material | DeliveryConfirmation) => void,
  disableDc: (selected: Material | DeliveryConfirmation) => void,
  hasPermission: boolean
): GridColDef[] => {
  return [
    { field: 'name', headerName: 'Type Name', width: 150, hideable: false },
    { field: 'description', headerName: 'Description', width: 400, hideable: false },
    {
      field: 'isActive',
      headerName: 'Status',
      filterable: false,
      hideable: false,
      width: 100,
      renderCell: (v) => {
        const data = v.row as DeliveryConfirmation;
        return <CellStatus isActive={data.isActive} />;
      }
    },
    {
      field: 'Actions',
      width: 100,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (v) => {
        const data = v.row as DeliveryConfirmation;
        return (
          <ActionCell
            disabled={!hasPermission}
            data={data}
            setEditSelected={setDeliverConfSelected}
            setDisableSelected={disableDc}
          />
        );
      }
    }
  ];
};

export const MaterialConfiguration = (): ReactElement => {
  const { coreState, updateCoreState } = useCoreState();
  const [pageState, setPageState] = useState<MaterialPageState>(initMaterialPageState);
  const hasPermission = coreState.permission.material >= rw;
  const { setLoading } = useLoading();
  const { setError } = useErrors();
  const { service } = useService();

  const initState = async () => {
    try {
      let materials: Material[] = [];
      let deliConfs: DeliveryConfirmation[] = [];

      const materialRes = await service.get(`${customEntity}${CEOP.getEntityPage}Material/`, {
        context: requestStr
      });
      const deliConfRes = await service.get(
        `${customEntity}${CEOP.getEntityPage}DeliveryConfirmation/`,
        {
          context: requestStr
        }
      );
      const materialDecoded = responseDecoder<ResType<Material>>(materialRes);
      const materialLoadError = responseErrorHelper(materialRes);
      const deliConfsDecoded = responseDecoder<ResType<DeliveryConfirmation>>(deliConfRes);
      const deliConfsLoadError = responseErrorHelper(deliConfRes);

      if (materialLoadError) {
        setLoading(false);
        if (
          materialLoadError.status === 403 &&
          materialLoadError.reason_code === reasonCode.packactIdError
        ) {
          const id = decontructPackedId(materialLoadError);
          if (id !== 0) {
            service.setPacketID(id);
            initState();
            return;
          }
          return;
        }

        if (materialLoadError.reason_code === reasonCode.sessionTimeout) {
          setError({ networkError: errorMessage.sessionTimeout });

          return;
        }
        setError({ networkError: materialLoadError.status_message });

        return;
      }
      if (deliConfsLoadError) {
        setLoading(false);
        if (deliConfsLoadError.reason_code === reasonCode.sessionTimeout) {
          setError({ networkError: errorMessage.sessionTimeout });

          return;
        }

        setError({ networkError: deliConfsLoadError.status_message });

        return;
      }

      materials = [
        ...materialDecoded.items.map((m): Material => {
          return {
            entityId: m.entityId,
            version: m.version,
            name: m.data.name,
            description: m.data.description,
            isActive: m.data.isActive
          };
        })
      ];

      deliConfs = [
        ...deliConfsDecoded.items.map((d): DeliveryConfirmation => {
          return {
            entityId: d.entityId,
            version: d.version,
            name: d.data.name,
            description: d.data.description,
            isActive: d.data.isActive
          };
        })
      ];
      updateCoreState({
        type: CoreActionTypes.SET_MATERIAL_DELIV,
        payload: { ...coreState, materials, deliveryConf: deliConfs }
      });

      setPageState({
        ...pageState,
        count: materialDecoded.count,
        dcCount: deliConfsDecoded.count,
        materials,
        delivConfs: deliConfs,
        modalData: defaultMaterial,
        modalOpen: false
      });
      setLoading(false);
    } catch (e) {
      if (e && typeof e === 'object') {
        const error = e as ReqError;
        if (error && error.reason_code !== reasonCode.packactIdError) {
          const errorMessage = getErrorMessage(error.reason_code);
          setError({ networkError: errorMessage });
        }
      }
    }
  };

  useEffect(() => {
    setLoading(true);
    initState();
  }, []);

  useEffect(() => {
    setLoading(true);
    setPageState({
      ...pageState,
      materials: coreState.materials,
      delivConfs: coreState.deliveryConf
    });
    setLoading(false);
  }, [coreState.materials, coreState.deliveryConf]);

  function setMaterialSelected(selected: Material | DeliveryConfirmation): void {
    const selectedMaterial = pageState.materials.find((t) => t.entityId === selected.entityId);
    if (selectedMaterial)
      setPageState({
        ...pageState,
        selectedMaterial,
        modalOpen: true,
        modalData: selectedMaterial,
        modalMode: 'Edit',
        modalLabel: materialDelivModalHeader.editMaterial
      });
  }
  function setDeliverConfSelected(selected: Material | DeliveryConfirmation): void {
    const selectedDelivConf = pageState.delivConfs.find((t) => t.entityId === selected.entityId);
    if (selectedDelivConf)
      setPageState({
        ...pageState,
        selectedDelivConf,
        modalOpen: true,
        modalData: selectedDelivConf,
        modalMode: 'Edit',
        modalLabel: materialDelivModalHeader.editDelivery
      });
  }

  async function disableMaterial(selected: Material | DeliveryConfirmation) {
    const s = selected as Material;

    try {
      const materialRes = await service.post(scriptOP.updateEntity, {
        dataJson: {
          name: s.name,
          description: s.description,
          isActive: !s.isActive
        },
        entityType: 'Material',
        acl: { other: 2 },
        entityId: s.entityId,
        version: s.version
      });
      const id = postResHelper(materialRes);

      if (id) {
        const updatedMaterials = pageState.materials.map((m) => {
          if (m.entityId === selected.entityId) {
            return { ...m, isActive: !m.isActive, version: m.version + 1 };
          }

          return m;
        });

        setPageState({ ...pageState, materials: [...updatedMaterials] });
      }
    } catch (e) {
      setError({ networkError: errorMessage.somethingWrong });
    }
  }

  async function disableDc(selected: Material | DeliveryConfirmation | User) {
    //disable
    const s = selected as DeliveryConfirmation;

    try {
      const dcRes = await service.post(scriptOP.updateEntity, {
        dataJson: {
          name: s.name,
          description: s.description,
          isActive: !s.isActive
        },
        entityType: 'DeliveryConfirmation',
        acl: { other: 2 },
        entityId: s.entityId,
        version: s.version
      });
      const id = postResHelper(dcRes);

      if (id) {
        const updatedDCs = pageState.delivConfs.map((m) => {
          if (m.entityId === s.entityId) {
            return { ...m, isActive: !m.isActive, version: m.version + 1 };
          }

          return m;
        });

        setPageState({ ...pageState, delivConfs: [...updatedDCs] });
      }
    } catch (e) {
      if (e && typeof e === 'object') {
        const error = e as ReqError;
        if (error && error.reason_code !== reasonCode.packactIdError) {
          const errorMessage = getErrorMessage(error.reason_code);
          setError({ networkError: errorMessage });
        }
      }
    }
  }

  const handleModalClose = (): void => {
    setPageState({
      ...pageState,
      selectedDelivConf: defaultDeliveryConf,
      selectedMaterial: defaultMaterial,
      modalData: defaultMaterial,
      modalOpen: false
    });
    initState();
  };

  const handleAddMaterial = (): void => {
    setPageState({
      ...pageState,
      modalOpen: true,
      modalLabel: materialDelivModalHeader.addMaterial,
      modalMode: 'New'
    });
  };

  const handleAddDelivConf = (): void => {
    setPageState({
      ...pageState,
      modalOpen: true,
      modalLabel: materialDelivModalHeader.addDelivery,
      modalMode: 'New'
    });
  };
  const theme = useTheme();

  return (
    <ContentContainer header title="Material Configuration">
      <Caption> NOTE: Configuration of Load Types is done on individual Project pages</Caption>
      <SubTitle2 style={{ margin: 0, marginTop: theme.spacing(4), marginBottom: theme.spacing(1) }}>
        Configure which Material Types are available to identify Load Types used in Projects.
      </SubTitle2>
      <Box
        sx={{
          height: '55%',
          width: '100%',
          minHeight: '430px',
          backgroundColor: 'white',
          padding: 2,
          borderRadius: '15px',
          boxShadow: theme.shadows[1]
        }}>
        <Header>
          <SubTitle>Material Types</SubTitle>
          {hasPermission && (
            <Button
              variant="outlined"
              size="normal"
              styles={{ width: 185 }}
              label="Add Material Type"
              color="secondary"
              onClick={handleAddMaterial}
            />
          )}
        </Header>
        <Box
          sx={{
            height: '100%',
            width: '100%',
            minHeight: '390px',
            '& .data-grid-row-blue': {
              bgcolor: `${lighterBlue}`,
              '&: hover': {
                '&: .MuiDataGrid-cell': { bgcolor: `rgba(22,153,213,0.3)` },
                bgcolor: `rgba(22,153,213,0.3)`
              }
            }
          }}>
          <DataGrid
            className={`#export-materials`} //how we get the file name for export donot delete or change format
            getRowClassName={(p) => {
              if (p.indexRelativeToCurrentPage % 2 === 1) {
                return `data-grid-row-blue`;
              } else {
                return `data-grid-row-white`;
              }
            }}
            columns={columns(setMaterialSelected, disableMaterial, hasPermission)}
            rows={pageState.materials}
            autoHeight
            pageSize={4}
            rowsPerPageOptions={[4]}
            checkboxSelection={false}
            disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: true }}
            components={{ Toolbar: ExportBar }}
            getRowId={(row) => row.entityId}
          />
        </Box>
      </Box>
      <SubTitle2 style={{ margin: 0, marginTop: theme.spacing(4), marginBottom: theme.spacing(1) }}>
        Configure which Confirmation Types are available to define Delivery Confirmation of loads to
        Receiving Sites.
      </SubTitle2>
      <Box
        sx={{
          height: '55%',
          width: '100%',
          backgroundColor: 'white',
          padding: 2,
          borderRadius: '15px',
          boxShadow: theme.shadows[1],
          minHeight: '430px'
        }}>
        <Header>
          <SubTitle>Delivery Confirmation Types</SubTitle>
          {hasPermission && (
            <Button
              variant="outlined"
              size="normal"
              styles={{ width: 185 }}
              label="Add Confirmation Type"
              color="secondary"
              onClick={handleAddDelivConf}
            />
          )}
        </Header>
        <Box
          sx={{
            height: '100%',
            width: '100%',
            minHeight: '390px',
            '& .data-grid-row-blue': {
              bgcolor: `${lighterBlue}`,
              '&: hover': {
                '&: .MuiDataGrid-cell': { bgcolor: `rgba(22,153,213,0.3)` },
                bgcolor: `rgba(22,153,213,0.3)`
              }
            }
          }}>
          <DataGrid
            className={`#export-delivery-confirmation`} //how we get the file name for export donot delete or change format
            getRowClassName={(p) => {
              if (p.indexRelativeToCurrentPage % 2 === 1) {
                return `data-grid-row-blue`;
              } else {
                return `data-grid-row-white`;
              }
            }}
            columns={dcColumns(setDeliverConfSelected, disableDc, hasPermission)}
            rows={pageState.delivConfs}
            autoHeight
            pageSize={4}
            rowsPerPageOptions={[4]}
            checkboxSelection={false}
            disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: true }}
            components={{ Toolbar: ExportBar }}
            getRowId={(row) => row.entityId}
          />
        </Box>
      </Box>
      <MaterialDeliverModal
        open={pageState.modalOpen}
        label={pageState.modalLabel}
        handleClose={handleModalClose}
        mode={pageState.modalMode}
        data={pageState.modalData}
      />
    </ContentContainer>
  );
};
