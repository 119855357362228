import { useTheme } from '@mui/material';
import React, { ReactElement } from 'react';
import { AiFillCheckCircle, AiFillWarning } from 'react-icons/ai';
import styled from 'styled-components';
import { Button, IconButton, SubTitle } from '../atoms';
import { ModalLoadingContainer } from './LoadingContainer';
import CloseIcon from '@mui/icons-material/Close';
export const defaultNotificationState = { open: false, success: false, message: '' };
export const NotificationPopup = (props: {
  isSuccess: boolean;
  message: string;
  open: boolean;
  borderRadius?: number;
  actionable?: boolean;
  title?: string;
  handleCancel?: () => void;
  handleConfirm?: () => void;
}): ReactElement => {
  const theme = useTheme();

  return props.open ? (
    <ModalLoadingContainer
      id="ModalLoadingContainer"
      style={{
        borderRadius: props.borderRadius ? props.borderRadius : 15
      }}>
      <FeedbackContainer style={{ height: props.actionable ? 200 : 150 }}>
        <Header style={{ justifyContent: props.title ? 'space-between' : 'flex-end' }}>
          {props.title && (
            <SubTitle style={{ marginLeft: 0, color: theme.palette.background.paper }}>
              {props.title}
            </SubTitle>
          )}
          {props.handleCancel && (
            <IconButton
              label="Close"
              onClick={() => {
                if (props.handleCancel) {
                  props.handleCancel();
                }
              }}>
              <CloseIcon style={{ color: theme.palette.background.paper }} />
            </IconButton>
          )}
        </Header>
        {props.actionable ? (
          <ActionableContent>
            <SubTitle style={{ marginLeft: 0 }}>{props.message}</SubTitle>
            <div
              style={{
                width: '100%',
                height: 40,
                display: 'flex',
                padding: '0 32px',
                justifyContent: 'space-around',
                alignItems: 'center'
              }}>
              <Button
                label="No"
                variant="outlined"
                color="secondary"
                size="normal"
                onClick={() => {
                  if (props.handleCancel) {
                    props.handleCancel();
                  }
                }}
              />
              <Button
                label="Yes"
                variant="contained"
                color="secondary"
                size="normal"
                onClick={() => {
                  if (props.handleConfirm) {
                    props.handleConfirm();
                  }
                }}
              />
            </div>
          </ActionableContent>
        ) : (
          <NotificationContent>
            {props.isSuccess ? (
              <AiFillCheckCircle size={50} color={theme.palette.success.main} />
            ) : (
              <AiFillWarning size={50} color={theme.palette.error.main} />
            )}
            <SubTitle style={{ marginLeft: 8 }}>{props.message}</SubTitle>
          </NotificationContent>
        )}
      </FeedbackContainer>
    </ModalLoadingContainer>
  ) : (
    <></>
  );
};

const FeedbackContainer = styled.div`
  height: 150px;
  width: 500px;
  padding: 5px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.background.paper};
`;

const Header = styled.div`
  height: 40px;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  background-color: ${({ theme }) => theme.palette.primary.main};
  display: flex;

  align-items: center;
  padding: 0px 8px;
`;

const NotificationContent = styled.div`
  height: 110px;
  width: 100%;
  position: absolute;
  top: 40px;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  text-align: left;
`;

const ActionableContent = styled.div`
  height: 160px;
  width: 100%;
  position: absolute;
  top: 40px;
  left: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 32px;
  text-align: left;
`;
