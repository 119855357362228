import React, { ReactElement } from 'react';

import bg from '../../asset/noData.png';
import { SubTitle } from '../../component/atoms/text';
import noLoadtype from '../../asset/LoadType_empty.png';
export const NoData = ({ label }: { label?: string }): ReactElement => {
  return (
    <div
      style={{
        height: '100%',
        width: 'auto',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexDirection: 'column'
      }}>
      <img src={bg} style={{ width: 'auto', height: '60%' }} />
      <SubTitle style={{ margin: 0 }}>{label ?? 'No data'}</SubTitle>
    </div>
  );
};

export const NoLoadtype = (): ReactElement => {
  return (
    <div
      style={{
        height: '100%',
        width: 'auto',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexDirection: 'column'
      }}>
      <img src={noLoadtype} height={200} width={200} />
      <SubTitle style={{ margin: 0 }}>{'No Load Type'}</SubTitle>
    </div>
  );
};
