/* eslint-disable prettier/prettier */
import React, { ReactElement, useEffect, useState } from 'react';
import { ContentContainer } from '../component/containers';
import Box from '@mui/material/Box/Box';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { ExportBar, SubTitle, SubTitle2, Truncation } from '../component/atoms';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  useTheme
} from '@mui/material';
import { Activity, defaultProject, defaultUser, Project, rw, User } from '../types';
import styled from 'styled-components';
import useCoreState from '../hooks/useCoreState';
import { timezone, format } from '../util';
import { NoData } from './staticPages';
import { useErrors, useService } from '../hooks';
import { getErrorMessage, getUser, reasonCode, ReqError, scriptOP } from '../network';
import { decontructPackedId } from '../util';
import dayjs from 'dayjs';
import { lighterBlue } from '../theme';

const Header = styled.div`
  width: 100%;
  height: 130 px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const FilterContainer = styled.div`
  width: 60%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 15px;
`;

type ActivityResponse = {
  count: number;
  moreAfter: boolean;
  moreBefore: boolean;
  page: number;
  items: Activity[];
};

type ActivityDisplay = {
  date: string;
  entityId: string;
  userName: string;
  projectName: string;
  action: string;
  subject: string;
};

type PageState = {
  projectFilters: Project[];
  userFilters: User[];
  activitiesD: ActivityDisplay[];
  allActivities: Activity[];
  isModalOpen: boolean;
  isGridLoading: boolean;
};

const defaultPaginationModal = {
  page: 0,
  moreAfter: false,
  moreBefore: false
};
const defaultPageState = {
  userFilters: [{ ...defaultUser, firstName: 'All', profileId: '-1' }],
  projectFilters: [{ ...defaultProject, name: 'All', entityId: '-1' }],
  activitiesD: [],
  allActivities: [],
  isModalOpen: false,
  isGridLoading: false
};

const columns = (): GridColDef[] => {
  return [
    { field: 'date', headerName: 'Date/Time', width: 150 },
    {
      field: 'userName',
      headerName: 'User',
      width: 150
    },

    {
      field: 'projectName',
      headerName: 'Project',
      type: 'string',
      width: 150
    },
    {
      field: 'action',
      headerName: 'Action',
      type: 'string',
      width: 150
    },
    {
      field: 'subject',
      headerName: 'Details',
      type: 'string',
      minWidth: 550,

      renderCell: (v) => {
        const data = v.row as ActivityDisplay;

        return (
          <Truncation label={data.subject} maxLength={38}>
            <SubTitle2 style={{ fontWeight: 'normal', fontSize: 14 }}>{data.subject}</SubTitle2>
          </Truncation>
        );
      }
    }
  ];
};

export const ActivityLog = (): ReactElement => {
  const { coreState } = useCoreState();
  const { setError } = useErrors();
  const { service } = useService();
  const [pageState, setPageState] = useState<PageState>(defaultPageState);
  const [paginationModel, setPaginationModel] = useState(defaultPaginationModal);
  const [rowCount, setRowCount] = useState(0);
  const hasPermission = coreState.permission.activity >= rw;
  const [selectedProject, setSelectedProject] = useState<Project>({
    ...defaultProject,
    name: 'All',
    entityId: '-1'
  });
  const [selectedUser, setSelectedUser] = useState<User>({
    ...defaultUser,
    firstName: 'All',
    profileId: '-1'
  });
  const theme = useTheme();

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (rowCount !== 0) {
      //rowCount === 0 means it's a intial load, shouldn't fetch more pages
      fetchPage(selectedUser.profileId ?? undefined, selectedProject.entityId ?? undefined);
    }
  }, [paginationModel.page]);

  const fetchPage = async (profileId?: string, projectId?: string) => {
    setPageState({
      ...pageState,
      isGridLoading: true
    });
    let searchCriteria = {};

    let pageNumber = paginationModel.page + 1;

    if (profileId && profileId !== '-1') {
      searchCriteria = { ...searchCriteria, 'data.profileId': profileId };
    }

    if (projectId && projectId !== '-1') {
      searchCriteria = { ...searchCriteria, 'data.projectId': projectId };
    }

    try {
      const res = await service.post(scriptOP.listActivity, {
        context: {
          pagination: {
            pageNumber: pageNumber,
            rowsPerPage: 15,
            doCount: true
          },
          searchCriteria,
          sortCriteria: {}
        }
      });

      const response = activityLogDecoder(res);

      if (response.reasonCode !== -1 && response.reasonCode !== reasonCode.packactIdError) {
        setError({ networkError: getErrorMessage(response.reasonCode) });
        setPageState({
          ...pageState,
          isGridLoading: false
        });

        return;
      }

      setPaginationModel({
        ...paginationModel,
        moreBefore: response.moreBefore,
        moreAfter: response.moreAfter
      });

      setRowCount(response.count);

      const activitiesD: ActivityDisplay[] = response.items.map((a): ActivityDisplay => {
        return {
          date: dayjs(a.date).tz(timezone, true).format(format).toString(),
          userName: a.userName,
          projectName: a.projectName,
          entityId: a.date + Math.random(),
          action: a.action,
          subject: a.subject
        };
      });

      setPaginationModel({
        ...paginationModel,
        moreAfter: response.moreAfter,
        moreBefore: response.moreBefore
      });

      setPageState({
        ...pageState,
        allActivities: [...response.items],
        activitiesD,

        isGridLoading: false
      });
    } catch (e) {
      if (e && typeof e === 'object') {
        const error = e as ReqError;
        if (error && error.reason_code !== reasonCode.packactIdError) {
          const errorMessage = getErrorMessage(error.reason_code);
          setError({ networkError: errorMessage });
        }

        setPageState({
          ...pageState,
          isGridLoading: false
        });
      }
    }
  };

  const init = async () => {
    setPageState({
      ...pageState,
      isGridLoading: true
    });
    try {
      let searchCriteria = {};

      if (!hasPermission) {
        if (coreState.appUser.profileId === '') {
          const res = await getUser(service);

          if (res) {
            searchCriteria = {
              ...searchCriteria,
              'data.profileId': res.profileId
            };
          }
        } else {
          searchCriteria = {
            ...searchCriteria,
            'data.profileId': coreState.appUser.profileId
          };
        }
      }
      const res = await service.post(scriptOP.listActivity, {
        context: {
          pagination: {
            pageNumber: 1,
            rowsPerPage: 15,
            doCount: true
          },
          searchCriteria,
          sortCriteria: {}
        }
      });
      const response = activityLogDecoder(res);

      if (response.reasonCode !== -1) {
        setError({ networkError: getErrorMessage(response.reasonCode) });
        setPageState({
          ...pageState,
          isGridLoading: false
        });
        return;
      }

      const activitiesD: ActivityDisplay[] = response.items.map((a): ActivityDisplay => {
        return {
          date: dayjs(a.date).tz(timezone, true).format(format).toString(),
          userName: a.userName,
          projectName: a.projectName,
          entityId: a.date + Math.random(),
          action: a.action,
          subject: a.subject
        };
      });

      setPaginationModel({
        page: 0,
        moreAfter: response.moreAfter,
        moreBefore: response.moreBefore
      });

      setPageState({
        ...pageState,
        allActivities: [...response.items],
        activitiesD,
        projectFilters: [{ ...defaultProject, name: 'All', entityId: '-1' }, ...coreState.projects],
        userFilters: [{ ...defaultUser, firstName: 'All', profileId: '-1' }, ...coreState.users],
        isGridLoading: false
      });
      setRowCount(response.count);
    } catch (e) {
      if (e && typeof e === 'object') {
        const error = e as ReqError;

        if (error && error.reason_code !== reasonCode.packactIdError) {
          if (error.reason_code === reasonCode.packactIdError) {
            const id = decontructPackedId(error);

            if (id !== 0) {
              service.setPacketID(id);
              init();
              return;
            }
          }
          const errorMessage = getErrorMessage(error.reason_code);
          setError({ networkError: errorMessage });
        }
      }
    }
  };

  const activityLogDecoder = (
    res: unknown
  ): {
    count: number;
    moreAfter: boolean;
    moreBefore: boolean;
    page: number;
    items: Activity[];
    reasonCode: number;
  } => {
    let result = {
      count: 0,
      moreAfter: false,
      moreBefore: false,
      page: 0,
      items: [],
      reasonCode: -1
    };
    const keys = ['count', 'items', 'moreAfter', 'moreBefore', 'page'];
    let hasAllKey = true;

    if (
      res &&
      typeof res === 'object' &&
      'data' in res &&
      res.data &&
      typeof res.data === 'object' &&
      'data' in res.data &&
      res.data.data &&
      typeof res.data.data === 'object'
    ) {
      if (
        'reasonCode' in res.data.data &&
        res.data.data.reasonCode &&
        typeof res.data.data.reasonCode === 'number'
      ) {
        return { ...result, reasonCode: res.data.data.reasonCode };
      }
      if (
        'response' in res.data.data &&
        res.data.data.response &&
        typeof res.data.data.response === 'object'
      ) {
        if (Array.isArray(res.data.data.response)) {
          const response: Activity[] = res.data.data.response;
          return {
            count: 0,
            moreAfter: false,
            moreBefore: false,
            page: 0,
            items: [...response],
            reasonCode: -1
          };
        }

        const response: ActivityResponse = res.data.data.response as ActivityResponse;

        keys.forEach((k) => {
          if (k in response === false) {
            hasAllKey = false;
          }
        });
        if (hasAllKey) {
          return { ...response, reasonCode: -1 };
        }
      }
    }

    return result;
  };

  const handleChange = (event: SelectChangeEvent) => {
    const project = pageState.projectFilters.find((p) => p.entityId === event.target.value);
    if (project) {
      setSelectedProject(project);
    }
    if (event.target.value === '-1' && selectedUser.profileId === '-1') {
      init();
      return;
    } else {
      fetchPage(selectedUser.profileId, event.target.value);
      return;
    }
  };

  const handleChangeUserFilter = (event: SelectChangeEvent) => {
    const user = pageState.userFilters.find((u) => u.profileId === event.target.value);
    if (user) {
      setSelectedUser(user);
    }
    if (event.target.value === '-1' && selectedProject.entityId === '-1') {
      init();
      return;
    } else {
      fetchPage(user?.profileId, selectedProject.entityId);
      return;
    }
  };

  return (
    <ContentContainer title="Activity Logs" header>
      <Box
        sx={{
          height: 1100,
          minHeight: '1100px',
          width: '100%',
          backgroundColor: 'white',
          padding: 2,
          borderRadius: '15px',
          boxShadow: theme.shadows[1]
        }}>
        <Header>
          <FilterContainer>
            <div>
              <SubTitle style={{ alignSelf: 'flex-start', margin: 0 }}>
                Filter View By User
              </SubTitle>
              <FormControl
                variant="filled"
                disabled={pageState.allActivities.length === 0 && rowCount === 0}
                sx={{
                  m: 1,
                  minWidth: 300,
                  '& .MuiFilledInput-root': {
                    backgroundColor: 'rgba(244, 245, 253, 0.6)',
                    height: '48px'
                  }
                }}>
                <InputLabel>Users</InputLabel>
                <Select
                  value={selectedUser.profileId}
                  onChange={handleChangeUserFilter}
                  disabled={!hasPermission}>
                  {pageState.userFilters.map((u) => (
                    <MenuItem value={u.profileId} key={u.profileId}>
                      {u.firstName + ' ' + u.lastName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div>
              <SubTitle style={{ alignSelf: 'flex-start', margin: 0 }}>
                Filter View By Project
              </SubTitle>
              <FormControl
                variant="filled"
                disabled={pageState.allActivities.length === 0 && rowCount === 0}
                sx={{
                  m: 1,
                  minWidth: 300,
                  '& .MuiFilledInput-root': {
                    backgroundColor: 'rgba(244, 245, 253, 0.6)',
                    height: '48px'
                  }
                }}>
                <InputLabel>Projects</InputLabel>
                <Select
                  value={selectedProject.entityId}
                  onChange={handleChange}
                  renderValue={(value) => {
                    return pageState.projectFilters.find((p) => p.entityId === value)?.name;
                  }}>
                  {pageState.projectFilters.map((p) => (
                    <MenuItem value={p.entityId} key={p.entityId}>
                      {p.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </FilterContainer>
        </Header>
        {pageState.allActivities.length === 0 ? (
          <NoData />
        ) : (
          <Box
            sx={{
              height: '100%',
              width: '100%',
              minHeight: '1000px',
              '& .data-grid-row-blue': {
                bgcolor: `${lighterBlue}`,
                '&: hover': {
                  '&: .MuiDataGrid-cell': { bgcolor: `rgba(22,153,213,0.3)` },
                  bgcolor: `rgba(22,153,213,0.3)`
                }
              }
            }}>
            <DataGrid
              className={`#export-activity-logs`} //how we get the file name for export donot delete or change format
              initialState={{ pagination: { ...defaultPaginationModal } }}
              getRowClassName={(p) => {
                if (p.indexRelativeToCurrentPage % 2 === 1) {
                  return `data-grid-row-blue`;
                } else {
                  return `data-grid-row-white`;
                }
              }}
              loading={pageState.isGridLoading}
              columns={columns()}
              rows={pageState.activitiesD}
              paginationMode="server"
              page={paginationModel.page}
              onPageChange={(page) => {
                setPaginationModel({ ...paginationModel, page });
              }}
              rowCount={rowCount}
              pageSize={15}
              autoHeight
              rowsPerPageOptions={[15]}
              checkboxSelection={false}
              disableSelectionOnClick
              experimentalFeatures={{ newEditingApi: true }}
              components={{ Toolbar: ExportBar }}
              getRowId={(row) => row.entityId}
            />
          </Box>
        )}
      </Box>
    </ContentContainer>
  );
};
