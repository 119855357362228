//set loading state within the content box (does not include the login page)
import React, { useContext } from 'react';
import { CoreActionTypes } from '../context';
import { CoreContext } from '../context/coreContext';

export const useLoading = (): { isLoading: boolean; setLoading: (state: boolean) => void } => {
  const { coreState, updateCoreState } = useContext(CoreContext);

  const setLoading = (state: boolean): void => {
    updateCoreState({
      type: CoreActionTypes.SET_LOADING,
      payload: { ...coreState, isLoading: state }
    });
  };
  return { isLoading: coreState.isLoading, setLoading };
};
