import React, { ReactElement, useEffect } from 'react';
import { AiFillFolderOpen, AiFillFolder } from 'react-icons/ai';
import { MdDashboard, MdLocationPin } from 'react-icons/md';
import { HiUsers } from 'react-icons/hi';
import { RxCounterClockwiseClock } from 'react-icons/rx';
import { FaCubes, FaTruck } from 'react-icons/fa';
import {
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  styled,
  Theme,
  CSSObject,
  List,
  Collapse
} from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import MuiDrawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';

import { useLocation, useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material';
import useCoreState from '../../hooks/useCoreState';
import { Caption, Truncation } from '../atoms';

type MenuItemData = {
  label: string;
  icon: JSX.Element;
  to: string;
  items?: { name: string; path: string };
};

const drawerWidth = 240;
const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden'
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`
  }
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    '& 	.MuiDrawer-paper': { top: 60, height: 'calc(100% - 60px)' },
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme)
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme)
    })
  })
);

export const menuItems: MenuItemData[] = [
  { label: 'Dashboard', icon: <MdDashboard style={{ height: 24, width: 24 }} />, to: '/' },
  { label: 'Active Projects', icon: <AiFillFolder style={{ height: 24, width: 24 }} />, to: '' },
  { label: 'Trucks', icon: <FaTruck style={{ height: 24, width: 24 }} />, to: '/trucks' },
  {
    label: 'Material Configuration',
    icon: <FaCubes style={{ height: 24, width: 24 }} />,
    to: '/material'
  },
  {
    label: 'Receiving Sites',
    icon: <MdLocationPin style={{ height: 24, width: 24 }} />,
    to: '/receivingSites'
  },
  { label: 'Team Management', icon: <HiUsers style={{ height: 24, width: 24 }} />, to: '/users' },
  {
    label: 'Activity Logs',
    icon: <RxCounterClockwiseClock style={{ height: 24, width: 24 }} />,
    to: '/activityLogs'
  }
];

export const SideMenu = ({
  updateMenuState
}: {
  updateMenuState: (state: boolean) => void;
}): ReactElement => {
  const [open, setOpen] = React.useState(false);
  const [projectlistOpen, setListOpen] = React.useState(false);
  const { coreState } = useCoreState();
  const [activeProject, setActiveProject] = React.useState([
    ...coreState.projects
      .filter((p) => p.isActive)
      .sort((x, y) => {
        if (x.startDate > y.startDate) return -1;
        if (x.startDate < y.startDate) return 1;
        else return 0;
      })
  ]);

  const navigate = useNavigate();

  const { pathname } = useLocation();
  const theme = useTheme();

  //const projectlist = activeProject.length > 6 ? activeProject.slice(0, 6) : activeProject;
  const truncationlength = 15;

  useEffect(() => {
    setActiveProject([
      ...coreState.projects
        .filter((p) => p.isActive)
        .sort((x, y) => {
          if (x.startDate > y.startDate) return -1;
          if (x.startDate < y.startDate) return 1;
          else return 0;
        })
    ]);
  }, [coreState.projects]);

  return (
    <Drawer variant="permanent" open={open} id="side-drawer">
      <IconButton
        onClick={() => {
          if (open) {
            setListOpen(false);
          }
          updateMenuState(!open);
          setOpen(!open);
        }}
        sx={{ height: 50, width: 50, marginLeft: `10px !important` }}>
        <MenuIcon sx={{ height: 32, width: 32 }} />
      </IconButton>
      <List id="side-bar-list">
        {menuItems.map((item) => {
          if (item.label === 'Active Projects') {
            return (
              <div
                key={item.label}
                style={{
                  borderBottom: projectlistOpen ? `1px solid ${theme.palette.primary.main}` : 'none'
                }}>
                <ListItem disablePadding sx={{ display: 'block' }}>
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5,
                      backgroundColor: theme.palette.background.paper,
                      color: theme.palette.primary.main,
                      '&: hover': {
                        bgcolor: theme.palette.primary.light
                      }
                    }}
                    onClick={() => {
                      updateMenuState(true);
                      setOpen(true);
                      setListOpen(!projectlistOpen);
                    }}>
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center',
                        color: theme.palette.primary.main
                      }}>
                      {projectlistOpen ? (
                        <AiFillFolderOpen style={{ height: 24, width: 24 }} />
                      ) : (
                        <AiFillFolder style={{ height: 24, width: 24 }} />
                      )}
                    </ListItemIcon>
                    <ListItemText primary={item.label} sx={{ opacity: open ? 1 : 0 }} />
                    {open && projectlistOpen ? (
                      <ExpandLess />
                    ) : open && !projectlistOpen ? (
                      <ExpandMore />
                    ) : (
                      <></>
                    )}
                  </ListItemButton>
                </ListItem>
                <Collapse
                  key={'collapse'}
                  in={projectlistOpen}
                  timeout="auto"
                  unmountOnExit
                  sx={{ borderBottom: '1px sold yellow' }}>
                  <List component="div" disablePadding>
                    {activeProject.map((p) => {
                      let label = p.name;
                      const path = `/project/${p.name}`.replace(/(\s)/g, '%20');

                      if (label.length > truncationlength) {
                        label = `${label.slice(0, truncationlength - 2)}...`;
                      }

                      return (
                        <ListItemButton
                          sx={{
                            backgroundColor:
                              pathname === path
                                ? theme.palette.primary.main
                                : theme.palette.background.paper,
                            color:
                              pathname === path
                                ? theme.palette.background.paper
                                : theme.palette.primary.main,

                            '&: hover': {
                              bgcolor:
                                pathname === path
                                  ? theme.palette.secondary.dark
                                  : theme.palette.primary.light
                            },
                            '& .MuiListItemText-root': {
                              paddingLeft: theme.spacing(8)
                            }
                          }}
                          key={p.entityId}
                          onClick={() => {
                            navigate(`/project/${p.name}`, { state: { entityId: p.entityId } });
                          }}>
                          <Truncation label={p.name} maxLength={truncationlength}>
                            <ListItemText primary={label} sx={{ opacity: open ? 1 : 0 }} />
                          </Truncation>
                        </ListItemButton>
                      );
                    })}
                  </List>
                </Collapse>
              </div>
            );
          }
          return (
            <ListItem key={item.label} disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                  backgroundColor:
                    pathname === item.to
                      ? theme.palette.primary.main
                      : theme.palette.background.paper,
                  color:
                    pathname === item.to
                      ? theme.palette.background.paper
                      : theme.palette.primary.main,
                  '&: hover': {
                    bgcolor:
                      pathname === item.to
                        ? theme.palette.secondary.dark
                        : theme.palette.primary.light
                  }
                }}
                onClick={() => {
                  navigate(item.to);
                  setListOpen(false);
                }}>
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                    color:
                      pathname === item.to
                        ? theme.palette.background.paper
                        : theme.palette.primary.main
                  }}>
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.label} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>

      {open && !projectlistOpen && (
        <Caption
          style={{
            color: theme.palette.primary.main,
            position: 'absolute',
            bottom: 0,
            left: 15
          }}>
          version: {process.env.REACT_APP_VERSION}
        </Caption>
      )}
    </Drawer>
  );
};
