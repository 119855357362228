import React, { ReactElement, useState, useEffect } from 'react';
import { useTheme, Checkbox } from '@mui/material';
import styled from 'styled-components';
import { lightGrey, lightPurple } from '../../theme';
import { SubTitle2 } from './text';

export type MultiSelectItem = {
  name: string;
  id: string;
  selected: boolean;
};

type Props = {
  list: MultiSelectItem[];
  disabled?: boolean;
  handleClick: (value: string) => void;
};

export const MultiSelector = ({ list, handleClick, disabled }: Props): ReactElement => {
  const theme = useTheme();
  const [options, setOptions] = useState<MultiSelectItem[]>(list);
  useEffect(() => {
    setOptions([...list]);
  }, [list]);

  return (
    <SelectContainer>
      {options.map((l) => {
        return (
          <SelectOption
            id="option"
            key={l.id}
            onClick={() => {
              if (disabled) return;
              handleClick(l.id);
            }}
            style={{
              backgroundColor: l.selected ? theme.palette.secondary.main : lightPurple
            }}
            value={l.name}>
            <Checkbox
              checked={l.selected}
              style={{ color: l.selected ? 'white' : 'black' }}
              disabled={disabled === true}
            />
            <SubTitle2 style={{ color: l.selected ? 'white' : 'black' }}>{l.name}</SubTitle2>
          </SelectOption>
        );
      })}
    </SelectContainer>
  );
};

const SelectContainer = styled.ul`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-color: ${lightPurple};
  padding-left: 0px;
`;

const SelectOption = styled.li`
  height: 40px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  &: hover {
    background-color: ${lightGrey} !important;
  }
`;
