import { ResType } from '../network';
import { BaseType } from './users';

export interface LoadType extends BaseType {
  name: string;
  material_name: string;
  qte: number;
  qte_type: string; //measurement unit
  special_requirements: string;
  isPile: boolean;
  zone_name: string;
  projectId: string;
  notes?: string;
  isActive: boolean;
}

export const loadTypeFrom = (l: ResType<LoadType>): LoadType => {
  return {
    entityId: l.entityId,
    version: l.version,
    name: l.data.name,
    material_name: l.data.material_name,
    qte: l.data.qte,
    qte_type: l.data.qte_type,
    special_requirements: l.data.special_requirements,
    isPile: l.data.isPile,
    projectId: l.data.projectId,
    zone_name: l.data.zone_name,
    notes: l.data.notes,
    isActive: l.data.isActive
  };
};

export const dataFromLoadType = (l: LoadType) => {
  return {
    name: l.name,
    special_requirements: l.special_requirements,
    material_name: l.material_name,
    qte: l.qte,
    qte_type: l.qte_type,
    isPile: true,
    projectId: l.projectId,
    zone_name: l.name,
    notes: l.notes,
    isActive: l.isActive
  };
};
