import React, { ReactElement, useState } from 'react';
import Box from '@mui/material/Box';

import { animated, useSpring } from '@react-spring/web';
import { useNavigate } from 'react-router-dom';

import useCoreState from '../../hooks/useCoreState';
import styled from 'styled-components';
import { CircleProgress, Button, SubTitle2, LinearProgressBar, CellStatus } from '../atoms';
import { DashboardProject } from '../../page';

const AnimatedBox = animated(Box);

export const DashboardDropdown = ({ project }: { project: DashboardProject }): ReactElement => {
  const navigate = useNavigate();
  const { coreState } = useCoreState();
  const [isExpand, setExpand] = useState<boolean>(false);

  const expandSprings = useSpring({
    config: { friction: 20 },
    height: isExpand ? '400px' : '100px'
  });
  const [arrowSprings, arrowApi] = useSpring(() => ({
    from: { rotate: 0 }
  }));
  const loadTypes = coreState.loadTypes.filter((p) => p.projectId === project.projectId);
  const getTotalLoads = (): number => {
    let totalLoads = 0;

    loadTypes.forEach((l) => (totalLoads = totalLoads + l.qte));

    return totalLoads;
  };

  return (
    <AnimatedBox
      borderTop="1px solid grey"
      style={expandSprings}
      sx={{
        width: '100%',
        padding: '0 10px'
      }}
      onClick={() => {
        //make sure project is loaded
        setExpand(!isExpand);
      }}>
      <div
        style={{
          width: '100%',
          height: '100px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer',
          flexDirection: 'column'
        }}>
        <div
          style={{
            alignSelf: 'flex-start',
            display: 'flex',
            justifyContent: 'flex-start',
            width: '100%'
          }}>
          <SubTitle2 style={{ margin: 0 }}>{project.name}</SubTitle2>
          <CellStatus isActive={project.isActive} style={{ marginLeft: 16 }} />
        </div>

        <LinearProgressBar
          totalLoads={project.summary.qte / 10}
          progress={project.summary.qte}
          total={getTotalLoads()}
          type={project.summary.qte_type}
          isValid={loadTypes.length > 0}
        />
      </div>
      <div style={{ display: isExpand ? 'flex' : 'none', width: '100%', height: 300 }}>
        <LoadTypeRow>
          <Button
            variant="outlined"
            color="secondary"
            label="View Project"
            size="normal"
            onClick={() => {
              navigate(`/project/${project.name}`, { state: { entityId: project.projectId } });
            }}
          />
          {loadTypes.map((l, i) => {
            const pSum = project.details.find((d) => d.loadTypeId === l.entityId);
            const totalCurrent = pSum?.qte ?? 0;
            const loadCount = totalCurrent >= l.qte ? 0 : l.qte - totalCurrent;

            return (
              <LoadTypeCard key={l.entityId} style={{ marginLeft: i === 0 ? '0' : '30px' }}>
                <div
                  style={{
                    height: '100%',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}>
                  <CircleProgress
                    loadTypeName={l.name}
                    materialName={l.material_name}
                    current={totalCurrent}
                    total={l.qte}
                    type={l.qte_type}
                    loadCount={loadCount / 10}
                  />
                </div>
              </LoadTypeCard>
            );
          })}
        </LoadTypeRow>
      </div>
    </AnimatedBox>
  );
};

export const LoadTypeRow = styled.ul`
  height: 230px;
  width: 100%;
  flex-wrap: nowrap;
  overflow-y: hidden;
  overflow-x: auto;
  white-space: nowrap;
  list-style-type: none;
  padding-left: 0;
`;

export const LoadTypeCard = styled.li`
  height: 200px;
  min-width: 100px;
  width: 150px;
  display: inline-block;
  margin-left: 30px;
`;
