import React, { ReactElement, useEffect, useState } from 'react';

import { StyledAppBar } from '../component/containers/appBar';
import Box from '@mui/material/Box';
import { animated, useSpring } from '@react-spring/web';
import { SideMenu } from '../component/containers';
import { Outlet, useNavigate } from 'react-router-dom';
import { CssBaseline } from '@mui/material';
import { NotificationModal } from '../component/modals';
import { useErrors, useLoading, useService, useToken, useUser } from '../hooks';
import { defaultError, getUser, reasonCode, ReqError, errorMessage } from '../network';
import { defaultUser } from '../types';
import { decontructPackedId } from '../util';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import duration from 'dayjs/plugin/duration';
import isBetween from 'dayjs/plugin/isBetween';
import dayjs from 'dayjs';
const MainContainer = animated(Box);

const drawerWidth = 240;
export const DefaultLayout = (): ReactElement => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const { setLoading } = useLoading();
  const [errorMsg, setErrorMessage] = useState<string | string[]>('');
  const { updateToken } = useToken();
  const navigate = useNavigate();
  const { error, setError } = useErrors();
  const { service } = useService();
  const { user, setUser } = useUser();

  useEffect(() => {
    if (error.networkError !== '' || error.loginError !== '' || error.pageError !== '') {
      const errors = Object.values(error).filter((e) => e !== '');

      if (errors.length > 1) {
        setErrorMessage(errors);
      } else {
        setErrorMessage(errors.toString());
      }
      setModalOpen(true);
    } else {
      setModalOpen(false);
    }
  }, [error]);

  const handleClose = (): void => {
    setErrorMessage('');
    setError({ ...defaultError });
  };

  const readUser = async () => {
    setLoading(true);
    getUser(service)
      .then((res) => {
        if (res) {
          setUser(res);
        }
      })
      .catch((e) => {
        const error = e as ReqError;

        if (error && error.reason_code === reasonCode.packactIdError) {
          const id = decontructPackedId(error);
          service.setPacketID(id);
          return;
        }
        if (error && error.status === 403 && error.reason_code === reasonCode.sessionTimeout) {
          setError({ networkError: errorMessage.sessionTimeout });
          return;
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleConfirm = (): void => {
    setErrorMessage('');
    setError({ ...defaultError });

    if (error.networkError === errorMessage.sessionTimeout) {
      updateToken('');
      navigate('/login');
      navigate(0);
    }
  };

  const [springs, api] = useSpring(() => ({
    width: 'calc(100% - 65px)'
  }));

  const updateMenuState = (state: boolean): void => {
    setMenuOpen(state);
  };

  useEffect(() => {
    if (isMenuOpen) {
      api.start({
        from: { width: 'calc(100% - 65px)' },
        to: { width: `calc(100% - ${drawerWidth}px)` }
      });
    } else {
      api.start({
        from: { width: `calc(100% - ${drawerWidth}px)` },
        to: { width: 'calc(100% - 65px)' }
      });
    }
  }, [isMenuOpen]);

  useEffect(() => {
    dayjs.extend(utc);
    dayjs.extend(timezone);
    dayjs.extend(duration);
    dayjs.extend(isBetween);
    if (user.profileId === defaultUser.profileId) {
      setTimeout(() => {
        readUser();
      }, 300);
    }
  }, []);

  return (
    <Box
      sx={{
        backgroundColor: `rgba(244, 245, 253, 0.6);`,
        height: '100%',
        width: '100%',
        display: 'flex'
      }}>
      <CssBaseline />
      <StyledAppBar />
      <SideMenu updateMenuState={updateMenuState} />
      <MainContainer
        style={springs}
        sx={{
          position: 'absolute',
          height: '100%',
          top: 0,
          right: 0,
          overflow: 'hidden',
          paddingTop: '60px',
          backgroundColor: `rgba(244, 245, 253, 0.6);`
        }}>
        <Outlet />
      </MainContainer>
      <NotificationModal
        open={isModalOpen}
        message={errorMsg}
        handleClose={handleClose}
        handleConfirm={handleConfirm}
      />
    </Box>
  );
};
