export const m3 = '㎥';
export const dateFormat = 'DD-MM-YYYY';
export const dateHourFormat = 'DD-MM-YYYY HH:mm:ss';

export enum userRoles {
  superAdmin = 'Super Admin',
  operator = 'Operator',
  supervisor = 'Supervisor',
  manager = 'Project Manager',
  foreman = 'Foreman',
  admin = 'Administrator'
}

export enum provincesInput {
  AB = 'Alberta',
  BC = 'British Columbia',
  MB = 'Manitoba',
  NB = 'New Brunswick',
  NL = 'Newfoundland and Labrador',
  NT = 'Northwest Territories',
  NS = 'Nova Scotia',
  NU = 'Nunavut',
  PE = 'Prince Edward Island',
  ON = 'Ontario',
  QC = 'Quebec',
  Sk = 'Saskatchewan',
  YT = 'Yukon'
}

export const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
export const numberTest = /^[0-9\b]+$/;

export const format = 'YY-MMM-DD HH:mm:ss';
export const timezone = 'America/Toronto';

export enum truckStatus {
  loading = 'loading',
  enRoute = 'en route to receiving site',
  arrived = 'arrived'
}
