import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  useTheme,
  SelectChangeEvent
} from '@mui/material';
import React, { ReactElement } from 'react';
import { Button, MultiSelectItem } from '../atoms';
import styled from 'styled-components';
import { SubTitle, MultiSelector } from '../atoms';

type Props = {
  baseOptions: MultiSelectItem[];
  filterSelected: string;
  multiSelectValue: MultiSelectItem[];
  inputLabel: string;
  buttonLabel: string;
  label: string;
  filterOptions?: string[];
  disabled?: boolean;
  addDisabled?: boolean;
  handleSelectFilter?: (event: SelectChangeEvent) => void;
  handleMultiSelect: (value: MultiSelectItem[]) => void;
  handleButtonClick: () => void;
};

export enum fixedFilters {
  all = 'All',
  selected = 'Selected Only'
}

export const ProjectElementContianer = (props: Props): ReactElement => {
  const theme = useTheme();

  const isAllSelected = (): boolean => {
    let allSelected = true;
    props.multiSelectValue.forEach((s) => {
      if (s.selected === false) {
        allSelected = false;
        return;
      }
    });
    return allSelected;
  };

  return (
    <Box
      component="form"
      id="trucks_container"
      autoComplete="on"
      sx={{
        height: '90%',
        width: '24%',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[1],
        borderRadius: '15px',
        padding: theme.spacing(2),
        position: 'relative'
      }}>
      <SubTitle style={{ alignSelf: 'flex-start', margin: 0 }}>{props.label}</SubTitle>
      {props.handleSelectFilter && props.filterOptions ? (
        <FormControl
          variant="filled"
          fullWidth
          sx={{
            m: 1,
            margin: 'auto',
            minWidth: 100,
            '& .MuiFilledInput-root': {
              backgroundColor: 'rgba(244, 245, 253, 0.6)',
              height: '48px'
            }
          }}>
          <InputLabel>{props.inputLabel}</InputLabel>
          <Select
            value={props.filterSelected}
            onChange={props.handleSelectFilter}
            disabled={props.disabled === true}>
            {props.filterOptions.map((p) => (
              <MenuItem value={p} key={p}>
                {p}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
        <div style={{ width: '100%', height: 8 }}></div>
      )}
      <SelectAllButtonRow>
        <Button
          variant="text"
          color="secondary"
          size="normal"
          disabled={props.disabled === true}
          label={isAllSelected() ? 'Deselect All' : 'Select All'}
          onClick={() => {
            if (isAllSelected()) {
              props.handleMultiSelect([
                ...props.multiSelectValue.map((v) => {
                  return { ...v, selected: false };
                })
              ]);
            } else {
              props.handleMultiSelect([
                ...props.multiSelectValue.map((v) => {
                  return { ...v, selected: true };
                })
              ]);
            }
          }}
        />
      </SelectAllButtonRow>

      <MultiSelectContainer id="multi-select">
        <MultiSelector
          disabled={props.disabled === true}
          list={props.multiSelectValue}
          handleClick={(value) => {
            const selectList = [...props.multiSelectValue];
            const updatedList = selectList.map((s) => {
              if (s.id === value) {
                return { ...s, selected: !s.selected };
              }
              return s;
            });
            props.handleMultiSelect([...updatedList]);
          }}
        />
      </MultiSelectContainer>
      <Button
        disabled={props.addDisabled === true}
        styles={{
          position: 'absolute',
          bottom: theme.spacing(2),
          right: theme.spacing(2),
          width: 150
        }}
        variant="outlined"
        color="secondary"
        size="normal"
        label={props.buttonLabel}
        onClick={() => {
          props.handleButtonClick();
        }}
      />
    </Box>
  );
};

const MultiSelectContainer = styled.div`
  height: 45%;
  width: 100%;
`;

const SelectAllButtonRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
