import { UserPermission } from './users';

export const operatorPermission: UserPermission = {
  project: 4,
  truck: 6,
  load: 6,
  material: 4,
  site: 4,
  user: 4,
  activity: 4,
  loadType: 4
};

export const foremanPermission: UserPermission = {
  project: 4,
  truck: 6,
  load: 6,
  material: 4,
  site: 4,
  user: 4,
  activity: 4,
  loadType: 4
};

export const supervisorPermission: UserPermission = {
  project: 4,
  truck: 6,
  load: 6,
  material: 6,
  site: 4,
  user: 4,
  activity: 4,
  loadType: 6
};

export const managerPermission: UserPermission = {
  project: 6,
  truck: 6,
  load: 6,
  material: 6,
  site: 6,
  user: 6,
  activity: 6,
  loadType: 6
};

export const adminPermission: UserPermission = {
  project: 6,
  truck: 6,
  load: 6,
  material: 6,
  site: 6,
  user: 6,
  activity: 6,
  loadType: 6
};

export const superAdminPermission: UserPermission = {
  project: 6,
  truck: 6,
  load: 6,
  material: 6,
  site: 6,
  user: 6,
  activity: 6,
  loadType: 6
};

export const defaultPermission: UserPermission = {
  project: 0,
  truck: 0,
  load: 0,
  material: 0,
  site: 0,
  user: 0,
  activity: 0,
  loadType: 0
};

export const userPermissions = {
  superAdmin: superAdminPermission,
  operator: operatorPermission,
  supervisor: supervisorPermission,
  manager: managerPermission,
  foreman: foremanPermission,
  admin: adminPermission
};

export const ro = 4;
export const rw = 6;
