import React, { ReactElement } from 'react';
import { TextField, TextFieldProps } from '@mui/material';

type Props = { width?: number };

export const StyledTextField = (props: TextFieldProps & Props): ReactElement => {
  return (
    <TextField
      {...props}
      sx={{
        ...props.sx,
        '& .MuiFilledInput-root': {
          backgroundColor: 'rgba(244, 245, 253, 0.6)',
          width: `${props.width}px`
        },
        '& .MuiFormLabel-root': {
          fontSize: '12px'
        }
      }}
    />
  );
};
