import React, { ReactElement, useEffect, useState } from 'react';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import { Caption, SubTitle } from '../atoms/text';
import styled, { useTheme } from 'styled-components';

import {
  Autocomplete,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent
} from '@mui/material';
import { IoClose } from 'react-icons/io5';
import { Button } from '../atoms/button';
import { StyledTextField } from '../atoms/styledTextField';
import { provinces } from '../../page';
import { Site } from '../../types';
import { postResHelper, responseErrorHelper, scriptOP } from '../../network';
import { Spinner } from '../atoms/spinner';
import useCoreState from '../../hooks/useCoreState';
import { CoreActionTypes } from '../../context';
import { useService } from '../../hooks';
import { AiFillCheckCircle, AiFillWarning } from 'react-icons/ai';
import { ModalLoadingContainer } from '../containers';
import { getProvinceValue } from '../../util';

const style = {
  // eslint-disable-next-line @typescript-eslint/prefer-as-const
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  minHeight: '500px',
  height: '65%',
  maxWidth: '950px',
  width: '50%',
  borderRadius: '25px'
};

type ModalErrors = {
  name: string;
  add1: string;
  add2: string;
  city: string;
  fName: string;
  lName: string;
  number: string;
};

type ModalState = {
  entityId: string;
  name: string;
  add1: string;
  add2: string;
  city: string;
  province: string;
  projectSelected: string[];
  fName: string;
  lName: string;
  number: string;
  notes: string;
  isLoading: boolean;
};

const defaultErrors: ModalErrors = {
  name: '',
  add1: '',
  add2: '',
  city: '',
  fName: '',
  lName: '',
  number: ''
};

const initModalState: ModalState = {
  name: '',
  add1: '',
  add2: '',
  city: '',
  province: 'Ontario',
  projectSelected: [],
  fName: '',
  lName: '',
  number: '',
  notes: '',
  isLoading: false,
  entityId: ''
};

//no edit mode for this dialog

export const AddReceivingSiteModal = ({
  open,
  shouldAssign,
  handleClose
}: {
  open: boolean;
  shouldAssign?: boolean;
  handleClose: (entityId?: string) => void;
}): ReactElement => {
  const { coreState, updateCoreState } = useCoreState();
  const [modalErrors, setModalErrors] = useState<ModalErrors>(defaultErrors);
  const [notificationState, setNotifications] = useState<{
    message: string;
    isSuccess: boolean;
    isOpen: boolean;
  }>({
    message: '',
    isSuccess: true,
    isOpen: false
  });
  const theme = useTheme();
  const [modalState, setModalState] = useState<ModalState>(initModalState);
  const { service } = useService();
  useEffect(() => {
    if (notificationState.isOpen) {
      setTimeout(() => {
        setNotifications({ message: '', isSuccess: true, isOpen: false });
        handleClose(modalState.entityId);
      }, 3000);
    }
  }, [notificationState.isOpen]);

  useEffect(() => {
    if (open) {
      setModalErrors({ ...defaultErrors });
      setModalState({
        ...initModalState
      });
    }
  }, [open]);

  const onClose = (): void => {
    handleClose();
  };

  const handleChangeProvince = (event: SelectChangeEvent) => {
    const province = event.target.value;
    setModalState({ ...modalState, province });
  };

  const hanldeValidation = (): boolean => {
    let error = false;

    if (
      modalState.fName &&
      modalState.lName &&
      modalState.add1 &&
      modalState.city &&
      modalState.name &&
      modalState.number
    ) {
      error = true;
    }

    setModalErrors({
      ...modalErrors,
      fName: modalState.fName ? '' : 'First name cannot be empty',
      lName: modalState.lName ? '' : 'Last name cannot be empty',
      name: modalState.name ? '' : 'Site name cannot be empty',
      add1: modalState.add1 ? '' : 'Address cannot be empty',
      city: modalState.city ? '' : 'City cannot be empty',
      number: modalState.number ? '' : 'Contact number cannot be empty'
    });

    return error;
  };

  const handleSubmit = async () => {
    if (!hanldeValidation()) return;
    setModalState({ ...modalState, isLoading: true });
    const projectIds: string[] = [];
    modalState.projectSelected.forEach((n) => {
      coreState.projects.forEach((p) => {
        if (p.name === n) {
          projectIds.push(p.entityId);
        }
      });
    });
    try {
      const dataRes = await service.post(scriptOP.createEntity, {
        dataJson: {
          name: modalState.name,
          isReceiving: true,
          projects: [...projectIds],
          contact: {
            fname: modalState.fName,
            lname: modalState.lName,
            phone: modalState.number
          },
          address: {
            street1: modalState.add1,
            street2: modalState.add2,
            city: modalState.city,
            prov: getProvinceValue(modalState.province)
          }
        },
        entityType: 'Site',
        acl: { other: 2 }
      });

      const entityId = postResHelper(dataRes);
      const error = responseErrorHelper(dataRes);
      if (error) {
        setNotifications({
          message: 'Error! Something went wrong saving the receiving site.',
          isOpen: true,
          isSuccess: false
        });
        return;
      }
      if (entityId) {
        const newReceivingSite: Site = {
          entityId,
          version: 1,
          name: modalState.name,
          isReceiving: true,
          projects: [...projectIds],
          summary: [],
          contact: {
            fname: modalState.fName,
            lname: modalState.lName,
            phone: modalState.number
          },
          address: {
            street1: modalState.add1,
            street2: modalState.add2,
            city: modalState.city,
            prov: modalState.province
          }
        };
        updateCoreState({
          type: CoreActionTypes.SET_RECEIVING_SITES,
          payload: { ...coreState, sites: [...coreState.sites, newReceivingSite] }
        });
        setModalState({ ...modalState, isLoading: false, entityId });
        setNotifications({
          message: 'Success! The data has been updated.',
          isOpen: true,
          isSuccess: true
        });
      }
    } catch (error) {
      setNotifications({
        message: 'Something went wrong, please try again.',
        isOpen: true,
        isSuccess: false
      });
    }
  };

  const handleInputChange = (
    type: 'name' | 'add1' | 'add2' | 'fName' | 'lName' | 'city' | 'number' | 'notes',
    value: string
  ): void => {
    switch (type) {
      case 'name':
        setModalState({ ...modalState, name: value });
        break;
      case 'add1':
        setModalState({ ...modalState, add1: value });
        break;
      case 'add2':
        setModalState({ ...modalState, add2: value });
        break;
      case 'fName':
        setModalState({ ...modalState, fName: value });
        break;
      case 'lName':
        setModalState({ ...modalState, lName: value });
        break;
      case 'city':
        setModalState({ ...modalState, city: value });
        break;
      case 'number':
        setModalState({ ...modalState, number: value });
        break;
      case 'notes':
        setModalState({ ...modalState, notes: value });
        break;
      default:
        return;
    }
  };

  const hanldeClearError = (
    type: 'name' | 'add1' | 'add2' | 'fName' | 'lName' | 'city' | 'number'
  ): void => {
    switch (type) {
      case 'name':
        setModalErrors({ ...modalErrors, name: '' });
        break;
      case 'add1':
        setModalErrors({ ...modalErrors, add1: '' });
        break;
      case 'add2':
        setModalErrors({ ...modalErrors, add2: '' });
        break;
      case 'fName':
        setModalErrors({ ...modalErrors, fName: '' });
        break;
      case 'lName':
        setModalErrors({ ...modalErrors, lName: '' });
        break;
      case 'city':
        setModalErrors({ ...modalErrors, city: '' });
        break;
      case 'number':
        setModalErrors({ ...modalErrors, number: '' });
        break;
      default:
        return;
    }
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={onClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{ backdrop: { timeout: 500 } }}>
      <Fade in={open}>
        <Box sx={style}>
          <Header>
            <SubTitle style={{ color: 'white' }}>Add New Receiving Site</SubTitle>
            <IconButton onClick={onClose} sx={{ height: 45, width: 45 }}>
              <IoClose style={{ height: 40, width: 40, color: 'white' }} />
            </IconButton>
          </Header>
          <Content>
            <div
              style={{
                width: '100%',
                height: '15%',
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center'
              }}>
              <InputGroup style={{ width: '50%' }}>
                <SubTitle style={{ alignSelf: 'flex-start', margin: 0 }}>Name*</SubTitle>
                <StyledTextField
                  label="Receiving Site Name"
                  id="filled-size-small"
                  required
                  error={modalErrors.name !== ''}
                  helperText={modalErrors.name}
                  onBlur={(e) => {
                    if (!e.target.value) {
                      setModalErrors({ ...modalErrors, name: 'This field is required.' });
                    }
                  }}
                  value={modalState.name}
                  variant="filled"
                  fullWidth
                  size="small"
                  onFocus={() => {
                    hanldeClearError('name');
                  }}
                  onChange={(e) => {
                    handleInputChange('name', e.target.value);
                  }}
                />
              </InputGroup>
            </div>
            <div
              style={{
                width: '100%',
                height: '40%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
              <div
                style={{
                  height: '100%',
                  width: '50%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}>
                <InputGroup style={{ width: '100%' }}>
                  <SubTitle style={{ alignSelf: 'flex-start', margin: 0 }}>
                    Location Information*
                  </SubTitle>
                  <StyledTextField
                    label="Address line 1"
                    id="filled-size-small"
                    required
                    error={modalErrors.add1 !== ''}
                    helperText={modalErrors.add1}
                    onBlur={(e) => {
                      if (!e.target.value) {
                        setModalErrors({ ...modalErrors, add1: 'This field is required.' });
                      }
                    }}
                    value={modalState.add1}
                    variant="filled"
                    fullWidth
                    size="small"
                    onFocus={() => {
                      hanldeClearError('add1');
                    }}
                    onChange={(e) => {
                      handleInputChange('add1', e.target.value);
                    }}
                  />
                  <StyledTextField
                    label="Address line 2"
                    id="filled-size-small"
                    helperText={modalErrors.add2}
                    value={modalState.add2}
                    variant="filled"
                    fullWidth
                    size="small"
                    onChange={(e) => {
                      handleInputChange('add2', e.target.value);
                    }}
                  />
                  <div
                    style={{
                      height: '48px',
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}>
                    <StyledTextField
                      label="City"
                      id="filled-size-small"
                      error={modalErrors.city !== ''}
                      helperText={modalErrors.city}
                      required
                      onBlur={(e) => {
                        if (!e.target.value) {
                          setModalErrors({ ...modalErrors, city: 'This field is required.' });
                        }
                      }}
                      value={modalState.city}
                      variant="filled"
                      size="small"
                      onFocus={() => {
                        hanldeClearError('city');
                      }}
                      onChange={(e) => {
                        handleInputChange('city', e.target.value);
                      }}
                    />
                    <FormControl
                      variant="filled"
                      sx={{
                        m: 1,
                        width: '50%',
                        marginRight: 0,
                        '& .MuiFilledInput-root': {
                          backgroundColor: 'rgba(244, 245, 253, 0.6)',
                          height: '48px'
                        }
                      }}>
                      <InputLabel>Province</InputLabel>
                      <Select value={modalState.province} onChange={handleChangeProvince}>
                        {provinces.map((p) => (
                          <MenuItem value={p} key={p}>
                            {p}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </InputGroup>
              </div>
              <div
                style={{
                  height: '100%',
                  width: '50%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}>
                <InputGroup style={{ width: '100%' }}>
                  <SubTitle style={{ alignSelf: 'flex-start', margin: 0 }}>
                    Contact Information*
                  </SubTitle>
                  <StyledTextField
                    label="First Name"
                    id="filled-size-small"
                    error={modalErrors.fName !== ''}
                    helperText={modalErrors.fName}
                    required
                    value={modalState.fName}
                    variant="filled"
                    fullWidth
                    size="small"
                    onFocus={() => {
                      hanldeClearError('fName');
                    }}
                    onChange={(e) => {
                      handleInputChange('fName', e.target.value);
                    }}
                  />
                  <StyledTextField
                    label="Last Name"
                    id="filled-size-small"
                    value={modalState.lName}
                    error={modalErrors.lName !== ''}
                    helperText={modalErrors.lName}
                    variant="filled"
                    required
                    fullWidth
                    size="small"
                    onFocus={() => {
                      hanldeClearError('lName');
                    }}
                    onChange={(e) => {
                      handleInputChange('lName', e.target.value);
                    }}
                  />
                  <StyledTextField
                    label="Phone Number"
                    id="filled-size-small"
                    required
                    helperText={modalErrors.number}
                    error={modalErrors.number !== ''}
                    onBlur={(e) => {
                      if (!e.target.value) {
                        setModalErrors({ ...modalErrors, name: 'This field is required.' });
                      }
                    }}
                    value={modalState.number}
                    variant="filled"
                    fullWidth
                    size="small"
                    onFocus={() => {
                      hanldeClearError('number');
                    }}
                    onChange={(e) => {
                      handleInputChange('number', e.target.value);
                    }}
                  />
                </InputGroup>
              </div>
            </div>
            <div
              style={{
                width: '100%',
                height: '30%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
              <div
                style={{
                  height: '100%',
                  width: '50%',
                  display: 'flex',
                  padding: '0 10px',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%'
                  }}>
                  <SubTitle style={{ alignSelf: 'flex-start', margin: 0 }}>Projects</SubTitle>
                  {shouldAssign !== false && (
                    <Button
                      variant="text"
                      color="secondary"
                      size="normal"
                      label={
                        modalState.projectSelected.length ===
                        coreState.projects.filter((p) => p.isActive).length
                          ? 'Deselect All'
                          : 'Select All'
                      }
                      onClick={() => {
                        if (
                          modalState.projectSelected.length ===
                          coreState.projects.filter((p) => p.isActive).length
                        ) {
                          setModalState({ ...modalState, projectSelected: [] });
                        } else {
                          setModalState({
                            ...modalState,
                            projectSelected: [
                              ...coreState.projects.filter((p) => p.isActive).map((p) => p.name)
                            ]
                          });
                        }
                      }}
                    />
                  )}
                </div>
                <Autocomplete
                  multiple
                  sx={{
                    width: '100%',
                    overflow: 'auto',
                    '& .MuiInputBase-root': {
                      backgroundColor: 'rgba(244, 245, 253, 0.6)'
                    }
                  }}
                  options={coreState.projects.filter((p) => p.isActive).map((p) => p.name)}
                  value={modalState.projectSelected}
                  disabled={shouldAssign === false}
                  onChange={(e, v) => {
                    setModalState({ ...modalState, projectSelected: [...v] });
                  }}
                  renderInput={(params) => (
                    <StyledTextField {...params} label="Select project(s)" variant="filled" />
                  )}
                />
              </div>
              <div
                style={{
                  height: '100%',
                  width: '50%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}>
                <InputGroup>
                  <SubTitle style={{ alignSelf: 'flex-start', margin: 0 }}>Notes</SubTitle>
                  <StyledTextField
                    id="filled-size-small"
                    value={modalState.notes}
                    variant="filled"
                    fullWidth
                    multiline
                    size="small"
                    rows={2}
                    onChange={(e) => {
                      handleInputChange('notes', e.target.value);
                    }}
                  />
                </InputGroup>
              </div>
            </div>
          </Content>
          <Footer>
            <Caption>*Required information</Caption>
            <div>
              <Button
                variant="outlined"
                label="Cancel"
                onClick={onClose}
                styles={{ marginRight: 20 }}
                size="normal"
                color="secondary"
              />
              <Button
                variant="contained"
                label="Confirm"
                onClick={handleSubmit}
                size="normal"
                color="secondary"
              />
            </div>
          </Footer>
          {modalState.isLoading && (
            <ModalLoadingContainer>
              <Spinner />
            </ModalLoadingContainer>
          )}

          {notificationState.isOpen && (
            <ModalLoadingContainer>
              <FeedbackContainer>
                {notificationState.isSuccess ? (
                  <AiFillCheckCircle size={50} color={theme.palette.success.main} />
                ) : (
                  <AiFillWarning size={50} color={theme.palette.error.main} />
                )}
                <SubTitle>{notificationState.message}</SubTitle>
              </FeedbackContainer>
            </ModalLoadingContainer>
          )}
        </Box>
      </Fade>
    </Modal>
  );
};

const Header = styled.div`
  height: 50px;
  background-color: ${({ theme }) => theme.palette.primary.main};
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  padding: 0 20px 0 20px;
`;

const Content = styled.div`
  position: relative;
  height: calc(100% - 100px);
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  padding: 0 20px;
`;

const Footer = styled.div`
  height: 50px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  padding: 0 20px 0 20px;
`;

const FeedbackContainer = styled.div`
  height: 125px;
  width: 400px;
  padding: 5px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.background.paper};
`;

const InputGroup = styled.div`
  justify-content: space-evenly;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 0 10px;
  flex-direction: column;
  align-items: center;
  & > p {
    margin-top: 5px !important;
  }
`;
