import { BASE_URL, APP_ID, bearere } from './property';

import axios, { AxiosInstance } from 'axios';
import { responseErrorHelper } from './helper';

export default class NetworkService {
  token: string;
  packetID: number;
  appID: string;
  client: AxiosInstance;

  constructor() {
    this.token = '';
    this.packetID = 0;
    this.appID = APP_ID;
    this.client = axios.create({
      headers: {
        'X-APPID': APP_ID,
        'content-type': 'application/json',
        'X-PACKETID': 1
      }
    });
  }

  async get(subURL: string, params?: object): Promise<unknown> {
    if (this.token === '') return Promise.reject('Token is missing');
    try {
      const res = await this.client.get(this.makeURL(subURL).href, {
        params,
        headers: {
          'X-APPID': APP_ID,
          'content-type': 'application/json',
          'X-PACKETID': this.getPacketID(),
          Authorization: this.token
        }
      });
      return res;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async post(subURL: string, body: object): Promise<unknown> {
    if (this.token === '') return Promise.reject('Token is missing');
    try {
      const res = await this.client.post(this.makeURL(subURL).href, body, {
        headers: {
          'X-APPID': APP_ID,
          'content-type': 'application/json',
          'X-PACKETID': this.getPacketID(),
          Authorization: this.token
        }
      });

      const error = responseErrorHelper(res);
      if (error) {
        return Promise.reject(error);
      }

      return res;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  getPacketID(): number {
    this.packetID = this.packetID + 1;

    return this.packetID;
  }

  setPacketID(id: number) {
    this.packetID = id;
  }

  makeURL(subURL: string): URL {
    return new URL(subURL, BASE_URL);
  }

  getToken(): string {
    return this.token;
  }

  setToken(token: string) {
    this.token = bearere + token;
  }
}
