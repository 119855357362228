import { useContext } from 'react';
import { NetworkContext } from '../network';
import NetworkService from '../network/service';

export const useService = (): {
  service: NetworkService;
} => {
  const { networkState } = useContext(NetworkContext);

  return { service: networkState.service };
};
