import { useContext } from 'react';
import { AppError, NetworkContext } from '../network';

export const useErrors = (): {
  error: AppError;
  setError: (e: Partial<AppError>) => void;
} => {
  const { networkState, setError } = useContext(NetworkContext);

  const setNetworkError = (e: Partial<AppError>): void => {
    const error = { ...networkState.error, ...e };

    setError(error);
  };

  return { error: networkState.error, setError: setNetworkError };
};
