import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { useTheme } from '@mui/material';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import { timezone } from '../../util';

const format = 'YY-MMM-DD hh:mm:ss SSS';
const getFileName = (path: string): string => {
  const timeStamp = dayjs().tz(timezone, true).format(format).toString();
  if (path.includes('users')) {
    return `users_${timeStamp}`;
  }

  if (path.includes('trucks')) {
    return `trucks_${timeStamp}`;
  }

  if (path.includes('activityLogs')) {
    return `user_logs_${timeStamp}`;
  }

  if (path.includes('material')) {
    return `material_deliveryConfirmation_${timeStamp}`;
  }

  return `export_file_${timeStamp}`;
};
export function ExportBar(): ReactElement {
  const theme = useTheme();
  const { pathname } = useLocation();

  const [fileName, setFileName] = useState<string>('');
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const timeStamp = dayjs().tz(timezone, true).format(format).toString();
    if (ref && ref.current) {
      const classNameList = ref.current.parentElement?.parentElement?.parentElement?.className;
      if (classNameList && classNameList.length > 0) {
        const allClassName = classNameList.split(' ');
        const fileNamePr = allClassName.find((name) => name.includes('#export-'));
        if (fileNamePr && fileNamePr.length > 8) {
          setFileName(fileNamePr.substring(8) + timeStamp);
        }
      }
    }
  }, []);

  return (
    <GridToolbarContainer sx={{ justifyContent: 'flex-end' }} ref={ref}>
      <GridToolbarExport
        csvOptions={{
          fileName: fileName ?? getFileName(pathname),
          delimiter: ';',
          utf8WithBom: true
        }}
        sx={{
          color: theme.palette.secondary.light,
          width: '120px',
          height: '30px',
          border: `1px solid ${theme.palette.secondary.light}`
        }}
      />
    </GridToolbarContainer>
  );
}
