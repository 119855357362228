import React, { ReactElement, useEffect, useState } from 'react';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import { SubTitle } from '../atoms/text';
import styled, { useTheme } from 'styled-components';
import { modalBtnType } from '../containers/contentContainer';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  IconButton
} from '@mui/material';
import { IoClose } from 'react-icons/io5';
import { Button } from '../atoms/button';
import { StyledTextField } from '../atoms/styledTextField';
import { provinces } from '../../page';
import {
  Company,
  Contact,
  defaultCompany,
  defaultProject,
  defaultTruck,
  Project,
  Truck
} from '../../types';
import {
  scriptOP,
  postResHelper,
  responseErrorHelper,
  responseStatus,
  ReqError,
  getErrorMessage,
  reasonCode
} from '../../network';
import { Spinner } from '../atoms/spinner';
import useCoreState from '../../hooks/useCoreState';
import { CoreActionTypes } from '../../context';
import { useErrors, useService } from '../../hooks';
import { AiFillCheckCircle, AiFillWarning } from 'react-icons/ai';
import { getProvinceValue, numberTest } from '../../util';
import { ModalLoadingContainer, NotificationPopup } from '../containers';
import { IconButton as IButton } from '../atoms';

import { MdClear } from 'react-icons/md';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import { lightGrey } from '../../theme';
import { debug } from 'console';
import { debuglog } from 'util';

//fetch projects && company names

const initModalState = {
  truck: defaultTruck,
  selectedCompany: defaultCompany,
  newCompany: defaultCompany,
  companies: [defaultCompany],
  isLoading: false,
  abName: '',
  truckId: '',
  selectedProject: defaultProject,
  projects: [],
  capcityInput: ''
};

const defaultNotificationState = {
  message: '',
  title: '',
  success: true,
  open: false,
  actionable: false
};

type ModalErrors = {
  company: string;
  abName: string;
  truckId: string;
  plate: string;
  fName: string;
  lName: string;
  contactNumber: string;
  capacity: string;
  note: string;
};

const defaultErrors: ModalErrors = {
  company: '',
  abName: '',
  truckId: '',
  plate: '',
  fName: '',
  lName: '',
  contactNumber: '',
  capacity: '',
  note: ''
};

export const TruckModal = ({
  open,
  label,
  mode,
  shouldAssign,
  data,
  handleClose,
  projectDate,
  projectName
}: {
  open: boolean;
  label: modalBtnType;
  mode: 'New' | 'Edit';
  shouldAssign?: boolean;
  projectDate?: string;
  projectName?: string;
  data?: Truck;
  handleClose: (entityId?: string) => void;
}): ReactElement => {
  const { coreState, updateCoreState } = useCoreState();
  const { service } = useService();
  const [time, setTime] = useState<Dayjs | null>(dayjs());
  const [date, setDate] = useState<string>('');
  const [modalErrors, setModalErrors] = useState<ModalErrors>(defaultErrors);
  const [notificationState, setNotifications] = useState<{
    message: string;
    title?: string;
    success: boolean;
    open: boolean;
    actionable: boolean;
    handleConfirm?: () => void;
  }>(defaultNotificationState);
  const { setError } = useErrors();
  const theme = useTheme();
  const [truckModalState, setTruckModalState] = useState<{
    truck: Truck;
    selectedCompany: Company;
    projects: Project[];
    newCompany: Company;
    companies: Company[];
    selectedProject: Project;
    isLoading: boolean;
    abName: string;
    truckId: string;
    capcityInput: string;
  }>(initModalState);

  useEffect(() => {
    if (notificationState.open && notificationState.actionable !== true) {
      setTimeout(() => {
        setNotifications({
          message: '',
          title: '',
          success: true,
          actionable: false,
          open: false,
          handleConfirm: undefined
        });
        const entityId = mode === 'New' ? truckModalState.truck.entityId : undefined;
        handleClose(entityId);
      }, 3000);
    }
  }, [notificationState.open, notificationState.actionable]);

  useEffect(() => {
    if (open) {
      setModalErrors({ ...defaultErrors });
    }
    if (mode === 'New') {
      if (projectName && projectDate && !shouldAssign) {
        //when adding new truck from project detail page
        const project = coreState.projects.find((p) => p.name === projectName);
        if (project && project.startDate === projectDate) {
          setTruckModalState({
            ...truckModalState,
            projects: [...coreState.projects],
            companies: coreState.companies,
            selectedProject: project
          });
        }
      } else if (projectDate && !shouldAssign) {
        //when adding new truck from new project page
        setTruckModalState({
          ...truckModalState,
          projects: [
            ...coreState.projects,
            { ...defaultProject, entityId: 'new project', name: 'New Project' }
          ],
          companies: coreState.companies,
          selectedProject: shouldAssign
            ? defaultProject
            : { ...defaultProject, entityId: 'new project', name: 'New Project' }
        });
      } else {
        setTruckModalState({
          ...truckModalState,
          projects: [...coreState.projects],
          companies: coreState.companies
        });
      }

      if (projectDate) {
        setDate(projectDate);
        setTime(dayjs(projectDate));
        if (!projectDate.includes('T') || projectDate.indexOf('T') < 5) {
          setDate(dayjs().toISOString());
          setTime(dayjs());
        }
      }
    }

    if (!open) {
      setTruckModalState({ ...initModalState });
      setDate('');
      setTime(null);
    }

    return () => {
      setTruckModalState({ ...initModalState });
      setDate('');
      setTime(null);
    };
  }, [open, mode, projectDate]);

  useEffect(() => {
    if (mode === 'Edit') {
      setTruckModalState({ ...truckModalState, isLoading: true });

      if (data) {
        const s = coreState.companies.find((c) => c.entityId === data.companyId);
        const p = coreState.projects.find((p) => p.name === projectName);

        setTruckModalState({
          ...truckModalState,
          companies: coreState.companies,
          projects: coreState.projects,
          newCompany: defaultCompany,
          selectedProject: p ? p : defaultProject,
          isLoading: false,
          truck: data,
          selectedCompany: s ? s : defaultCompany,
          abName: data.splId,
          truckId: data.truckNumber ? data.truckNumber.toString() : '',
          capcityInput: data.capacity ? data.capacity.toString() : ''
        });
        if (data.scheduleDate) {
          setDate(data.scheduleDate);
          setTime(dayjs(data.scheduleDate));
          if (!data.scheduleDate.includes('T') || data.scheduleDate.indexOf('T') < 5) {
            setDate(dayjs().toISOString());
            setTime(dayjs());
          }
        } else if (p && !data.scheduleDate) {
          setDate(p.startDate);
          setTime(dayjs(p.startDate));
        } else {
          setDate(dayjs().toISOString());
        }
      }
    }
    return () => {
      setTruckModalState({ ...initModalState });
      setDate('');
      setTime(null);
    };
  }, [mode, data]);

  const onClose = (): void => {
    handleClose();
  };

  // delete truck by adding the inactive flag
  const onDelete = (): void => {
    setNotifications({
      open: true,
      actionable: true,
      message: 'Are you sure? Deleting a Truck cannot be undone.',
      title: 'Delete Truck',
      success: false,
      handleConfirm: handleDeleteTruck
    });
    return;
  };

  const handleDeleteTruck = async () => {
    const { truck } = truckModalState;
    try {
      await service.post(scriptOP.updateEntity, {
        dataJson: {
          ...truck,
          inactive: true
        },
        entityType: 'Truck',
        acl: { other: 2 },
        entityId: truck.entityId,
        version: truck.version
      });

      setNotifications({
        message: '',
        title: '',
        success: true,
        open: false,
        actionable: false
      });

      handleClose();
    } catch (e) {
      console.log({ e });
      setNotifications({
        message: 'An error occured. Please try again. ',
        title: 'Error',
        success: false,
        open: true,
        actionable: false
      });
    }
  };

  const handleSelectProject = (event: SelectChangeEvent) => {
    const entityId = event.target.value;
    const projectFound = truckModalState.projects.find((p) => p.entityId === entityId);

    if (projectFound) {
      setTruckModalState({ ...truckModalState, selectedProject: projectFound });
      setDate(projectFound.startDate);
    }
  };

  const handleSelectCompany = (event: SelectChangeEvent) => {
    handleCreateCompany('');
    const companyName = event.target.value;
    const companyFind = truckModalState.companies.find((c) => c.name === companyName);

    if (companyName !== '' && companyFind) {
      setTruckModalState({
        ...truckModalState,
        selectedCompany: companyFind,
        newCompany: defaultCompany
      });
    }
  };

  const handleCreateCompany = (name: string): void => {
    const company: Company = { ...defaultCompany, name: name ?? '' };
    if (coreState.companies.find((c) => c.name === name) && name !== defaultCompany.name) {
      setModalErrors({ ...modalErrors, company: `The name ${name} is already being used.` });
      return;
    }
    setTruckModalState({
      ...truckModalState,
      newCompany: company,
      selectedCompany: defaultCompany
    });
  };

  const handleNewTruckInput = (
    input: string,
    type: 'splId' | 'plate' | 'notes' | 'truckId' | 'capacity'
  ) => {
    if (type === 'splId') {
      setModalErrors({ ...defaultErrors, abName: '' });

      setTruckModalState({ ...truckModalState, abName: input });
      return;
    }
    if (type === 'capacity') {
      if (numberTest.test(input) || input === '') {
        setModalErrors({ ...defaultErrors, capacity: '' });
        const truck: Truck = {
          ...truckModalState.truck,
          capacity: input === '' ? 0 : parseInt(input)
        };

        setTruckModalState({ ...truckModalState, truck, capcityInput: input });
        return;
      } else {
        setModalErrors({ ...defaultErrors, capacity: 'Numbers Only' });
        return;
      }
    }
    if (type === 'plate') {
      setModalErrors({ ...defaultErrors, plate: '' });
      if (input.length && input.length > 8) {
        setModalErrors({ ...defaultErrors, plate: 'Please use a valid license plate.' });
        return;
      }
      const truck: Truck = { ...truckModalState.truck, plate: input };
      setTruckModalState({ ...truckModalState, truck });
      return;
    }
    if (type === 'notes') {
      const truck: Truck = { ...truckModalState.truck, note: input };
      setTruckModalState({ ...truckModalState, truck });
      return;
    }
    if (type === 'truckId') {
      setModalErrors({ ...defaultErrors, truckId: '' });
      setTruckModalState({ ...truckModalState, truckId: input });
    }

    return;
  };

  const getDate = (): string => {
    return date.substring(0, date.indexOf('T'));
  };

  const handleDriverCreate = (input: string, type: 'firstName' | 'lastName' | 'number') => {
    if (type === 'firstName') {
      setModalErrors({ ...defaultErrors, fName: '' });
      const driver: Contact = { ...truckModalState.truck.driver, fname: input };

      setTruckModalState({ ...truckModalState, truck: { ...truckModalState.truck, driver } });
      return;
    }
    if (type === 'lastName') {
      setModalErrors({ ...defaultErrors, lName: '' });
      const driver: Contact = { ...truckModalState.truck.driver, lname: input };

      setTruckModalState({ ...truckModalState, truck: { ...truckModalState.truck, driver } });
      return;
    }
    if (type === 'number') {
      setModalErrors({ ...defaultErrors, contactNumber: '' });

      const driver: Contact = { ...truckModalState.truck.driver, phone: input };

      setTruckModalState({ ...truckModalState, truck: { ...truckModalState.truck, driver } });
      return;
    }
  };

  const handleChangeProvince = (event: SelectChangeEvent) => {
    const province = event.target.value;
    const truck = { ...truckModalState.truck, province };
    setTruckModalState({ ...truckModalState, truck });
  };

  const hanldeValidation = (): boolean => {
    const errors = { ...defaultErrors };
    const splId = truckModalState.abName + ' ' + truckModalState.truckId;
    if (
      truckModalState.selectedCompany.entityId === defaultCompany.entityId &&
      !truckModalState.selectedCompany.name &&
      truckModalState.newCompany.name === defaultCompany.name
    ) {
      errors.company = 'You must select an exisiting company or create a new one.';
    }

    if (!truckModalState.abName) {
      errors.abName = 'This field is required.';
    }

    if (coreState.trucks.find((t) => t.splId + ' ' + t.truckNumber === splId)) {
      if (mode === 'Edit' && splId !== `${data?.splId} ${data?.truckNumber}`) {
        errors.truckId = 'Truck ID has to be unique.';
      }

      if (mode === 'New') {
        errors.truckId = 'Truck ID has to be unique.';
      }
    }

    if (!truckModalState.truckId) {
      errors.truckId = 'This field is required.';
    }

    if (!truckModalState.truck.driver.fname) {
      errors.fName = 'Driver name is required';
    }

    if (truckModalState.truck.note && truckModalState.truck.note?.length > 32) {
      errors.note = 'too long';
    }

    setModalErrors({ ...errors });
    return Object.values(errors).filter((e) => e !== '').length !== 0;
  };

  const handleSubmitEdit = async () => {
    //edit truck
    setTruckModalState({ ...truckModalState, isLoading: true });
    const hasErrors = hanldeValidation();

    if (!hasErrors) {
      const { selectedCompany, truck, newCompany, selectedProject } = truckModalState;
      let companyEntityId: string = selectedCompany.entityId;
      let companyRes: unknown;
      let projectResId: string | undefined;

      try {
        if (selectedCompany.entityId === defaultCompany.entityId && newCompany.name) {
          //create a new company when user input a new company name
          companyRes = await service.post(scriptOP.createEntity, {
            dataJson: {
              name: newCompany.name
            },
            entityType: 'TruckingCompany',
            acl: { other: 2 }
          });
          const res = postResHelper(companyRes);
          const companyLoadError = responseErrorHelper(companyRes);
          if (companyLoadError) {
            setError({ networkError: companyLoadError.status_message });
            return;
          }
          if (res) companyEntityId = res;
        }

        console.log({ date });

        const dataPost = {
          splId: truckModalState.abName,
          truckNumber: parseInt(truckModalState.truckId),
          companyId: companyEntityId,
          province: getProvinceValue(truck.province),
          plate: truck.plate,
          driver: truck.driver,
          capacity: truck.capacity,
          note: truck.note ?? '',
          noteImage: truck.noteImage ?? '',
          scheduleDate: selectedProject.entityId !== defaultProject.entityId ? date : '',
          projectId: selectedProject.entityId,
          isAssigned: projectName !== defaultProject.name || projectResId ? true : false
        };

        //post to update truck
        const truckRes = await service.post(scriptOP.updateEntity, {
          dataJson: { ...dataPost },
          entityType: 'Truck',
          acl: { other: 2 },
          entityId: truck.entityId,
          version: truck.version
        });

        const responses = responseStatus(truckRes);

        //const truckID = postResHelper(truckRes);

        if (!responses.success) {
          setNotifications({
            message: 'Error! Something went wrong editing the truck.',
            open: true,
            success: false,
            actionable: false,
            title: ''
          });
          return;
        }

        if (responses.success && data) {
          const updatedTruck: Truck = {
            entityId: data.entityId,
            version: truck.version + 1,
            driverId: '',
            scheduleDate: data.scheduleDate,
            splId: truckModalState.abName,
            truckNumber: parseInt(truckModalState.truckId),
            companyId: companyEntityId,
            driver: truck.driver,
            province: truck.province,
            plate: truck.plate,
            capacity: truck.capacity,
            note: truck.note ?? '',
            noteImage: truck.noteImage ?? '',
            isAssigned: false,
            projectId: selectedProject.entityId
          };

          if (coreState.trucks.find((t) => t.entityId === data.entityId)) {
            const trucksUpdate = coreState.trucks.map((t) => {
              if (t.entityId === data.entityId) {
                return updatedTruck;
              } else {
                return t;
              }
            });

            updateCoreState({
              type: CoreActionTypes.SET_TRUCKS,
              payload: { ...coreState, trucks: trucksUpdate }
            });
            setTruckModalState({ ...truckModalState, isLoading: false });
            setNotifications({
              message: 'Success! The truck has been updated.',
              open: true,
              success: true,
              title: '',
              actionable: false
            });
          }
        }
      } catch (e) {
        if (e && typeof e === 'object') {
          const error = e as ReqError;
          if (error && error.reason_code !== reasonCode.packactIdError) {
            const errorMessage = getErrorMessage(error.reason_code);

            setNotifications({
              message: errorMessage,
              open: true,
              success: false,
              actionable: false
            });
          }
        }
      }
    } else {
      setTruckModalState({ ...truckModalState, isLoading: false });
    }
  };
  const handleSubmitCreate = async () => {
    const hasErrors = hanldeValidation();

    if (hasErrors) {
      setTruckModalState({ ...truckModalState, isLoading: false });
      return;
    } else {
      setTruckModalState({ ...truckModalState, isLoading: true });

      const { selectedCompany, truck, newCompany, selectedProject } = truckModalState;
      let companyEntityId: string = selectedCompany.entityId;
      let companyRes: unknown;

      try {
        if (selectedCompany.entityId === '' && newCompany.name) {
          //create new Company
          companyRes = await service.post(scriptOP.createEntity, {
            dataJson: {
              name: newCompany.name
            },
            entityType: 'TruckingCompany',
            acl: { other: 2 }
          });
          const res = postResHelper(companyRes);
          const companyPostError = responseErrorHelper(companyRes);
          if (companyPostError) {
            setNotifications({
              message: 'Error! Something went wrong completing your request.',
              open: true,
              success: false,
              actionable: false
            });
            return;
          }
          if (res) companyEntityId = res;
        }

        if (companyEntityId) {
          //Check for duplicate truck
          const nameChecker = await service.post(scriptOP.truckNameChecker, {
            dataJson: {
              splId: truckModalState.abName,
              truckNumber: parseInt(truckModalState.truckId),
              companyId: companyEntityId
            }
          });

          const nameCheckResponse = responseStatus(nameChecker);

          if (!nameCheckResponse.success) {
            setNotifications({
              message: 'Error! Something went wrong editing the truck.',
              open: true,
              success: false,
              actionable: false
            });
            return;
          }

          if (nameCheckResponse.success) {
            let canCreate = false;

            if (nameChecker && typeof nameChecker === 'object') {
              if (
                'data' in nameChecker &&
                nameChecker.data &&
                typeof nameChecker.data === 'object'
              ) {
                if (
                  'data' in nameChecker.data &&
                  nameChecker.data.data &&
                  typeof nameChecker.data.data === 'object'
                ) {
                  if (
                    'response' in nameChecker.data.data &&
                    nameChecker.data.data.response &&
                    typeof nameChecker.data.data.response === 'object'
                  ) {
                    if (
                      'canCreate' in nameChecker.data.data.response &&
                      typeof nameChecker.data.data.response.canCreate === 'boolean'
                    ) {
                      canCreate = nameChecker.data.data.response.canCreate;
                    }
                  }
                }
              }
            }

            if (!canCreate) {
              setNotifications({
                message: 'Error! A Truck with that name and number already exists in this Company.',
                open: true,
                success: false,
                actionable: false
              });
              return;
            }
          }

          //Create Truck
          const truckRes = await service.post(scriptOP.createEntity, {
            dataJson: {
              splId: truckModalState.abName,
              truckNumber: parseInt(truckModalState.truckId),
              companyId: companyEntityId,
              driver: truck.driver,
              scheduleDate: selectedProject.entityId === defaultProject.entityId ? '' : date,
              province: getProvinceValue(truck.province),
              plate: truck.plate,
              capacity: truck.capacity,
              note: truck.note ?? '',
              noteImage: truck.noteImage ?? '',
              projectId: selectedProject.entityId,
              isAssigned: selectedProject.entityId === defaultProject.entityId ? false : true
            },
            entityType: 'Truck',
            acl: { other: 2 }
          });
          const truckEntityID = postResHelper(truckRes);
          const turckResError = responseErrorHelper(truckRes);
          if (turckResError) {
            setNotifications({
              message: 'Error! Something went wrong saving your change.',
              open: true,
              success: false,
              actionable: false
            });
            return;
          }

          if (truckEntityID) {
            const newTruck: Truck = {
              splId: truckModalState.abName,
              truckNumber: parseInt(truckModalState.truckId),
              companyId: companyEntityId,
              driverId: '',
              driver: truck.driver,
              scheduleDate: selectedProject.entityId === defaultProject.entityId ? '' : date,
              province: truck.province,
              plate: truck.plate,
              capacity: 10,
              note: truck.note ?? '',
              noteImage: truck.noteImage ?? '',
              isAssigned: selectedProject.entityId !== defaultProject.entityId ? true : false,
              entityId: truckEntityID,
              version: 1,
              projectId: selectedProject.entityId
            };

            const newCompany: Company = {
              entityId: companyEntityId,
              version: 1,
              name: selectedCompany.name
            };

            const updatedTrucks = [...coreState.trucks, newTruck];
            const updatedCompanies = coreState.companies.find((c) => c.entityId === companyEntityId)
              ? [...coreState.companies]
              : [...coreState.companies, newCompany];

            updateCoreState({
              type: CoreActionTypes.ADD_TRUCK,
              payload: {
                ...coreState,
                trucks: updatedTrucks,
                companies: updatedCompanies
              }
            });

            setTruckModalState({
              ...truckModalState,
              isLoading: false,
              truck: { ...truckModalState.truck, entityId: truckEntityID }
            });
          } else {
            setTruckModalState({ ...truckModalState, isLoading: false });
          }

          setNotifications({
            message: 'Success! New truck created.',
            open: true,
            success: true,
            actionable: false
          });
        }
      } catch (e) {
        setNotifications({
          message: 'Something went wrong, please try again.',
          open: true,
          success: false,
          actionable: false
        });
      }
    }
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={onClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{ backdrop: { timeout: 500 } }}>
      <Fade in={open}>
        <Box sx={style}>
          <Header>
            <SubTitle style={{ color: 'white' }}>{label}</SubTitle>
            <IconButton onClick={onClose} sx={{ height: 45, width: 45 }}>
              <IoClose style={{ height: 40, width: 40, color: 'white' }} />
            </IconButton>
          </Header>
          <Content>
            <FormBase>
              <Column>
                <SubTitle style={{ alignSelf: 'flex-start', margin: 0 }}>Company</SubTitle>
                <FormControl
                  fullWidth
                  variant="filled"
                  sx={{
                    m: 1,
                    minWidth: 120,
                    '& .MuiFilledInput-root': {
                      backgroundColor: 'rgba(244, 245, 253, 0.6)',
                      height: '48px'
                    }
                  }}>
                  <InputLabel>Choose Existing Company</InputLabel>
                  <Select
                    value={truckModalState.selectedCompany.name}
                    onChange={handleSelectCompany}
                    fullWidth>
                    {truckModalState.companies.map((p) => (
                      <MenuItem value={p.name} key={p.entityId}>
                        {p.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <SubTitle style={{ alignSelf: 'flex-start', margin: 0, marginTop: 22.5 }}>
                  Or
                </SubTitle>
                <StyledTextField
                  label="Type a New Company Name"
                  id="filled-size-small"
                  onFocus={() => {
                    setModalErrors({ ...modalErrors, company: '' });
                  }}
                  sx={{ marginTop: 1 }}
                  error={modalErrors.company !== ''}
                  helperText={modalErrors.company}
                  onChange={(e) => {
                    handleCreateCompany(e.target.value);
                  }}
                  value={truckModalState.newCompany.name}
                  variant="filled"
                  fullWidth
                  size="small"
                />
                <SubTitle style={{ alignSelf: 'flex-start', margin: 0, marginTop: 16 }}>
                  SPL Truck ID
                </SubTitle>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    width: '100%',
                    justifyContent: 'space-between',
                    marginTop: 8
                  }}>
                  <StyledTextField
                    label="Abbreviation"
                    id="filled-size-small"
                    variant="filled"
                    onFocus={() => {
                      setModalErrors({ ...modalErrors, abName: '' });
                    }}
                    error={modalErrors.abName !== ''}
                    helperText={modalErrors.abName}
                    value={truckModalState.abName}
                    required
                    width={150}
                    size="small"
                    onChange={(e) => {
                      handleNewTruckInput(e.target.value, 'splId');
                    }}
                  />
                  <StyledTextField
                    label="Truck Number"
                    id="filled-size-small"
                    onFocus={() => {
                      setModalErrors({ ...modalErrors, truckId: '' });
                    }}
                    value={truckModalState.truckId}
                    error={modalErrors.truckId !== ''}
                    helperText={modalErrors.truckId}
                    type="number"
                    required
                    variant="filled"
                    size="small"
                    width={120}
                    onBlur={() => {
                      const isNum = numberTest.test(truckModalState.truckId);
                      if (!isNum) {
                        setModalErrors({ ...defaultErrors, truckId: 'Numbers only.' });
                      }
                    }}
                    onChange={(e) => {
                      handleNewTruckInput(e.target.value, 'truckId');
                    }}
                    sx={{
                      '& .MuiFilledInput-root': {
                        width: '90px !important'
                      },
                      '&: .MuiTextField-root': {
                        width: '90px !important'
                      }
                    }}
                  />
                </div>
                <SubTitle style={{ alignSelf: 'flex-start', margin: 0, marginTop: 16 }}>
                  License Plate
                </SubTitle>
                <div
                  style={{
                    height: 120,
                    display: 'flex',
                    alignItems: 'flex-start',
                    width: '100%',
                    marginTop: 8,
                    justifyContent: 'space-between'
                  }}>
                  <FormControl
                    variant="filled"
                    sx={{
                      '& .MuiFilledInput-root': {
                        backgroundColor: 'rgba(244, 245, 253, 0.6)',
                        height: '48px'
                      }
                    }}>
                    <InputLabel>Province</InputLabel>
                    <Select value={truckModalState.truck.province} onChange={handleChangeProvince}>
                      {provinces.map((p) => (
                        <MenuItem value={p} key={p}>
                          {p}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <StyledTextField
                    label="Plate Number"
                    id="filled-size-small"
                    value={truckModalState.truck.plate}
                    error={modalErrors.plate !== ''}
                    helperText={modalErrors.plate}
                    variant="filled"
                    size="small"
                    onFocus={() => {
                      setModalErrors({ ...modalErrors, plate: '' });
                    }}
                    onChange={(e) => {
                      handleNewTruckInput(e.target.value, 'plate');
                    }}
                  />
                </div>
                <NoteContainer>
                  <SubTitle style={{ alignSelf: 'flex-start', margin: 0 }}>
                    Note (32 character max)
                  </SubTitle>
                  <StyledTextField
                    id="filled-size-small"
                    value={truckModalState.truck.note}
                    variant="filled"
                    onBlur={(e) => {
                      if (e.target.value.length > 32) {
                        setModalErrors({ ...modalErrors, note: '32 character max' });
                      }
                    }}
                    error={modalErrors.note !== ''}
                    helperText={`${truckModalState.truck.note?.length ?? 0} / 32`}
                    onFocus={() => {
                      setModalErrors({ ...modalErrors, note: '' });
                    }}
                    fullWidth
                    multiline
                    size="small"
                    rows={1}
                    onChange={(e) => {
                      handleNewTruckInput(e.target.value, 'notes');
                    }}
                  />
                </NoteContainer>
              </Column>
              <Column>
                <SubTitle style={{ alignSelf: 'flex-start', margin: 0 }}>Driver</SubTitle>
                <StyledTextField
                  label="First Name"
                  id="filled-size-small"
                  sx={{ marginTop: 1 }}
                  required
                  error={modalErrors.fName !== ''}
                  helperText={modalErrors.fName}
                  onBlur={(e) => {
                    if (!e.target.value) {
                      setModalErrors({ ...modalErrors, fName: 'This field is required.' });
                    }
                  }}
                  value={truckModalState.truck.driver.fname}
                  variant="filled"
                  fullWidth
                  size="small"
                  onFocus={() => {
                    setModalErrors({ ...modalErrors, fName: '' });
                  }}
                  onChange={(e) => {
                    handleDriverCreate(e.target.value, 'firstName');
                  }}
                />
                <StyledTextField
                  label="Last Name"
                  id="filled-size-small"
                  sx={{ marginTop: 1 }}
                  required
                  error={modalErrors.lName !== ''}
                  helperText={modalErrors.lName}
                  onFocus={() => {
                    setModalErrors({ ...modalErrors, lName: '' });
                  }}
                  onBlur={(e) => {
                    if (!e.target.value) {
                      setModalErrors({ ...modalErrors, lName: 'This field is required.' });
                    }
                  }}
                  value={truckModalState.truck.driver.lname}
                  variant="filled"
                  fullWidth
                  size="small"
                  onChange={(e) => {
                    handleDriverCreate(e.target.value, 'lastName');
                  }}
                />
                <StyledTextField
                  label="Phone Number"
                  id="filled-size-small"
                  sx={{ marginTop: 1 }}
                  onFocus={() => {
                    setModalErrors({ ...modalErrors, contactNumber: '' });
                  }}
                  error={modalErrors.contactNumber !== ''}
                  helperText={modalErrors.contactNumber}
                  value={truckModalState.truck.driver.phone}
                  variant="filled"
                  fullWidth
                  size="small"
                  onChange={(e) => {
                    handleDriverCreate(e.target.value, 'number');
                  }}
                />
                <SubTitle style={{ alignSelf: 'flex-start', margin: 0, marginTop: 16 }}>
                  Project (optional)
                </SubTitle>
                <FormControl
                  fullWidth
                  variant="filled"
                  sx={{
                    m: 1,
                    minWidth: 120,
                    '& .MuiFilledInput-root': {
                      backgroundColor: 'rgba(244, 245, 253, 0.6)',
                      height: '48px'
                    }
                  }}>
                  <InputLabel>Select Project ...</InputLabel>
                  <Select
                    value={truckModalState.selectedProject.entityId}
                    disabled={shouldAssign === false}
                    endAdornment={
                      truckModalState.selectedProject.entityId ===
                      defaultProject.entityId ? undefined : (
                        <IButton
                          label="Delete Selection"
                          height="30"
                          width="30"
                          disabled={shouldAssign === false}
                          onClick={() => {
                            setTruckModalState({
                              ...truckModalState,
                              selectedProject: defaultProject
                            });
                          }}>
                          <MdClear
                            style={{
                              height: 25,
                              width: 25,
                              color: shouldAssign === false ? lightGrey : theme.palette.error.main
                            }}
                          />
                        </IButton>
                      )
                    }
                    onChange={(e) => {
                      handleSelectProject(e);
                    }}
                    fullWidth>
                    {truckModalState.projects.map((p) => (
                      <MenuItem value={p.entityId} key={p.entityId}>
                        {p.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <SubTitle style={{ alignSelf: 'flex-start', margin: 0, marginTop: 8 }}>
                  Load Capacity (m3)
                </SubTitle>
                <StyledTextField
                  id="filled-size-small"
                  value={truckModalState.capcityInput}
                  error={modalErrors.capacity !== ''}
                  helperText={modalErrors.capacity}
                  required
                  variant="filled"
                  sx={{ marginTop: 1, alignSelf: 'flex-start' }}
                  size="small"
                  onFocus={() => {
                    setModalErrors({ ...modalErrors, capacity: '' });
                  }}
                  onChange={(e) => {
                    handleNewTruckInput(e.target.value, 'capacity');
                  }}
                />
                <SubTitle
                  style={{ alignSelf: 'flex-start', margin: 0, marginTop: 8, marginBottom: 8 }}>
                  Daily Start Time {getDate()}
                </SubTitle>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <div id="start-time-picker" style={{ alignSelf: 'flex-start' }}>
                    <TimePicker
                      label="Time"
                      disabled={
                        truckModalState.selectedProject.entityId === defaultProject.entityId &&
                        !shouldAssign
                      }
                      value={time}
                      onChange={(newValue) => {
                        if (newValue?.isValid()) {
                          const value = dayjs(newValue).toISOString();
                          const tIndex = value.indexOf('T');
                          const time = value.substring(tIndex);

                          const d = date.substring(0, date.indexOf('T'));
                          setDate(d + time);

                          setTime(newValue);
                        }
                      }}
                      renderInput={(params) => (
                        <StyledTextField {...params} variant="filled" size="small" />
                      )}
                    />
                  </div>
                </LocalizationProvider>
              </Column>
            </FormBase>
          </Content>
          <Footer>
            <Button
              variant="outlined"
              label="Delete Truck"
              onClick={onDelete}
              styles={{ marginRight: 20 }}
              size="normal"
              color="warning"
            />
            <Button
              variant="outlined"
              label="Cancel"
              onClick={onClose}
              styles={{ marginRight: 20 }}
              size="normal"
              color="secondary"
            />
            <Button
              variant="contained"
              label="Confirm"
              onClick={() => {
                mode === 'New' ? handleSubmitCreate() : handleSubmitEdit();
              }}
              size="normal"
              color="secondary"
            />
          </Footer>
          {truckModalState.isLoading && (
            <ModalLoadingContainer>
              <Spinner />
            </ModalLoadingContainer>
          )}

          {notificationState.open && !notificationState.actionable && (
            <ModalLoadingContainer>
              <FeedbackContainer>
                {notificationState.success ? (
                  <AiFillCheckCircle size={50} color={theme.palette.success.main} />
                ) : (
                  <AiFillWarning size={50} color={theme.palette.error.main} />
                )}
                <SubTitle>{notificationState.message}</SubTitle>
              </FeedbackContainer>
            </ModalLoadingContainer>
          )}

          <NotificationPopup
            open={notificationState.open && notificationState.actionable}
            isSuccess={notificationState.success}
            actionable={notificationState.actionable}
            handleConfirm={notificationState.handleConfirm}
            title={notificationState.title}
            message={notificationState.message}
            handleCancel={() => {
              setNotifications({
                open: false,
                success: true,
                message: '',
                title: undefined,
                actionable: false
              });
            }}
            borderRadius={0}
          />
        </Box>
      </Fade>
    </Modal>
  );
};

const style = {
  // eslint-disable-next-line @typescript-eslint/prefer-as-const
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  minHeight: '700px',
  height: '75%',
  maxWidth: '950px',
  width: '55%',
  borderRadius: '25px'
};

const FeedbackContainer = styled.div`
  height: 125px;
  width: 400px;
  padding: 5px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.background.paper};
`;

const Header = styled.div`
  height: 50px;
  background-color: ${({ theme }) => theme.palette.primary.main};
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  padding: 0 20px 0 20px;
`;

const Footer = styled.div`
  height: 50px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  padding: 0 20px 0 20px;
`;

const Content = styled.div`
  position: relative;
  height: calc(100% - 100px);
  width: 100%;

  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  padding: 0 20px;
`;

const FormBase = styled.div`
  height: 80%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Column = styled.div`
  height: 100%;
  width: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding: 0 20px;
`;

const NoteContainer = styled.div`
  width: 100%;
  align-self: flex-start;
`;
