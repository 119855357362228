import { CoreState } from './CoreContext.interface';

export enum CoreActionTypes {
  SET_USER = 'SET_USER',
  SET_LOADING = 'SET_LOADING',
  SET_TRUCKS = 'SET_TRUCKS',
  SET_COMPANIES = 'SET_COMPANIES',
  SET_LOADTYPES = 'SET_LOADTYPES',
  SET_INIT_LOAD = 'SET_INIT_LOAD',
  SET_MATERIALS = 'SET_MATERIALS',
  SET_DELIV_DEF = 'SET_DELIV_DEF',
  SET_MATERIAL_DELIV = 'SET_MATERIAL_DELIV',
  SET_RECEIVING_SITES = 'SET_RECEIVING_SITES',
  ADD_TRUCK = 'ADD_TRUCK',
  SET_PROJECT = 'SET_PROJECT',
  SET_PROJECT_ADDED = 'SET_PROJECT_ADDED',
  ADD_LOADTYPE = 'ADD_LOADTYPE',
  INIT_RE_SITE = 'INIT_RE_SITE',
  SET_USERS = 'SET_USERS'
}

export interface CoreActions {
  type: CoreActionTypes;
  payload: CoreState;
}
