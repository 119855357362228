import React, { ReactElement } from 'react';
import { Tooltip } from '@mui/material';

export const Truncation = ({
  children,
  label,
  maxLength
}: {
  children: JSX.Element;
  label: string;
  maxLength: number;
}): ReactElement => {
  return label.length > maxLength ? <Tooltip title={label}>{children}</Tooltip> : children;
};
