/* eslint-disable prettier/prettier */
import React, { ReactElement, useEffect, useState } from 'react';
import { ContentContainer } from '../component/containers';
import Box from '@mui/material/Box/Box';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import {
  ActionCell,
  Caption,
  CellStatus,
  ExportBar,
  IconButton,
  SubTitle,
  SubTitle2
} from '../component/atoms';
import {
  Avatar,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Theme,
  useTheme
} from '@mui/material';
import UserIcon from '../asset/icon/user.png';
import { defaultProject, defaultUser, Project, rw, User } from '../types';
import { useErrors, useLoading, useService } from '../hooks';
import useCoreState from '../hooks/useCoreState';
import {
  scriptOP,
  decodeUsers,
  reasonCode,
  errorMessage,
  ReqError,
  postResHelper
} from '../network';
import { NoData } from './staticPages';
import { UserModal } from '../component/modals';
import { CoreActionTypes } from '../context';
import styled from 'styled-components';
import { decontructPackedId, getRoleValue, userRoles } from '../util';
import EditIcon from '@mui/icons-material/Edit';
import { lighterBlue } from '../theme';
type PageState = {
  selectedUser: User;
  isModalOpen: boolean;
  selectedRoleFilter: string;
  roleFilters: string[];
  selectedProjectFilter: Project;
  projectFilters: Project[];
  users: User[];
};

const AllProject: Project = { ...defaultProject, name: 'All', entityId: '-1' };

const defaultPageState = {
  selectedUser: defaultUser,
  isModalOpen: false,
  users: [],
  selectedRoleFilter: 'All',
  roleFilters: ['All'],
  selectedProjectFilter: AllProject,
  projectFilters: [AllProject]
};

const columns = (
  theme: Theme,
  setSelected: (s: User) => void,
  disableUser: (s: User) => void,
  hasPermission: boolean
): GridColDef[] => {
  return [
    {
      field: 'Team Member',
      width: 250,
      disableColumnMenu: true,
      valueGetter: (params) => params.row.name,
      renderCell: (v) => {
        const data = v.row as User;
        return (
          <div
            style={{
              width: 250,
              height: 52,
              display: 'flex',
              justifyContent: 'space-evenly',
              alignItems: 'center'
            }}>
            <Avatar
              sx={{
                width: 35,
                height: 35,
                color: theme.palette.primary.main,
                backgroundColor: theme.palette.primary.light
              }}>
              {data.lastName && data.firstName ? (
                data.firstName[0].toUpperCase() + data.lastName[0].toUpperCase()
              ) : (
                <img src={UserIcon} height="20px" alt="user icon" />
              )}
            </Avatar>
            <div
              style={{
                width: '80%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'flex-start'
              }}>
              <SubTitle2 style={{ margin: 0, fontWeight: 500 }}>
                {data.firstName + data.lastName}
              </SubTitle2>
              <SubTitle2 style={{ margin: 0, fontWeight: 400 }}>{data.emailAddress}</SubTitle2>
            </div>
          </div>
        );
      }
    },
    {
      field: 'phone',
      headerName: 'Phone Number',
      filterable: false,
      hideable: false,
      width: 180
    },
    {
      field: 'role',
      headerName: 'Role',
      filterable: false,
      hideable: false,
      width: 150
    },
    {
      field: 'lastLogin',
      headerName: 'Last Login',
      filterable: false,
      hideable: false,
      type: 'string',
      width: 150
    },
    {
      field: 'isActive',
      headerName: 'Status',
      filterable: false,
      hideable: false,
      width: 100,
      renderCell: (v) => {
        const data = v.row as User;
        return <CellStatus isActive={data.isActive} />;
      }
    },
    {
      field: 'Actions',
      width: 100,
      sortable: false,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      renderCell: (v) => {
        const data = v.row as User;

        return hasPermission ? (
          <ActionCell data={data} setEditSelected={setSelected} setDisableSelected={disableUser} />
        ) : (
          <IconButton
            onClick={() => {
              setSelected(data);
            }}
            label="Edit">
            <EditIcon sx={{ height: 32, width: 32 }} />
          </IconButton>
        );
      }
    }
  ];
};

export const Users = (): ReactElement => {
  const theme = useTheme();
  const [pageState, setPageState] = useState<PageState>(defaultPageState);
  const { coreState, updateCoreState } = useCoreState();
  const { setLoading } = useLoading();
  const hasPermission = coreState.permission.user >= rw;
  const viewOtherPermission =
    Object.values(coreState.permission).reduce((sum, p) => sum + p, 0) > 36;

  const { setError } = useErrors();
  const { service } = useService();

  const initLoad = async () => {
    setLoading(true);
    try {
      const users = await service.post(scriptOP.listUsers, {});
      const decodedUsers = decodeUsers(users);

      if ('reasone_code' in decodedUsers) {
        if (decodedUsers.reasone_code === reasonCode.sessionTimeout) {
          setError({ networkError: errorMessage.sessionTimeout });
          return;
        }

        setError({ networkError: 'There was an error fetching users. Please try again.' });
        return;
      }

      if (Array.isArray(decodedUsers)) {
        const users = decodedUsers as unknown as User[];

        setPageState({
          ...defaultPageState,
          users: viewOtherPermission
            ? decodedUsers
            : users.filter((u) => u.profileId === coreState.appUser.profileId),
          selectedUser: defaultUser,
          isModalOpen: false,
          roleFilters: ['All', ...Object.values(userRoles)],
          projectFilters: [AllProject, ...coreState.projects]
        });
        updateCoreState({
          type: CoreActionTypes.SET_USERS,
          payload: { ...coreState, users: decodedUsers }
        });
      }
    } catch (error) {
      if (error && typeof error === 'object') {
        const e = error as ReqError;
        if (e.reason_code === reasonCode.sessionTimeout) {
          setError({ networkError: errorMessage.sessionTimeout });
          return;
        } else if (e.reason_code === reasonCode.packactIdError) {
          const id = decontructPackedId(e);
          if (id !== 0) {
            service.setPacketID(id);
            initLoad();
            return;
          }
          return;
        }
      }
    } finally {
      setLoading(false);
    }
  };

  const handleModalClose = (): void => {
    initLoad();
  };
  const setSelected = (selected: User): void => {
    setPageState({
      ...pageState,
      selectedUser: selected,
      isModalOpen: true
    });
  };

  const disableUser = async (u: User) => {
    if (u) {
      try {
        const update = await service.post(scriptOP.updateUser, {
          profileId: u.profileId,
          emailAddress: u.emailAddress,
          role: getRoleValue(u.role),
          projects: [...u.projects],
          phone: u.phone,
          firstName: u.firstName,
          lastName: u.lastName,
          isActive: !u.isActive
        });
        const id = postResHelper(update);
        if (id) {
          const updatedUsers = pageState.users.map((user) => {
            if (user.profileId === id) {
              return { ...user, isActive: !user.isActive };
            }
            return user;
          });

          setPageState({ ...pageState, users: [...updatedUsers] });
        }
      } catch (error) {
        if (error && typeof error === 'object') {
          const e = error as ReqError;
          if (e.reason_code === reasonCode.sessionTimeout) {
            setError({ networkError: errorMessage.sessionTimeout });
            return;
          } else if (e.reason_code === reasonCode.packactIdError) {
            setPageState({
              ...defaultPageState,
              users: coreState.users,
              selectedUser: defaultUser,
              isModalOpen: false,
              roleFilters: ['All', ...Object.values(userRoles)],
              projectFilters: [AllProject, ...coreState.projects]
            });
          }
        }
      }
    }
    //diable
  };

  const handleChangeRoleFilter = (event: SelectChangeEvent): void => {
    const value = event.target.value;
    let userFiltered: User[] = [];
    if (pageState.selectedProjectFilter.entityId === AllProject.entityId) {
      userFiltered = [...coreState.users];
    } else {
      userFiltered = [
        ...coreState.users.filter((u) => {
          return (
            u.projects.find((p) => p === pageState.selectedProjectFilter.entityId) !== undefined
          );
        })
      ];
    }
    if (value === 'All') {
      setPageState({
        ...pageState,
        users: [...userFiltered],
        selectedRoleFilter: value
      });
    } else {
      setPageState({
        ...pageState,
        users: userFiltered.filter((u) => u.role === value),
        selectedRoleFilter: value
      });
    }
  };
  const handleChangeProjectFilter = (event: SelectChangeEvent): void => {
    const value = event.target.value;
    let userFiltered: User[] = [];

    if (pageState.selectedRoleFilter === 'All') {
      userFiltered = [...coreState.users];
    } else {
      userFiltered = [...coreState.users.filter((u) => u.role === pageState.selectedRoleFilter)];
    }

    if (value === AllProject.entityId) {
      setPageState({
        ...pageState,
        users: userFiltered,
        selectedProjectFilter: AllProject
      });
    } else {
      const pFound = pageState.projectFilters.find((p) => p.entityId === value);
      if (pFound)
        setPageState({
          ...pageState,
          users: userFiltered.filter((u) => {
            return u.projects.find((p) => p === value) !== undefined;
          }),
          selectedProjectFilter: pFound
        });
    }
  };
  useEffect(() => {
    initLoad();
  }, []);

  return (
    <ContentContainer header title="Team Management">
      <Box
        sx={{
          height: '120%',
          width: '100%',
          minHeight: '990px',
          backgroundColor: 'white',
          padding: 2,
          borderRadius: '15px',
          boxShadow: theme.shadows[1]
        }}>
        <SubTitle>
          View all Members of your Team, their assigned Roles, their current account status as well
          as when the were added and last logged on.
        </SubTitle>
        <Caption>
          You may also add new Members to the Team here as well as edit details of existing members.
        </Caption>
        <Caption>Use the available Actions below to set a Team Member’s account status..</Caption>
        <Header>
          <FilterContainer>
            <div style={{ width: '30%' }}>
              <SubTitle style={{ alignSelf: 'flex-start', margin: 0 }}>
                Filter View By Role
              </SubTitle>
              <FormControl
                variant="filled"
                sx={{
                  minWidth: 210,
                  '& .MuiFilledInput-root': {
                    backgroundColor: 'rgba(244, 245, 253, 0.6)',
                    height: '56px'
                  }
                }}>
                <InputLabel>Assignment Status</InputLabel>
                <Select value={pageState.selectedRoleFilter} onChange={handleChangeRoleFilter}>
                  {pageState.roleFilters.map((p) => (
                    <MenuItem value={p} key={p}>
                      {p}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div style={{ width: '30%' }}>
              <SubTitle style={{ alignSelf: 'flex-start', margin: 0 }}>
                Filter View By Project
              </SubTitle>
              <FormControl
                variant="filled"
                sx={{
                  minWidth: 210,
                  '& .MuiFilledInput-root': {
                    backgroundColor: 'rgba(244, 245, 253, 0.6)',
                    height: '56px'
                  }
                }}>
                <InputLabel>Assignment Status</InputLabel>
                <Select
                  value={pageState.selectedProjectFilter.entityId}
                  onChange={handleChangeProjectFilter}>
                  {pageState.projectFilters.map((p) => (
                    <MenuItem value={p.entityId} key={p.entityId}>
                      {p.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </FilterContainer>
        </Header>
        {coreState.users.length === 0 ? (
          <NoData />
        ) : (
          <Box
            sx={{
              height: '100%',
              width: '100%',
              minHeight: '670px',
              '& .data-grid-row-blue': {
                bgcolor: `${lighterBlue}`,
                '&: hover': {
                  '&: .MuiDataGrid-cell': { bgcolor: `rgba(22,153,213,0.3)` },
                  bgcolor: `rgba(22,153,213,0.3)`
                }
              }
            }}>
            <DataGrid
              className={`#export-users-`} //how we get the file name for export donot delete or change format
              getRowClassName={(p) => {
                if (p.indexRelativeToCurrentPage % 2 === 1) {
                  return `data-grid-row-blue`;
                } else {
                  return `data-grid-row-white`;
                }
              }}
              columns={columns(theme, setSelected, disableUser, hasPermission)}
              rows={pageState.users}
              autoHeight
              pageSize={10}
              rowsPerPageOptions={[10]}
              checkboxSelection={false}
              disableSelectionOnClick
              experimentalFeatures={{ newEditingApi: true }}
              components={{ Toolbar: ExportBar }}
              getRowId={(row) => row.profileId}
            />
          </Box>
        )}
      </Box>
      <UserModal
        open={pageState.isModalOpen}
        data={pageState.selectedUser}
        mode="edit"
        handleClose={handleModalClose}
      />
    </ContentContainer>
  );
};
const Header = styled.div`
  width: 100%;
  height: 145px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 15px;
`;

const FilterContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
