import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { lightGrey } from '../../theme';

export type ButtonType = 'outlined' | 'contained' | 'text';
export type ButtonColor = 'primary' | 'secondary' | 'error' | 'success' | 'warning' | 'white';
export type ButtonSize = 'xl' | 'large' | 'normal' | 'small' | 'xs';

type Props = {
  onClick: () => void;
  label: string;
  variant: ButtonType;
  color: ButtonColor;
  size: ButtonSize;
  disabled?: boolean;
  styles?: React.CSSProperties;
};

const ButtonContainer = styled.button<{
  variant: ButtonType;
  color: ButtonColor;
  size: ButtonSize;
  isDisabled: boolean;
}>`
  ${({ variant, color, size, theme, isDisabled }) => {
    const palette = theme.palette;
    const c =
      color === 'secondary'
        ? palette.secondary.main
        : color === 'error'
        ? palette.error.main
        : color === 'success'
        ? palette.success.main
        : color === 'warning'
        ? palette.warning.main
        : color === 'white'
        ? palette.primary.contrastText
        : palette.primary.main;

    let bgColor = c;
    let borderColor = c;
    const textColor = variant === 'contained' ? palette.primary.contrastText : c;

    const hoverColor =
      color === 'secondary'
        ? palette.secondary.dark
        : color === 'error'
        ? palette.error.dark
        : color === 'success'
        ? palette.success.dark
        : color === 'warning'
        ? palette.warning.dark
        : color === 'white'
        ? '#d7d7d7'
        : isDisabled === true
        ? lightGrey
        : palette.primary.dark;

    if (variant === 'text') {
      bgColor = palette.primary.contrastText;
      borderColor = palette.primary.contrastText;
    } else if (variant === 'outlined') {
      bgColor = 'transparent';
    }
    return `
    margin: 5px 0;
    box-sizing: border-box;
    cursor: ${isDisabled === true ? 'not-allowed' : 'pointer'};
    border-radius: 5px;
    color: ${isDisabled === true ? lightGrey : textColor};
    background-color: ${isDisabled === true ? lightGrey : bgColor};
    border: ${variant === 'text' ? 'none' : '1px solid ' + borderColor};
    height: ${size === 'xl' ? '60px' : '30px'};
    width: ${
      size === 'xl' || size === 'large'
        ? '300px'
        : size === 'normal'
        ? '120px'
        : size === 'small'
        ? '60px'
        : '45px'
    };
     &: hover {
         background-color: ${variant === 'contained' && hoverColor};
         color:  ${variant !== 'contained' && hoverColor};
         border-color: ${variant === 'outlined' && hoverColor};
     }
     > label {
       text-transform: capitalize;
       cursor: ${isDisabled === true ? 'not-allowed' : 'pointer'};
       font-size: ${theme.fontSize.buttonText};
       font-weight: ${theme.fontWeight.body};
        
    }
    `;
  }}
`;

const Label = styled.label``;

export const Button = ({
  onClick,
  label,
  variant,
  color,
  disabled,
  size,
  styles
}: Props): ReactElement => {
  return (
    <ButtonContainer
      className="custom_button"
      style={{ ...styles }}
      variant={variant}
      color={color}
      disabled={disabled}
      isDisabled={disabled ? disabled : false}
      size={size}
      onClick={(e) => {
        e.preventDefault();
        onClick();
      }}>
      <Label>{label}</Label>
    </ButtonContainer>
  );
};
