import { ResType } from '../network';
import { BaseType } from './users';
import { Address, Contact } from './utilityTypes';

export interface Project extends BaseType {
  name: string;
  projectNumber: number;
  startDate: string;
  isActive: boolean;
  note?: string;
  address: Address;
  contact: Contact;
  deliveryConfirmations: string[];
}
export const projectFrom = (value: ResType<Omit<Project, 'version' | 'entityId'>>): Project => {
  return {
    entityId: value.entityId,
    version: value.version,
    name: value.data.name,
    startDate: value.data.startDate,
    address: value.data.address,
    contact: value.data.contact,
    isActive: value.data.isActive,
    projectNumber: value.data.projectNumber ?? -1,
    note: value.data.note,
    deliveryConfirmations: value.data.deliveryConfirmations
  };
};

export const dataFromProject = (l: Project) => {
  return {
    // name: l.name,
    // special_requirements: l.special_requirements,
    // material_name: l.material_name,
    // qte: l.qte,
    // qte_type: l.qte_type,
    // isPile: true,
    // projectId: l.projectId,
    // zone_name: l.name,
    // notes: l.notes,
    // isActive: l.isActive
  };
};
