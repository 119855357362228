import React, { ReactElement, useEffect, useState } from 'react';

import styled from 'styled-components';
import { Button, SubTitle, SubTitle2 } from '../component/atoms';
import { ContentContainer, DashboardDropdown } from '../component/containers';
import { CoreActionTypes } from '../context';
import { useErrors, useLoading, useService, useUser } from '../hooks';
import useCoreState from '../hooks/useCoreState';
import { errorMessage, fetchAll, reasonCode, ReqError } from '../network';
import {
  Company,
  DashboardDetail,
  defaultAddress,
  defaultContact,
  DeliveryConfirmation,
  LoadType,
  loadTypeFrom,
  Material,
  Project,
  projectFrom,
  Site,
  Truck,
  User
} from '../types';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  useTheme
} from '@mui/material';
import { decontructPackedId, getProvince, getRole, m3 } from '../util';
import ExportPromptModal from '../component/modals/exportPromptModal';
const filterOptions = ['All', 'Active', 'Disabled'];

export type DashboardProject = {
  name: string;
  projectId: string;
  summary: { qte: number; total: number; qte_type: string };
  details: DashboardDetail[];
  startDate: string;
  isActive: boolean;
};

export const DashboardPage = (): ReactElement => {
  const { coreState, updateCoreState } = useCoreState();

  const [selectedFilter, setFilter] = useState<string>('Active');
  const [summaries, setSummaries] = useState<DashboardProject[]>([]);
  const [displaySummaries, setDisplaySummaries] = useState<DashboardProject[]>([]);
  const [pageError, setPageError] = useState<boolean>(false);
  const [requestCSVExport, setRequestCSVExport] = useState<boolean>(false);
  const { setLoading } = useLoading();
  const { setError } = useErrors();
  const { service } = useService();
  const theme = useTheme();
  const { user } = useUser();

  const initApplication = async () => {
    setLoading(true);
    let trucks: Truck[] = [];
    let companies: Company[] = [];
    let loadTypes: LoadType[] = [];
    let projects: Project[] = [];
    let sites: Site[] = [];
    let materials: Material[] = [];
    let delivConfs: DeliveryConfirmation[] = [];
    let users: User[] = [];
    const projectsMapped: DashboardProject[] = [];
    try {
      const allData = await fetchAll(service);

      if (allData && 'users' in allData) {
        const companiesDecoded = allData.truckingCompanies;
        const truckTypesDecoded = allData.trucks;
        const projectsDecoded = allData.projects;
        const sitesDecoded = allData.sites;
        const materialDecoded = allData.materials;
        const delivConfDecoded = allData.deliveryConfirmation;
        const usersDecoded = allData.users;
        const summaries = allData.summaries;
        const loadTypeDecoded = allData.loadTypes;

        companies = [
          ...companiesDecoded.map((c): Company => {
            return {
              version: c.version,
              entityId: c.entityId,
              name: c.data.name,
              contactName: c.data.contactName ?? '',
              contactphone: c.data.contactphone ?? ''
            };
          })
        ];

        trucks = [
          ...truckTypesDecoded.map((t): Truck => {
            return {
              companyId: t.data.companyId,
              driver: { ...t.data.driver },
              scheduleDate: t.data.scheduleDate,
              splId: t.data.splId,
              plate: t.data.plate,
              capacity: t.data.capacity,
              entityId: t.entityId,
              version: t.version,
              driverId: t.data.driverId,
              province: getProvince(t.data.province),
              isAssigned: t.data.isAssigned,
              projectId: t.data.projectId,
              truckNumber: t.data.truckNumber,
              note: t.data.note ? t.data.note : '',
              noteImage: t.data.noteImage ? t.data.noteImage : ''
            };
          })
        ];

        loadTypes = loadTypeDecoded.map(loadTypeFrom);

        projects = [
          ...projectsDecoded
            .sort((x, y) => {
              if (x.data.startDate > y.data.startDate) return -1;
              if (x.data.startDate < y.data.startDate) return 1;
              else return 0;
            })
            .map((p): Project => {
              return projectFrom(p);
            })
        ];

        if (summaries && summaries.length > 0) {
          projects.forEach((p) => {
            const projectFound = summaries.find((s) => s.projectId === p.entityId);
            if (projectFound) {
              projectsMapped.push({
                ...projectFound,
                isActive: p.isActive,
                startDate: p.startDate
              });
            } else {
              //find loadtypes with no summaries

              const filteredLoadTypes = loadTypes.filter((l) => l.projectId === p.entityId);

              projectsMapped.push({
                name: p.name,
                projectId: p.entityId,
                summary: { qte: 0, total: 0, qte_type: m3 },
                details: filteredLoadTypes.map((fl) => {
                  return {
                    loadTypeId: fl.entityId,
                    name: fl.material_name,
                    qte: 0,
                    qte_type: m3,
                    total: 0,
                    loads: 0
                  };
                }),
                startDate: p.startDate,
                isActive: p.isActive
              });
            }
          });
          setSummaries([...projectsMapped]);
          setDisplaySummaries(projectsMapped.filter((pm) => pm.isActive === true));
        }

        sites = [
          ...sitesDecoded.map((s): Site => {
            return {
              name: s.data.name,
              isReceiving: s.data.isReceiving,
              projects: s.data.projects ? [...s.data.projects] : [],
              entityId: s.entityId,
              version: s.version,
              address: s.data.address ? { ...s.data.address } : defaultAddress,
              contact: s.data.contact ? { ...s.data.contact } : defaultContact,
              summary: s.data.summary
            };
          })
        ];

        materials = [
          ...materialDecoded.map((m): Material => {
            return {
              name: m.data.name,
              isActive: m.data.isActive,
              description: m.data.description,
              entityId: m.entityId,
              version: m.version
            };
          })
        ];

        delivConfs = [
          ...delivConfDecoded.map((m): DeliveryConfirmation => {
            return {
              name: m.data.name,
              isActive: m.data.isActive,
              description: m.data.description,
              entityId: m.entityId,
              version: m.version
            };
          })
        ];
        users = [
          ...usersDecoded.map((u) => {
            return {
              ...u,
              role: getRole(u.role),
              name: u.name ? u.name : `${u.firstName} ${u.lastName}`
            };
          })
        ];
      } else {
        setPageError(true);
      }

      updateCoreState({
        type: CoreActionTypes.SET_INIT_LOAD,
        payload: {
          ...coreState,
          trucks,
          loadTypes,
          companies,
          projects,
          sites,
          materials,
          users,
          summaries: [...projectsMapped],
          deliveryConf: [...delivConfs]
        }
      });
    } catch (e) {
      const error = e as ReqError;
      if (error && error.reason_code === reasonCode.packactIdError) {
        const id = decontructPackedId(error);

        if (id !== 0) {
          service.setPacketID(id);
          initApplication();
          return;
        }
        return;
      }
      if (error && error.status === 403 && error.reason_code === reasonCode.sessionTimeout) {
        setError({ networkError: errorMessage.sessionTimeout });
        return;
      }
    }
  };

  const handleSelectFilter = (event: SelectChangeEvent): void => {
    setFilter(event.target.value);
    if (event.target.value === 'All') {
      setDisplaySummaries([...summaries]);
      return;
    }
    if (event.target.value === 'Active') {
      setDisplaySummaries([...summaries.filter((s) => s.isActive)]);
      return;
    }

    if (event.target.value === 'Disabled') {
      setDisplaySummaries([...summaries.filter((s) => !s.isActive)]);
      return;
    }
  };

  useEffect(() => {
    initApplication();
  }, []);

  useEffect(() => {
    if (pageError) {
      initApplication();
    }

    if (!pageError) {
      setLoading(false);
    }
  }, [pageError]);

  return (
    <ContentContainer
      title="All Projects"
      extraActions={
        user.role === 'Super Admin' ? (
          <Button
            variant="outlined"
            color="secondary"
            size="normal"
            onClick={() => {
              setRequestCSVExport(true);
            }}
            label="Export data"
            styles={{ width: '150px', marginRight: '10px' }}
          />
        ) : undefined
      }>
      <ProgressContainer style={{ boxShadow: theme.shadows[1] }}>
        <div
          style={{
            width: '100%',
            height: '90px',
            display: 'flex',
            cursor: 'pointer',
            flexDirection: 'row',
            padding: '0 10px',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
          <div>
            <SubTitle style={{ margin: 0 }}>Project Load Progress</SubTitle>
            <SubTitle2 style={{ margin: 0 }}>
              Click a project progress bar below to view more details
            </SubTitle2>
          </div>
          <FormControl
            variant="filled"
            sx={{
              width: 200,
              minWidth: 100,
              margin: 0,
              '& .MuiFilledInput-root': {
                backgroundColor: 'rgba(244, 245, 253, 0.6)',
                height: '48px'
              }
            }}>
            <InputLabel>Filter Projects</InputLabel>
            <Select value={selectedFilter} onChange={handleSelectFilter}>
              {filterOptions.map((p) => (
                <MenuItem value={p} key={p} className="dashboard-option">
                  {p}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        {displaySummaries.map((p) => (
          <DashboardDropdown key={p.projectId} project={p} />
        ))}
      </ProgressContainer>
      <ExportPromptModal open={requestCSVExport} handleClose={() => setRequestCSVExport(false)} />
    </ContentContainer>
  );
};

const ProgressContainer = styled.div`
  width: 100%;
  min-height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.background.paper};
  border-radius: 25px;
`;
