import { BASE_URL, APP_ID, scriptOP } from './property';
import axios from 'axios';
import NetworkService from './service';
import { User } from '../types';
import { decodeSingleUser } from './helper';
import { getRole } from '../util';

export async function login(username: string, password: string): Promise<unknown> {
  const route = 'authentication/AUTHENTICATE';
  try {
    const result = await axios.create().post(
      BASE_URL + route,
      { username, password },
      {
        headers: { 'X-APPID': APP_ID, 'content-type': 'application/json', 'X-PACKETID': 1 }
      }
    );

    return result;
  } catch (e) {
    return Promise.reject(e);
  }
}

export async function getUser(service: NetworkService): Promise<User | void> {
  try {
    const res = await service.post(scriptOP.aboutme, {});
    const resDecoded = decodeSingleUser(res);
    if (
      typeof resDecoded !== 'string' &&
      typeof resDecoded === 'object' &&
      'profileId' in resDecoded
    ) {
      const user: User = { ...resDecoded, role: getRole(resDecoded.role) };

      return Promise.resolve(user);
    }
  } catch (error) {
    return Promise.reject(error);
  }
}
