import React, { ReactElement, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import { useLoading, useWindowSize } from '../../hooks';
import { MdDashboard, MdOutlineArrowForwardIos } from 'react-icons/md';
import { Box, IconButton } from '@mui/material';
import { Button, Caption, Spinner, SubTitle } from '../atoms';
import { TruckModal, AddLoadTypeModal, AddReceivingSiteModal, UserModal } from '../modals';
import { ContentLoadingContainer } from './LoadingContainer';
import useCoreState from '../../hooks/useCoreState';
import { UserPermission, ro, rw } from '../../types';

const DashboardContainer = styled.div<{ height: number; width: number }>`
  padding: 3%;
  height: ${(props) => props.height - 60}px;
  overflow: hidden;
  position: relative;
  overflow-y: auto !important;
`;

const HeaderContainer = styled.div`
  height: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const HeaderRow = styled.div`
  height: 80px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export type modalBtnType =
  | 'Add Truck'
  | 'Add Load Type'
  | 'Add Receiving Site'
  | 'Add Team Member'
  | 'Edit Truck'
  | 'Edit Load Type';

const getName = (
  path: string,
  permission: UserPermission
): { menuTitle: string; button?: modalBtnType; secondaryMenu?: string; hasPermission: boolean } => {
  if (path.includes('/project/')) {
    return { menuTitle: 'Project', hasPermission: permission.project >= rw };
  }
  switch (path) {
    case '/trucks':
      return { menuTitle: 'Trucks', button: 'Add Truck', hasPermission: permission.truck >= ro };
    case '/newProject':
      return { menuTitle: 'New Project', hasPermission: permission.project >= rw };
    case '/material':
      return { menuTitle: 'Material Configuration', hasPermission: permission.material >= rw };
    case '/receivingSites':
      return {
        menuTitle: 'Receiving Sites',
        button: 'Add Receiving Site',
        hasPermission: permission.site >= rw
      };
    case '/activityLogs':
      return { menuTitle: 'Activity Logs', hasPermission: permission.activity >= rw };
    case '/users':
      return {
        menuTitle: 'Team Management',
        button: 'Add Team Member',
        hasPermission: permission.user >= rw
      };
    case '/users/profile':
      return { menuTitle: 'Team Management', secondaryMenu: 'profile', hasPermission: true };
    default:
      return { menuTitle: '', hasPermission: true };
  }
};

const defaultModalState = {
  truckModal: false,
  loadTypeModal: false,
  siteModal: false,
  userModal: false
};

export const ContentContainer = ({
  children,
  header,
  title,
  extraActions
}: {
  children: JSX.Element | JSX.Element[];
  header?: boolean;
  title: string;
  extraActions?: JSX.Element | JSX.Element[];
}): ReactElement => {
  const { width, height } = useWindowSize();
  const { isLoading } = useLoading();
  const { coreState } = useCoreState();
  const [openModal, setModalOpen] = useState<{
    truckModal: boolean;
    loadTypeModal: boolean;
    siteModal: boolean;
    userModal: boolean;
  }>(defaultModalState);
  const { pathname } = useLocation();

  const theme = useTheme();
  const navigate = useNavigate();

  const handleOpen = (type: modalBtnType) => {
    if (type === 'Add Load Type') {
      setModalOpen({ ...defaultModalState, loadTypeModal: true });
      return;
    }
    if (type === 'Add Receiving Site') {
      setModalOpen({ ...defaultModalState, siteModal: true });
      return;
    }
    if (type === 'Add Truck') {
      setModalOpen({ ...defaultModalState, truckModal: true });
      return;
    }
    if (type === 'Add Team Member') {
      setModalOpen({ ...defaultModalState, userModal: true });
      return;
    }
  };
  const handleClose = (type: modalBtnType) => {
    if (type === 'Add Load Type') {
      setModalOpen({ ...defaultModalState, loadTypeModal: false });
      return;
    }
    if (type === 'Add Receiving Site') {
      setModalOpen({ ...defaultModalState, siteModal: false });
      return;
    }
    if (type === 'Add Truck') {
      setModalOpen({ ...defaultModalState, truckModal: false });
      return;
    }
    if (type === 'Add Team Member') {
      setModalOpen({ ...defaultModalState, userModal: false });
      return;
    }
  };

  const { menuTitle, button, secondaryMenu, hasPermission } = getName(
    pathname,
    coreState.permission
  );

  return isLoading ? (
    <ContentLoadingContainer>
      <Spinner />
    </ContentLoadingContainer>
  ) : (
    <DashboardContainer height={height} width={width} className="dashborad-container">
      {header && (
        <HeaderContainer>
          <IconButton
            onClick={() => {
              navigate('/');
            }}
            sx={{ height: '50px', width: '50px' }}>
            <MdDashboard
              style={{ height: 25, width: 25, color: theme.palette.background.default }}
            />
          </IconButton>
          <MdOutlineArrowForwardIos style={{ height: 24, width: 24, color: '#bdbdbd' }} />
          {secondaryMenu ? (
            <Link to={menuTitle === 'Team Management' ? '/users' : pathname}>
              <Caption>{menuTitle}</Caption>
            </Link>
          ) : (
            <Caption>{menuTitle}</Caption>
          )}
          {secondaryMenu && (
            <>
              <MdOutlineArrowForwardIos style={{ height: 24, width: 24, color: '#bdbdbd' }} />
              <Caption>{secondaryMenu}</Caption>
            </>
          )}
        </HeaderContainer>
      )}
      {title && (
        <HeaderRow>
          <SubTitle>{title}</SubTitle>
          <Box sx={{ alignItems: 'flex-end' }}>
            {extraActions}
            {button && hasPermission && (
              <Button
                variant="outlined"
                color="secondary"
                size="normal"
                onClick={() => {
                  handleOpen(button);
                }}
                label={button as string}
                styles={{ width: button === 'Add Truck' ? undefined : '150px' }}
              />
            )}
            {pathname === '/' && coreState.permission.project >= rw && (
              <Button
                variant="outlined"
                color="secondary"
                size="normal"
                onClick={() => {
                  navigate('/newProject');
                }}
                label="New Project"
                styles={{ width: button === 'Add Truck' ? undefined : '150px' }}
              />
            )}
          </Box>
        </HeaderRow>
      )}
      {children}
      {button && button === 'Add Truck' && (
        <TruckModal
          open={openModal.truckModal}
          handleClose={() => {
            handleClose(button);
          }}
          label={button}
          mode="New"
        />
      )}
      {button && button === 'Add Load Type' && (
        <AddLoadTypeModal
          label={button}
          mode="New"
          open={openModal.loadTypeModal}
          handleClose={() => {
            handleClose(button);
          }}
        />
      )}
      {button && button === 'Add Receiving Site' && (
        <AddReceivingSiteModal
          open={openModal.siteModal}
          handleClose={() => {
            handleClose(button);
          }}
        />
      )}
      {button && button === 'Add Team Member' && (
        <UserModal
          open={openModal.userModal}
          handleClose={() => {
            handleClose(button);
          }}
          mode="new"
        />
      )}
    </DashboardContainer>
  );
};
