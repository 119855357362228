import React, { ReactElement, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import {
  Button,
  CellStatus,
  MultiSelectItem,
  StyledTextField,
  SubTitle,
  IconButton,
  SubTitle2,
  ExportBar,
  Caption,
  CircleProgress,
  ActionCell
} from '../component/atoms';
import {
  ContentContainer,
  fixedFilters,
  LoadTypeCard,
  LoadTypeRow,
  NotificationPopup,
  ProjectElementContianer
} from '../component/containers';
import { useErrors, useLoading, useService } from '../hooks';
import {
  CEOP,
  customEntity,
  readSingle,
  responseErrorHelper,
  reasonCode,
  errorMessage,
  scriptOP,
  ReqError,
  fetchAll,
  loadMapper,
  responseStatus,
  getErrorMessage,
  entityUpdatedResult
} from '../network';
import {
  ActiveLoad,
  DashboardSummary,
  dataFromLoadType,
  defaultActiveLoad,
  defaultLoadType,
  defaultProject,
  initDashboardSummary,
  Load,
  LoadHistory,
  LoadType,
  loadTypeFrom,
  Material,
  Project,
  rw
} from '../types';
import { animated, useSpring } from '@react-spring/web';
import { AiFillCheckCircle, AiOutlineArrowDown } from 'react-icons/ai';
import { MdDashboard, MdOutlineArrowForwardIos, MdOutlineRefresh } from 'react-icons/md';

import {
  Box,
  FormControl,
  IconButton as IButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  useTheme
} from '@mui/material';
import useCoreState from '../hooks/useCoreState';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs } from 'dayjs';
import { provinces } from './newProject';
import {
  decontructPackedId,
  format,
  getDate,
  getToday,
  m3,
  timeDiff,
  timezone,
  truckStatus,
  userRoles,
  validateEmail
} from '../util';
import {
  TruckModal,
  UserModal,
  AddReceivingSiteModal,
  AddLoadTypeModal,
  JobTicketModal,
  MaterialDeliverModal
} from '../component/modals';
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridColumns,
  GridRowParams
} from '@mui/x-data-grid';
import { NoData, NoLoadtype } from './staticPages';
import { CoreActionTypes } from '../context';
import { lightPurple, lighterBlue } from '../theme';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import { projectFrom } from '../types/Project.type';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import SendLoadHistoryModal from '../component/modals/sendLoadHistoryModal';

type PageState = {
  emailLoadHistory?: LoadHistory;
  project: Project;
  selectedTrucks: MultiSelectItem[];
  selectedUsers: MultiSelectItem[];
  selectedReceivingSites: MultiSelectItem[];
  selectedLoadType: LoadType;
  selectedDeliveryConfs: MultiSelectItem[];
  userFilter: string;
  companyFilter: string;
  siteFilter: string;
  deliveryConfsFilter: string;
  projectSum: DashboardSummary;
  trucks: MultiSelectItem[];
  users: MultiSelectItem[];
  receivingSites: MultiSelectItem[];
  deliveryConfs: MultiSelectItem[];
  loadTypeModalMode: 'New' | 'Edit';
  loadTypes: LoadType[];
  activeLoads: ActiveLoad[];
  loadHistories: LoadHistory[];
  allLoadHistories: LoadHistory[];
  loads: Load[]; //all load data goes here, shouldn't mutate this
  filterableLoads: Load[]; //this is the array for filtering
  enableSubmit: boolean;
  isInfoOpen: boolean;
  addTruckModalOpen: boolean;
  addSiteModalOpen: boolean;
  addUserModalOpen: boolean;
  addLoadModalOpen: boolean;
  addDeliveryConfModalOpen: boolean;
  jobTicketModalOpen: boolean;
  jobTicketModalData: { ticket: ActiveLoad; loads: Load[] };
};

const defaultState: PageState = {
  project: defaultProject,
  isInfoOpen: false,
  addTruckModalOpen: false,
  addSiteModalOpen: false,
  addUserModalOpen: false,
  addLoadModalOpen: false,
  addDeliveryConfModalOpen: false,
  jobTicketModalOpen: false,
  jobTicketModalData: { ticket: defaultActiveLoad, loads: [] },
  enableSubmit: false,
  selectedTrucks: [],
  selectedUsers: [],
  selectedReceivingSites: [],
  selectedLoadType: defaultLoadType,
  loadTypes: [],
  trucks: [],
  users: [],
  receivingSites: [],
  activeLoads: [],
  loadHistories: [],
  loadTypeModalMode: 'New',
  loads: [],
  allLoadHistories: [],
  filterableLoads: [],
  userFilter: fixedFilters.selected,
  companyFilter: fixedFilters.selected,
  siteFilter: fixedFilters.selected,
  projectSum: initDashboardSummary,
  selectedDeliveryConfs: [],
  deliveryConfsFilter: fixedFilters.all,
  deliveryConfs: [],
  emailLoadHistory: undefined
};

const defaultNotificationState = {
  open: false,
  success: false,
  message: '',
  actionable: false,
  title: undefined,
  handleConfirm: undefined
};

type PageError = {
  projectName: boolean;
  address: boolean;
  address2: boolean;
  city: boolean;
  dateError: boolean;
  fname: boolean;
  lname: boolean;
  phone: boolean;
  email: boolean;
};

const activeLoadsColumns = (): GridColumns => {
  return [
    {
      field: 'truck',
      headerName: 'Truck ID (Company Name)',
      width: 200,
      hideable: false,
      disableColumnMenu: true
    },
    {
      field: 'loadNumber',
      headerName: 'Current Load Number',
      width: 150,
      hideable: false,
      disableColumnMenu: true
    },
    {
      field: 'loadType',
      headerName: 'Current Load Type',
      width: 150,
      hideable: false,
      disableColumnMenu: true
    },
    {
      field: 'receivingSite',
      headerName: 'Receiving Site*',
      width: 200,
      hideable: false,
      disableColumnMenu: true
    },
    {
      field: 'status',
      headerName: 'Load Status (by truck)',
      width: 200,
      hideable: false,
      disableColumnMenu: true
    },
    {
      field: 'deliveredQte',
      headerName: 'Delivered Today (m3)',
      width: 180,
      hideable: false,
      disableColumnMenu: true
    },
    {
      field: 'whoConfirmed',
      headerName: 'Confirmed by',
      width: 180,
      hideable: false,
      disableColumnMenu: true
    }
  ];
};

const loadHistoryColumns = (emailAction: (row: LoadHistory) => void): GridColumns<LoadHistory> => {
  return [
    {
      field: 'date',
      headerName: 'Date',
      width: 100,
      hideable: false,
      disableColumnMenu: true
    },
    {
      field: 'truckId',
      headerName: 'Truck ID',
      width: 120,
      hideable: false,
      disableColumnMenu: true,
      renderCell: (v) => {
        const data = v.row as LoadHistory;
        return <SubTitle style={{ color: '#1e98d7' }}>{data.truckId}</SubTitle>;
      }
    },
    {
      field: 'loadNo',
      headerName: 'Load No.',
      width: 80,
      hideable: false,
      disableColumnMenu: true
    },
    {
      field: 'loadType',
      headerName: 'Load Type',
      width: 100,
      hideable: false,
      disableColumnMenu: true
    },
    {
      field: 'qte',
      headerName: 'Load Qty',
      width: 80,
      hideable: false,
      disableColumnMenu: true
    },
    {
      field: 'receivingSite',
      headerName: 'Receiving Site',
      width: 150,
      hideable: false,
      disableColumnMenu: true
    },
    {
      field: 'pile',
      headerName: 'Pile',
      width: 80,
      hideable: false,
      disableColumnMenu: true
    },
    {
      field: 'inTime',
      headerName: 'In Time',
      width: 100,
      hideable: false,
      disableColumnMenu: true
    },
    {
      field: 'outTime',
      headerName: 'Out Time',
      width: 100,
      hideable: false,
      disableColumnMenu: true
    },
    {
      field: 'arrivalTime',
      headerName: 'Delivery Time',
      width: 100,
      hideable: false,
      disableColumnMenu: true
    },
    {
      field: 'tripTime',
      headerName: 'Trip Time',
      width: 100,
      hideable: false,
      disableColumnMenu: true
    },
    {
      field: 'tlt',
      headerName: 'Total Load Time',
      width: 100,
      hideable: false,
      disableColumnMenu: true
    },
    {
      field: 'ticketId',
      headerName: 'Job Ticket ID',
      width: 180,
      hideable: false,
      disableColumnMenu: true,
      sortable: false
    },
    {
      field: 'whoConfirmed',
      headerName: 'Confirmed by',
      width: 180,
      hideable: false,
      disableColumnMenu: true,
      sortable: false
    },
    {
      field: 'notes',
      headerName: 'Notes',
      width: 225,
      hideable: false,
      disableColumnMenu: true,
      sortable: false
    },
    {
      field: 'actions',
      type: 'actions',
      maxWidth: 30,
      getActions: (params: GridRowParams) => [
        <GridActionsCellItem
          key={params.id}
          icon={<ForwardToInboxIcon />}
          onClick={() => {
            emailAction(params.row);
          }}
          label="Email"
        />
      ]
    }
  ];
};

const loadTypeColumns = (
  setItemSelected: (selected: LoadType) => void,
  disableLoadType: (selected: LoadType) => void,
  hasPermission: boolean
): GridColDef[] => {
  return [
    { field: 'material_name', headerName: 'Material', width: 200, hideable: false },
    {
      field: 'zone_name',
      headerName: 'Zone ID',
      width: 150,
      hideable: false
    },
    {
      field: 'qte',
      headerName: `Quantity (${m3})`,
      width: 150,
      hideable: false
    },
    {
      field: 'special_requirements',
      headerName: 'Delivery Confirmation Type',
      width: 250,
      hideable: false
    },
    {
      field: 'notes',
      headerName: 'Notes',
      filterable: false,
      hideable: false,
      width: 300
    },
    {
      field: 'Actions',
      width: 100,
      sortable: false,

      disableColumnMenu: true,
      renderCell: (v) => {
        const data = v.row as Material;
        return (
          <ActionCell
            disabled={!hasPermission}
            data={data}
            setEditSelected={setItemSelected}
            setDisableSelected={disableLoadType}
          />
        );
      }
    }
  ];
};

export const ProjectPage = (): ReactElement => {
  const param = useParams();
  const navigate = useNavigate();
  const [pageState, setPageState] = useState<PageState>(defaultState);
  const [notificationState, setNotifications] = useState<{
    open: boolean;
    success: boolean;
    message: string;
    actionable: boolean;
    title?: string;
    handleConfirm?: () => void;
  }>(defaultNotificationState);
  const [pageErrors, setPageErrors] = useState<PageError>({
    projectName: false,
    phone: false,
    address: false,
    address2: false,
    city: false,
    fname: false,
    lname: false,
    dateError: false,
    email: false
  });

  const [dateTime, setDateTime] = useState<{ date: string; time: Dayjs | null }>({
    date: dayjs().toISOString(),
    time: dayjs()
  });

  const [dateFilter, setDateFilter] = useState<Dayjs | null>(null);
  const [dateRangeFilterStart, setDateRangeFilterStart] = useState<Dayjs | null>(null);
  const [dateRangeFilterEnd, setDateRangeFilterEnd] = useState<Dayjs | null>(null);
  const [truckFilter, setTruckFilter] = useState<{
    filter: MultiSelectItem[];
    selectedItem: MultiSelectItem;
  }>({
    filter: [
      {
        name: 'All',
        id: '-1',
        selected: false
      }
    ],
    selectedItem: {
      name: 'All',
      id: '-1',
      selected: false
    }
  });

  const { coreState, updateCoreState } = useCoreState();
  const { state } = useLocation();
  const { setLoading } = useLoading();
  const { setError } = useErrors();
  const theme = useTheme();
  const { service } = useService();

  const hasProjectPermission = coreState.permission.project >= rw;
  const hasLoadTypePermission = coreState.permission.loadType >= rw;

  const expandSprings = useSpring({
    config: { friction: 20 },
    height: pageState.isInfoOpen ? '1400px' : '75px'
  });
  const [arrowSprings, arrowApi] = useSpring(() => ({
    from: { rotate: 0 }
  }));

  const handleDisableConfirm = async () => {
    const { project } = pageState;
    try {
      await service.post(scriptOP.updateEntity, {
        dataJson: {
          name: project.name,
          startDate: project.startDate,
          isActive: !project.isActive,
          address: { ...project.address },
          contact: { ...project.contact },
          note: project.note
        },
        entityType: 'Project',
        acl: { other: 2 },
        entityId: state.entityId,
        version: pageState.project.version
      });

      setPageState({ ...pageState, project: { ...project, isActive: !project.isActive } });
      setNotifications({
        ...defaultNotificationState,
        open: true,
        message: project.isActive ? 'Project is disabled.' : 'Project is now active. ',
        success: true,
        actionable: false
      });
      updateCoreState({
        type: CoreActionTypes.SET_PROJECT,
        payload: {
          ...coreState,
          projects: [
            ...coreState.projects.map((p) => {
              if (p.entityId === state.entityId) {
                return { ...p, isActive: !p.isActive };
              } else return p;
            })
          ]
        }
      });
    } catch (error) {
      const e = error as ReqError;

      if ('reason_code' in e) {
        if (e.reason_code === reasonCode.sessionTimeout) {
          setError({ networkError: errorMessage.sessionTimeout });
          return;
        }
        setNotifications({
          ...defaultNotificationState,
          open: true,
          message: errorMessage.somethingWrong,
          success: false,
          actionable: false
        });
      }
    }
  };

  const disableProject = () => {
    const { project } = pageState;
    setNotifications({
      open: true,
      actionable: true,
      message: project.isActive
        ? 'Are you sure you wish to disable this project and hide it from all lists?'
        : 'Are you sure you wish to activate this project?',
      success: true,
      title: project.isActive ? 'Disable Project' : 'Activate Project',
      handleConfirm: handleDisableConfirm
    });
    return;

    //datet
  };

  const dateTimeHelper = (): string => {
    const d = dateTime.date.substring(0, dateTime.date.indexOf('T'));
    const t = dayjs(dateTime.time).toISOString();
    const ti = t.substring(t.indexOf('T'));

    return d + ti;
  };

  function isSelected(id: string | string[], projectId: string): boolean {
    let selected = false;

    if (id === undefined) {
      return selected;
    }

    if (Array.isArray(id)) {
      selected = id.find((i) => i === projectId) !== undefined;
    } else {
      selected = id ? (id === projectId ? true : false) : false;
    }

    return selected;
  }

  function mapLoadHistory(
    loads: Load[],
    trucks: MultiSelectItem[],
    sites: MultiSelectItem[],
    loadTypes: LoadType[]
  ): LoadHistory[] {
    let hLoads: LoadHistory[] = [];
    loads
      .filter((l) => l.projectId === state.entityId)
      .forEach((l) => {
        const truckFound = trucks.find((t) => t.id === l.truckId);
        const siteFound = sites.find((s) => s.id === l.receivingSiteId);
        const loadTypeFound = loadTypes.find((lt) => lt.entityId === l.loadTypeId);
        if (truckFound && siteFound && loadTypeFound) {
          const date = getDate(l.creation);
          const _creation = dayjs(l.creation).tz(timezone, true);

          let arrival = 'n/a';
          let departure = 'n/a';
          let tripTime = 'n/a';
          let tltime = 'n/a';
          const creation = _creation.format('H:mm');

          if (l.arrival) {
            const a = dayjs(l.arrival).tz(timezone, true);
            const _arrival = a.format('H:mm');
            if (l.departure) {
              const d = dayjs(l.departure).tz(timezone, true);
              const _departure = d.format('H:mm');

              const durationTrip = timeDiff(_departure, _arrival);
              tripTime = durationTrip.diff;
              const durationTtl = timeDiff(creation, _arrival);
              tltime = durationTtl.diff;
              arrival = _arrival;
              departure = _departure;
            }
          }

          const history: LoadHistory = {
            date,
            truckEntityId: l.truckId,
            entityId: l.entityId,
            truckId: truckFound.name,
            loadNo: l.loadNumber,
            loadType: loadTypeFound.material_name,
            qte: l.qte,
            receivingSite: siteFound.name,
            pile: l.pileNumber,
            inTime: creation,
            arrivalTime: arrival,
            outTime: departure,
            tripTime: tripTime,
            tlt: tltime,
            ticketId: truckFound.name + '-' + getDate(l.creation),
            notes: l.notes ?? '',
            whoConfirmed: l.whoConfirmed
          };
          hLoads.push(history);
        }
      });
    return hLoads;
  }

  function mapActiveLoad(
    loads: Load[],
    trucks: MultiSelectItem[],
    sites: MultiSelectItem[],
    loadTypes: LoadType[]
  ): ActiveLoad[] {
    let aLoads: ActiveLoad[] = [];

    loads.forEach((l) => {
      const existingRecordIndex = aLoads.findIndex(
        (al) =>
          al.truckId === l.truckId &&
          al.loadTypeId === l.loadTypeId &&
          al.siteId === l.receivingSiteId
      );

      if (existingRecordIndex !== -1) {
        const existingRecord = aLoads[existingRecordIndex];

        if (l.loadNumber > existingRecord.loadNumber) {
          existingRecord.loadNumber = l.loadNumber;
          if (l.creation && !l.departure && !l.arrival) {
            existingRecord.status = truckStatus.loading;
          } else if (l.creation && l.departure && !l.arrival) {
            existingRecord.status = truckStatus.enRoute;
          } else if (l.creation && l.departure && l.arrival) {
            existingRecord.status = truckStatus.arrived;
          }
        }
        existingRecord.deliveredQte = existingRecord.deliveredQte + l.qte;
      } else {
        const id = l.creation + l.truckId;
        const truckId = l.truckId; //truck entityID
        const siteId = l.receivingSiteId;
        const loadTypeId = l.loadTypeId;
        const departureDate = getDate(l.departure);

        const truckFound = trucks.find((t) => t.id === l.truckId);
        const siteFound = sites.find((s) => s.id === l.receivingSiteId);
        const loadTypeFound = loadTypes.find((lt) => lt.entityId === l.loadTypeId);

        if (truckFound && siteFound && loadTypeFound) {
          aLoads.push({
            id,
            truckId,
            siteId,
            loadTypeId,
            truck: truckFound.name,
            departure: departureDate,
            loadNumber: l.loadNumber,
            loadType: loadTypeFound.material_name,
            receivingSite: siteFound.name,
            status:
              l.arrival && l.arrival !== ''
                ? truckStatus.arrived
                : l.departure !== '' && !l.arrival
                ? truckStatus.enRoute
                : truckStatus.loading,
            deliveredQte: l.qte,
            arrive: l.arrival ? getDate(l.arrival) : '',
            jobTicket: truckFound.name + '-' + getDate(l.creation),
            creation: l.creation,
            whoConfirmed: l.whoConfirmed
          });
        }
      }
    });

    return aLoads;
  }

  const loadProject = async () => {
    setLoading(true);

    try {
      if (state && 'entityId' in state) {
        const allData = await fetchAll(service);

        const res = await service.get(`${customEntity}${CEOP.readEntity}Project/${state.entityId}`);

        const result = readSingle<Omit<Project, 'entityId' | 'version'>>(res);

        if (result && allData && 'users' in allData) {
          const receivingSites: MultiSelectItem[] = [];
          const users: MultiSelectItem[] = [];
          const deliveryConfs: MultiSelectItem[] = [];

          const projectLoaded: Project = projectFrom(result);

          allData.sites.forEach((s) => {
            if (s.data.isReceiving) {
              receivingSites.push({
                name: s.data.name,
                id: s.entityId,
                selected: isSelected(s.data.projects, state.entityId)
              });
            }
          });

          allData.deliveryConfirmation.forEach((dc) => {
            if (dc.data.isActive) {
              const isFound = projectLoaded.deliveryConfirmations?.find(
                (element: string) => element === dc.data.name
              );
              deliveryConfs.push({
                name: dc.data.name,
                id: dc.entityId,
                selected: isFound ? true : false
              });
            }
          });

          allData.users.forEach((u) => {
            if (u.isActive) {
              users.push({
                name: `${u.firstName} ${u.lastName}`,
                id: u.profileId,
                selected: isSelected(u.projects, state.entityId)
              });
            }
          });

          const loadTypeDecoded = allData.loadTypes;

          const allLoadTypes = loadTypeDecoded.map(loadTypeFrom);

          const loadReq = await service.post(scriptOP.listLoad, {
            projectId: state.entityId
          });
          const status = responseStatus(loadReq);
          if (!status.success && status.reasonCode) {
            setError({
              networkError:
                'There was an error loading load data for this project, try refresh the page'
            });
          }

          const loads = loadMapper(loadReq, setError).filter((l) => l.projectId === state.entityId);

          const trucks: MultiSelectItem[] = [
            ...coreState.trucks.map((t) => {
              const truckFound = t.projectId === projectLoaded.entityId;
              return {
                name: t.splId + ' ' + t.truckNumber,
                id: t.entityId,
                selected: truckFound
              };
            })
          ];

          const loadHistories = mapLoadHistory(loads, trucks, receivingSites, allLoadTypes);

          const loadToday = loads.filter((l) => getDate(l.creation) === getToday());
          const todaysActiveLoads = mapActiveLoad(loadToday, trucks, receivingSites, allLoadTypes);

          updateCoreState({
            type: CoreActionTypes.SET_LOADTYPES,
            payload: { ...coreState, loadTypes: allLoadTypes }
          });

          const loadTypes = allLoadTypes.filter((a) => a.projectId === state.entityId);
          const sum = coreState.summaries.find((s) => s.projectId === state.entityId);
          const selectedUser = users.filter((u) => u.selected);
          setDateFilter(null);
          setDateRangeFilterEnd(null);
          setDateRangeFilterStart(null);
          setDateTime({
            ...dateTime,
            date: result.data.startDate,
            time: dayjs(result.data.startDate)
          });
          setPageState({
            ...pageState,
            loads,
            filterableLoads: [...loads],
            isInfoOpen: false,
            projectSum: sum ?? initDashboardSummary,
            project: { ...projectLoaded },
            trucks: trucks,
            selectedTrucks: trucks.filter((t) => t.selected),
            receivingSites,
            deliveryConfs: deliveryConfs,
            selectedDeliveryConfs: deliveryConfs,
            selectedReceivingSites: receivingSites.filter((r) => r.selected),
            users: [...users],
            selectedUsers: selectedUser.length > 0 ? selectedUser : users,
            siteFilter: fixedFilters.selected,
            userFilter: selectedUser.length > 0 ? fixedFilters.selected : fixedFilters.all,
            companyFilter: fixedFilters.selected,
            loadTypes,
            activeLoads: todaysActiveLoads,
            loadHistories: loadHistories,
            allLoadHistories: loadHistories,
            addLoadModalOpen: false,
            addSiteModalOpen: false,
            addTruckModalOpen: false,
            addUserModalOpen: false,
            jobTicketModalOpen: false,
            jobTicketModalData: { ticket: defaultActiveLoad, loads: [] },
            addDeliveryConfModalOpen: false
          });

          setTruckFilter({
            selectedItem: {
              name: 'All',
              id: '-1',
              selected: false
            },
            filter: [
              {
                name: 'All',
                id: '-1',
                selected: false
              },
              ...trucks.filter((t) => t.selected)
            ]
          });
        }
      }
    } catch (error) {
      if (
        error &&
        typeof error === 'object' &&
        'reason_code' in error &&
        Object.keys(error).length > 0 &&
        typeof error.reason_code === 'number'
      ) {
        if (error.reason_code === reasonCode.sessionTimeout) {
          setPageState({ ...defaultState });
          setError({ networkError: errorMessage.sessionTimeout });
          return;
        } else {
          if (error.reason_code === reasonCode.packactIdError) {
            const id = decontructPackedId(error);
            if (id !== 0) {
              service.setPacketID(id);
              loadProject();
              return;
            }
            return;
          }

          if (error.reason_code) {
            setError({
              networkError: getErrorMessage(error.reason_code)
            });
          }
        }
      }
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  };

  const handleSelectMultipleUsers = (value: MultiSelectItem[]) => {
    setPageState({ ...pageState, selectedUsers: [...value], enableSubmit: true });
  };

  const openUserModal = (): void => {
    setPageState({ ...pageState, addUserModalOpen: true });
  };

  const handleChangeMultipleTruck = (value: MultiSelectItem[]) => {
    let trucks = pageState.trucks;
    let selectedTrucks = pageState.selectedTrucks;

    for (let i = 0; i < value.length; i++) {
      let truck = value[i];
      let index = trucks.findIndex((t) => t.id == truck.id);

      if (index > -1) {
        trucks[index].selected = truck.selected;

        let selectedIndex = selectedTrucks.findIndex((t) => t.id == truck.id);
        if (selectedIndex > -1) {
          selectedTrucks[selectedIndex].selected = truck.selected;
        }
      }
    }

    setPageState({ ...pageState, trucks, selectedTrucks, enableSubmit: true });
  };

  const openTruckModal = (): void => {
    setPageState({ ...pageState, addTruckModalOpen: true });
  };

  const handleChangeMultipleSites = (value: MultiSelectItem[]) => {
    setPageState({ ...pageState, selectedReceivingSites: [...value], enableSubmit: true });
  };

  const openSiteModal = (): void => {
    setPageState({ ...pageState, addSiteModalOpen: true });
  };

  const handleChangeMultipleDeliveryConfs = (value: MultiSelectItem[]) => {
    setPageState({
      ...pageState,
      deliveryConfs: [...value],
      selectedDeliveryConfs: [...value],
      enableSubmit: true
    });
  };

  const openDeliveryConfsModal = (): void => {
    setPageState({ ...pageState, addDeliveryConfModalOpen: true });
  };

  const handleSelecRoleFilter = (event: SelectChangeEvent) => {
    const allUsers = coreState.users.filter((u) => u.isActive);
    const selected = pageState.selectedUsers.filter((u) => u.selected);

    let updatedList: MultiSelectItem[] = [];

    if (event.target.value === fixedFilters.all) {
      updatedList = pageState.users.map((u) => {
        const userFound = pageState.selectedUsers.find((su) => su.id === u.id);
        if (userFound) {
          return userFound;
        }
        return u;
      });
    } else if (event.target.value === fixedFilters.selected) {
      updatedList = pageState.users.filter((u) => u.selected);
    } else {
      const role = event.target.value as userRoles;

      updatedList = allUsers
        .filter((u) => u.role === role)
        .map((au) => {
          const userFind = selected.find((s) => s.id === au.profileId);
          return {
            name: `${au.firstName} ${au.lastName} (${au.role})`,
            id: au.profileId,
            selected: userFind && userFind.selected ? true : false
          };
        });
    }

    setPageState({
      ...pageState,
      userFilter: event.target.value,
      selectedUsers: [...updatedList]
    });
  };

  const handleSelecCompanyFilter = (event: SelectChangeEvent) => {
    const allTrucks = coreState.trucks.filter(
      (t) => t.projectId !== undefined || t.projectId !== ''
    );
    const selected = pageState.trucks.filter((ft) => ft.selected);
    let updatedList: MultiSelectItem[] = [];

    if (event.target.value === fixedFilters.all) {
      updatedList = pageState.trucks;
    } else if (event.target.value === fixedFilters.selected) {
      updatedList = selected;
    } else {
      const company = coreState.companies.find((c) => c.name === event.target.value);
      if (company) {
        updatedList = allTrucks
          .filter((t) => t.companyId === company.entityId)
          .map((at) => {
            const truckFind = pageState.trucks.find((t) => t.id === at.entityId);
            return {
              name: at.splId + ' ' + at.truckNumber,
              id: at.entityId,
              selected: truckFind && truckFind.selected ? true : false
            };
          });
      } else {
        updatedList = [];
      }
    }
    setPageState({
      ...pageState,
      companyFilter: event.target.value,
      selectedTrucks: [...updatedList]
    });
  };
  const handleSelectSiteFilter = (event: SelectChangeEvent) => {
    let updatedList: MultiSelectItem[] = [];
    if (event.target.value === fixedFilters.all) {
      updatedList = pageState.receivingSites.map((s) => {
        const siteFound = pageState.selectedReceivingSites.find((sr) => sr.id === s.id);
        if (siteFound) {
          return siteFound;
        } else {
          return s;
        }
      });
    } else {
      updatedList = pageState.selectedReceivingSites.filter((s) => s.selected);
    }

    setPageState({
      ...pageState,
      siteFilter: event.target.value,
      selectedReceivingSites: [...updatedList]
    });
  };
  const hanldeValidation = (
    value: string,
    type: 'name' | 'add1' | 'add2' | 'city' | 'fName' | 'lName' | 'number' | 'email'
  ): void => {
    switch (type) {
      case 'name':
        if (
          coreState.projects
            .filter((p) => p.entityId !== state.entityId)
            .find((p) => p.name === value)
        ) {
          setError({ pageError: 'Project name already exists.' });
          setPageErrors({
            ...pageErrors,
            projectName: true
          });
          break;
        }

        if (!value) {
          setError({ pageError: 'Project name cannot be empty.' });
          setPageErrors({
            ...pageErrors,
            projectName: true
          });
          break;
        }
        setPageErrors({
          ...pageErrors,
          projectName: false
        });

        break;
      case 'add1':
        if (!value) {
          setError({ pageError: 'address cannot be empty.' });
          setPageErrors({
            ...pageErrors,
            address: true
          });
          break;
        }
        setPageErrors({
          ...pageErrors,
          address: false
        });
        break;
      case 'add2':
        if (!value) {
          setError({ pageError: 'Postal code cannot be empty. ' });
          setPageErrors({
            ...pageErrors,
            address2: true
          });
          break;
        }
        setPageErrors({
          ...pageErrors,
          address2: false
        });
        break;
      case 'city':
        if (!value) {
          setError({ pageError: 'City cannot be empty. ' });
          setPageErrors({
            ...pageErrors,
            city: true
          });
          break;
        }
        setPageErrors({
          ...pageErrors,
          city: false
        });
        break;
      case 'fName':
        if (!value) {
          setError({ pageError: 'Contact name cannot be empty. ' });
          setPageErrors({
            ...pageErrors,
            fname: true
          });
          break;
        }
        setPageErrors({
          ...pageErrors,
          fname: false
        });
        break;
      case 'lName':
        if (!value) {
          setError({ pageError: 'Contact name cannot be empty. ' });
          setPageErrors({
            ...pageErrors,
            lname: true
          });
          break;
        }
        setPageErrors({
          ...pageErrors,
          lname: false
        });
        break;
      case 'number':
        if (!value) {
          setError({ pageError: 'Contact number cannot be empty. ' });
          setPageErrors({
            ...pageErrors,
            phone: true
          });
          break;
        }
        setPageErrors({
          ...pageErrors,
          phone: false
        });
        break;
      case 'email':
        if (value && !validateEmail(value)) {
          setError({ pageError: 'Email address not valid. ' });
          setPageErrors({
            ...pageErrors,
            email: true
          });
          break;
        }
        setPageErrors({
          ...pageErrors,
          email: false
        });
        break;
      default:
        break;
    }
  };

  const setItemSelected = (selected: LoadType): void => {
    setPageState({
      ...pageState,
      selectedLoadType: selected,
      loadTypeModalMode: 'Edit',
      addLoadModalOpen: true
    });
  };

  const disableLoadType = async (selected: LoadType) => {
    console.debug(`Disabling Load Type ${selected.name}`);
    selected.isActive = !selected.isActive;

    const loadTypeData = {
      ...dataFromLoadType(selected)
    };

    try {
      const loadTypeRes = await service.post(scriptOP.updateEntity, {
        dataJson: {
          ...loadTypeData
        },
        entityType: 'LoadType',
        acl: { other: 2 },
        entityId: selected.entityId,
        version: selected.version
      });
      // const res = responseStatus(loadTypeRes);

      const entity = entityUpdatedResult<LoadType>(loadTypeRes);

      if (entity && entity.version > selected.version) {
        if (entity) selected.version = entity?.version;
        updateCoreState({
          type: CoreActionTypes.ADD_LOADTYPE,
          payload: {
            ...coreState,
            loadTypes: [
              ...coreState.loadTypes.map((l) =>
                l.entityId === selected.entityId ? { ...selected, ...loadTypeData } : l
              )
            ]
          }
        });
      } else {
        console.error('Operation failed.', loadTypeRes);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleEmailLoad = async (load: LoadHistory) => {
    setPageState({ ...pageState, emailLoadHistory: load });
  };

  const preventReload = (e: BeforeUnloadEvent): void => {
    e.preventDefault();
    setNotifications({
      open: true,
      success: true,
      message: 'Please use refresh button to reload this page.',
      actionable: false
    });
  };

  const handleSubmit = async () => {
    setLoading(true);
    const startDate = dateTimeHelper();
    const today = dayjs().toISOString();

    const hasError = Object.values(pageErrors).filter((e) => e === true).length > 0;

    if (pageState.project.startDate === '' && startDate < today) {
      setError({ pageError: 'The start date is invalid.' });
      setPageErrors({
        ...pageErrors,
        dateError: true
      });
      setLoading(false);
      return;
    }
    //other errors
    if (hasError) {
      setLoading(false);
      return;
    }

    const project: Omit<Project, 'entityId' | 'version'> = {
      ...pageState.project,
      startDate
    };

    const selectedUsers = pageState.selectedUsers.filter((i) => i.selected).map((i) => i.id);
    const unselectedUsers = pageState.users
      .filter((au) => selectedUsers.find((ss) => ss === au.id) === undefined)
      .map((u) => u.id);
    const selectedTrucks = pageState.trucks
      .filter((i) => i.selected)
      .map((i) => {
        return { truckId: i.id, scheduleDate: startDate };
      });
    const unselectedTrucks = pageState.trucks.filter((t) => !t.selected).map((t) => t.id);

    const selectedSites = pageState.selectedReceivingSites
      .filter((i) => i.selected)
      .map((i) => i.id);
    const unselectedSites = pageState.receivingSites
      .filter((s) => selectedSites.find((ss) => ss === s.id) === undefined)
      .map((s) => s.id);

    try {
      const updateProject = await service.post(scriptOP.updateEntity, {
        dataJson: {
          name: project.name,
          startDate,
          isActive: project.isActive,
          address: { ...project.address },
          contact: { ...project.contact },
          projectNumber: project.projectNumber,
          note: project.note,
          deliveryConfirmations: pageState.deliveryConfs
            .filter((dc) => dc.selected === true)
            .map((item) => item.name)
        },
        entityType: 'Project',
        acl: { other: 2 },
        entityId: state.entityId,
        version: pageState.project.version
      });

      const updateError = responseErrorHelper(updateProject);
      if (updateError) {
        if (updateError.reason_code === reasonCode.sessionTimeout) {
          setError({ networkError: errorMessage.sessionTimeout });
          return;
        }
        setNotifications({
          open: true,
          message: getErrorMessage(updateError.reason_code),
          success: false,
          actionable: false
        });
      }

      const assignTruckReq = await service.post(scriptOP.assignTrucks, {
        projectId: state.entityId,
        assignment: [...selectedTrucks],
        unassigned: [...unselectedTrucks]
      });

      await service.post(scriptOP.assignSites, {
        projectId: state.entityId,
        assignment: [...selectedSites],
        unassigned: [...unselectedSites]
      });

      await service.post(scriptOP.assignUsers, {
        projectId: state.entityId,
        assignment: [...selectedUsers],
        unassigned: [...unselectedUsers]
      });

      const assginError = responseErrorHelper(assignTruckReq);
      if (assginError) {
        setError({ networkError: assginError.status_message });
      }
      setPageState({ ...pageState, enableSubmit: false });
      setNotifications({
        open: true,
        message: 'Project is updated',
        success: true,
        actionable: false
      });

      setLoading(false);
    } catch (error) {
      //error

      const e = error as ReqError;

      if ('reason_code' in e && Object.keys(e).length > 0) {
        if (e.reason_code === reasonCode.sessionTimeout) {
          setError({ networkError: errorMessage.sessionTimeout });
          return;
        }
        if (e.reason_code !== reasonCode.packactIdError) {
          setNotifications({
            open: true,
            message: getErrorMessage(e.reason_code),
            success: false,
            actionable: false
          });
        }
      }
    }
  };

  useEffect(() => {
    window.addEventListener('beforeunload', preventReload);

    return () => {
      window.removeEventListener('beforeunload', preventReload);
    };
  }, []);

  useEffect(() => {
    if (pageState.isInfoOpen) {
      arrowApi.start({
        from: { rotate: 0 },
        to: { rotate: 180 }
      });
    } else {
      arrowApi.start({
        from: { rotate: 180 },
        to: { rotate: 0 }
      });
    }
  }, [pageState.isInfoOpen]);

  useEffect(() => {
    loadProject();
    return () => {
      setPageState({ ...defaultState });
    };
  }, [param.projectName]);

  useEffect(() => {
    if (notificationState.open && notificationState.actionable !== true) {
      setTimeout(() => {
        setNotifications(defaultNotificationState);
      }, 3000);
    }
  }, [notificationState.open, notificationState.actionable]);

  const handleChangeProvince = (event: SelectChangeEvent) => {
    setPageState({
      ...pageState,
      project: {
        ...pageState.project,
        address: { ...pageState.project.address, prov: event.target.value }
      }
    });
  };

  const handleInputChange = (
    value: string,
    type: 'name' | 'add1' | 'add2' | 'city' | 'fName' | 'lName' | 'number' | 'note' | 'email'
  ): void => {
    switch (type) {
      case 'name':
        setPageState({
          ...pageState,
          project: { ...pageState.project, name: value },
          enableSubmit: true
        });

        setPageErrors({
          ...pageErrors,
          projectName: false
        });
        break;
      case 'add1':
        setPageState({
          ...pageState,
          project: {
            ...pageState.project,
            address: { ...pageState.project.address, street1: value }
          },
          enableSubmit: true
        });
        setPageErrors({
          ...pageErrors,
          address: false
        });
        break;
      case 'add2':
        setPageState({
          ...pageState,
          project: {
            ...pageState.project,
            address: { ...pageState.project.address, street2: value }
          },
          enableSubmit: true
        });
        setPageErrors({
          ...pageErrors,
          address2: false
        });
        break;
      case 'city':
        setPageState({
          ...pageState,
          project: {
            ...pageState.project,
            address: { ...pageState.project.address, city: value }
          },
          enableSubmit: true
        });
        setPageErrors({
          ...pageErrors,
          city: false
        });
        break;
      case 'fName':
        setPageState({
          ...pageState,
          project: {
            ...pageState.project,
            contact: { ...pageState.project.contact, fname: value }
          },
          enableSubmit: true
        });
        setPageErrors({
          ...pageErrors,
          fname: false
        });
        break;
      case 'lName':
        setPageState({
          ...pageState,
          project: {
            ...pageState.project,
            contact: { ...pageState.project.contact, lname: value }
          },

          enableSubmit: true
        });
        setPageErrors({
          ...pageErrors,
          lname: false
        });
        break;
      case 'number':
        setPageState({
          ...pageState,
          project: {
            ...pageState.project,
            contact: { ...pageState.project.contact, phone: value }
          },
          enableSubmit: true
        });
        setPageErrors({
          ...pageErrors,
          phone: false
        });
        break;
      case 'note':
        setPageState({
          ...pageState,
          project: { ...pageState.project, note: value },
          enableSubmit: true
        });
        break;
      case 'email':
        setPageState({
          ...pageState,
          project: {
            ...pageState.project,
            contact: { ...pageState.project.contact, email: value }
          },
          enableSubmit: true
        });
        break;
      default:
        break;
    }
  };

  const hanldeDatefilterChange = (newValue: dayjs.Dayjs | null): void => {
    setDateFilter(newValue);
    setDateRangeFilterStart(null);
    setDateRangeFilterEnd(null);

    if (dayjs(newValue).format(format).toString() === 'Invalid Date') {
      //date filter is not valid
      if (!newValue) {
        if (truckFilter.selectedItem.id !== '-1') {
          const loadsFiltered = pageState.allLoadHistories.filter(
            (l) => l.truckEntityId === truckFilter.selectedItem.id
          );
          setPageState({
            ...pageState,
            loadHistories: loadsFiltered
          });
        } else {
          //no truck filter
          setPageState({
            ...pageState,
            filterableLoads: [...pageState.loads],
            loadHistories: [...pageState.allLoadHistories]
          });
        }
      }
    } else {
      const isoString = dayjs(newValue).toISOString();
      const date = getDate(isoString);
      let loadsFiltered: LoadHistory[] = [];

      if (truckFilter.selectedItem.id !== '-1') {
        loadsFiltered = pageState.allLoadHistories.filter(
          (lh) => lh.date === date && lh.truckEntityId === truckFilter.selectedItem.id
        );
      } else {
        loadsFiltered = pageState.allLoadHistories.filter((lh) => lh.date === date);
      }

      setPageState({
        ...pageState,
        loadHistories: loadsFiltered
      });
    }
  };

  const handleChangeTruckFilter = (event: SelectChangeEvent) => {
    const value = event.target.value;

    const truckFound = truckFilter.filter.find((t) => t.id === value);
    if (truckFound) {
      setTruckFilter({ ...truckFilter, selectedItem: truckFound });

      if (truckFound.name === 'All') {
        //clear truck filter
        if (dateFilter !== null) {
          //if there is a date filter

          const isoString = dayjs(dateFilter).toISOString();
          const date = getDate(isoString);
          setPageState({
            ...pageState,
            loadHistories: [...pageState.allLoadHistories.filter((l) => l.date === date)]
          });
          return;
        }
        if (dateRangeFilterEnd !== null && dateRangeFilterStart !== null) {
          //if there is a range filter
          const dateFrom = dayjs(dateRangeFilterStart).toISOString();
          const dateTo = dayjs(dateRangeFilterEnd).toISOString();
          const f = getDate(dateFrom);
          const t = getDate(dateTo);
          setPageState({
            ...pageState,
            loadHistories: [
              ...pageState.allLoadHistories.filter((h) => {
                if (dayjs(h.date).isBetween(f, t, 'day', '[]')) {
                  return h;
                }
              })
            ]
          });
          return;
        }
        setPageState({
          ...pageState,
          loadHistories: [...pageState.allLoadHistories]
        });
      } else {
        if (dateFilter !== null) {
          const isoString = dayjs(dateFilter).toISOString();
          const date = getDate(isoString);
          const filterableData = [...pageState.allLoadHistories.filter((l) => l.date === date)];
          setPageState({
            ...pageState,
            loadHistories: [...filterableData.filter((l) => l.truckEntityId === value)]
          });
          return;
        }
        if (dateRangeFilterEnd !== null && dateRangeFilterStart !== null) {
          //if there is a range filter
          const dateFrom = dayjs(dateRangeFilterStart).toISOString();
          const dateTo = dayjs(dateRangeFilterEnd).toISOString();
          const f = getDate(dateFrom);
          const t = getDate(dateTo);
          const filterableData = [
            ...pageState.allLoadHistories.filter((h) => {
              if (dayjs(h.date).isBetween(f, t, 'day', '[]')) {
                return h;
              }
            })
          ];

          setPageState({
            ...pageState,
            loadHistories: [...filterableData.filter((l) => l.truckEntityId === value)]
          });
          return;
        }
        setPageState({
          ...pageState,
          loadHistories: [...pageState.allLoadHistories.filter((l) => l.truckEntityId === value)]
        });
      }
    }
  };

  const handleDateRangeFilterApply = (from: dayjs.Dayjs | null, to: dayjs.Dayjs | null) => {
    setDateFilter(null);
    if (from === null || to === null) {
      return;
      //missing range
    }

    const dateFrom = dayjs(from).toISOString();
    const dateTo = dayjs(to).toISOString();
    const f = getDate(dateFrom);
    const t = getDate(dateTo);

    setDateFilter(null);

    if (truckFilter.selectedItem.id === '-1') {
      //no truck filter
      const filterdHistory = pageState.allLoadHistories.filter((h) => {
        if (dayjs(h.date).isBetween(f, t, 'day', '[]')) {
          return h;
        }
      });
      setPageState({ ...pageState, loadHistories: filterdHistory });
    } else {
      //has truck filter
      const filterdHistory = pageState.allLoadHistories.filter((h) => {
        if (
          dayjs(h.date).isBetween(f, t, 'day', '[]') &&
          h.truckEntityId === truckFilter.selectedItem.id
        ) {
          return h;
        }
      });
      setPageState({ ...pageState, loadHistories: filterdHistory });
    }
  };

  return (
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    <ContentContainer title="">
      <HeaderContainer>
        <div style={{ width: '50%', display: 'flex', alignItems: 'center' }}>
          <IButton
            onClick={() => {
              navigate('/');
            }}
            sx={{ height: '50px', width: '50px' }}>
            <MdDashboard
              style={{ height: 25, width: 25, color: theme.palette.background.default }}
            />
          </IButton>
          <MdOutlineArrowForwardIos style={{ height: 24, width: 24, color: '#bdbdbd' }} />
          <Caption>Project</Caption>
        </div>
        <HeaderRefreshOContainer>
          <Button
            variant="text"
            color="secondary"
            onClick={() => {
              loadProject();
            }}
            label="Refresh page"
            size="normal"
          />
          <IconButton
            label="Refresh page"
            onClick={() => {
              loadProject();
            }}>
            <MdOutlineRefresh
              style={{
                height: 25,
                width: 25,
                color: theme.palette.background.default
              }}
            />
          </IconButton>
        </HeaderRefreshOContainer>
      </HeaderContainer>
      <HeaderRow>
        <div style={{ width: '50%', display: 'flex', alignItems: 'center' }}>
          <SubTitle>
            {param.projectName}{' '}
            {pageState.project.projectNumber === -1
              ? ''
              : ` (#` + pageState.project.projectNumber + ')'}
          </SubTitle>
          <CellStatus isActive={pageState.project.isActive} style={{ marginLeft: 8 }} />
        </div>
        <HeaderRefreshOContainer>
          {hasProjectPermission && (
            <IconButton
              label={pageState.project.isActive ? 'Disable Project' : 'Enable Project'}
              onClick={() => {
                disableProject();
              }}>
              {pageState.project.isActive ? (
                <DoDisturbIcon
                  style={{
                    height: 25,
                    width: 25,
                    color: theme.palette.error.main
                  }}
                />
              ) : (
                <AiFillCheckCircle size={25} color={theme.palette.success.main} />
              )}
            </IconButton>
          )}
        </HeaderRefreshOContainer>
      </HeaderRow>
      <AnimatedInformationContainer
        style={{
          ...expandSprings,
          boxShadow:
            '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)'
        }}>
        <InformationContainerAction>
          <SubTitle>Project Configuration</SubTitle>
          <div>
            <Button
              variant="text"
              color="secondary"
              onClick={() => {
                setPageState({ ...pageState, isInfoOpen: !pageState.isInfoOpen });
              }}
              label={pageState.isInfoOpen ? 'Hide Details' : 'Show Details'}
              size="normal"
            />
            <IconButton
              label={pageState.isInfoOpen ? 'Hide Details' : 'Show Details'}
              onClick={() => {
                setPageState({ ...pageState, isInfoOpen: !pageState.isInfoOpen });
              }}>
              <AnimatedArrowIcon
                style={{
                  ...arrowSprings,
                  height: 25,
                  width: 25,
                  color: theme.palette.background.default
                }}
              />
            </IconButton>
          </div>
        </InformationContainerAction>
        <div
          style={{
            height: 1375,
            width: '100%',
            display: pageState.isInfoOpen ? 'flex' : 'none',
            padding: `0 15px`,
            flexDirection: 'column'
          }}>
          <Box
            component="form"
            autoComplete="on"
            sx={{
              height: 350,
              width: '100%',
              backgroundColor: theme.palette.background.paper,
              padding: theme.spacing(1),
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
            <Column>
              <SubTitle style={{ alignSelf: 'flex-start', margin: 0 }}>Project Name</SubTitle>
              <StyledTextField
                label="Project Name"
                id="filled-size-small"
                disabled={!hasProjectPermission}
                error={pageErrors.projectName}
                required
                value={pageState.project.name}
                onChange={(e) => {
                  handleInputChange(e.target.value, 'name');
                }}
                onBlur={(e) => {
                  hanldeValidation(e.target.value, 'name');
                }}
                variant="filled"
                size="small"
                fullWidth
              />
              <SubTitle style={{ alignSelf: 'flex-start', margin: 0, marginTop: 10 }}>
                Location Information
              </SubTitle>
              <StyledTextField
                label="Address line 1"
                id="filled-size-small"
                required
                disabled={!hasProjectPermission}
                sx={{ marginTop: theme.spacing(1) }}
                error={pageErrors.address}
                value={pageState.project.address.street1}
                onChange={(e) => {
                  handleInputChange(e.target.value, 'add1');
                }}
                onBlur={(e) => {
                  hanldeValidation(e.target.value, 'add1');
                }}
                variant="filled"
                fullWidth
                size="small"
              />
              <StyledTextField
                label="Address line 2"
                id="filled-size-small"
                sx={{ marginTop: theme.spacing(1) }}
                disabled={!hasProjectPermission}
                value={pageState.project.address.street2}
                onChange={(e) => {
                  handleInputChange(e.target.value, 'add2');
                }}
                variant="filled"
                fullWidth
                size="small"
              />
              <div
                style={{
                  height: 48,
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                  justifyContent: 'space-between',
                  marginTop: theme.spacing(1)
                }}>
                <StyledTextField
                  label="City"
                  id="filled-size-small"
                  required
                  onBlur={(e) => {
                    hanldeValidation(e.target.value, 'city');
                  }}
                  disabled={!hasProjectPermission}
                  value={pageState.project.address.city}
                  error={pageErrors.city}
                  onChange={(e) => {
                    handleInputChange(e.target.value, 'city');
                  }}
                  variant="filled"
                  size="small"
                />
                <FormControl
                  variant="filled"
                  sx={{
                    m: 1,
                    minWidth: 120,
                    '& .MuiFilledInput-root': {
                      backgroundColor: 'rgba(244, 245, 253, 0.6)',
                      height: '48px'
                    }
                  }}>
                  <InputLabel>Province</InputLabel>
                  <Select
                    value={pageState.project.address.prov}
                    onChange={handleChangeProvince}
                    disabled={!hasProjectPermission}>
                    {provinces.map((p) => (
                      <MenuItem value={p} key={p}>
                        {p}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </Column>
            <Column id="column_2">
              <SubTitle style={{ alignSelf: 'flex-start', margin: 0 }}>Start Date</SubTitle>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <div id="start-date-picker" style={{ alignSelf: 'flex-start' }}>
                  <DatePicker
                    disabled={!hasProjectPermission}
                    label="Date*"
                    value={dayjs(dateTime.date).toISOString()}
                    onChange={(newValue) => {
                      if (dayjs(newValue).isValid()) {
                        const isoString = dayjs(newValue).toISOString();
                        setDateTime({ ...dateTime, date: isoString });
                        //set the start date in project to empty string to indicate it's modified
                        setPageState({
                          ...pageState,
                          enableSubmit: true,
                          project: { ...pageState.project, startDate: '' }
                        });
                      }
                    }}
                    renderInput={(params) => (
                      <StyledTextField {...params} variant="filled" size="small" />
                    )}
                  />
                </div>
              </LocalizationProvider>
              <SubTitle style={{ alignSelf: 'flex-start', margin: 0, marginTop: 10 }}>
                Contact Information
              </SubTitle>
              <StyledTextField
                label="First Name"
                id="filled-size-small"
                disabled={!hasProjectPermission}
                required
                error={pageErrors.fname}
                value={pageState.project.contact.fname}
                sx={{ marginTop: theme.spacing(1) }}
                onChange={(e) => {
                  handleInputChange(e.target.value, 'fName');
                }}
                onBlur={(e) => {
                  hanldeValidation(e.target.value, 'fName');
                }}
                variant="filled"
                fullWidth
                size="small"
              />
              <StyledTextField
                label="Last Name"
                id="filled-size-small"
                disabled={!hasProjectPermission}
                required
                error={pageErrors.lname}
                value={pageState.project.contact.lname}
                sx={{ marginTop: theme.spacing(1) }}
                onChange={(e) => {
                  handleInputChange(e.target.value, 'lName');
                }}
                onBlur={(e) => {
                  hanldeValidation(e.target.value, 'lName');
                }}
                variant="filled"
                fullWidth
                size="small"
              />
              <StyledTextField
                label="Phone Number"
                id="filled-size-small"
                disabled={!hasProjectPermission}
                sx={{ marginTop: theme.spacing(1) }}
                value={pageState.project.contact.phone}
                onBlur={(e) => {
                  hanldeValidation(e.target.value, 'number');
                }}
                onChange={(e) => {
                  handleInputChange(e.target.value, 'number');
                }}
                required
                error={pageErrors.phone}
                variant="filled"
                fullWidth
                size="small"
              />
              <StyledTextField
                label="Email Address"
                id="filled-size-small"
                disabled={!hasProjectPermission}
                sx={{ marginTop: theme.spacing(1) }}
                value={pageState.project.contact.email}
                type="email"
                onChange={(e) => {
                  handleInputChange(e.target.value, 'email');
                }}
                onBlur={(e) => {
                  hanldeValidation(e.target.value, 'email');
                }}
                // required
                error={pageErrors.email}
                variant="filled"
                fullWidth
                size="small"
              />
            </Column>
            <Column id="column_3">
              <SubTitle style={{ alignSelf: 'flex-start', margin: 0 }}>Daily Start Time</SubTitle>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <div id="start-time-picker" style={{ alignSelf: 'flex-start' }}>
                  <TimePicker
                    disabled={!hasProjectPermission}
                    label="Time*"
                    value={dateTime.time}
                    onChange={(newValue) => {
                      if (newValue?.isValid()) {
                        setDateTime({ ...dateTime, time: newValue });
                        //set the start date in project to empty string to indicate it's modified
                        setPageState({
                          ...pageState,
                          enableSubmit: true,
                          project: { ...pageState.project, startDate: '' }
                        });
                      }
                    }}
                    renderInput={(params) => (
                      <StyledTextField {...params} variant="filled" size="small" />
                    )}
                  />
                </div>
              </LocalizationProvider>
              <SubTitle style={{ alignSelf: 'flex-start', margin: 0, marginTop: 10 }}>
                Notes
              </SubTitle>
              <StyledTextField
                disabled={!hasProjectPermission}
                id="filled-size-small"
                sx={{ marginTop: theme.spacing(1) }}
                value={pageState.project.note}
                onChange={(e) => {
                  handleInputChange(e.target.value, 'note');
                }}
                variant="filled"
                fullWidth
                multiline
                size="small"
                rows={4}
              />
            </Column>
          </Box>
          <Box
            component="div"
            id="lower-container"
            sx={{
              height: '500px',
              marginTop: 3,
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between'
            }}>
            <ProjectElementContianer
              baseOptions={pageState.users}
              multiSelectValue={pageState.selectedUsers}
              filterSelected={pageState.userFilter}
              disabled={!hasProjectPermission}
              inputLabel="Filter by Role"
              buttonLabel="Add New Member"
              label="Team"
              addDisabled={coreState.permission.user < rw}
              handleSelectFilter={handleSelecRoleFilter}
              handleMultiSelect={handleSelectMultipleUsers}
              handleButtonClick={openUserModal}
              filterOptions={[fixedFilters.all, fixedFilters.selected, ...Object.values(userRoles)]}
            />
            <ProjectElementContianer
              baseOptions={pageState.trucks}
              multiSelectValue={pageState.selectedTrucks}
              filterSelected={pageState.companyFilter}
              disabled={!hasProjectPermission}
              inputLabel="Filter by Company"
              buttonLabel="Add New Truck"
              label="Trucks"
              addDisabled={coreState.permission.truck < rw}
              handleSelectFilter={handleSelecCompanyFilter}
              handleMultiSelect={handleChangeMultipleTruck}
              handleButtonClick={openTruckModal}
              filterOptions={[
                fixedFilters.all,
                fixedFilters.selected,
                ...coreState.companies.map((c) => c.name)
              ]}
            />
            <ProjectElementContianer
              baseOptions={pageState.receivingSites}
              multiSelectValue={pageState.selectedReceivingSites}
              disabled={!hasProjectPermission}
              addDisabled={coreState.permission.site < rw}
              filterSelected={pageState.siteFilter}
              inputLabel="Receiving Sites"
              buttonLabel="Add New Site"
              label="Receiving Site(s)"
              handleMultiSelect={handleChangeMultipleSites}
              handleSelectFilter={handleSelectSiteFilter}
              handleButtonClick={openSiteModal}
              filterOptions={[fixedFilters.all, fixedFilters.selected]}
            />
            <ProjectElementContianer
              baseOptions={pageState.deliveryConfs}
              multiSelectValue={pageState.selectedDeliveryConfs}
              disabled={!hasProjectPermission}
              addDisabled={coreState.permission.site < rw}
              filterSelected={pageState.deliveryConfsFilter}
              inputLabel="Delivery Confirmation Types"
              buttonLabel="Add New Type"
              label="Delivery Confirmation Types"
              handleMultiSelect={handleChangeMultipleDeliveryConfs}
              handleButtonClick={openDeliveryConfsModal}
            />
          </Box>
          <div
            style={{
              width: '100%',
              height: '100px',
              display: pageState.isInfoOpen ? 'flex' : 'none',
              justifyContent: 'flex-end',
              padding: '5px 15px'
            }}>
            <Button
              variant="outlined"
              size="normal"
              label="Cancel"
              color="secondary"
              styles={{ marginRight: '15px' }}
              disabled={!pageState.enableSubmit}
              onClick={() => {
                loadProject();
              }}
            />
            <Button
              variant="contained"
              size="normal"
              label="Submit"
              color="primary"
              disabled={!pageState.enableSubmit}
              onClick={() => {
                handleSubmit();
              }}
            />
          </div>
          <div
            id="loadtype Row"
            style={{ height: 300, width: '100%', borderTop: '1px solid grey' }}>
            <div
              id="loadtype header"
              style={{
                height: 50,
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}>
              <SubTitle2>Load Type</SubTitle2>
              {hasLoadTypePermission && (
                <Button
                  variant="outlined"
                  size="normal"
                  label="Add Load Type"
                  color="secondary"
                  disabled={pageState.selectedReceivingSites.filter((s) => s.selected).length === 0}
                  onClick={() => {
                    setPageState({
                      ...pageState,
                      addLoadModalOpen: true,
                      loadTypeModalMode: 'New'
                    });
                  }}
                />
              )}
            </div>
            {pageState.isInfoOpen && (
              <div id="loadtype-table" style={{ height: '100%', width: '100%' }}>
                {pageState.loadTypes.length > 0 ? (
                  <Box
                    sx={{
                      height: '100%',
                      width: '100%',
                      '& .data-grid-row-blue': {
                        bgcolor: `${lighterBlue}`,
                        '&: hover': {
                          '&: .MuiDataGrid-cell': { bgcolor: `rgba(22,153,213,0.3)` },
                          bgcolor: `rgba(22,153,213,0.3)`
                        }
                      }
                    }}>
                    <DataGrid
                      columns={loadTypeColumns(
                        setItemSelected,
                        disableLoadType,
                        hasLoadTypePermission
                      )}
                      rows={pageState.loadTypes}
                      autoHeight
                      pageSize={3}
                      checkboxSelection={false}
                      rowsPerPageOptions={[3]}
                      disableSelectionOnClick
                      experimentalFeatures={{ newEditingApi: true }}
                      getRowId={(row) => row.entityId}
                      getRowClassName={(p) => {
                        if (p.indexRelativeToCurrentPage % 2 === 1) {
                          return `data-grid-row-blue`;
                        } else {
                          return `data-grid-row-white`;
                        }
                      }}
                    />
                  </Box>
                ) : (
                  <NoLoadtype />
                )}
              </div>
            )}
          </div>
        </div>
      </AnimatedInformationContainer>
      <Box
        boxShadow={1}
        padding={2}
        sx={{
          minHeight: '200px',
          maxHeight: '400px',
          marginTop: '15px',
          width: '100%',
          backgroundColor: theme.palette.background.paper,
          borderRadius: '15px'
        }}>
        <SubTitle>Remaining Quantities</SubTitle>
        {pageState.projectSum.projectId !== '' && (
          <LoadTypeRow>
            {pageState.loadTypes.map((l, i) => {
              const pSum = pageState.projectSum.details.find((d) => d.loadTypeId === l.entityId);
              const totalCurrent = pSum?.qte ?? 0;
              const loadCount = totalCurrent >= l.qte ? 0 : l.qte - totalCurrent;
              return (
                <LoadTypeCard key={l.entityId} style={{ marginLeft: i === 0 ? '0' : '30px' }}>
                  <div
                    style={{
                      height: '100%',
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}>
                    <CircleProgress
                      loadTypeName={l.name}
                      materialName={l.material_name}
                      current={totalCurrent}
                      total={l.qte}
                      type={l.qte_type}
                      loadCount={loadCount / 10}
                    />
                  </div>
                </LoadTypeCard>
              );
            })}
          </LoadTypeRow>
        )}
      </Box>
      <TableContainer style={{ height: pageState.activeLoads.length === 0 ? '300px' : '750px' }}>
        <SubTitle>Today’s Active Loads</SubTitle>
        {pageState.activeLoads.length === 0 ? (
          <NoData label="No Load history for today" />
        ) : (
          <Box
            sx={{
              height: '100%',
              width: '100%',
              minHeight: '700px',
              '& .data-grid-row-blue': {
                bgcolor: `${lighterBlue}`,
                '&: hover': {
                  '&: .MuiDataGrid-cell': { bgcolor: `rgba(22,153,213,0.3)` },
                  bgcolor: `rgba(22,153,213,0.3)`
                }
              }
            }}>
            <DataGrid
              className="#export-active-loads" //how we get the file name for export donot delete or change format
              getRowClassName={(p) => {
                if (p.indexRelativeToCurrentPage % 2 === 1) {
                  return `data-grid-row-blue`;
                } else {
                  return `data-grid-row-white`;
                }
              }}
              columns={activeLoadsColumns()}
              rows={pageState.activeLoads}
              autoHeight
              pageSize={10}
              rowsPerPageOptions={[10]}
              checkboxSelection={false}
              disableSelectionOnClick
              rowCount={pageState.activeLoads.length}
              experimentalFeatures={{ newEditingApi: true }}
              components={{ Toolbar: ExportBar }}
              getRowId={(row) => row.id}
            />
          </Box>
        )}
      </TableContainer>
      <TableContainer style={{ height: pageState.loadHistories.length === 0 ? '450px' : '900px' }}>
        <SubTitle>Load History</SubTitle>
        <Header style={{ marginBottom: '16px' }}>
          <div>
            <SubTitle style={{ alignSelf: 'flex-start', margin: 0 }}>Filter By Truck</SubTitle>
            <FormControl
              variant="filled"
              sx={{
                minWidth: 210,
                '& .MuiFilledInput-root': {
                  backgroundColor: 'rgba(244, 245, 253, 0.6)',
                  height: '56px'
                }
              }}>
              <InputLabel>Truck ID</InputLabel>
              <Select value={truckFilter.selectedItem.id} onChange={handleChangeTruckFilter}>
                {truckFilter.filter.map((t) => (
                  <MenuItem value={t.id} key={t.id}>
                    {t.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div>
            <SubTitle style={{ alignSelf: 'flex-start', margin: 0 }}>Choose date</SubTitle>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <div id="start-date-picker" style={{ alignSelf: 'flex-start' }}>
                <DatePicker
                  disabled={pageState.allLoadHistories.length === 0}
                  label="Date Filter"
                  value={dateFilter}
                  disableFuture
                  onChange={(newValue) => {
                    hanldeDatefilterChange(newValue);
                  }}
                  renderInput={(params) => (
                    <StyledTextField {...params} variant="filled" size="small" />
                  )}
                />
                <Button
                  variant="text"
                  color="secondary"
                  size="small"
                  label="Clear"
                  onClick={() => {
                    hanldeDatefilterChange(null);
                  }}
                />
              </div>
            </LocalizationProvider>
          </div>
          <div>
            <SubTitle style={{ alignSelf: 'flex-start', margin: 0 }}>
              Or Filter View by Date Range
            </SubTitle>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <div id="start-date-picker" style={{ alignSelf: 'flex-start' }}>
                <DatePicker
                  disabled={pageState.allLoadHistories.length === 0}
                  label="From"
                  maxDate={dateRangeFilterEnd}
                  value={dateRangeFilterStart}
                  disableFuture
                  onChange={(newValue) => {
                    if (newValue?.isValid()) {
                      setDateRangeFilterStart(newValue);
                      handleDateRangeFilterApply(newValue, dateRangeFilterEnd);
                    }
                  }}
                  renderInput={(params) => (
                    <StyledTextField {...params} variant="filled" size="small" />
                  )}
                />
              </div>
            </LocalizationProvider>
          </div>
          <div>
            <SubTitle style={{ alignSelf: 'flex-start', visibility: 'hidden', margin: 0 }}>
              Or Filter View by Date Range
            </SubTitle>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <div id="start-date-picker" style={{ alignSelf: 'flex-start' }}>
                <DatePicker
                  disabled={pageState.allLoadHistories.length === 0}
                  label="To"
                  minDate={dateRangeFilterStart}
                  value={dateRangeFilterEnd}
                  disableFuture
                  onChange={(newValue) => {
                    if (newValue?.isValid()) {
                      setDateRangeFilterEnd(newValue);
                      handleDateRangeFilterApply(dateRangeFilterStart, newValue);
                    }
                  }}
                  renderInput={(params) => (
                    <StyledTextField {...params} variant="filled" size="small" />
                  )}
                />
                <Button
                  variant="text"
                  color="secondary"
                  size="small"
                  label="Clear"
                  onClick={() => {
                    setDateRangeFilterStart(null);
                    setDateRangeFilterEnd(null);
                    setDateFilter(null);

                    setTruckFilter({
                      ...truckFilter,
                      selectedItem: {
                        name: 'All',
                        id: '-1',
                        selected: false
                      }
                    });
                    setPageState({ ...pageState, loadHistories: [...pageState.allLoadHistories] });
                  }}
                />
              </div>
            </LocalizationProvider>
          </div>
        </Header>
        {pageState.loadHistories.length === 0 ? (
          <NoData label="No load history found" />
        ) : (
          <Box
            sx={{
              height: '100%',
              width: '100%',
              minHeight: '850px',
              cursor: 'pointer',
              '& .data-grid-row-blue': {
                bgcolor: `${lighterBlue}`,
                '&: hover': {
                  '&: .MuiDataGrid-cell': { bgcolor: `rgba(22,153,213,0.3)` },
                  bgcolor: `rgba(22,153,213,0.3)`
                }
              },
              '& .MuiDataGrid-columnHeaderTitle': {
                textOverflow: 'clip',
                whiteSpace: 'break-spaces',
                lineHeight: 1
              }
            }}>
            <DataGrid
              className="#export-load-hitory"
              getRowClassName={(p) => {
                if (p.indexRelativeToCurrentPage % 2 === 1) {
                  return `data-grid-row-blue`;
                } else {
                  return `data-grid-row-white`;
                }
              }}
              columns={loadHistoryColumns(handleEmailLoad)}
              rows={pageState.loadHistories}
              onRowClick={(v) => {
                const data = v.row as LoadHistory;
                setPageState({
                  ...pageState,
                  jobTicketModalData: {
                    loads: [],
                    ticket: { ...defaultActiveLoad, truckId: data.truckEntityId }
                  },
                  jobTicketModalOpen: true
                });
              }}
              autoHeight
              pageSize={10}
              rowsPerPageOptions={[10]}
              checkboxSelection={false}
              disableSelectionOnClick
              experimentalFeatures={{ newEditingApi: true }}
              components={{ Toolbar: ExportBar }}
              getRowId={(row) => row.entityId}
              rowCount={pageState.loadHistories.length}
            />
          </Box>
        )}
      </TableContainer>
      <TruckModal
        open={pageState.addTruckModalOpen}
        handleClose={(entityId?: string) => {
          const newTruck = coreState.trucks.find((t) => t.entityId === entityId);

          if (newTruck) {
            const newSelection = {
              id: newTruck.entityId,
              name: newTruck.splId + +' ' + newTruck.truckNumber,
              selected: true
            };
            setPageState({
              ...pageState,
              addTruckModalOpen: false,
              trucks: [...pageState.trucks, newSelection],
              selectedTrucks: [...pageState.selectedTrucks, newSelection]
            });
            return;
          }

          setPageState({ ...pageState, addTruckModalOpen: false });
        }}
        label="Add Truck"
        mode="New"
        projectName={pageState.project.name}
        projectDate={pageState.project.startDate}
        shouldAssign={false}
      />
      <UserModal
        open={pageState.addUserModalOpen}
        handleClose={(profileId?: string) => {
          //setUsers();
          const newUser = coreState.users.find((u) => u.profileId === profileId);

          if (newUser) {
            const newSelection = {
              id: newUser.profileId,
              name: `${newUser.firstName} ${newUser.lastName} (${newUser.role})`,
              selected: true
            };
            setPageState({
              ...pageState,
              users: [...pageState.users, newSelection],
              selectedUsers: [...pageState.selectedUsers, newSelection],
              addUserModalOpen: false
            });
            return;
          }
          setPageState({ ...pageState, addUserModalOpen: false });
        }}
        mode="new"
      />
      <AddReceivingSiteModal
        open={pageState.addSiteModalOpen}
        shouldAssign={false}
        handleClose={(entityId?: string) => {
          const newSite = coreState.sites.find((s) => s.entityId === entityId);
          if (newSite) {
            setPageState({
              ...pageState,
              addSiteModalOpen: false,
              receivingSites: [
                ...pageState.receivingSites,
                { id: newSite.entityId, name: newSite.name, selected: true }
              ],
              selectedReceivingSites: [
                ...pageState.selectedReceivingSites,
                { id: newSite.entityId, name: newSite.name, selected: true }
              ]
            });
            return;
          }
          setPageState({ ...pageState, addSiteModalOpen: false });
        }}
      />
      <MaterialDeliverModal
        open={pageState.addDeliveryConfModalOpen}
        label="Add New Delivery Confirmation Type"
        mode="New"
        handleClose={(entityId?: string) => {
          const newDelivConf = coreState.deliveryConf.find((dc) => dc.entityId === entityId);
          if (newDelivConf) {
            setPageState({
              ...pageState,
              addDeliveryConfModalOpen: false,
              deliveryConfs: [
                ...pageState.deliveryConfs,
                { id: newDelivConf.entityId, name: newDelivConf.name, selected: true }
              ],
              selectedDeliveryConfs: [
                ...pageState.selectedDeliveryConfs,
                { id: newDelivConf.entityId, name: newDelivConf.name, selected: true }
              ]
            });
            return;
          }
          setPageState({ ...pageState, addDeliveryConfModalOpen: false });
        }}
      />
      <AddLoadTypeModal
        open={pageState.addLoadModalOpen}
        data={pageState.selectedLoadType}
        projectId={state.entityId}
        label={pageState.loadTypeModalMode === 'New' ? 'Add Load Type' : 'Edit Load Type'}
        handleClose={() => {
          loadProject();
        }}
        mode={pageState.loadTypeModalMode}
      />
      <NotificationPopup
        open={notificationState.open}
        isSuccess={notificationState.success}
        actionable={notificationState.actionable}
        handleConfirm={notificationState.handleConfirm}
        title={notificationState.title}
        message={notificationState.message}
        handleCancel={() => {
          setNotifications({
            open: false,
            success: true,
            message: '',
            actionable: false
          });
        }}
        borderRadius={0}
      />
      <JobTicketModal
        open={pageState.jobTicketModalOpen}
        data={{
          ...pageState.jobTicketModalData,
          projectName: `${pageState.project.address.street1}${
            pageState.project.projectNumber === -1 ? '' : ' #' + pageState.project.projectNumber
          }`,
          projectId: pageState.project.entityId
        }}
        handleClose={() => {
          loadProject();
        }}
      />
      <SendLoadHistoryModal
        load={pageState.emailLoadHistory}
        handleClose={() => {
          setPageState({ ...pageState, emailLoadHistory: undefined });
        }}
      />
    </ContentContainer>
  );
};

const Column = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  text-align: left;
  padding: 10px 10px;
`;

const InformationContainer = styled.div`
  height: 75px;
  width: 100%;
  border-radius: 15px;
  background-color: ${({ theme }) => theme.palette.background.paper};
`;

const InformationContainerAction = styled.div`
  height: 75px;
  width: 100%;
  border-radius: 15px;
  background-color: ${({ theme }) => theme.palette.background.paper};
  display: flex;
  justify-content: space-between;
  padding: 0 15px;
  align-items: center;
`;

const TableContainer = styled.div`
  height: 800px;
  width: 100%;
  background-color: ${({ theme }) => theme.palette.background.paper};
  padding: 16px;
  border-radius: 15px;
  margin-top: 15px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);
`;

const Header = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
  padding-left: 16px;
`;

const HeaderContainer = styled.div`
  height: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 8px;
`;

const HeaderRefreshOContainer = styled.div`
  width: 15%;
  display: flex;
  margin-right: 16px;
  align-items: center;
  justify-content: flex-end;
  > button {
    background-color: ${lightPurple} !important;
  }
`;

const HeaderRow = styled.div`
  height: 80px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 8px;
`;

const AnimatedInformationContainer = animated(InformationContainer);
const AnimatedArrowIcon = animated(AiOutlineArrowDown);
