import dayjs from 'dayjs';
import { reasonCode } from '../network';
import { UserPermission, defaultPermission, userPermissions } from '../types';
import { provincesInput, timezone, userRoles } from './constant';

export function getRole(input: string): userRoles {
  const role = Object.values(userRoles).find((r) => r === input);
  if (role) {
    return role;
  }
  const keyIndex = Object.keys(userRoles).findIndex((k) => k === input);
  if (keyIndex !== -1) {
    return Object.values(userRoles)[keyIndex];
  }

  return userRoles.operator;
}

export function getProvince(input: string): provincesInput {
  const province = Object.values(provincesInput).find((p) => p === input);
  if (province) {
    return province;
  }

  const keyIndex = Object.keys(provincesInput).findIndex((k) => k === input);
  if (keyIndex !== -1) {
    return Object.values(provincesInput)[keyIndex];
  }
  return provincesInput.ON;
}

export function getRoleValue(input: string): string {
  const keys = Object.keys(userRoles);
  const values = Object.values(userRoles);
  const failSafe = keys.find((k) => k === input);
  if (failSafe) return failSafe;
  const index = values.findIndex((r) => r === input);
  if (index !== -1) {
    return keys[index];
  }
  return 'operator';
}

export function getProvinceValue(input: string): string {
  const keys = Object.keys(provincesInput);
  const failSafe = keys.find((k) => k === input);
  if (failSafe) return failSafe;
  const values = Object.values(provincesInput);
  const index = values.findIndex((r) => r === input);
  if (index !== -1) {
    return keys[index];
  }
  return 'ON';
}

export function decontructPackedId(error: object): number {
  if (
    'status_message' in error &&
    typeof error.status_message === 'string' &&
    'reason_code' in error &&
    typeof error.reason_code === 'number'
  ) {
    if (error.reason_code === reasonCode.packactIdError) {
      const i = error.status_message.lastIndexOf(']');
      const spaceI = error.status_message.lastIndexOf(' ');
      const lastPacketId = parseInt(error.status_message.substring(spaceI + 1, i));
      return lastPacketId + 1;
    }
  }
  if (
    'statusMessage' in error &&
    typeof error.statusMessage === 'string' &&
    'reasonCode' in error &&
    typeof error.reasonCode === 'number'
  ) {
    if (error.reasonCode === reasonCode.packactIdError) {
      const i = error.statusMessage.lastIndexOf(']');
      const spaceI = error.statusMessage.lastIndexOf(' ');
      const lastPacketId = parseInt(error.statusMessage.substring(spaceI, i));
      return lastPacketId + 1;
    }
  }
  return 0;
}

export function getPermissons(userRoles: string): UserPermission {
  const roleIndex = Object.keys(userPermissions).findIndex((u) => u === userRoles);
  if (roleIndex !== -1) {
    return Object.values(userPermissions)[roleIndex];
  }
  return defaultPermission;
}

export function getDate(date: string): string {
  if (!date) {
    return '';
  }
  const d = dayjs(date).tz(timezone, true).toISOString();

  return d.substring(0, d.indexOf('T'));
}

export function getToday(): string {
  const today = dayjs().tz(timezone, true).toISOString();
  return today.substring(0, today.indexOf('T'));
}

export function timeDiff(
  inTime: string,
  outTime: string
): { diff: string; hour: number; minute: number } {
  const inIndex = inTime.indexOf(':');
  const outIndex = outTime.indexOf(':');

  const inHr = inTime.substring(0, inIndex);
  const outHr = outTime.substring(0, outIndex);

  if (inIndex + 1 < inTime.length && outIndex + 1 < outTime.length) {
    const inMin = inTime.substring(inIndex + 1);
    const outMin = outTime.substring(outIndex + 1);
    const iH = parseInt(inHr);
    const oH = parseInt(outHr);
    const iM = parseInt(inMin);
    const oM = parseInt(outMin);

    if (!isNaN(iH) && !isNaN(oH) && !isNaN(iM) && !isNaN(oM)) {
      let hour = oH - iH;
      let min = 0;

      if (oM < iM) {
        hour = hour - 1;
        min = 60 + oM - iM;
        return { diff: `${hour}:${min < 10 ? '0' + min : min}`, hour, minute: min };
      } else if (oM >= iM) {
        min = oM - iM;
        return { diff: `${hour}:${min < 10 ? '0' + min : min}`, hour, minute: min };
      }
    }
  }

  return { diff: '', hour: -1, minute: -1 };
}

export const validateEmail = (email: string | undefined | null): boolean => {
  if (!email) return false;
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};
