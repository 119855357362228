import React, { ReactElement, useState, useEffect } from 'react';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  useTheme,
  Select,
  SelectChangeEvent
} from '@mui/material';
import { ContentContainer, fixedFilters, ProjectElementContianer } from '../component/containers';
import { Button, MultiSelectItem, StyledTextField, SubTitle } from '../component/atoms';
import styled from 'styled-components';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs } from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { Company, LoadType, loadTypeFrom, Project, rw, Truck } from '../types';
import useCoreState from '../hooks/useCoreState';
import { CoreActionTypes } from '../context';
import {
  CEOP,
  customEntity,
  errorMessage,
  postResHelper,
  reasonCode,
  requestStr,
  responseDecoder,
  responseErrorHelper,
  ResType,
  scriptOP
} from '../network';
import { useErrors, useLoading, useService } from '../hooks';
import {
  TruckModal,
  UserModal,
  AddReceivingSiteModal,
  AddLoadTypeModal,
  MaterialDeliverModal
} from '../component/modals';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { m3, userRoles } from '../util';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { NoData } from './staticPages';
import { lighterBlue } from '../theme';
export const provinces = [
  'Alberta',
  'British Columbia',
  'Manitoba',
  'New Brunswick',
  'Newfoundland and Labrador',
  'Northwest Territories',
  'Nova Scotia',
  'Nunavut',
  'Ontario',
  'Prince Edward Island',
  'Quebec',
  'Saskatchewan',
  'Yukon'
];

type ProjectDisplay = {
  name: string;
  add1: string;
  add2: string;
  city: string;
  province: string;
  fName: string;
  lName: string;
  number: string;
  date: string;
  note: string;
  email: string;
  selectedTruckIds: string[];
  selectedLoadTypeIds: string[];
};

type NewProjectState = {
  project: ProjectDisplay;
  loadTypes: LoadType[];
  trucks: MultiSelectItem[];
  users: MultiSelectItem[];
  receivingSites: MultiSelectItem[];
  deliveryConfs: MultiSelectItem[];

  selectedTrucks: MultiSelectItem[];
  selectedUsers: MultiSelectItem[];
  selectedReceivingSites: MultiSelectItem[];
  selectedDeliveryConfs: MultiSelectItem[];

  userFilter: string;
  companyFilter: string;

  isModalLoading: boolean;
  isModalSuccess: boolean;
  isModalError: boolean;
  addTruckModalOpen: boolean;
  addSiteModalOpen: boolean;
  addUserModalOpen: boolean;
  addLoadModalOpen: boolean;
  addDeliveryConfModalOpen: boolean;
};

const defaultProjectDisplay: ProjectDisplay = {
  name: '',
  add1: '',
  add2: '',
  city: '',
  province: 'Ontario',
  fName: '',
  lName: '',
  number: '',
  date: dayjs().toISOString(),
  note: '',
  email: '',
  selectedLoadTypeIds: [],
  selectedTruckIds: []
};

const defaultProjectState = (): NewProjectState => {
  return {
    project: defaultProjectDisplay,
    trucks: [],
    users: [],
    receivingSites: [],
    loadTypes: [],
    deliveryConfs: [],

    selectedTrucks: [],
    selectedUsers: [],
    selectedReceivingSites: [],
    selectedDeliveryConfs: [],

    userFilter: fixedFilters.all,
    companyFilter: fixedFilters.all,

    isModalLoading: false,
    isModalSuccess: false,
    isModalError: false,
    addTruckModalOpen: false,
    addSiteModalOpen: false,
    addUserModalOpen: false,
    addLoadModalOpen: false,
    addDeliveryConfModalOpen: false
  };
};

type PageError = {
  projectName: boolean;
  address: boolean;
  city: boolean;
  contactFName: boolean;
  dateError: boolean;
  contactLName: boolean;
  phone: boolean;
};

export type UserOptions = {
  name: string;
  entityId: string;
};

const columns = (): GridColDef[] => {
  return [
    { field: 'material_name', headerName: 'Material', width: 150, hideable: false },
    { field: 'zone_name', headerName: 'Zone ID', width: 100, hideable: false },
    {
      field: 'qte',
      headerName: `Quantity (${m3})`,
      width: 100,
      hideable: false
    },
    {
      field: 'special_requirements',
      headerName: 'Delivery Confirmation Type',
      width: 250,
      hideable: false
    },
    {
      field: 'notes',
      headerName: 'Notes',
      filterable: false,
      hideable: false,
      width: 350
    }
  ];
};

//needs to load trucks loadTypes Receiving Sites

export const NewProjectPage = (): ReactElement => {
  const theme = useTheme();

  const { coreState, updateCoreState } = useCoreState();
  const [time, setTime] = React.useState<Dayjs | null>(dayjs());
  const [projectState, setProjectState] = useState<NewProjectState>(defaultProjectState());
  const { setLoading } = useLoading();
  const { service } = useService();
  const [pageErrors, setPageErrors] = useState<PageError>({
    projectName: false,
    address: false,
    city: false,
    contactFName: false,
    dateError: false,
    contactLName: false,
    phone: false
  });
  const { setError } = useErrors();

  const navigate = useNavigate();
  const today = dayjs().toISOString();
  const hasProjectPermission = coreState.permission.project >= rw;
  const hasLoadTypePermission = coreState.permission.loadType >= rw;

  useEffect(() => {
    initPageLoad();
  }, []);

  function setTrucks(entityId?: string) {
    //merge updated corestate trucks to keep the options up to date
    const trucks = coreState.trucks
      .filter((t) => t.projectId === '' || t.projectId === undefined)
      .sort((a, b) => (a.splId + ' ' + a.truckNumber).localeCompare(b.splId + ' ' + b.truckNumber))
      .map((t) => {
        return { name: t.splId + ' ' + t.truckNumber, id: t.entityId, selected: false };
      });

    const selectedTrucks = trucks.map((t): MultiSelectItem => {
      const truckFound = projectState.selectedTrucks.find((st) => st.id === t.id);
      if (truckFound) {
        return truckFound;
      } else {
        if (entityId && t.id === entityId) {
          return { ...t, selected: true };
        }
        return t;
      }
    });

    selectedTrucks.sort((x, y) => {
      if (x.selected > y.selected) return -1;
      if (x.selected < y.selected) return 1;
      else return 0;
    });

    setProjectState({
      ...projectState,
      trucks,
      addTruckModalOpen: false,
      selectedTrucks
    });
  }

  function setReceivingSites(entityId?: string) {
    //merge updated corestate receiving sites to keep the options up to date

    const sites = coreState.sites
      .filter((s) => s.isReceiving)
      .map((s) => {
        return {
          name: s.name,
          id: s.entityId,
          selected: false
        };
      });

    const selectedReceivingSites = sites.map((s): MultiSelectItem => {
      const siteFound = projectState.selectedReceivingSites.find((sr) => sr.id === s.id);

      if (siteFound) return siteFound;
      else {
        if (entityId && s.id === entityId) {
          return { ...s, selected: true };
        }
        return s;
      }
    });

    selectedReceivingSites.sort((x, y) => {
      if (x.selected > y.selected) return -1;
      if (x.selected < y.selected) return 1;
      else return 0;
    });

    setProjectState({
      ...projectState,
      addSiteModalOpen: false,
      receivingSites: [...sites],
      selectedReceivingSites
    });
  }

  function setUsers(profileId?: string) {
    //merge updated corestate users to keep the options up to date

    const users = coreState.users
      .filter((u) => u.isActive)
      .map((u) => {
        return {
          name: `${u.firstName} ${u.lastName} (${u.role})`,
          id: u.profileId,
          selected: false
        };
      });

    const selectedUsers = users.map((u) => {
      const userFound = projectState.selectedUsers.find((su) => su.id === u.id);
      if (userFound) return userFound;
      else {
        if (profileId && u.id === profileId) {
          return { ...u, selected: true };
        }
        return u;
      }
    });

    selectedUsers.sort((x, y) => {
      if (x.selected > y.selected) return -1;
      if (x.selected < y.selected) return 1;
      else return 0;
    });

    setProjectState({
      ...projectState,
      users,
      addUserModalOpen: false,
      selectedUsers
    });
  }

  const initPageLoad = async () => {
    setLoading(true);
    let trucks: Truck[] = [];
    let companies: Company[] = [];
    try {
      const truckRes = await service.get(`${customEntity}${CEOP.getEntityPage}Truck/`, {
        context: requestStr
      });
      const companyRes = await service.get(`${customEntity}${CEOP.getEntityPage}TruckingCompany/`, {
        context: requestStr
      });

      const truckTypesDecoded = responseDecoder<ResType<Truck>>(truckRes);
      const trucksLoadError = responseErrorHelper(truckRes);
      const companiesDecoded = responseDecoder<ResType<Company>>(companyRes);

      if (
        trucksLoadError &&
        trucksLoadError.status === 403 &&
        trucksLoadError.reason_code === reasonCode.sessionTimeout
      ) {
        setError({ networkError: errorMessage.sessionTimeout });
        return;
      }

      if (truckTypesDecoded.count > 0) {
        trucks = [
          ...truckTypesDecoded.items.map((t): Truck => {
            return {
              companyId: t.data.companyId,
              driver: { ...t.data.driver },
              scheduleDate: t.data.scheduleDate,
              splId: t.data.splId,
              truckNumber: t.data.truckNumber,
              plate: t.data.plate,
              capacity: t.data.capacity,
              entityId: t.entityId,
              version: t.version,
              driverId: t.data.driverId,
              province: t.data.province,
              isAssigned: t.data.isAssigned,
              projectId: t.data.projectId
            };
          })
        ];
      }
      if (companiesDecoded.items.length > 0) {
        companies = [
          ...companiesDecoded.items.map((c): Company => {
            return {
              version: c.version,
              entityId: c.entityId,
              name: c.data.name,
              contactName: c.data.contactName ?? '',
              contactphone: c.data.contactphone ?? ''
            };
          })
        ];
      }
      //udpating context
      updateCoreState({
        type: CoreActionTypes.ADD_TRUCK,
        payload: {
          ...coreState,
          trucks,
          companies
        }
      });

      //setting page state

      const trucksDisplay = trucks
        .filter((t) => t.projectId !== undefined || t.projectId !== '')
        .sort((a, b) =>
          (a.splId + ' ' + a.truckNumber).localeCompare(b.splId + ' ' + b.truckNumber)
        )
        .map((t) => {
          return { name: t.splId + ' ' + t.truckNumber ?? '', id: t.entityId, selected: false };
        });

      const sites = coreState.sites
        .filter((s) => s.isReceiving)
        .map((s) => {
          return {
            name: s.name,
            id: s.entityId,
            selected: false
          };
        });

      const users = coreState.users
        .filter((u) => u.isActive)
        .map((u) => {
          return {
            name: `${u.firstName} ${u.lastName} (${u.role})`,
            id: u.profileId,
            selected: false
          };
        });

      const deliveryConfs: MultiSelectItem[] = [];

      coreState.deliveryConf
        .filter((dc) => dc.isActive)
        .forEach((dc) =>
          deliveryConfs.push({
            name: dc.name,
            id: dc.entityId,
            selected: false
          })
        );

      setProjectState({
        ...projectState,
        trucks: [...trucksDisplay],
        addTruckModalOpen: false,
        selectedTrucks: [...trucksDisplay],
        users,
        addUserModalOpen: false,
        selectedUsers: [...users],
        addSiteModalOpen: false,
        receivingSites: [...sites],
        selectedReceivingSites: [...sites],
        deliveryConfs: [...deliveryConfs],
        selectedDeliveryConfs: [...deliveryConfs]
      });
      setLoading(false);
    } catch (e) {
      if (e === 403) {
        setError({ networkError: errorMessage.sessionTimeout });
      }
    }
  };

  const handleSelectMultipleUsers = (value: MultiSelectItem[]) => {
    setProjectState({ ...projectState, selectedUsers: [...value] });
  };

  const openUserModal = (): void => {
    setProjectState({ ...projectState, addUserModalOpen: true });
  };

  const handleChangeMultipleTruck = (value: MultiSelectItem[]) => {
    setProjectState({ ...projectState, selectedTrucks: [...value] });
  };
  const openTruckModal = (): void => {
    setProjectState({ ...projectState, addTruckModalOpen: true });
  };

  const handleChangeMultipleSites = (value: MultiSelectItem[]) => {
    setProjectState({ ...projectState, selectedReceivingSites: [...value] });
  };
  const openSiteModal = (): void => {
    setProjectState({ ...projectState, addSiteModalOpen: true });
  };

  const openDeliveryConfsModal = (): void => {
    setProjectState({ ...projectState, addDeliveryConfModalOpen: true });
  };

  const handleChangeMultipleDeliveryConfs = (value: MultiSelectItem[]) => {
    setProjectState({
      ...projectState,
      deliveryConfs: [...value],
      selectedDeliveryConfs: [...value]
    });
  };

  const handleSelecRoleFilter = (event: SelectChangeEvent) => {
    const allUsers = coreState.users.filter((u) => u.isActive);
    const selected = projectState.selectedUsers.filter((u) => u.selected);

    let updatedList: MultiSelectItem[] = [];

    if (event.target.value === 'All') {
      updatedList = allUsers.map((au) => {
        const userFind = selected.find((s) => s.id === au.profileId);
        return {
          name: `${au.firstName} ${au.lastName} (${au.role})`,
          id: au.profileId,
          selected: userFind && userFind.selected ? true : false
        };
      });
    } else {
      const role = event.target.value as userRoles;

      updatedList = allUsers
        .filter((u) => u.role === role)
        .map((au) => {
          const userFind = selected.find((s) => s.id === au.profileId);
          return {
            name: `${au.firstName} ${au.lastName} (${au.role})`,
            id: au.profileId,
            selected: userFind && userFind.selected ? true : false
          };
        });
    }

    setProjectState({
      ...projectState,
      userFilter: event.target.value,
      selectedUsers: [...updatedList]
    });
  };
  const handleSelecCompanyFilter = (event: SelectChangeEvent) => {
    const allTrucks = coreState.trucks.filter(
      (t) => t.projectId !== undefined || t.projectId !== ''
    );
    const selected = projectState.selectedTrucks.filter((ft) => ft.selected);
    let updatedList: MultiSelectItem[] = [];
    if (event.target.value === 'All') {
      updatedList = allTrucks.map((at) => {
        const truckFind = selected.find((t) => t.id === at.entityId);
        return {
          name: at.splId + ' ' + at.truckNumber,
          id: at.entityId,
          selected: truckFind && truckFind.selected ? true : false
        };
      });
    } else {
      const company = coreState.companies.find((c) => c.name === event.target.value);
      if (company) {
        updatedList = allTrucks
          .filter((t) => t.companyId === company.entityId)
          .map((at) => {
            const truckFind = selected.find((t) => t.id === at.entityId);
            return {
              name: at.splId + ' ' + at.truckNumber,
              id: at.entityId,
              selected: truckFind && truckFind.selected ? true : false
            };
          });
      } else {
        updatedList = [...projectState.selectedTrucks];
      }
    }
    setProjectState({
      ...projectState,
      companyFilter: event.target.value,
      selectedTrucks: [...updatedList]
    });
  };

  const handleChangeProvince = (event: SelectChangeEvent) => {
    setProjectState({
      ...projectState,
      project: { ...projectState.project, province: event.target.value }
    });
  };
  const handleLoadTypeClose = (data: LoadType): void => {
    if (data.entityId) {
      setProjectState({
        ...projectState,
        addLoadModalOpen: false,
        loadTypes: [...projectState.loadTypes, data]
      });
    } else {
      setProjectState({
        ...projectState,
        addLoadModalOpen: false
      });
    }
  };

  const handleCreateNewProject = async () => {
    setLoading(true);
    if (
      !projectState.project.name ||
      !projectState.project.add1 ||
      !projectState.project.city ||
      !projectState.project.fName ||
      !projectState.project.lName ||
      !projectState.project.number
    ) {
      setError({ pageError: 'Please fill in required field(s).' });
      setPageErrors({
        ...pageErrors,
        projectName: projectState.project.name === '',
        address: projectState.project.add1 === '',
        city: projectState.project.city === '',
        contactFName: projectState.project.fName === '',
        contactLName: projectState.project.lName === '',
        phone: projectState.project.number === ''
      });
      setLoading(false);
      return;
    }

    if (coreState.projects.find((p) => p.name === projectState.project.name)) {
      setError({ pageError: 'Project name is being used, please select an another name' });
      setPageErrors({
        ...pageErrors,
        projectName: true
      });
      setLoading(false);
      return;
    }

    if (projectState.project.date < today) {
      setError({ pageError: 'Project start date is invalid' });
      setPageErrors({
        ...pageErrors,
        dateError: true
      });
      setLoading(false);
      return;
    }

    const newProject: Omit<Project, 'entityId' | 'version' | 'projectNumber'> = {
      name: projectState.project.name,
      address: {
        street1: projectState.project.add1,
        street2: projectState.project.add2,
        city: projectState.project.city,
        prov: projectState.project.province
      },
      startDate: projectState.project.date,
      contact: {
        fname: projectState.project.fName,
        lname: projectState.project.lName,
        phone: projectState.project.number,
        email: projectState.project.email
      },
      note: projectState.project.note,
      isActive: true,
      deliveryConfirmations: projectState.selectedDeliveryConfs
        .filter((dc) => dc.selected)
        .map((dc) => dc.name)
    };

    const selectedSiteIds = [
      ...projectState.selectedReceivingSites.filter((sr) => sr.selected).map((sr) => sr.id)
    ];

    const selectedTruckIds = [
      ...projectState.selectedTrucks
        .filter((st) => st.selected)
        .map((i) => {
          return { truckId: i.id, scheduleDate: projectState.project.date };
        })
    ];

    const selectedUsers = projectState.selectedUsers.filter((su) => su.selected).map((u) => u.id);

    const unselectedUsers = projectState.users
      .filter((au) => selectedUsers.find((ss) => ss === au.id) === undefined)
      .map((u) => u.id);

    try {
      const projectRes = await service.post(scriptOP.creatProject, {
        ...newProject,
        trucks: [...projectState.selectedTrucks.filter((st) => st.selected).map((t) => t.id)],
        receivingSites: [...selectedSiteIds],
        loadTypes: [
          ...projectState.loadTypes.map((l) => {
            return {
              name: l.name,
              special_requirements: l.special_requirements,
              material_name: l.material_name,
              qte: l.qte,
              qte_type: l.qte_type,
              isPile: true,
              projectId: '',
              zone_name: l.name,
              notes: l.notes
            };
          })
        ]
      });

      const entityId = postResHelper(projectRes);

      const error = responseErrorHelper(projectRes);

      if (error) {
        if (error.reason_code === reasonCode.sessionTimeout) {
          setError({ networkError: errorMessage.sessionTimeout });
        } else {
          setError({ pageError: `Something was wrong with your request` });
        }
      }

      if (entityId) {
        //update all trucks
        //for coreState local update
        const trucksUpdated = coreState.trucks.map((t) => {
          if (selectedTruckIds.find((pt) => pt.truckId === t.entityId)) {
            return { ...t, isAssigned: true, projectId: entityId };
          }
          return { ...t };
        });

        const sitesUpdated = coreState.sites.map((s) => {
          if (selectedSiteIds.find((si) => si === s.entityId)) {
            return { ...s, projects: [...s.projects.filter((p) => p !== entityId), entityId] };
          }
          return { ...s };
        });

        const userUpdated = coreState.users.map((u) => {
          const userFound = projectState.selectedUsers.find((su) => su.id === u.profileId);

          if (userFound) {
            if (userFound.selected) {
              return { ...u, projects: [...u.projects, entityId] };
            } else {
              return { ...u, projects: [...u.projects.filter((p) => p !== entityId)] };
            }
          }
          return { ...u };
        });

        const assignUserRes = await service.post(scriptOP.assignUsers, {
          projectId: entityId,
          assignment: [...selectedUsers],
          unassigned: [...unselectedUsers]
        });

        const assginError = responseErrorHelper(assignUserRes);

        if (assginError) {
          setError({ pageError: 'There was an error saving the changes ' });
        }

        updateCoreState({
          type: CoreActionTypes.SET_PROJECT_ADDED,
          payload: {
            ...coreState,
            projects: [
              ...coreState.projects,
              { ...newProject, entityId, version: 1, projectNumber: -1 }
            ],
            trucks: [...trucksUpdated],
            users: [...userUpdated],
            sites: [...sitesUpdated],
            loadTypes: [...coreState.loadTypes]
          }
        });

        //redirect to project detail page
        setTimeout(() => {
          navigate(`/project/${newProject.name}`, { state: { entityId } });
        }, 2000);
      }
      setPageErrors({
        ...pageErrors,
        projectName: false,
        address: false,
        city: false,
        contactFName: false,
        dateError: false
      });
    } catch (error) {
      setLoading(false);
    }
  };

  const handleInputChange = (
    value: string,
    type: 'name' | 'add1' | 'add2' | 'city' | 'fName' | 'lName' | 'number' | 'note'
  ): void => {
    switch (type) {
      case 'name':
        setProjectState({ ...projectState, project: { ...projectState.project, name: value } });

        setPageErrors({
          ...pageErrors,
          projectName: false
        });
        break;
      case 'add1':
        setProjectState({ ...projectState, project: { ...projectState.project, add1: value } });
        setPageErrors({
          ...pageErrors,
          address: false
        });
        break;
      case 'add2':
        setProjectState({ ...projectState, project: { ...projectState.project, add2: value } });
        break;
      case 'city':
        setProjectState({ ...projectState, project: { ...projectState.project, city: value } });
        setPageErrors({
          ...pageErrors,
          city: false
        });
        break;
      case 'fName':
        setProjectState({ ...projectState, project: { ...projectState.project, fName: value } });
        setPageErrors({
          ...pageErrors,
          contactFName: false
        });
        break;
      case 'lName':
        setProjectState({ ...projectState, project: { ...projectState.project, lName: value } });
        break;
      case 'number':
        setProjectState({ ...projectState, project: { ...projectState.project, number: value } });
        break;
      case 'note':
        setProjectState({ ...projectState, project: { ...projectState.project, note: value } });
        break;
      default:
        break;
    }
  };

  return (
    <ContentContainer header title="Create New Project">
      <Box
        component="form"
        autoComplete="on"
        sx={{
          height: '350px',
          width: '100%',
          backgroundColor: theme.palette.background.paper,
          boxShadow: theme.shadows[1],
          borderRadius: '15px',
          padding: theme.spacing(1),
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
        <Column>
          <SubTitle style={{ alignSelf: 'flex-start', margin: 0 }}>Project Name</SubTitle>
          <StyledTextField
            label="Project Name"
            id="filled-size-small"
            disabled={!hasProjectPermission}
            onBlur={() => {
              if (coreState.projects.find((p) => p.name === projectState.project.name)) {
                setError({
                  pageError: 'Project name is being used, please select an another name'
                });
                setPageErrors({
                  ...pageErrors,
                  projectName: true
                });
              }
            }}
            onFocus={() => {
              setPageErrors({
                ...pageErrors,
                projectName: false
              });
            }}
            error={pageErrors.projectName}
            required
            value={projectState.project.name}
            onChange={(e) => {
              handleInputChange(e.target.value, 'name');
            }}
            variant="filled"
            size="small"
            fullWidth
          />
          <SubTitle style={{ alignSelf: 'flex-start', margin: 0, marginTop: 10 }}>
            Location Information
          </SubTitle>
          <StyledTextField
            label="Address line 1"
            id="filled-size-small"
            sx={{ marginTop: theme.spacing(1) }}
            required
            error={pageErrors.address}
            value={projectState.project.add1}
            disabled={!hasProjectPermission}
            onChange={(e) => {
              handleInputChange(e.target.value, 'add1');
            }}
            variant="filled"
            fullWidth
            size="small"
          />
          <StyledTextField
            label="Address line 2"
            id="filled-size-small"
            sx={{ marginTop: theme.spacing(1) }}
            value={projectState.project.add2}
            disabled={!hasProjectPermission}
            onChange={(e) => {
              handleInputChange(e.target.value, 'add2');
            }}
            variant="filled"
            fullWidth
            size="small"
          />
          <div
            style={{
              height: 48,
              display: 'flex',
              alignItems: 'center',
              marginTop: theme.spacing(1),
              width: '100%',
              justifyContent: 'space-between'
            }}>
            <StyledTextField
              label="City"
              id="filled-size-small"
              required
              value={projectState.project.city}
              disabled={!hasProjectPermission}
              error={pageErrors.city}
              onChange={(e) => {
                handleInputChange(e.target.value, 'city');
              }}
              variant="filled"
              size="small"
            />
            <FormControl
              variant="filled"
              sx={{
                m: 1,
                minWidth: 120,
                '& .MuiFilledInput-root': {
                  backgroundColor: 'rgba(244, 245, 253, 0.6)',
                  height: '48px'
                }
              }}>
              <InputLabel>Province</InputLabel>
              <Select
                value={projectState.project.province}
                onChange={handleChangeProvince}
                disabled={!hasProjectPermission}>
                {provinces.map((p) => (
                  <MenuItem value={p} key={p}>
                    {p}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </Column>
        <Column id="column_2">
          <SubTitle style={{ alignSelf: 'flex-start', margin: 0 }}>Start Date</SubTitle>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div id="start-date-picker" style={{ alignSelf: 'flex-start' }}>
              <DatePicker
                label="Date*"
                disabled={!hasProjectPermission}
                value={dayjs(projectState.project.date).toISOString()}
                onChange={(newValue) => {
                  if (dayjs(newValue).isValid()) {
                    const isoString = dayjs(newValue).toISOString();
                    const date = isoString.substring(0, isoString.indexOf('T'));
                    const time = projectState.project.date.substring(
                      projectState.project.date.indexOf('T')
                    );
                    setProjectState({
                      ...projectState,
                      project: { ...projectState.project, date: date + time }
                    });
                  }
                }}
                renderInput={(params) => (
                  <StyledTextField {...params} variant="filled" size="small" />
                )}
              />
            </div>
          </LocalizationProvider>
          <SubTitle style={{ alignSelf: 'flex-start', margin: 0, marginTop: 10 }}>
            Contact Information
          </SubTitle>
          <StyledTextField
            label="First Name"
            disabled={!hasProjectPermission}
            id="filled-size-small"
            required
            error={pageErrors.contactFName}
            sx={{ marginTop: theme.spacing(1) }}
            value={projectState.project.fName}
            onChange={(e) => {
              handleInputChange(e.target.value, 'fName');
            }}
            variant="filled"
            fullWidth
            size="small"
          />
          <StyledTextField
            label="Last Name"
            required
            disabled={!hasProjectPermission}
            error={pageErrors.contactLName}
            id="filled-size-small"
            sx={{ marginTop: theme.spacing(1) }}
            value={projectState.project.lName}
            onChange={(e) => {
              handleInputChange(e.target.value, 'lName');
            }}
            variant="filled"
            fullWidth
            size="small"
          />
          <StyledTextField
            label="Phone Number"
            required
            disabled={!hasProjectPermission}
            error={pageErrors.phone}
            id="filled-size-small"
            sx={{ marginTop: theme.spacing(1) }}
            value={projectState.project.number}
            onChange={(e) => {
              handleInputChange(e.target.value, 'number');
            }}
            variant="filled"
            fullWidth
            size="small"
          />
        </Column>
        <Column id="column_3">
          <SubTitle style={{ alignSelf: 'flex-start', margin: 0 }}>Daily Start Time</SubTitle>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div id="start-time-picker" style={{ alignSelf: 'flex-start' }}>
              <TimePicker
                label="Time*"
                disabled={!hasProjectPermission}
                value={time}
                onChange={(newValue) => {
                  if (newValue?.isValid()) {
                    const value = dayjs(newValue).toISOString();
                    const tIndex = value.indexOf('T');
                    const time = value.substring(tIndex);
                    const date = projectState.project.date.substring(
                      0,
                      projectState.project.date.indexOf('T')
                    );
                    setProjectState({
                      ...projectState,
                      project: { ...projectState.project, date: date + time }
                    });

                    setTime(newValue);
                  }
                }}
                renderInput={(params) => (
                  <StyledTextField {...params} variant="filled" size="small" />
                )}
              />
            </div>
          </LocalizationProvider>
          <SubTitle style={{ alignSelf: 'flex-start', margin: 0, marginTop: 10 }}>Notes</SubTitle>
          <StyledTextField
            id="filled-size-small"
            disabled={!hasProjectPermission}
            value={projectState.project.note}
            sx={{ marginTop: theme.spacing(1) }}
            onChange={(e) => {
              handleInputChange(e.target.value, 'note');
            }}
            variant="filled"
            fullWidth
            multiline
            size="small"
            rows={4}
          />
        </Column>
      </Box>
      <Box
        component="div"
        id="lower-container"
        sx={{
          height: '500px',
          marginTop: 3,
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between'
        }}>
        <ProjectElementContianer
          baseOptions={projectState.users}
          multiSelectValue={projectState.selectedUsers}
          filterSelected={projectState.userFilter}
          disabled={!hasProjectPermission}
          addDisabled={coreState.permission.user < rw}
          inputLabel="Filter by Role"
          buttonLabel="Add New Member"
          label="Team"
          filterOptions={[fixedFilters.all, ...Object.values(userRoles)]}
          handleSelectFilter={handleSelecRoleFilter}
          handleMultiSelect={handleSelectMultipleUsers}
          handleButtonClick={openUserModal}
        />
        <ProjectElementContianer
          baseOptions={projectState.trucks}
          multiSelectValue={projectState.selectedTrucks}
          filterSelected={projectState.companyFilter}
          addDisabled={coreState.permission.truck < rw}
          disabled={!hasProjectPermission}
          inputLabel="Filter by Company"
          buttonLabel="Add New Truck"
          label="Trucks"
          filterOptions={[fixedFilters.all, ...coreState.companies.map((c) => c.name)]}
          handleSelectFilter={handleSelecCompanyFilter}
          handleMultiSelect={handleChangeMultipleTruck}
          handleButtonClick={openTruckModal}
        />
        <ProjectElementContianer
          baseOptions={projectState.receivingSites}
          multiSelectValue={projectState.selectedReceivingSites}
          filterSelected={''}
          disabled={!hasProjectPermission}
          addDisabled={coreState.permission.site < rw}
          inputLabel=""
          buttonLabel="Add New Site"
          label="Receiving Site(s)"
          handleMultiSelect={handleChangeMultipleSites}
          handleButtonClick={openSiteModal}
        />
        <ProjectElementContianer
          baseOptions={projectState.deliveryConfs}
          multiSelectValue={projectState.selectedDeliveryConfs}
          disabled={!hasProjectPermission}
          addDisabled={coreState.permission.site < rw}
          filterSelected={'All'}
          inputLabel="Delivery Confirmation Types"
          buttonLabel="Add New Type"
          label="Delivery Confirmation Types"
          handleMultiSelect={handleChangeMultipleDeliveryConfs}
          handleButtonClick={openDeliveryConfsModal}
        />
      </Box>
      <Box
        component="div"
        id="lower-container"
        sx={{
          height: '350px',
          marginTop: 3,
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor: theme.palette.background.paper,
          boxShadow: theme.shadows[1],
          borderRadius: '15px',
          position: 'relative'
        }}>
        <div
          style={{
            height: '50px',
            marginTop: 3,
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderRadius: '15px',
            padding: theme.spacing(2),
            position: 'relative'
          }}>
          <div>
            <SubTitle style={{ alignSelf: 'flex-start', margin: 0 }}>Create Load Types</SubTitle>
          </div>
          {hasLoadTypePermission && (
            <Button
              variant="outlined"
              color="secondary"
              size="normal"
              label="Add Load Type"
              onClick={() => {
                setProjectState({ ...projectState, addLoadModalOpen: true });
              }}
            />
          )}
        </div>
        <div
          id="loadtype-table"
          style={{
            height: 300,
            width: projectState.loadTypes.length > 0 ? '100%' : 'auto',
            minHeight: '300px'
          }}>
          {projectState.loadTypes.length > 0 ? (
            <Box
              sx={{
                height: '100%',
                width: '100%',
                minHeight: '300px',
                '& .data-grid-row-blue': {
                  bgcolor: `${lighterBlue}`,
                  '&: hover': {
                    '&: .MuiDataGrid-cell': { bgcolor: `rgba(22,153,213,0.3)` },
                    bgcolor: `rgba(22,153,213,0.3)`
                  }
                }
              }}>
              <DataGrid
                columns={columns()}
                rows={projectState.loadTypes}
                autoHeight
                pageSize={3}
                rowsPerPageOptions={[3]}
                checkboxSelection={false}
                disableSelectionOnClick
                experimentalFeatures={{ newEditingApi: true }}
                getRowId={(row) => row.entityId}
                getRowClassName={(p) => {
                  if (p.indexRelativeToCurrentPage % 2 === 1) {
                    return `data-grid-row-blue`;
                  } else {
                    return `data-grid-row-white`;
                  }
                }}
              />
            </Box>
          ) : (
            <NoData label="Create new load type for your project." />
          )}
        </div>
      </Box>
      <div
        style={{
          width: '100%',
          height: 60,
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center'
        }}>
        <Button
          styles={{ marginRight: theme.spacing(2) }}
          variant="outlined"
          color="secondary"
          size="normal"
          label="Cancel"
          onClick={() => {
            navigate('/');
          }}
        />
        <Button
          variant="contained"
          color="secondary"
          size="normal"
          label="Save"
          onClick={() => {
            handleCreateNewProject();
          }}
        />
      </div>
      <TruckModal
        open={projectState.addTruckModalOpen}
        handleClose={(entityId?: string) => {
          setTrucks(entityId);
        }}
        label="Add Truck"
        mode="New"
        shouldAssign={false}
        projectDate={projectState.project.date}
      />
      <UserModal
        open={projectState.addUserModalOpen}
        handleClose={(profileId?: string) => {
          setUsers(profileId);
        }}
        mode="new"
      />
      <AddReceivingSiteModal
        open={projectState.addSiteModalOpen}
        shouldAssign={false}
        handleClose={(entityId?: string) => {
          setReceivingSites(entityId);
        }}
      />
      <AddLoadTypeModal
        open={projectState.addLoadModalOpen}
        label="Add Load Type"
        handleClose={handleLoadTypeClose}
        mode="Offline"
      />
      <MaterialDeliverModal
        open={projectState.addDeliveryConfModalOpen}
        label="Add New Delivery Confirmation Type"
        mode="New"
        handleClose={(entityId?: string) => {
          const newDelivConf = coreState.deliveryConf.find((dc) => dc.entityId == entityId);
          if (newDelivConf) {
            setProjectState({
              ...projectState,
              addDeliveryConfModalOpen: false,
              deliveryConfs: [
                ...projectState.deliveryConfs,
                { id: newDelivConf.entityId, name: newDelivConf.name, selected: true }
              ],
              selectedDeliveryConfs: [
                ...projectState.selectedDeliveryConfs,
                { id: newDelivConf.entityId, name: newDelivConf.name, selected: true }
              ]
            });
            return;
          }
          setProjectState({ ...projectState, addDeliveryConfModalOpen: false });
        }}
      />
    </ContentContainer>
  );
};

const Column = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  text-align: left;
  padding: 10px 10px;
`;
