import React, { ReactElement, useState } from 'react';
import styled from 'styled-components';
import { defaultLoadType, LoadType } from '../../types';
import { Button } from '../atoms/button';
import { StyledTextField } from '../atoms/styledTextField';

const Content = styled.div`
  position: relative;
  height: calc(100% - 100px);
  width: 100%;
  margin-top: 50px;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
`;

const Footer = styled.div`
  height: 50px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  padding: 0 20px 0 20px;
`;

export const LoadTypeInsert = ({
  handleCreateNewLoadType,
  handleClose,
  handleConfirm,
  confirmLabel
}: {
  handleCreateNewLoadType: (name?: string, details?: string, notes?: string) => void;
  handleClose: () => void;
  handleConfirm: () => void;
  confirmLabel?: string;
}): ReactElement => {
  const [loadType, setLoadType] = useState<LoadType>(defaultLoadType);
  const [error, setError] = useState<string>('');
  return (
    <>
      <Content>
        <StyledTextField
          label="Name"
          id="filled-size-small"
          defaultValue=""
          value={loadType.name}
          variant="filled"
          error={error !== ''}
          helperText={error}
          fullWidth
          required
          size="small"
          onChange={(e) => {
            setLoadType({ ...loadType, name: e.target.value });
            handleCreateNewLoadType(e.target.value);
          }}
        />
        <StyledTextField
          label="Special Requirments Details"
          id="filled-size-small"
          value={loadType.special_requirements}
          variant="filled"
          fullWidth
          multiline
          size="small"
          rows={2}
          onChange={(e) => {
            e.target.value === ''
              ? setLoadType({
                  ...loadType,
                  special_requirements: e.target.value
                })
              : setLoadType({
                  ...loadType,
                  special_requirements: e.target.value
                });

            handleCreateNewLoadType(undefined, e.target.value);
          }}
        />
        <StyledTextField
          id="filled-size-small"
          value={loadType.notes}
          variant="filled"
          fullWidth
          multiline
          size="small"
          rows={2}
          onChange={(e) => {
            setLoadType({ ...loadType, notes: e.target.value });
            handleCreateNewLoadType(undefined, undefined, e.target.value);
          }}
        />
      </Content>
      <Footer>
        <Button
          variant="outlined"
          label="Cancel"
          onClick={handleClose}
          styles={{ marginRight: 20 }}
          size="normal"
          color="error"
        />
        <Button
          variant="contained"
          label={confirmLabel ? confirmLabel : 'Submit'}
          onClick={() => {
            if (loadType.name) {
              handleConfirm();
            } else {
              setError('Load type name cannot be empty');
            }
          }}
          size="normal"
          color="primary"
        />
      </Footer>
    </>
  );
};
