import React, { ReactElement, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { animated, useSpring } from '@react-spring/web';
import { AppBarMenuItem } from '../atoms/appBarMenuItem';
const MenuContainer = styled.div<{ right?: number }>`
  width: 240px;
  min-height: 100px;
  height: 100%;
  background-color: ${({ theme }) => theme.palette.background.paper};
  border-radius: 15px;
  z-index: 1000;
  position: absolute;
  transform-origin: top right;
  top: 65px;
  right: ${(props) => props.right}px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%),
    0px 1px 10px 0px rgb(0 0 0 / 12%);
`;

const AnimatedMenuContainer = animated(MenuContainer);

export type itemData = {
  label: string;
  onClick?: () => void;
  divider?: boolean;
};

type Props = {
  menuData: itemData[];
  onClose: () => void;
  open: boolean;
  right: number;
};

export const AppBarMenu = ({ menuData, open, right, onClose }: Props): ReactElement => {
  const springs = useSpring({
    scale: open ? 1 : 0.1,
    opacity: open ? 1 : 0,
    height: open ? menuData.length * 50 : menuData.length * 25,
    config: { friction: 20 }
  });

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOut = (e: any) => {
      if (ref.current && !ref.current?.contains(e.target) && open) {
        onClose();
      }
    };

    document.addEventListener('click', handleClickOut, true);

    return () => {
      document.removeEventListener('click', handleClickOut, true);
    };
  }, []);

  return (
    <AnimatedMenuContainer style={springs} right={right} ref={ref}>
      {menuData.map((m, i) => {
        const dividerBottom = m.divider && i === 0 ? true : false;
        const dividerTop = m.divider && i === menuData.length - 1 ? true : false;

        return (
          <AppBarMenuItem
            disabled={!open}
            key={m.label}
            dividerTop={dividerTop}
            dividerBottom={dividerBottom}
            label={m.label}
            disableClick={m.onClick ? false : true}
            onClick={() => {
              if (m.onClick) m.onClick();
              onClose();
            }}
          />
        );
      })}
    </AnimatedMenuContainer>
  );
};
