import React, { ReactElement, useState, useEffect, useRef } from 'react';

import LinearProgress from '@mui/material/LinearProgress';
import { animated, useSpring } from '@react-spring/web';
import { Caption } from './text';
import { useTheme } from '@mui/material';
import { m3 } from '../../util';

import TruckIcon from '../../asset/icon/iconTruckXS.svg';

const AnimatedProgressIndicator = animated.div;

export const LinearProgressBar = ({
  progress,
  total,
  type,
  isValid,
  totalLoads
}: {
  progress: number;
  total: number;
  type: string;
  isValid: boolean;
  totalLoads: number;
}): ReactElement => {
  const theme = useTheme();
  const [progressLeft, setProgressLeft] = useState<number>(100);
  const progressRef = useRef<HTMLDivElement | null>(null);
  const [percentage, setPercentage] = useState<number>(0);

  useEffect(() => {
    let per = 0;
    if (progress > 0 && progress >= total) {
      per = 100;
    } else {
      per = Math.round((progress / total + Number.EPSILON) * 100);
    }
    if (!isValid) {
      per = 0;
    }

    setPercentage(per);

    if (progressRef.current) {
      const width = progressRef.current.offsetWidth - 20;

      if (!isValid || progress == 0) {
        setProgressLeft(0);
        return;
      }
      if (progress < total) {
        setProgressLeft(width * (progress / total));
        return;
      }

      if (progress >= total) {
        setProgressLeft(width - 10);
        return;
      }
    }
  }, []);

  useEffect(() => {
    if (!progressRef.current) return;
    const resizeOb = new ResizeObserver(() => {
      if (progressRef.current) setOffsetWidth(progressRef.current);
    });
    resizeOb.observe(progressRef.current);

    return () => resizeOb.disconnect();
  }, []);

  const setOffsetWidth = (component: HTMLDivElement): void => {
    const width = component.offsetWidth - 20;

    if (!isValid) {
      setProgressLeft(0);
      return;
    }
    if (progress < total) {
      setProgressLeft(width * (progress / total));
      return;
    }

    if (progress >= total) {
      setProgressLeft(width - 10);
      return;
    }
  };

  const markerSprings = useSpring({
    config: { friction: 35 },
    left: progressLeft
  });

  return (
    <div
      style={{
        height: 50,
        width: '100%',
        position: 'relative',
        display: 'flex',
        alignItems: 'center'
      }}>
      <div style={{ height: 50, width: 30, alignSelf: 'flex-start' }}>
        <Caption>0</Caption>
      </div>
      <div
        ref={(node) => {
          progressRef.current = node;
        }}
        style={{
          height: 50,
          width: '100%',
          alignSelf: 'center',
          position: 'relative',
          paddingLeft: 10,
          paddingRight: 10,
          display: 'flex',
          alignItems: 'center'
        }}>
        <AnimatedProgressIndicator
          style={{
            position: 'absolute',
            top: 5,
            left: progressLeft,
            width: 60,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start'
          }}>
          <div style={{ display: 'flex', justifyContent: 'space-around' }}>
            <Caption style={{ color: '#8c8c8c', margin: 0, textAlign: 'center' }}>
              {isValid ? totalLoads : 'n/a'}
            </Caption>
            <img
              src={TruckIcon}
              height={'auto'}
              width={15}
              style={{ marginLeft: 4, objectFit: 'contain' }}
            />
          </div>
          <Caption
            style={{
              color: theme.palette.secondary.main,
              marginTop: 5
            }}>{`${progress} ${type === 'm3' ? m3 : type}`}</Caption>
        </AnimatedProgressIndicator>
        <LinearProgress
          variant="determinate"
          value={percentage}
          sx={{
            width: '100%',
            backgroundColor: theme.palette.primary.light,
            ' .MuiLinearProgress-bar': {
              transition: 'none',
              backgroundColor: isValid ? theme.palette.secondary.main : theme.palette.primary.light
            }
          }}
        />
      </div>
      <div style={{ height: 50, width: 100, alignSelf: 'flex-end', textAlign: 'center' }}>
        <Caption>{`${total} ${type === 'm3' ? m3 : type}`}</Caption>
      </div>
    </div>
  );
};
