import React, { ReactElement } from 'react';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import { SubTitle } from '../atoms/text';
import styled, { useTheme } from 'styled-components';
import { IoIosCloudDone } from 'react-icons/io';
import { BiMessageAltError } from 'react-icons/bi';

import { IconButton } from '@mui/material';
import { IoClose } from 'react-icons/io5';

import { Spinner } from '../atoms/spinner';

import { ModalLoadingContainer } from '../containers';

export const GenericModal = ({
  open,
  children,
  title,
  handleClose,
  isLoading,
  isSuccess,
  isError
}: {
  open: boolean;
  title: string;
  children: ReactElement | ReactElement[];
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  handleClose: () => void;
}): ReactElement => {
  const theme = useTheme();

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{ backdrop: { timeout: 500 } }}>
      <Fade in={open}>
        <Box sx={style}>
          <Header>
            <SubTitle style={{ color: 'white' }}>{title}</SubTitle>
            <IconButton onClick={handleClose} sx={{ height: 45, width: 45 }}>
              <IoClose style={{ height: 40, width: 40, color: 'white' }} />
            </IconButton>
          </Header>
          {children}
          {isLoading && (
            <ModalLoadingContainer>
              <Spinner />
            </ModalLoadingContainer>
          )}

          {isSuccess && (
            <ModalLoadingContainer>
              <InformationContainer>
                <IoIosCloudDone size={50} color={theme.palette.success.main} />
                <SubTitle style={{ color: theme.palette.success.main, marginLeft: 20 }}>
                  Success
                </SubTitle>
              </InformationContainer>
            </ModalLoadingContainer>
          )}
          {isError && (
            <ModalLoadingContainer>
              <InformationContainer>
                <BiMessageAltError size={50} color={theme.palette.error.main} />
                <SubTitle style={{ color: theme.palette.error.main, marginLeft: 20 }}>
                  Something went wrong, please try again.
                </SubTitle>
              </InformationContainer>
            </ModalLoadingContainer>
          )}
        </Box>
      </Fade>
    </Modal>
  );
};

const style = {
  // eslint-disable-next-line @typescript-eslint/prefer-as-const
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  height: '35%',
  maxWidth: '450px',
  width: '35%',
  borderRadius: '25px'
};

const Header = styled.div`
  height: 50px;
  background-color: ${({ theme }) => theme.palette.primary.main};
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  padding: 0 20px 0 20px;
`;

const InformationContainer = styled.div`
  height: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
