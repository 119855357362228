import axios, { AxiosError } from 'axios';
import React, { ReactElement, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import bg from '../asset/bg2.png';
import {
  Button,
  ErrorMsg,
  Spinner,
  StyledTextField,
  SubTitle,
  SubTitle2
} from '../component/atoms';
import { useService, useToken, useUser } from '../hooks';
import { forgetPsw, login, BASE_URL, APP_ID, getUser } from '../network';
import logo from '../asset/logo.png';
import { FaUserLock } from 'react-icons/fa';
import { IconButton } from '@mui/material';
import { IoClose } from 'react-icons/io5';
import { emailRegex } from '../util';
import { ModalLoadingContainer } from '../component/containers';

const LoginPage = (): ReactElement => {
  const theme = useTheme();
  const { service } = useService();
  const { setUser } = useUser();
  const navigate = useNavigate();

  const { updateToken } = useToken();

  const [emailForgot, setEmail] = useState<{
    email: string;
    error: string;
    success: boolean;
    popupOpen: boolean;
  }>({
    email: '',
    error: '',
    success: false,
    popupOpen: false
  });

  const [userLogin, setUserLogin] = useState<{ email: string; psw: string }>({
    email: '',
    psw: ''
  });
  const [errorMessage, setErrorMessage] = useState<{
    username: string;
    psw: string;
    network: string;
  }>({
    username: '',
    psw: '',
    network: ''
  });
  const [loading, setLoading] = useState<boolean>(false);

  const handleForgetPsw = async () => {
    if (emailForgot.email) {
      await axios.create().post(
        BASE_URL + forgetPsw,
        { email: emailForgot.email },
        {
          headers: {
            'X-APPID': APP_ID,
            'content-type': 'application/json; charset=utf-8'
          }
        }
      );
    }
    setEmail({ ...emailForgot, success: true });
  };

  const handleSubmit = (): void => {
    setLoading(true);
    if (userLogin.email === '') {
      setErrorMessage({ ...errorMessage, username: 'Email cannot be empty' });
      setLoading(false);
    } else if (userLogin.psw === '') {
      setErrorMessage({ ...errorMessage, psw: 'Password cannot be empty' });
      setLoading(false);
    } else if (!emailRegex.test(userLogin.email)) {
      setErrorMessage({ ...errorMessage, username: 'Please provide a valid email address' });
      setLoading(false);
    } else {
      setErrorMessage({
        username: '',
        psw: '',
        network: ''
      });

      login(userLogin.email, userLogin.psw)
        .then((res) => {
          if (typeof res === 'object' && res !== null) {
            const resObj = res as { data: { token: string } };

            if (resObj.data.token) {
              updateToken(resObj.data.token);

              getUser(service)
                .then((res) => {
                  if (res) {
                    setUser(res);
                  }
                })
                .catch((e) => {
                  throw e;
                });
              setTimeout(() => {
                navigate('/');
              }, 500);
            }
          }
        })
        .catch((e) => {
          const er = e as AxiosError;
          const status = er.response?.status;
          if (status === 401 || status === 403) {
            setErrorMessage({
              ...errorMessage,
              network: 'Invalid user name or password'
            });
          }
          setLoading(false);
        });
    }
  };

  return (
    <Container>
      <Card>
        <LogoContainer>
          <img
            src={logo}
            width={300}
            height="auto"
            alt="Truck Log Logo"
            style={{ objectFit: 'cover' }}
          />
          <LogoRow align="flex-start" background={theme.palette.primary.contrastText}>
            <LogoLabel>Operations Portal</LogoLabel>
          </LogoRow>
        </LogoContainer>
        <StyledForm>
          <StyledTextField
            label="Enter email"
            id="filled-size-small"
            variant="filled"
            size="small"
            required
            autoFocus
            value={userLogin.email}
            type="email"
            autoComplete="yes"
            width={300}
            error={errorMessage.username !== ''}
            onFocus={() => {
              setErrorMessage({ ...errorMessage, username: '', network: '' });
            }}
            helperText={errorMessage.username}
            onChange={(e) => {
              setUserLogin({ ...userLogin, email: e.target.value });
            }}
          />
          <StyledTextField
            label="Enter password"
            variant="filled"
            size="small"
            required
            type="password"
            autoComplete="yes"
            width={300}
            value={userLogin.psw}
            error={errorMessage.psw !== ''}
            helperText={errorMessage.psw}
            onChange={(e) => {
              setUserLogin({ ...userLogin, psw: e.target.value });
            }}
            onFocus={() => {
              setErrorMessage({ ...errorMessage, psw: '', network: '' });
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                handleSubmit();
              }
            }}
          />

          <Button
            variant="contained"
            onClick={handleSubmit}
            size="large"
            color="primary"
            label="login"
          />
          <ErrorMsg>{errorMessage.network}</ErrorMsg>
        </StyledForm>
        <EndContainer>
          <Button
            variant="text"
            onClick={() => {
              setEmail({ ...emailForgot, popupOpen: true });
            }}
            size="large"
            color="primary"
            label="Forgot your password?"
          />
        </EndContainer>
        <ErrorMsg
          style={{
            color: theme.palette.primary.main,
            position: 'absolute',
            bottom: 0,
            left: '43%'
          }}>
          version {process.env.REACT_APP_VERSION}
        </ErrorMsg>
      </Card>

      {loading && (
        <ModalLoadingContainer style={{ borderRadius: 0 }}>
          <Spinner />
        </ModalLoadingContainer>
      )}

      {emailForgot.popupOpen && (
        <ModalLoadingContainer style={{ borderRadius: 0 }}>
          <PopContainer>
            <Header>
              <SubTitle style={{ color: theme.palette.background.paper }}>Forgot Password</SubTitle>
              <IconButton
                onClick={() => {
                  setEmail({
                    ...emailForgot,
                    popupOpen: false,
                    success: false,
                    email: '',
                    error: ''
                  });
                }}
                sx={{ height: 45, width: 45 }}>
                <IoClose style={{ height: 40, width: 40, color: theme.palette.background.paper }} />
              </IconButton>
            </Header>
            {emailForgot.success === false ? (
              <div>
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}>
                  <FaUserLock
                    size={50}
                    color={theme.palette.success.main}
                    style={{ marginRight: 20 }}
                  />
                  <SubTitle2>
                    Enter the email address used to register your account. Click the link sent to
                    you to reset your password.
                  </SubTitle2>
                </div>
                <StyledTextField
                  label="Email"
                  id="filled-size-small"
                  required
                  error={emailForgot.error !== ''}
                  helperText={emailForgot.error}
                  value={emailForgot.email}
                  onFocus={() => {
                    setEmail({ ...emailForgot, error: '' });
                  }}
                  onBlur={() => {
                    if (emailForgot.email !== '' && !emailRegex.test(emailForgot.email)) {
                      setEmail({ ...emailForgot, error: 'Invalid email format' });
                    }
                  }}
                  variant="filled"
                  fullWidth
                  size="small"
                  onChange={(e) => {
                    setEmail({ ...emailForgot, email: e.target.value });
                  }}
                />
              </div>
            ) : (
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}>
                <FaUserLock
                  size={50}
                  color={theme.palette.success.main}
                  style={{ marginRight: 20 }}
                />
                <SubTitle2>The email has been sent, please check your inbox.</SubTitle2>
              </div>
            )}

            <Footer>
              <Button
                variant="contained"
                label={emailForgot.success ? 'Close' : 'Send Link'}
                size="normal"
                color="secondary"
                disabled={emailForgot.email === '' || emailForgot.error !== ''}
                onClick={() => {
                  emailForgot.success
                    ? setEmail({
                        ...emailForgot,
                        popupOpen: false,
                        success: false,
                        email: '',
                        error: ''
                      })
                    : handleForgetPsw();
                }}
              />
            </Footer>
          </PopContainer>
        </ModalLoadingContainer>
      )}
    </Container>
  );
};

export default LoginPage;

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background-image: url(${bg});
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 10%;
`;

const Card = styled.div`
  position: relative;
  min-width: 374px;
  min-height: 600px;
  max-width: 500px;
  width: 35%;
  height: 70%;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5%;
  flex-direction: column;
  background-color: ${(props) => props.theme.palette.primary.contrastText};
`;

const LogoContainer = styled.div`
  min-width: 300px;
  height: 210px;
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const LogoRow = styled.div<{ align: string; background: string }>`
  width: 100%;
  height: 30%;
  display: flex;
  justify-content: center;
  background-color: ${(props) => props.background};
  align-items: ${(props) => props.align};
  flex-direction: row;
`;

const LogoLabel = styled.p`
  line-height: 1em;
  letter-spacing: 7.5px;
  margin-block-start: 5px;
  ${({ theme }) => `font-size: ${theme.fontSize.h6}px; 
  font-weight: ${theme.fontWeight.body2};`}
  user-select: none;
`;

const StyledForm = styled.form`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  height: 45%;
  width: 100%;
  min-width: 374px;
`;

const EndContainer = styled.div`
  height: 20%;
  width: 100%;
  min-width: 374px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
`;

const Header = styled.div`
  position: absolute;
  height: 50px;
  top: 0;
  left: 0;
  right: 0;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  background-color: ${(props) => props.theme.palette.primary.main};
  padding: 0 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Footer = styled.div`
  position: absolute;
  height: 50px;
  bottom: 0;
  left: 0;
  right: 0;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  padding: 0 16px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
const PopContainer = styled.div`
  height: 300px;
  position: relative;
  width: 40%;
  background-color: ${(props) => props.theme.palette.background.paper};
  z-index: 2000;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
`;
