import React, { ReactElement, useEffect, useState } from 'react';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import { SubTitle } from '../atoms/text';
import styled, { useTheme } from 'styled-components';

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  IconButton
} from '@mui/material';
import { IoClose } from 'react-icons/io5';
import { Button } from '../atoms/button';
import { StyledTextField } from '../atoms/styledTextField';
import { provinces } from '../../page';
import {
  ActiveLoad,
  Company,
  Contact,
  defaultActiveLoad,
  defaultCompany,
  defaultProject,
  defaultTruck,
  Load,
  Project,
  Truck
} from '../../types';
import { scriptOP, responseStatus, ReqError, getErrorMessage, reasonCode } from '../../network';
import { Spinner } from '../atoms/spinner';
import useCoreState from '../../hooks/useCoreState';
import { CoreActionTypes } from '../../context';
import { useService } from '../../hooks';
import { AiFillCheckCircle, AiFillWarning } from 'react-icons/ai';
import { getProvinceValue, numberTest } from '../../util';
import { ModalLoadingContainer } from '../containers';

import dayjs, { Dayjs } from 'dayjs';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

type Props = {
  open: boolean;
  data: { ticket: ActiveLoad; loads: Load[]; projectName: string; projectId: string };
  handleClose: () => void;
};

type ModalErrors = {
  company: string;
  abName: string;
  truckId: string;
  plate: string;
  fName: string;
  lName: string;
  contactNumber: string;
  capacity: string;
  note: string;
};

type ModalState = {
  activeTab: number;
  loads: LoadDisplay[];
  ticket: ActiveLoad;
  truck: Truck;
  selectedCompany: Company;
  companies: Company[];
  selectedProject: Project;
  isLoading: boolean;
  abName: string;
  truckId: string;
  totalTime: string;
  totalQte: number;
  hasMadeEdit: boolean;
};

const initModalState: ModalState = {
  activeTab: 0,
  loads: [],
  ticket: defaultActiveLoad,
  truck: defaultTruck,
  selectedCompany: defaultCompany,
  companies: [defaultCompany],
  isLoading: false,
  abName: '',
  selectedProject: defaultProject,
  truckId: '',
  totalTime: '',
  totalQte: 0,
  hasMadeEdit: false
};

const defaultErrors: ModalErrors = {
  company: '',
  abName: '',
  truckId: '',
  plate: '',
  fName: '',
  lName: '',
  contactNumber: '',
  capacity: '',
  note: ''
};

type LoadDisplay = {
  entityId: string;
  loadNumber: number;
  loadTypeName: string;
  qte: number;
  exSite: string;
  reSite: string;
  departure: string;
  arrive: string;
  time: string;
  notes: string;
  creation: string;
};

export const JobTicketModal = ({ open, data, handleClose }: Props): ReactElement => {
  const [modalState, setModalState] = useState<ModalState>(initModalState);
  const { coreState, updateCoreState } = useCoreState();
  const { service } = useService();
  const [time, setTime] = useState<Dayjs | null>(dayjs());
  const [date, setDate] = useState<string>('');
  const [modalErrors, setModalErrors] = useState<ModalErrors>(defaultErrors);
  const [notificationState, setNotifications] = useState<{
    message: string;
    isSuccess: boolean;
    isOpen: boolean;
    closeModal: boolean;
  }>({
    message: '',
    isSuccess: true,
    isOpen: false,
    closeModal: false
  });

  const theme = useTheme();
  const onClose = (): void => {
    handleClose();
  };

  const handleChangeProvince = (event: SelectChangeEvent) => {
    const province = event.target.value;
    const truck = { ...modalState.truck, province };
    setModalState({ ...modalState, truck, hasMadeEdit: true });
  };

  const handleDriverCreate = (input: string, type: 'firstName' | 'lastName' | 'number') => {
    if (type === 'firstName') {
      setModalErrors({ ...defaultErrors, fName: '' });
      const driver: Contact = { ...modalState.truck.driver, fname: input };

      setModalState({ ...modalState, truck: { ...modalState.truck, driver }, hasMadeEdit: true });
      return;
    }
    if (type === 'lastName') {
      setModalErrors({ ...defaultErrors, lName: '' });
      const driver: Contact = { ...modalState.truck.driver, lname: input };

      setModalState({ ...modalState, truck: { ...modalState.truck, driver }, hasMadeEdit: true });
      return;
    }
    if (type === 'number') {
      setModalErrors({ ...defaultErrors, contactNumber: '' });

      const driver: Contact = { ...modalState.truck.driver, phone: input };

      setModalState({ ...modalState, truck: { ...modalState.truck, driver }, hasMadeEdit: true });
      return;
    }
  };

  const handleNewTruckInput = (input: string, type: 'splId' | 'plate' | 'notes' | 'truckId') => {
    if (type === 'splId') {
      setModalErrors({ ...defaultErrors, abName: '' });

      setModalState({ ...modalState, abName: input, hasMadeEdit: true });
      return;
    }

    if (type === 'plate') {
      setModalErrors({ ...defaultErrors, plate: '' });
      if (input.length && input.length > 8) {
        setModalErrors({ ...defaultErrors, plate: 'Please use a valid license plate.' });
        return;
      }
      const truck: Truck = { ...modalState.truck, plate: input };
      setModalState({ ...modalState, truck, hasMadeEdit: true });
      return;
    }
    if (type === 'notes') {
      const truck: Truck = { ...modalState.truck, note: input };
      setModalState({ ...modalState, truck, hasMadeEdit: true });
      return;
    }
    if (type === 'truckId') {
      setModalErrors({ ...defaultErrors, truckId: '' });
      setModalState({ ...modalState, truckId: input, hasMadeEdit: true });
    }

    return;
  };

  const handleSelectCompany = (event: SelectChangeEvent) => {
    const companyName = event.target.value;
    const companyFind = modalState.companies.find((c) => c.name === companyName);

    if (companyName !== '' && companyFind) {
      setModalState({
        ...modalState,
        selectedCompany: companyFind,
        hasMadeEdit: true
      });
    }
  };

  const hanldeValidation = (): boolean => {
    const errors = { ...defaultErrors };
    const splId = modalState.abName + ' ' + modalState.truckId;

    if (!modalState.abName) {
      errors.abName = 'This field is required.';
    }

    if (
      coreState.trucks.find((t) => t.splId + ' ' + t.truckNumber === splId) !== undefined &&
      splId !== `${modalState.truck.splId} ${modalState.truck.truckNumber}`
    ) {
      errors.truckId = 'Truck ID has to be unique.';
    }

    if (!modalState.truckId) {
      errors.truckId = 'This field is required.';
    }

    if (!modalState.truck.driver.fname) {
      errors.fName = 'Driver name is required';
    }

    if (modalState.truck.note && modalState.truck.note?.length > 32) {
      errors.note = 'too long';
    }

    setModalErrors({ ...errors });
    return Object.values(errors).filter((e) => e !== '').length !== 0;
  };

  useEffect(() => {
    if (open) {
      setModalErrors({ ...defaultErrors });
    }

    if (!open) {
      setModalState({ ...initModalState });
    }

    return () => {
      setModalState({ ...initModalState });
    };
  }, [open]);

  const handleSubmitEdit = async () => {
    //edit truck
    setModalState({ ...modalState, isLoading: true });
    const hasErrors = hanldeValidation();

    if (!hasErrors) {
      const { selectedCompany, truck, selectedProject } = modalState;
      let companyEntityId: string = selectedCompany.entityId;

      try {
        const dataPost = {
          splId: modalState.abName,
          truckNumber: parseInt(modalState.truckId),
          companyId: companyEntityId,
          province: getProvinceValue(truck.province),
          plate: truck.plate,
          driver: truck.driver,
          capacity: truck.capacity,
          note: truck.note ?? '',
          noteImage: truck.noteImage ?? '',
          scheduleDate: date,
          projectId: data.projectId,
          isAssigned: truck.isAssigned
        };
        //post to update truck
        const truckRes = await service.post(scriptOP.updateEntity, {
          dataJson: { ...dataPost },
          entityType: 'Truck',
          acl: { other: 2 },
          entityId: truck.entityId,
          version: truck.version
        });

        const responses = responseStatus(truckRes);

        //const truckID = postResHelper(truckRes);

        if (!responses.success) {
          setNotifications({
            message: 'Error! Something went wrong editing the truck.',
            isOpen: true,
            isSuccess: false,
            closeModal: false
          });
          return;
        }

        if (responses.success) {
          const updatedTruck: Truck = {
            entityId: truck.entityId,
            version: truck.version + 1,
            driverId: '',
            scheduleDate: date,
            splId: modalState.abName,
            truckNumber: parseInt(modalState.truckId),
            companyId: companyEntityId,
            driver: truck.driver,
            province: truck.province,
            plate: truck.plate,
            capacity: truck.capacity,
            note: truck.note ?? '',
            noteImage: truck.noteImage ?? '',
            isAssigned: false,
            projectId: selectedProject.entityId
          };

          if (coreState.trucks.find((t) => t.entityId === truck.entityId)) {
            const trucksUpdate = coreState.trucks.map((t) => {
              if (t.entityId === truck.entityId) {
                return updatedTruck;
              } else {
                return t;
              }
            });

            updateCoreState({
              type: CoreActionTypes.SET_TRUCKS,
              payload: { ...coreState, trucks: trucksUpdate }
            });
            setModalState({ ...modalState, isLoading: false });
            setNotifications({
              message: 'Success! The truck has been updated.',
              isOpen: true,
              isSuccess: true,
              closeModal: true
            });
          }
        }
      } catch (e) {
        if (e && typeof e === 'object') {
          const error = e as ReqError;
          if (error && error.reason_code !== reasonCode.packactIdError) {
            const errorMessage = getErrorMessage(error.reason_code);
            setNotifications({
              message: errorMessage,
              isOpen: true,
              isSuccess: false,
              closeModal: false
            });
          }
        }
      }
    } else {
      setModalState({ ...modalState, isLoading: false });
    }
  };

  const calTtlTime = (min: number, hr: number): string => {
    let hour = hr;
    let minute = 0;

    if (min >= 60) {
      hour = hour + Math.floor(min / 60);
      minute = min % 60;
      return minute >= 10 ? `${hour}:${minute}` : `${hour}:0${minute}`;
    } else {
      return min >= 10 ? `${hour}:${min}` : `${hour}:0${min}`;
    }
  };

  useEffect(() => {
    setModalState({ ...modalState, isLoading: true });

    let totalMin = 0;
    let totalHr = 0;
    let totalQte = 0;

    const truckFound = coreState.trucks.find((t) => t.entityId === data.ticket.truckId);
    const projectFound = coreState.projects.find((p) => p.entityId === data.projectId);

    if (truckFound) {
      console.log('found truck');
      setModalState({
        ...modalState,

        totalTime: calTtlTime(totalMin, totalHr),
        totalQte: totalQte,
        companies: [...coreState.companies],
        isLoading: false,
        selectedCompany:
          coreState.companies.find((c) => c.entityId === truckFound.companyId) ?? defaultCompany,
        abName: truckFound.splId,
        truckId: truckFound.truckNumber.toString(),
        truck: truckFound
      });

      if (
        truckFound.scheduleDate !== '' &&
        truckFound.scheduleDate.indexOf('T') !== 0 &&
        truckFound.scheduleDate.includes('T')
      ) {
        setDate(truckFound.scheduleDate);
        setTime(dayjs(truckFound.scheduleDate));
      } else {
        if (projectFound) {
          setDate(projectFound.startDate);
          setTime(dayjs(projectFound.startDate));
        } else {
          setDate(dayjs().toISOString());
          setTime(dayjs());
        }
      }
    }
  }, [data.loads, data.projectName, data.projectId]);

  useEffect(() => {
    if (notificationState.isOpen) {
      setTimeout(() => {
        setNotifications({ message: '', isSuccess: true, isOpen: false, closeModal: false });

        if (notificationState.closeModal) handleClose();
      }, 3000);
    }
  }, [notificationState.isOpen]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={onClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{ backdrop: { timeout: 500 } }}>
      <Fade in={open}>
        <Box sx={style}>
          <Header>
            <SubTitle style={{ color: 'white' }}>Edit Truck</SubTitle>
            <IconButton onClick={onClose} sx={{ height: 45, width: 45 }}>
              <IoClose style={{ height: 40, width: 40, color: 'white' }} />
            </IconButton>
          </Header>
          <Content className="Content">
            <div
              style={{
                height: '100%',
                width: '100%',
                position: 'absolute',
                left: 0,
                top: 60,
                borderBottomLeftRadius: 15,
                borderBottomRightRadius: 15
              }}>
              <div
                style={{
                  height: '45%',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-evenly',
                  alignItems: 'center',
                  padding: '8px'
                }}>
                <InputColumn>
                  <InputCell>
                    <SubTitle style={{ alignSelf: 'flex-start', margin: 0 }}>SPL Truck ID</SubTitle>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        width: '100%',
                        justifyContent: 'space-between',
                        marginTop: 8
                      }}>
                      <StyledTextField
                        label="Abbreviation"
                        id="filled-size-small"
                        variant="filled"
                        onFocus={() => {
                          setModalErrors({ ...modalErrors, abName: '' });
                        }}
                        error={modalErrors.abName !== ''}
                        helperText={modalErrors.abName}
                        value={modalState.abName}
                        required
                        width={120}
                        size="small"
                        onChange={(e) => {
                          handleNewTruckInput(e.target.value, 'splId');
                        }}
                      />
                      <StyledTextField
                        label="Truck Number"
                        id="filled-size-small"
                        onFocus={() => {
                          setModalErrors({ ...modalErrors, truckId: '' });
                        }}
                        value={modalState.truckId}
                        error={modalErrors.truckId !== ''}
                        helperText={modalErrors.truckId}
                        type="number"
                        required
                        variant="filled"
                        size="small"
                        width={120}
                        onBlur={() => {
                          const isNum = numberTest.test(modalState.truckId);
                          if (!isNum) {
                            setModalErrors({ ...defaultErrors, truckId: 'Numbers only.' });
                          }
                        }}
                        onChange={(e) => {
                          handleNewTruckInput(e.target.value, 'truckId');
                        }}
                        sx={{
                          '& .MuiFilledInput-root': {
                            width: '90px !important'
                          },
                          '&: .MuiTextField-root': {
                            width: '90px !important'
                          }
                        }}
                      />
                    </div>
                  </InputCell>
                  <InputCell>
                    <SubTitle style={{ alignSelf: 'flex-start', margin: 0 }}>Project</SubTitle>
                    <StyledTextField
                      label="Project"
                      id="filled-size-small"
                      disabled
                      value={data.projectName}
                      variant="filled"
                      size="small"
                      fullWidth
                      sx={{
                        marginTop: '8px',
                        '& .MuiFilledInput-root': {
                          width: '90px !important'
                        },
                        '&: .MuiTextField-root': {
                          width: '90px !important'
                        }
                      }}
                    />
                  </InputCell>
                </InputColumn>
                <InputColumn>
                  <InputCell>
                    <SubTitle style={{ alignSelf: 'flex-start', margin: 0 }}>Company</SubTitle>
                    <FormControl
                      fullWidth
                      variant="filled"
                      sx={{
                        m: 1,
                        minWidth: 120,
                        '& .MuiFilledInput-root': {
                          backgroundColor: 'rgba(244, 245, 253, 0.6)',
                          height: '48px'
                        }
                      }}>
                      <InputLabel>Choose Existing Company</InputLabel>
                      <Select
                        value={modalState.selectedCompany.name}
                        onChange={handleSelectCompany}
                        fullWidth>
                        {modalState.companies.map((p) => (
                          <MenuItem value={p.name} key={p.entityId}>
                            {p.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </InputCell>
                  <InputCell>
                    <SubTitle style={{ alignSelf: 'flex-start', margin: 0 }}>
                      License Plate
                    </SubTitle>
                    <div
                      style={{
                        height: 120,
                        display: 'flex',
                        alignItems: 'flex-start',
                        width: '100%',
                        marginTop: 8,
                        justifyContent: 'space-between'
                      }}>
                      <FormControl
                        variant="filled"
                        sx={{
                          width: 150,
                          '& .MuiFilledInput-root': {
                            backgroundColor: 'rgba(244, 245, 253, 0.6)',
                            height: '48px'
                          }
                        }}>
                        <InputLabel>Province</InputLabel>
                        <Select
                          value={modalState.truck.province}
                          fullWidth
                          onChange={handleChangeProvince}>
                          {provinces.map((p) => (
                            <MenuItem value={p} key={p}>
                              {p}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <StyledTextField
                        label="Plate Number"
                        id="filled-size-small"
                        value={modalState.truck.plate}
                        error={modalErrors.plate !== ''}
                        helperText={modalErrors.plate}
                        width={120}
                        variant="filled"
                        size="small"
                        onFocus={() => {
                          setModalErrors({ ...modalErrors, plate: '' });
                        }}
                        onChange={(e) => {
                          handleNewTruckInput(e.target.value, 'plate');
                        }}
                      />
                    </div>
                  </InputCell>
                </InputColumn>
                <InputColumn>
                  <InputCell style={{ height: '80%' }}>
                    <SubTitle style={{ alignSelf: 'flex-start', margin: 0 }}>Driver</SubTitle>
                    <StyledTextField
                      label="First Name"
                      id="filled-size-small"
                      sx={{ marginTop: 1 }}
                      required
                      error={modalErrors.fName !== ''}
                      helperText={modalErrors.fName}
                      onBlur={(e) => {
                        if (!e.target.value) {
                          setModalErrors({ ...modalErrors, fName: 'This field is required.' });
                        }
                      }}
                      value={modalState.truck.driver.fname}
                      variant="filled"
                      fullWidth
                      size="small"
                      onFocus={() => {
                        setModalErrors({ ...modalErrors, fName: '' });
                      }}
                      onChange={(e) => {
                        handleDriverCreate(e.target.value, 'firstName');
                      }}
                    />
                    <StyledTextField
                      label="Last Name"
                      id="filled-size-small"
                      sx={{ marginTop: 1.25 }}
                      required
                      error={modalErrors.lName !== ''}
                      helperText={modalErrors.lName}
                      onFocus={() => {
                        setModalErrors({ ...modalErrors, lName: '' });
                      }}
                      onBlur={(e) => {
                        if (!e.target.value) {
                          setModalErrors({ ...modalErrors, lName: 'This field is required.' });
                        }
                      }}
                      value={modalState.truck.driver.lname}
                      variant="filled"
                      fullWidth
                      size="small"
                      onChange={(e) => {
                        handleDriverCreate(e.target.value, 'lastName');
                      }}
                    />
                    <StyledTextField
                      label="Phone Number"
                      id="filled-size-small"
                      sx={{ marginTop: 1.25 }}
                      onFocus={() => {
                        setModalErrors({ ...modalErrors, contactNumber: '' });
                      }}
                      error={modalErrors.contactNumber !== ''}
                      helperText={modalErrors.contactNumber}
                      value={modalState.truck.driver.phone}
                      variant="filled"
                      fullWidth
                      size="small"
                      onChange={(e) => {
                        handleDriverCreate(e.target.value, 'number');
                      }}
                    />
                  </InputCell>
                </InputColumn>
              </div>
              <NoteContainer>
                <div style={{ height: '100%', width: '65%' }}>
                  <SubTitle style={{ alignSelf: 'flex-start', margin: 0 }}>
                    Note (32 character max)
                  </SubTitle>
                  <StyledTextField
                    id="filled-size-small"
                    value={modalState.truck.note}
                    variant="filled"
                    onBlur={(e) => {
                      if (e.target.value.length > 32) {
                        setModalErrors({ ...modalErrors, note: '32 character max' });
                      }
                    }}
                    error={modalErrors.note !== ''}
                    helperText={`${modalState.truck.note?.length ?? 0} / 32`}
                    onFocus={() => {
                      setModalErrors({ ...modalErrors, note: '' });
                    }}
                    fullWidth
                    multiline
                    size="small"
                    rows={1}
                    onChange={(e) => {
                      handleNewTruckInput(e.target.value, 'notes');
                    }}
                  />
                </div>
                <div style={{ height: '100%', width: '35%', paddingLeft: '3%' }}>
                  <SubTitle
                    style={{
                      alignSelf: 'flex-start',
                      marginTop: 0,
                      marginBottom: 0
                    }}>
                    Daily Start Time
                  </SubTitle>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <div id="start-time-picker" style={{ alignSelf: 'flex-start' }}>
                      <TimePicker
                        label="Time"
                        value={time}
                        onChange={(newValue) => {
                          if (newValue?.isValid()) {
                            const value = dayjs(newValue).toISOString();
                            const tIndex = value.indexOf('T');
                            const time = value.substring(tIndex);
                            setModalState({ ...modalState, hasMadeEdit: true });
                            const d = date.substring(0, date.indexOf('T'));

                            setDate(d + time);

                            setTime(newValue);
                          }
                        }}
                        renderInput={(params) => (
                          <StyledTextField {...params} variant="filled" size="small" />
                        )}
                      />
                    </div>
                  </LocalizationProvider>
                </div>
              </NoteContainer>
              <ActionContainer>
                <Button
                  variant="outlined"
                  size="normal"
                  color="secondary"
                  label="Cancel"
                  onClick={handleClose}
                  styles={{ marginRight: '16px' }}
                />
                <Button
                  variant="contained"
                  size="normal"
                  color="secondary"
                  label="Submit"
                  disabled={modalState.hasMadeEdit === false}
                  onClick={() => handleSubmitEdit()}
                />
              </ActionContainer>
            </div>
          </Content>
          {modalState.isLoading && (
            <ModalLoadingContainer>
              <Spinner />
            </ModalLoadingContainer>
          )}
          {notificationState.isOpen && (
            <ModalLoadingContainer>
              <FeedbackContainer>
                {notificationState.isSuccess ? (
                  <AiFillCheckCircle size={50} color={theme.palette.success.main} />
                ) : (
                  <AiFillWarning size={50} color={theme.palette.error.main} />
                )}
                <SubTitle>{notificationState.message}</SubTitle>
              </FeedbackContainer>
            </ModalLoadingContainer>
          )}
        </Box>
      </Fade>
    </Modal>
  );
};

const Header = styled.div`
  height: 50px;
  background-color: ${({ theme }) => theme.palette.primary.main};
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  padding: 0 20px 0 20px;
`;

const Content = styled.div`
  position: relative;
  height: calc(100% - 100px);
  width: 100%;

  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  padding: 0 20px;
`;
const style = {
  // eslint-disable-next-line @typescript-eslint/prefer-as-const
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  minHeight: '700px',
  height: '65%',
  maxWidth: '950px',
  width: '65%',
  borderRadius: '25px'
};

const ActionContainer = styled.div`
  height: 60px;
  width: 100%;
  position: absolute;
  bottom: 16px;
  left: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 16px;
`;

const InputColumn = styled.div`
  height: 100%;
  width: 33%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding: 8px;
`;

const InputCell = styled.div`
  height: 50%;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
`;

const FeedbackContainer = styled.div`
  height: 125px;
  width: 400px;
  padding: 5px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.background.paper};
`;

const NoteContainer = styled.div`
  width: 100%;
  align-self: flex-start;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  height: 114px;
`;
