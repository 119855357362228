import React, { ReactElement } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import { useTheme } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { DeliveryConfirmation, Material, User } from '../../types';
import { IconButton } from './iconButton';
import { lightGrey } from '../../theme';
type Props = {
  data: Material | DeliveryConfirmation | User;
  setEditSelected: (data: any) => void;
  setDisableSelected: (data: any) => void;
  disabled?: boolean;
};
export const ActionCell = ({
  data,
  setEditSelected,
  disabled,
  setDisableSelected
}: Props): ReactElement => {
  const theme = useTheme();
  return (
    <div
      style={{
        height: '100%',
        width: 150,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}>
      <IconButton
        disabled={disabled === true}
        onClick={() => {
          setEditSelected(data);
        }}
        height="40px"
        width="40px"
        label="Edit">
        <EditIcon sx={{ height: 30, width: 30 }} />
      </IconButton>
      <IconButton
        disabled={disabled === true}
        onClick={() => {
          setDisableSelected(data);
        }}
        height="40px"
        width="40px"
        label={data.isActive ? 'Disable' : 'Activate'}>
        {data.isActive ? (
          <DoDisturbIcon
            sx={{ height: 30, width: 30, color: disabled ? lightGrey : theme.palette.error.main }}
          />
        ) : (
          <CheckCircleOutlineIcon
            sx={{ height: 30, width: 30, color: disabled ? lightGrey : theme.palette.success.main }}
          />
        )}
      </IconButton>
    </div>
  );
};
