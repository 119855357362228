import React from 'react';
import './App.css';
import { CoreContextProvider } from './hooks';
import { ErrorBoundary } from 'react-error-boundary';
import { NetworkError, Page404 } from './page/staticPages';
import { NetworkContextProvider, ProtectiveRoute } from './network';
import { ThemeProvider } from 'styled-components';
import { ThemeProvider as MuiThemeProvider } from '@mui/material';
import { GlobalStyles, styledTheme, muiTheme } from './theme';
import { Routes, Route } from 'react-router-dom';
import {
  ActivityLog,
  DashboardPage,
  DefaultLayout,
  MaterialConfiguration,
  NewProjectPage,
  ProjectPage,
  ReceivingSites,
  Trucks,
  UserProfile,
  Users
} from './page';
import LoginPage from './page/login';

function App() {
  return (
    <ErrorBoundary fallback={NetworkError()}>
      <NetworkContextProvider>
        <CoreContextProvider>
          <ThemeProvider theme={styledTheme}>
            <MuiThemeProvider theme={muiTheme}>
              <GlobalStyles />
              <Routes>
                <Route path="*" element={<Page404 />} />
                <Route
                  path="/"
                  element={
                    <ProtectiveRoute>
                      <DefaultLayout />
                    </ProtectiveRoute>
                  }>
                  <Route path="" element={<DashboardPage />} />
                  <Route path="newProject" element={<NewProjectPage />} />
                  <Route path="trucks" element={<Trucks />} />
                  <Route path="material" element={<MaterialConfiguration />} />
                  <Route path="receivingSites" element={<ReceivingSites />} />
                  <Route path="project/:projectName" element={<ProjectPage />} />
                  <Route path="activityLogs" element={<ActivityLog />} />
                  <Route path="users" element={<Users />} />
                  <Route path="users/profile" element={<UserProfile />} />
                </Route>
                <Route path="/login" element={<LoginPage />} />
              </Routes>
            </MuiThemeProvider>
          </ThemeProvider>
        </CoreContextProvider>
      </NetworkContextProvider>
    </ErrorBoundary>
  );
}

export default App;
