import React, { ReactElement } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useService } from '../hooks';
import { storageKey } from './property';

export const ProtectiveRoute = ({ children }: { children: JSX.Element }): ReactElement => {
  const { service } = useService();
  const storageToken = localStorage.getItem(storageKey);
  const location = useLocation();

  if (!service.getToken()) {
    if (storageToken) {
      service.setToken(storageToken);
    } else {
      return <Navigate to="/login" state={{ from: location }} replace />;
    }
  }

  return children;
  //verify token here
};
