import React, { ReactElement, useEffect, useState } from 'react';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import { Caption, SubTitle, SubTitle2 } from '../atoms/text';
import styled, { useTheme } from 'styled-components';

import {
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent
} from '@mui/material';
import { IoClose } from 'react-icons/io5';
import { Button } from '../atoms/button';
import { StyledTextField } from '../atoms/styledTextField';

import { User, defaultUser, rw } from '../../types';

import { Spinner } from '../atoms/spinner';

import { useService } from '../../hooks';
import { AiFillCheckCircle, AiFillWarning } from 'react-icons/ai';
import { emailRegex, getRole, getRoleValue, userRoles } from '../../util';
import { scriptOP, postResHelper, ReqError, getErrorMessage, reasonCode } from '../../network';
import { ModalLoadingContainer } from '../containers';
import useCoreState from '../../hooks/useCoreState';
import { CoreActionTypes } from '../../context';

type ModalErrors = {
  fname: string;
  lname: string;
  role: string;
  email: string;
  number: string;
};

type ModalState = {
  role: userRoles;
  isActive: boolean;
  entityId: string;
  fName: string;
  lName: string;
  email: string;
  number: string;
  mode: 'new' | 'edit' | 'newProject';
  isLoading: boolean;
};

const defaultErrors: ModalErrors = {
  fname: '',
  lname: '',
  role: '',
  email: '',
  number: ''
};

const initModalState: ModalState = {
  role: userRoles.superAdmin,
  entityId: '',
  fName: '',
  lName: '',
  number: '',
  isActive: true,
  email: '',
  mode: 'new',
  isLoading: false
};

export const UserModal = ({
  open,
  handleClose,
  mode,
  data
}: {
  open: boolean;
  handleClose: (profileId?: string) => void;
  mode: 'new' | 'edit';
  data?: User;
}): ReactElement => {
  const [modalErrors, setModalErrors] = useState<ModalErrors>(defaultErrors);
  const [notificationState, setNotifications] = useState<{
    message: string;
    isSuccess: boolean;
    isOpen: boolean;
  }>({
    message: '',
    isSuccess: true,
    isOpen: false
  });

  const theme = useTheme();
  const [modalState, setModalState] = useState<ModalState>(initModalState);
  const { service } = useService();
  const { coreState, updateCoreState } = useCoreState();
  const hasPermission = coreState.permission.user >= rw;

  useEffect(() => {
    if (notificationState.isOpen) {
      setTimeout(() => {
        setNotifications({ message: '', isSuccess: true, isOpen: false });

        handleClose(modalState.entityId);
      }, 3000);
    }
  }, [notificationState.isOpen]);

  useEffect(() => {
    if (open) {
      setModalErrors({ ...defaultErrors });
    }
    if (mode === 'new') {
      setModalState({
        ...initModalState
      });
    }

    if (!open) {
      setModalState({ ...initModalState });
    }

    return () => {
      setModalState({ ...initModalState });
    };
  }, [open, mode]);

  useEffect(() => {
    if (mode === 'edit') {
      setModalState({ ...initModalState, isLoading: true });

      if (data) {
        setModalState({
          ...initModalState,
          role: getRole(data.role),
          fName: data.firstName,
          lName: data.lastName,
          number: data.phone,
          isActive: data.isActive,
          email: data.emailAddress,
          isLoading: false
        });
      }
    }
    return () => {
      setModalState({ ...initModalState });
    };
  }, [mode, data]);

  const onClose = (): void => {
    handleClose();
  };

  const validate = (): boolean => {
    if (modalState.fName && modalState.lName && modalState.email && modalState.number) {
      return true;
    }

    setModalErrors({
      ...modalErrors,
      fname: modalState.fName ? '' : 'Fisrt name cannot be empty',
      lname: modalState.lName ? '' : 'Last name cannot be empty',
      email: modalState.email ? '' : 'Email cannot be empty',
      number: modalState.number ? '' : 'Phone number cannot be empty'
    });
    return false;
  };

  const handleSubmitCreate = async () => {
    setModalState({ ...modalState, isLoading: true });

    if (!validate()) {
      setModalState({ ...modalState, isLoading: false });
      return;
    }

    try {
      const create = await service.post(scriptOP.createUser, {
        emailAddress: modalState.email,
        role: getRoleValue(modalState.role),
        projects: [],
        phone: modalState.number,
        firstName: modalState.fName,
        lastName: modalState.lName,
        isActive: modalState.isActive
      });

      const profileId = postResHelper(create);

      if (profileId) {
        setModalState({ ...modalState, entityId: profileId, isLoading: false });
        setNotifications({ message: 'New user Created.', isOpen: true, isSuccess: true });
        updateCoreState({
          type: CoreActionTypes.SET_USERS,
          payload: {
            ...coreState,
            users: [
              ...coreState.users,
              {
                ...defaultUser,
                profileId,
                emailAddress: modalState.email,
                role: modalState.role,
                projects: [],
                phone: modalState.number,
                firstName: modalState.fName,
                lastName: modalState.lName,
                isActive: modalState.isActive
              }
            ]
          }
        });
        return;
      }
    } catch (e) {
      setModalState({ ...modalState, isLoading: false });
      if (e && typeof e === 'object') {
        const error = e as ReqError;
        if (error && error.reason_code !== reasonCode.packactIdError) {
          const errorMessage = getErrorMessage(error.reason_code);
          setNotifications({
            message: errorMessage,
            isOpen: true,
            isSuccess: false
          });
        }
      }
    }
  };

  const handleSubmitEdit = async () => {
    setModalState({ ...modalState, isLoading: true });
    if (!validate() || !data) {
      setModalState({ ...modalState, isLoading: false });
      return;
    }
    try {
      const update = await service.post(scriptOP.updateUser, {
        profileId: data.profileId,
        emailAddress: modalState.email,
        role: getRoleValue(modalState.role),
        projects: [],
        phone: modalState.number,
        firstName: modalState.fName,
        lastName: modalState.lName,
        isActive: modalState.isActive
      });
      const profileId = postResHelper(update);
      if (profileId) {
        const usersUpdated = coreState.users.map((u) => {
          if (u.profileId === profileId) {
            return {
              ...u,
              profileId: data.profileId,
              emailAddress: modalState.email,
              role: modalState.role,
              projects: [],
              phone: modalState.number,
              firstName: modalState.fName,
              lastName: modalState.lName,
              isActive: modalState.isActive
            };
          } else return u;
        });
        updateCoreState({
          type: CoreActionTypes.SET_USERS,
          payload: {
            ...coreState,
            users: [...usersUpdated]
          }
        });
        setModalState({ ...modalState, isLoading: false });
        setNotifications({ message: 'User info saved.', isOpen: true, isSuccess: true });
        return;
      }
    } catch (e) {
      if (e && typeof e === 'object') {
        const error = e as ReqError;
        if (error && error.reason_code !== reasonCode.packactIdError) {
          const errorMessage = getErrorMessage(error.reason_code);
          setNotifications({
            message: errorMessage,
            isOpen: true,
            isSuccess: false
          });
        }
      }
    }
  };

  const handleResetPsw = async () => {
    if (!data) {
      return;
    }

    try {
      await service.post(scriptOP.resetPsw, {
        profileId: data.profileId
      });

      setNotifications({
        message: 'Password reset email is sent, please check your inbox.',
        isOpen: true,
        isSuccess: true
      });
    } catch (e) {
      if (e && typeof e === 'object') {
        const error = e as ReqError;
        if (error && error.reason_code !== reasonCode.packactIdError) {
          const errorMessage = getErrorMessage(error.reason_code);
          setNotifications({
            message: errorMessage,
            isOpen: true,
            isSuccess: false
          });
        }
      }
    }
  };

  const handleInputChange = (input: string, type: 'fname' | 'lname' | 'phone' | 'email'): void => {
    switch (type) {
      case 'fname':
        setModalState({ ...modalState, fName: input });
        break;
      case 'lname':
        setModalState({ ...modalState, lName: input });
        break;
      case 'phone':
        setModalState({ ...modalState, number: input });
        break;
      case 'email':
        setModalState({ ...modalState, email: input });
        break;

      default:
        break;
    }
  };
  const handleClearError = (type: 'fname' | 'lname' | 'phone' | 'email'): void => {
    switch (type) {
      case 'fname':
        setModalErrors({ ...modalErrors, fname: '' });
        break;
      case 'lname':
        setModalErrors({ ...modalErrors, lname: '' });
        break;
      case 'phone':
        setModalErrors({ ...modalErrors, number: '' });
        break;
      case 'email':
        setModalErrors({ ...modalErrors, email: '' });
        break;

      default:
        break;
    }
  };

  const handleChangeUserRole = (event: SelectChangeEvent) => {
    const role = event.target.value as userRoles;
    setModalState({ ...modalState, role });
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={onClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{ backdrop: { timeout: 500 } }}>
      <Fade in={open}>
        <Box sx={style}>
          <Header>
            <SubTitle style={{ color: 'white' }}>
              {mode === 'new' ? 'Add ' : 'Edit '} Team Member
            </SubTitle>
            <IconButton onClick={onClose} sx={{ height: 45, width: 45 }}>
              <IoClose style={{ height: 40, width: 40, color: 'white' }} />
            </IconButton>
          </Header>
          <Content>
            <div
              style={{
                width: '100%',
                height: '30%',
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center'
              }}>
              <div
                style={{
                  height: '100%',
                  width: '50%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}>
                <InputGroup>
                  <StyledTextField
                    label="First Name"
                    id="filled-size-small"
                    required
                    error={modalErrors.fname !== ''}
                    helperText={modalErrors.fname}
                    onFocus={() => {
                      handleClearError('fname');
                    }}
                    onBlur={(e) => {
                      if (!e.target.value) {
                        setModalErrors({ ...modalErrors, fname: 'First name cannot be empty' });
                      }
                    }}
                    value={modalState.fName}
                    variant="filled"
                    fullWidth
                    size="small"
                    onChange={(e) => {
                      handleInputChange(e.target.value, 'fname');
                    }}
                  />
                  <StyledTextField
                    label="Last Name"
                    required
                    error={modalErrors.lname !== ''}
                    helperText={modalErrors.lname}
                    onFocus={() => {
                      handleClearError('lname');
                    }}
                    onBlur={(e) => {
                      if (!e.target.value) {
                        setModalErrors({ ...modalErrors, lname: 'Last name cannot be empty' });
                      }
                    }}
                    id="filled-size-small"
                    value={modalState.lName}
                    variant="filled"
                    fullWidth
                    size="small"
                    onChange={(e) => {
                      handleInputChange(e.target.value, 'lname');
                    }}
                  />
                </InputGroup>
              </div>
              <div
                style={{
                  height: '100%',
                  width: '50%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}>
                <InputGroup>
                  <StyledTextField
                    label="Email"
                    id="filled-size-small"
                    required
                    error={modalErrors.email !== ''}
                    helperText={modalErrors.email}
                    onFocus={() => {
                      handleClearError('email');
                    }}
                    onBlur={(e) => {
                      if (!e.target.value) {
                        setModalErrors({ ...modalErrors, email: 'Email cannot be empty.' });
                        return;
                      }
                      if (!emailRegex.test(e.target.value)) {
                        setModalErrors({ ...modalErrors, email: 'Invalid email format.' });
                        return;
                      }
                    }}
                    value={modalState.email}
                    variant="filled"
                    fullWidth
                    size="small"
                    onChange={(e) => {
                      handleInputChange(e.target.value, 'email');
                    }}
                  />
                  <StyledTextField
                    label="Phone Number"
                    required
                    error={modalErrors.number !== ''}
                    helperText={modalErrors.number}
                    onFocus={() => {
                      handleClearError('phone');
                    }}
                    onBlur={(e) => {
                      if (!e.target.value) {
                        setModalErrors({
                          ...modalErrors,
                          number: 'Phone number cannot be empty.'
                        });
                        return;
                      }
                    }}
                    id="filled-size-small"
                    value={modalState.number}
                    variant="filled"
                    fullWidth
                    size="small"
                    onChange={(e) => {
                      handleInputChange(e.target.value, 'phone');
                    }}
                  />
                </InputGroup>
              </div>
            </div>
            <div
              style={{
                width: '100%',
                height: '55%',
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center'
              }}>
              <div
                style={{
                  height: '100%',
                  width: '50%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '0 10px'
                }}>
                <FormControl
                  variant="filled"
                  sx={{
                    m: 1,
                    width: '100%',
                    marginRight: 0,
                    '& .MuiFilledInput-root': {
                      backgroundColor: 'rgba(244, 245, 253, 0.6)',
                      height: '48px'
                    }
                  }}>
                  <InputLabel>Role *</InputLabel>
                  <Select
                    value={modalState.role}
                    onChange={handleChangeUserRole}
                    fullWidth
                    disabled={!hasPermission}>
                    {Object.values(userRoles).map((r) => (
                      <MenuItem value={r} key={r}>
                        {r}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl sx={{ width: '100%' }}>
                  <FormLabel id="user active lable">Account Status</FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="user active group"
                    name="acount status"
                    value={modalState.isActive}
                    sx={{
                      width: '100%',
                      '& .Mui-checked': {
                        color: theme.palette.secondary.main
                      }
                    }}
                    onChange={(e, c) => {
                      if (c === 'false') {
                        setModalState({ ...modalState, isActive: false });
                      } else {
                        setModalState({ ...modalState, isActive: true });
                      }
                    }}>
                    <FormControlLabel
                      disabled={!hasPermission}
                      value={true}
                      control={<Radio />}
                      label="Active"
                    />
                    <FormControlLabel
                      value={false}
                      disabled={!hasPermission}
                      control={<Radio />}
                      label="Disabled"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
              {mode === 'edit' && (
                <div
                  style={{
                    height: '100%',
                    width: '50%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}>
                  <SubTitle2>Reset Password?</SubTitle2>
                  <Caption>
                    A message will be sent to the email listed on this account with instructions on
                    how to reset the password.
                  </Caption>
                  <Button
                    variant="outlined"
                    label="Reset Password"
                    onClick={() => handleResetPsw()}
                    size="large"
                    color="warning"
                  />
                </div>
              )}
            </div>
          </Content>
          <Footer>
            <Caption>*Required information</Caption>
            <div>
              <Button
                variant="outlined"
                label="Cancel"
                onClick={onClose}
                styles={{ marginRight: 20 }}
                size="normal"
                color="secondary"
              />
              <Button
                variant="contained"
                label="Confirm"
                onClick={() => {
                  mode === 'new' ? handleSubmitCreate() : handleSubmitEdit();
                }}
                size="normal"
                color="secondary"
              />
            </div>
          </Footer>
          {modalState.isLoading && (
            <ModalLoadingContainer>
              <Spinner />
            </ModalLoadingContainer>
          )}

          {notificationState.isOpen && (
            <ModalLoadingContainer>
              <FeedbackContainer>
                {notificationState.isSuccess ? (
                  <AiFillCheckCircle size={50} color={theme.palette.success.main} />
                ) : (
                  <AiFillWarning size={50} color={theme.palette.error.main} />
                )}
                <SubTitle>{notificationState.message}</SubTitle>
              </FeedbackContainer>
            </ModalLoadingContainer>
          )}
        </Box>
      </Fade>
    </Modal>
  );
};

const Header = styled.div`
  height: 50px;
  background-color: ${({ theme }) => theme.palette.primary.main};
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  padding: 0 20px 0 20px;
`;

const Content = styled.div`
  position: relative;
  height: calc(100% - 100px);
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  padding: 0 20px;
`;

const Footer = styled.div`
  height: 50px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  padding: 0 20px 0 20px;
`;

const FeedbackContainer = styled.div`
  height: 125px;
  width: 400px;
  padding: 5px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.background.paper};
`;

const InputGroup = styled.div`
  justify-content: space-evenly;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 0 10px;
  flex-direction: column;
  align-items: center;
  & > p {
    margin-top: 5px !important;
  }
`;

const style = {
  // eslint-disable-next-line @typescript-eslint/prefer-as-const
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  minHeight: '400px',
  height: '55%',
  maxWidth: '950px',
  width: '50%',
  borderRadius: '25px'
};
