import styled from 'styled-components';

export const SubTitle2 = styled.p`
  ${({ theme }) =>
    `font-size: ${theme.fontSize.sub2}px; font-weight: ${theme.fontWeight.sub2}; color: ${theme.palette.primary.dark}`}
`;

export const SubTitle = styled.p`
  ${({ theme }) =>
    `font-size: ${theme.fontSize.sub}px; font-weight: ${theme.fontWeight.sub}; color: ${theme.palette.primary.dark}`}
`;

export const Caption = styled.p`
  ${({ theme }) =>
    `font-size: ${theme.fontSize.caption}px; font-weight: ${theme.fontWeight.caption}; color: ${theme.palette.primary.dark}`}
`;

export const Body = styled.p`
  ${({ theme }) =>
    `font-size: ${theme.fontSize.body}px; font-weight: ${theme.fontWeight.body}; color: ${theme.palette.primary.dark}`}
`;

export const Body2 = styled.p`
  ${({ theme }) =>
    `font-size: ${theme.fontSize.body2}px; font-weight: ${theme.fontWeight.body2}; color: ${theme.palette.primary.dark}`}
`;
