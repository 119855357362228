import React, { ReactElement, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { Caption, SubTitle, SubTitle2, Button } from '../component/atoms';
import styled from 'styled-components';
import { StyledTextField } from '../component/atoms';
import { useTheme } from '@mui/material';
import { AiFillCheckCircle, AiFillWarning } from 'react-icons/ai';
import { ContentContainer, ModalLoadingContainer } from '../component/containers';
import { useErrors, useLoading, useService, useUser } from '../hooks';
import { defaultUser } from '../types';

import { ReqError, getErrorMessage, postResHelper, reasonCode, scriptOP } from '../network';
import { emailRegex, getRoleValue } from '../util';

type State = {
  fName: string;
  lName: string;
  email: string;
  number: string;
  submitDisabled: boolean;
};

const initState: State = {
  fName: '',
  lName: '',
  number: '',
  email: '',
  submitDisabled: true
};
type PageErrors = {
  fname: string;
  lname: string;
  role: string;
  email: string;
  number: string;
};

const defaultErrors: PageErrors = {
  fname: '',
  lname: '',
  role: '',
  email: '',
  number: ''
};

export const UserProfile = (): ReactElement => {
  const theme = useTheme();
  const { setLoading } = useLoading();
  const { service } = useService();
  const [pageErrors, setPageErrors] = useState<PageErrors>(defaultErrors);
  const { setError } = useErrors();
  const [notificationState, setNotifications] = useState<{
    message: string;
    isSuccess: boolean;
    isOpen: boolean;
  }>({
    message: '',
    isSuccess: true,
    isOpen: false
  });
  const { user } = useUser();

  useEffect(() => {
    setState({
      ...state,
      fName: user.firstName,
      lName: user.lastName,
      email: user.emailAddress,
      number: user.phone
    });
  }, []);

  useEffect(() => {
    if (notificationState.isOpen) {
      setTimeout(() => {
        setNotifications({ message: '', isSuccess: true, isOpen: false });
      }, 3000);
    }
  }, [notificationState.isOpen]);

  const [state, setState] = useState<State>(initState);

  const handleSubmitEdit = async () => {
    setLoading(true);
    if (Object.values(pageErrors).filter((e) => e !== '').length > 0) {
      setLoading(false);
      return;
    }
    try {
      const update = await service.post(scriptOP.updateUser, {
        profileId: user.profileId,
        emailAddress: state.email,
        role: getRoleValue(user.role),
        projects: [],
        phone: state.number,
        firstName: state.fName,
        lastName: state.lName,
        isActive: user.isActive
      });

      if (postResHelper(update)) {
        setLoading(false);
        setNotifications({ message: 'User info saved.', isOpen: true, isSuccess: true });
        return;
      }
    } catch (e) {
      if (e && typeof e === 'object') {
        const error = e as ReqError;
        if (error && error.reason_code !== reasonCode.packactIdError) {
          const errorMessage = getErrorMessage(error.reason_code);
          setError({ networkError: errorMessage });
        }
      }
    }
  };

  const handleResetPsw = async () => {
    if (user.profileId === defaultUser.profileId) {
      return;
    }

    try {
      await service.post(scriptOP.resetPsw, {
        profileId: user.profileId
      });

      setNotifications({
        message: 'Password reset email is sent, please check your inbox.',
        isOpen: true,
        isSuccess: true
      });
    } catch (e) {
      if (e && typeof e === 'object') {
        const error = e as ReqError;
        if (error && error.reason_code !== reasonCode.packactIdError) {
          const errorMessage = getErrorMessage(error.reason_code);
          setError({ networkError: errorMessage });
        }
      }
    }
  };

  const handleCancle = (): void => {
    setState({
      ...initState,
      fName: user.firstName,
      lName: user.lastName,
      email: user.emailAddress,
      number: user.phone
    });
    setPageErrors({ ...defaultErrors });
  };

  const handleInputChange = (input: string, type: 'fname' | 'lname' | 'phone' | 'email'): void => {
    switch (type) {
      case 'fname':
        setState({ ...state, fName: input, submitDisabled: false });
        break;
      case 'lname':
        setState({ ...state, lName: input, submitDisabled: false });
        break;
      case 'phone':
        setState({ ...state, number: input, submitDisabled: false });
        break;
      case 'email':
        setState({ ...state, email: input, submitDisabled: false });
        break;

      default:
        break;
    }
  };
  const handleClearError = (type: 'fname' | 'lname' | 'phone' | 'email'): void => {
    switch (type) {
      case 'fname':
        setPageErrors({ ...pageErrors, fname: '' });
        break;
      case 'lname':
        setPageErrors({ ...pageErrors, lname: '' });
        break;
      case 'phone':
        setPageErrors({ ...pageErrors, number: '' });
        break;
      case 'email':
        setPageErrors({ ...pageErrors, email: '' });
        break;

      default:
        break;
    }
  };

  return (
    <ContentContainer header title={'My Profile'}>
      <Box
        sx={{
          height: '65%',
          width: '80%',
          backgroundColor: 'white',
          padding: 2,
          borderRadius: '15px',
          boxShadow: theme.shadows[1]
        }}>
        <Content>
          <div
            style={{
              width: '100%',
              height: '45%',
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center'
            }}>
            <div
              style={{
                height: '100%',
                width: '50%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
              <InputGroup>
                <StyledTextField
                  label="First Name"
                  id="filled-size-small"
                  required
                  value={state.fName}
                  error={pageErrors.fname !== ''}
                  helperText={pageErrors.fname}
                  onFocus={() => {
                    handleClearError('fname');
                  }}
                  onBlur={(e) => {
                    if (!e.target.value) {
                      setPageErrors({ ...pageErrors, fname: 'First name cannot be empty' });
                    }
                  }}
                  variant="filled"
                  fullWidth
                  size="small"
                  onChange={(e) => {
                    handleInputChange(e.target.value, 'fname');
                  }}
                />
                <StyledTextField
                  label="Last Name"
                  required
                  error={pageErrors.lname !== ''}
                  helperText={pageErrors.lname}
                  onFocus={() => {
                    handleClearError('lname');
                  }}
                  onBlur={(e) => {
                    if (!e.target.value) {
                      setPageErrors({ ...pageErrors, lname: 'Last name cannot be empty' });
                    }
                  }}
                  id="filled-size-small"
                  value={state.lName}
                  variant="filled"
                  fullWidth
                  size="small"
                  onChange={(e) => {
                    handleInputChange(e.target.value, 'lname');
                  }}
                />
              </InputGroup>
            </div>
            <div
              style={{
                height: '100%',
                width: '50%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
              <InputGroup>
                <StyledTextField
                  label="Email"
                  disabled
                  id="filled-size-small"
                  required
                  value={state.email}
                  error={pageErrors.email !== ''}
                  helperText={pageErrors.email}
                  onFocus={() => {
                    handleClearError('email');
                  }}
                  onBlur={(e) => {
                    if (!e.target.value) {
                      setPageErrors({ ...pageErrors, email: 'Email cannot be empty.' });
                      return;
                    }
                    if (!emailRegex.test(e.target.value)) {
                      setPageErrors({ ...pageErrors, email: 'Invalid email format.' });
                      return;
                    }
                  }}
                  variant="filled"
                  fullWidth
                  size="small"
                  onChange={(e) => {
                    handleInputChange(e.target.value, 'email');
                  }}
                />
                <StyledTextField
                  label="Phone Number"
                  required
                  error={pageErrors.number !== ''}
                  helperText={pageErrors.number}
                  onFocus={() => {
                    handleClearError('phone');
                  }}
                  onBlur={(e) => {
                    if (!e.target.value) {
                      setPageErrors({
                        ...pageErrors,
                        number: 'Phone number cannot be empty.'
                      });
                      return;
                    }
                  }}
                  id="filled-size-small"
                  value={state.number}
                  variant="filled"
                  fullWidth
                  size="small"
                  onChange={(e) => {
                    handleInputChange(e.target.value, 'phone');
                  }}
                />
              </InputGroup>
            </div>
          </div>
          <div
            style={{
              width: '100%',
              height: '55%',
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center'
            }}>
            <div
              style={{
                height: '100%',
                width: '50%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
              <SubTitle2>Reset Password?</SubTitle2>
              <Caption>
                A message will be sent to the email listed on this account with instructions on how
                to reset the password.
              </Caption>
            </div>
            <div
              style={{
                height: '100%',
                width: '50%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'flex-end'
              }}>
              <Button
                variant="outlined"
                label="Reset Password"
                onClick={() => {
                  handleResetPsw();
                }}
                size="large"
                color="warning"
              />
            </div>
          </div>
          <div
            style={{
              width: '100%',
              height: '70px',
              justifyContent: 'flex-end',
              display: 'flex',
              padding: '5px 15px'
            }}>
            <Button
              variant="outlined"
              size="normal"
              label="Cancel"
              color="secondary"
              disabled={state.submitDisabled}
              styles={{ marginRight: '15px' }}
              onClick={() => {
                handleCancle();
              }}
            />
            <Button
              variant="contained"
              size="normal"
              label="Submit"
              color="primary"
              disabled={state.submitDisabled}
              onClick={() => {
                handleSubmitEdit();
              }}
            />
          </div>
        </Content>

        {notificationState.isOpen && (
          <ModalLoadingContainer>
            <FeedbackContainer>
              {notificationState.isSuccess ? (
                <AiFillCheckCircle size={50} color={theme.palette.success.main} />
              ) : (
                <AiFillWarning size={50} color={theme.palette.error.main} />
              )}
              <SubTitle>{notificationState.message}</SubTitle>
            </FeedbackContainer>
          </ModalLoadingContainer>
        )}
      </Box>
    </ContentContainer>
  );
};

const Content = styled.div`
  position: relative;
  height: calc(100% - 100px);
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  padding: 0 20px;
`;

const FeedbackContainer = styled.div`
  height: 125px;
  width: 400px;
  padding: 5px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.background.paper};
`;

const InputGroup = styled.div`
  justify-content: space-evenly;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 0 10px;
  flex-direction: column;
  align-items: center;
  & > p {
    margin-top: 5px !important;
  }
`;
