import React, { useContext } from 'react';
import { CoreActionTypes } from '../context';
import { CoreContext } from '../context/coreContext';
import { User } from '../types';
import { getPermissons, getRoleValue } from '../util';

export const useUser = (): { user: User; setUser: (user: User) => void } => {
  const { coreState, updateCoreState } = useContext(CoreContext);

  const setUser = (user: User): void => {
    updateCoreState({
      type: CoreActionTypes.SET_USER,
      payload: { ...coreState, appUser: user, permission: getPermissons(getRoleValue(user.role)) }
    });
  };
  return { user: coreState.appUser, setUser };
};
