/* eslint-disable prettier/prettier */
import React, { ReactElement, useEffect, useState } from 'react';
import {
  ContentContainer,
  defaultNotificationState,
  NotificationPopup
} from '../component/containers';


import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import Box from '@mui/material/Box/Box';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Button, Caption, ExportBar, MultiSelectItem, StyledTextField, SubTitle } from '../component/atoms';
import {
  Autocomplete,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tab,
  Tabs,
  useTheme
} from '@mui/material';
import {
  defaultAddress,
  defaultContact,
  defaultSite,
  Load,
  LoadType,
  loadTypeFrom,
  Project,
  rw,
  Site,
  Truck
} from '../types';
import styled from 'styled-components';
import { useErrors, useLoading, useService } from '../hooks';

import useCoreState from '../hooks/useCoreState';
import { AiOutlineArrowDown } from 'react-icons/ai';
import {
  CEOP,
  customEntity,
  errorMessage,
  getErrorMessage,
  loadMapper,
  reasonCode,
  requestStr,
  responseDecoder,
  responseErrorHelper,
  responseStatus,
  ResType,
  scriptOP
} from '../network';

import { animated, useSpring } from '@react-spring/web';
import { InputGroup } from '../component/modals';
import { NoData } from './staticPages';
import { provinces } from './newProject';
import { CoreActionTypes } from '../context';
import { decontructPackedId, format, getProvince, timezone, getDate, validateEmail} from '../util';
import dayjs, { Dayjs } from 'dayjs';
import { lighterBlue } from '../theme';

const Header = styled.div`
  width: 100%;
  height: 130 px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const FilterContainer = styled.div`
  width: 60%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

type RSitePageState = {
  count: number;
  loadTypes: LoadType[];
  loadDisplays: LoadDisplay[];
  filterableLoads: LoadDisplay[]; 
  projects: Project[];
  trucks: Truck[];
  loads: Load[];
  sites: Site[];
  deliveryDisplay: RSiteDisplay[];
  modalOpen: boolean;
  selectedTab: number;
  selectedSite: Site;
  selectedSiteCopy: Site;
  isInfoOpen: boolean;
  selectedProjects: Project[];
  disableSubmit: boolean;
  isGridLoading: boolean;
};

export type RSiteDisplay = {
  date: string;
  loadTypeZone: string;
  quantity: number;
  projectName: string;
  truckSPL: string;
  pileN: number;
  isDelivered: boolean;
  whoConfirmed: string;
};

const initRSitePageState: RSitePageState = {
  count: 0,
  loadTypes: [],
  projects: [],
  trucks: [],
  loads: [],
  sites: [],
  deliveryDisplay: [],
  modalOpen: false,
  selectedSiteCopy: defaultSite,
  selectedTab: 0,
  isInfoOpen: false,
  selectedProjects: [],
  selectedSite: defaultSite,
  disableSubmit: true,
  isGridLoading: false,
  loadDisplays: [],
  filterableLoads: []
};

type PageErrors = {
  name: string;
  add1: string;
  city: string;
  fName: string;
  lName: string;
  number: string;
  email: string;
};

const defaultErrors: PageErrors = {
  name: '',
  add1: '',
  city: '',
  fName: '',
  lName: '',
  number: '',
  email: ''
};

type LoadDisplay = {
  time: string;
  loadType: string;
  qte: number;
  project: string;
  truck: string;
  pile: number;
  deliveryConf: string;
  entityId: string;
  whoConfirmed: string;
};

const columns = (): GridColDef[] => {
  return [
    { field: 'time', headerName: 'Date/Time', width: 150, hideable: false, sortable: false },
    {
      field: 'loadType',
      headerName: 'Load Type',
      hideable: false,
      width: 350
    },
    {
      field: 'qte',
      headerName: 'Quantity (m³)',
      hideable: false,
      type: 'string',
      width: 100
    },
    {
      field: 'project',
      headerName: 'Project (Job#)',
      hideable: false,
      type: 'string',
      width: 250
    },
    {
      field: 'truck',
      headerName: 'Truck ID',
      hideable: false,
      type: 'string',
      width: 150
    },
    {
      field: 'pile',
      headerName: 'Pile Number',
      hideable: false,
      type: 'string',
      width: 110
    },
    {
      field: 'deliveryConf',
      headerName: 'Delivery Confirmation',
      hideable: false,
      type: 'string',
      width: 155
    },
    {
      field: 'whoConfirmed',
      headerName: 'Confirmed by',
      hideable: false,
      type: 'string',
      width: 150
    },
  ];
};

export const ReceivingSites = (): ReactElement => {
  const { coreState, updateCoreState } = useCoreState();
  const [pageState, setPageState] = useState<RSitePageState>(initRSitePageState);
  const [pageErrors, setPageErrors] = useState<PageErrors>(defaultErrors);
  const [notificationState, setNotifications] = useState<{
    open: boolean;
    success: boolean;
    message: string;
  }>(defaultNotificationState);

  const { setLoading } = useLoading();
  const { setError } = useErrors();
  const { service } = useService();
  const theme = useTheme();
  const hasPermission = coreState.permission.site >= rw;
  const hasSites = pageState.sites.length > 0;

  const [dateFilter, setDateFilter] = useState<Dayjs | null>(null);
  const [dateRangeFilterStart, setDateRangeFilterStart] = useState<Dayjs | null>(null);
  const [dateRangeFilterEnd, setDateRangeFilterEnd] = useState<Dayjs | null>(null);
  const [truckFilter, setTruckFilter] = useState<{
    filter: MultiSelectItem[];
    selectedItem: MultiSelectItem;
  }>({
    filter: [
      {
        name: 'All',
        id: '-1',
        selected: false
      }
    ],
    selectedItem: {
      name: 'All',
      id: '-1',
      selected: false
    }
  });

  const expandSprings = useSpring({
    config: { friction: 20 },
    height: pageState.isInfoOpen ? '550px' : '75px'
  });

  const fetchLoad = async (tabIndex: number) => {
    let loads: Load[] = [];
    const site = pageState.sites[tabIndex];

    try {
      if (pageState.selectedSite.entityId !== defaultSite.entityId) {
        const loadReq = await service.post(scriptOP.listLoad, {
          siteId: site.entityId
        });
        const status = responseStatus(loadReq);
        loads = loadMapper(loadReq, setError);
        if (status.success) {
          if (loads.length > 0) {
            const loadsNotArrived = [...loads.filter((l) => !l.arrival)];
            const loadsArrived = [...loads.filter((l) => l.arrival)];
            loadsArrived.sort((x, y) => {
              if (!x.arrival || !y.arrival) {
                return 0;
              }
              if (dayjs(x.arrival).isAfter(dayjs(y.arrival))) {
                return -1;
              } else {
                return 1;
              }
            });
            const allLoads = [...loadsArrived, ...loadsNotArrived];

            const trucks: MultiSelectItem[]  = [];

            const loadDisplays: LoadDisplay[] = allLoads.map((l) => {
              const project = coreState.projects.find((p) => p.entityId === l.projectId);
              const loadType = coreState.loadTypes.find((lt) => lt.entityId === l.loadTypeId);
              const truck = coreState.trucks.find((t) => t.entityId === l.truckId);

              const truckName = truck ? `${truck.splId} ${truck.truckNumber}` : 'No data';
              
              if (truckName !== 'No data') {
                const alreadyAdded = trucks.findIndex(x => x.name == truckName); 
                  if (alreadyAdded === -1) {
                    trucks.push({
                      name: truckName,
                      id: truckName,
                      selected: true
                  });
                }
              }

              trucks.sort((a, b) => a.name.localeCompare(b.name));

              return {
                time: l.arrival ? dayjs(l.arrival).format(format).toString() : 'No data',
                entityId: l.entityId,
                loadType: loadType
                  ? `${loadType.material_name}, ${loadType.zone_name ?? 'No zone data'}`
                  : 'No data',
                qte: l.qte,
                project: project
                  ? `${project.name} ${
                      project.projectNumber === -1 ? '' : ' (' + project.projectNumber + ')'
                    } `
                  : 'No data',
                truck: truckName,
                pile: l.pileNumber === -1 ? NaN : l.pileNumber,
                deliveryConf: l.delivery_confirmation ?? 'No data',
                whoConfirmed: l.whoConfirmed
              };
            });

            // loadDisplays.sort((x, y) => (dayjs(x.time).isAfter(dayjs(y.time)) ? -1 : 1));

            setDateFilter(null);
            setDateRangeFilterEnd(null);
            setDateRangeFilterStart(null);

            setTruckFilter({
              selectedItem: {
                name: 'All',
                id: '-1',
                selected: false
              },
              filter: [
                {
                  name: 'All',
                  id: '-1',
                  selected: false
                },
                ...trucks.filter((t) => t.selected)
              ]
            });
            
            setPageState({
              ...pageState,
              isGridLoading: false,
              loads,
              loadDisplays,
              filterableLoads: [...loadDisplays],
              selectedTab: tabIndex,
              selectedSite: site,
              selectedSiteCopy: site,
              selectedProjects: coreState.projects.filter(
                (p) => site.projects.find((i) => i === p.entityId) !== undefined
              )
            });
          } else {
            setPageState({
              ...pageState,
              isGridLoading: false,
              loads: [],
              loadDisplays: [],
              filterableLoads: [],
              selectedTab: tabIndex,
              selectedSite: site,
              selectedSiteCopy: site,
              selectedProjects: coreState.projects.filter(
                (p) => site.projects.find((i) => i === p.entityId) !== undefined
              )
            });
          }
        }
      }
    } catch (error) {
      if (error && typeof error === 'object') {
        if (
          'reason_code' in error &&
          error.reason_code &&
          typeof error.reason_code === 'number' &&
          error.reason_code !== reasonCode.packactIdError
        ) {
          setError({ networkError: getErrorMessage(error.reason_code) });
        }
      }
    }
  };

  useEffect(() => {
    if (notificationState.open) {
      setTimeout(() => {
        setNotifications(defaultNotificationState);
      }, 3000);
    }
  }, [notificationState.open]);

  const [arrowSprings, arrowApi] = useSpring(() => ({
    from: { rotate: 0 }
  }));

  const initState = async () => {
    try {
      //fetch
      let receivingSites: Site[] = [];
      let loads: Load[] = [];
      const loadDisplays: LoadDisplay[] = [];
      const siteRes = await service.get(`${customEntity}${CEOP.getEntityPage}Site/`, {
        context: requestStr
      });

      const siteDecoded = responseDecoder<ResType<Site>>(siteRes);
      const siteError = responseErrorHelper(siteRes);

      if (siteError) {
        setLoading(false);
        if (siteError.reason_code === reasonCode.sessionTimeout) {
          setError({ networkError: errorMessage.sessionTimeout });
          return;
        }

        if (siteError.reason_code === reasonCode.packactIdError) {
          const id = decontructPackedId(siteError);
          if (id !== 0) {
            service.setPacketID(id);
            initState();
            return;
          }
        }
        setError({ networkError: errorMessage.somethingWrong });
        return;
      }

      const loadTypeRes = await service.get(`${customEntity}${CEOP.getEntityPage}LoadType/`, {
        context: requestStr
      });

      const loadTypeDecoded = responseDecoder<ResType<LoadType>>(loadTypeRes);
      const loadTypeError = responseErrorHelper(loadTypeRes);

      if (loadTypeError) {
        if (loadTypeError.reason_code === reasonCode.sessionTimeout) {
          setError({ networkError: errorMessage.sessionTimeout });
          return;
        }

        setError({ networkError: loadTypeError.status_message });
      }

      const allLoadTypes = loadTypeDecoded.items.map(loadTypeFrom);
    

      updateCoreState({
        type: CoreActionTypes.SET_RECEIVING_SITES,
        payload: {
          ...coreState,
          sites: siteDecoded.items.map((s): Site => {
            return {
              name: s.data.name,
              isReceiving: s.data.isReceiving,
              projects: s.data.projects ? [...s.data.projects] : [],
              entityId: s.entityId,
              version: s.version,
              address: s.data.address ? { ...s.data.address } : defaultAddress,
              contact: s.data.contact ? { ...s.data.contact } : defaultContact,
              summary: []
            };
          }),
          loadTypes: allLoadTypes
        }
      });
      receivingSites = [
        ...siteDecoded.items
          .filter((s) => s.data.isReceiving)
          .map((s): Site => {
            return {
              name: s.data.name,
              isReceiving: s.data.isReceiving,
              projects: s.data.projects ? [...s.data.projects] : [],
              entityId: s.entityId,
              version: s.version,
              address: s.data.address
                ? { ...s.data.address, prov: getProvince(s.data.address.prov) }
                : defaultAddress,
              contact: s.data.contact ? { ...s.data.contact } : defaultContact,
              summary: []
            };
          })
      ];

      if (receivingSites.length > 0) {
        const loadReq = await service.post(scriptOP.listLoad, {
          siteId: receivingSites[0].entityId
        });

        loads = loadMapper(loadReq, setError);
        if (loads.length > 0) {
          const loadsNotArrived = [...loads.filter((l) => !l.arrival)];
          const loadsArrived = [...loads.filter((l) => l.arrival)];
          loadsArrived.sort((x, y) => {
            if (!x.arrival || !y.arrival) {
              return 0;
            }
            if (dayjs(x.arrival).isAfter(dayjs(y.arrival))) {
              return -1;
            } else {
              return 1;
            }
          });
          const allLoads = [...loadsArrived, ...loadsNotArrived];
          const trucks: MultiSelectItem[]  = [];

          allLoads.forEach((l) => {
            const project = coreState.projects.find((p) => p.entityId === l.projectId);
            const loadType = allLoadTypes.find((lt) => lt.entityId === l.loadTypeId);
            const truck = coreState.trucks.find((t) => t.entityId === l.truckId);

            const truckName = truck ? `${truck.splId} ${truck.truckNumber}` : 'No data';
              
            if (truckName !== 'No data') {
              const alreadyAdded = trucks.findIndex((t) => t.name == truckName); 
                if (alreadyAdded === -1 ) {
                  trucks.push({
                    name: truckName,
                    id: truckName,
                    selected: true
                });
              }
            }

            trucks.sort((a, b) => a.name.localeCompare(b.name));

            loadDisplays.push({
              time: l.arrival
                ? dayjs(l.arrival).tz(timezone, true).format(format).toString()
                : 'No data',
              entityId: l.entityId,
              loadType: loadType ? `${loadType.material_name}, ${loadType.zone_name}` : 'No data',
              qte: l.qte,
              project: project ? project.name : 'No data',
              truck: truck ? `${truck.splId} ${truck.truckNumber}` : 'No data',
              pile: 0,
              deliveryConf: l.delivery_confirmation,
              whoConfirmed: l.whoConfirmed
            });
          });

          setTruckFilter({
            selectedItem: {
              name: 'All',
              id: '-1',
              selected: false
            },
            filter: [
              {
                name: 'All',
                id: '-1',
                selected: false
              },
              ...trucks.filter((t) => t.selected)
            ]
          });
        }
      }

      setDateFilter(null);
      setDateRangeFilterEnd(null);
      setDateRangeFilterStart(null);
      
      setPageState({
        ...pageState,
        sites: [...receivingSites],
        selectedTab: 0,
        isGridLoading: false,
        loads,
        loadDisplays,
        filterableLoads: [...loadDisplays],
        selectedSiteCopy: receivingSites.length > 0 ? receivingSites[0] : defaultSite,
        selectedSite: receivingSites.length > 0 ? receivingSites[0] : defaultSite,
        selectedProjects:
          receivingSites.length > 0
            ? coreState.projects.filter(
                (p) => receivingSites[0].projects.find((i) => i === p.entityId) !== undefined
              )
            : [],
        modalOpen: false
      });
    } catch (e) {
      if (e && typeof e === 'object' && 'reason_code' in e && 'status_message' in e) {
        if (e.reason_code === reasonCode.packactIdError) return;
        const msg = e.status_message as string;
        setError({ pageError: msg });
      }
    }
  };

  const handleEditSubmit = async () => {
    if (hanldeValidation()) return;
    setLoading(true);

    try {
      const SiteRes = await service.post(scriptOP.updateEntity, {
        dataJson: {
          name: pageState.selectedSite.name,
          isReceiving: true,
          projects: [...pageState.selectedSite.projects],
          contact: {
            fname: pageState.selectedSite.contact.fname,
            lname: pageState.selectedSite.contact.lname,
            phone: pageState.selectedSite.contact.phone,
            email: pageState.selectedSite.contact.email
          },
          address: {
            street1: pageState.selectedSite.address.street1,
            street2: pageState.selectedSite.address.street2,
            city: pageState.selectedSite.address.city,
            prov: pageState.selectedSite.address.prov
          }
        },
        entityType: 'Site',
        acl: { other: 2 },
        entityId: pageState.selectedSite.entityId,
        version: pageState.selectedSite.version
      });
      const siteUpdateError = responseErrorHelper(SiteRes);

      if (siteUpdateError) {
        if (siteUpdateError.reason_code === reasonCode.sessionTimeout) {
          setError({ networkError: errorMessage.sessionTimeout });
        } else if (siteUpdateError.reason_code !== reasonCode.packactIdError) {
          setNotifications({ open: true, message: errorMessage.somethingWrong, success: false });
        }
        setLoading(false);
        return;
      }

      const allSites = [
        ...coreState.sites.map((s) => {
          if (s.entityId === pageState.selectedSite.entityId) return { ...pageState.selectedSite };
          return s;
        }),
        pageState.selectedSite
      ];
      updateCoreState({
        type: CoreActionTypes.SET_RECEIVING_SITES,
        payload: {
          ...coreState,
          sites: allSites
        }
      });

      setPageState({
        ...pageState,
        disableSubmit: true,
        sites: pageState.sites.map((s) => {
          if (s.entityId === pageState.selectedSite.entityId) return { ...pageState.selectedSite };
          return s;
        })
      });
      setLoading(false);
      setNotifications({ open: true, message: 'Receiving site updated.', success: true });
    } catch (error) {
      if (error) {
        setNotifications({ open: true, message: errorMessage.somethingWrong, success: false });
      }
    }
  };

  const handleChangeTab = async (event: React.SyntheticEvent, newValue: number) => {
    if (pageState.disableSubmit === false) {
      setNotifications({
        open: true,
        message: 'You have unsaved changes, please submit or cancel first.',
        success: false
      });
      return;
    }
    if (pageState.sites[newValue]) {
      setPageState({
        ...pageState,
        isGridLoading: true,
        loads: [],
        loadDisplays: [],
        filterableLoads: []
      });

      await fetchLoad(newValue);
    }
  };

  const handleCancelEdit = (): void => {
    setPageState({
      ...pageState,
      selectedSite: { ...pageState.selectedSiteCopy },
      disableSubmit: true,
      selectedProjects: coreState.projects.filter(
        (p) => pageState.selectedSiteCopy.projects.find((i) => i === p.entityId) !== undefined
      )
    });

    setPageErrors(defaultErrors);
  };

  const hanldeValidation = (): boolean => {
    let hasError = false;
    Object.values(pageErrors).forEach((e) => {
      if (e !== '') {
        hasError = true;
      }
    });

    return hasError;
  };

  const handleDatefilterChange = (newValue: dayjs.Dayjs | null): void => {
    console.log("hanldeDatefilterChange " + newValue?.toString());

    setDateFilter(newValue);
    setDateRangeFilterStart(null);
    setDateRangeFilterEnd(null);

    if (dayjs(newValue).format(format).toString() === 'Invalid Date') {
      //date filter is not valid
      if (!newValue) {
          setPageState({
            ...pageState,
            filterableLoads: [...pageState.loadDisplays]
          });
      }
    } else {
      const date = dayjs(newValue).tz(timezone, true).format("YY-MMM-DD").toString();
      let loadsFiltered: LoadDisplay[] = [];

      console.log(date);
      
      loadsFiltered = pageState.loadDisplays.filter((lh) => {
        let found = lh.time.indexOf(date) !== -1;
        console.log(found);
        if(found) {
          return lh;
        }
      });
      
      setPageState({
        ...pageState,
        filterableLoads: loadsFiltered
      });
    }
  }

  const handleChangeTruckFilter = (event: SelectChangeEvent) => {
    const value = event.target.value;

    const truckFound = truckFilter.filter.find((t) => t.id === value);
    if (truckFound) {
      setTruckFilter({ ...truckFilter, selectedItem: truckFound });

      if (truckFound.name === 'All') {
        //clear truck filter
        if (dateFilter !== null) {
          //if there is a date filter

          const isoString = dayjs(dateFilter).toISOString();
          const date = getDate(isoString);
          setPageState({
            ...pageState,
            filterableLoads: [...pageState.loadDisplays.filter((l) => l.time === date)]
          });
          return;
        }
        if (dateRangeFilterEnd !== null && dateRangeFilterStart !== null) {
          //if there is a range filter
          const dateFrom = dayjs(dateRangeFilterStart).tz(timezone, true);
          const dateTo = dayjs(dateRangeFilterEnd).tz(timezone, true);

          setPageState({
            ...pageState,
            filterableLoads: [
              ...pageState.loadDisplays.filter((h) => {
                if (h.time !== 'No data') {
                  let time: dayjs.Dayjs = dayjs(h.time,"YY-MMM-DD HH:mm:ss").tz(timezone, true);
                  if (time.isBetween(dateFrom, dateTo, 'day', '[]')) {
                    return h;
                  }
                }
              })
            ]
          });
          return;
        }
        setPageState({
          ...pageState,
          filterableLoads: [...pageState.loadDisplays]
        });
      } else {
        if (dateFilter !== null) {
          const isoString = dayjs(dateFilter).toISOString();
          const date = getDate(isoString);
          const filterableData = [...pageState.loadDisplays.filter((l) => l.time === date)];
          setPageState({
            ...pageState,
            filterableLoads: [...filterableData.filter((l) => l.truck === value)]
          });
          return;
        }
        if (dateRangeFilterEnd !== null && dateRangeFilterStart !== null) {
          //if there is a range filter
          const dateFrom = dayjs(dateRangeFilterStart).tz(timezone, true);
          const dateTo = dayjs(dateRangeFilterEnd).tz(timezone, true);

          const filterableData = [
            ...pageState.loadDisplays.filter((h) => {
              if (h.time !== 'No data') {
                let time: dayjs.Dayjs = dayjs(h.time,"YY-MMM-DD HH:mm:ss").tz(timezone, true);
                if (time.isBetween(dateFrom, dateTo, 'day', '[]')) {
                  return h;
                }
              }
            })
          ];

          setPageState({
            ...pageState,
            filterableLoads: [...filterableData.filter((l) => l.truck === value)]
          });
          return;
        }
        setPageState({
          ...pageState,
          filterableLoads: [...pageState.loadDisplays.filter((l) => l.truck === value)]
        });
      }
    }
  };

  const handleDateRangeFilterApply = (from: dayjs.Dayjs | null, to: dayjs.Dayjs | null) => {
    //setDateFilter(null);
    if (from === null || to === null) {
      return;
      //missing range
    }

    const dateFrom = dayjs(from).tz(timezone, true);
    const dateTo = dayjs(to).tz(timezone, true);


    setDateFilter(null);

    if (truckFilter.selectedItem.id === '-1') {
      //no truck filter
      const filterdHistory = pageState.loadDisplays.filter((h) => {
        

        if(h.time !== 'No data') {
          let time: dayjs.Dayjs = dayjs(h.time,"YY-MMM-DD HH:mm:ss").tz(timezone, true);

          if (time.isBetween(dateFrom, dateTo, 'day', '[]')) {
            return h;
          }
        }

       
      });
      setPageState({ ...pageState, filterableLoads: filterdHistory });
    } else {
      //has truck filter
      const filterdHistory = pageState.loadDisplays.filter((h) => {
        if (h.time !== 'No data') {
          let time: dayjs.Dayjs = dayjs(h.time,"YY-MMM-DD HH:mm:ss").tz(timezone, true);
          if (
            time.isBetween(dateFrom, dateTo, 'day', '[]') &&
            h.truck === truckFilter.selectedItem.id
          ) {
            return h;
          }
        }
      });
      setPageState({ ...pageState, filterableLoads: filterdHistory });
    }
  };

  useEffect(() => {
    setLoading(true);
    initState();
    setLoading(false);
  }, []);

  useEffect(() => {
    if (pageState.isInfoOpen) {
      arrowApi.start({
        from: { rotate: 0 },
        to: { rotate: 180 }
      });
    } else {
      arrowApi.start({
        from: { rotate: 180 },
        to: { rotate: 0 }
      });
    }
  }, [pageState.isInfoOpen]);

  const handleInputChange = (
    type: 'name' | 'add1' | 'add2' | 'fName' | 'lName' | 'city' | 'number' | 'email',
    value: string
  ): void => {
    switch (type) {
      case 'name':
        setPageState({
          ...pageState,
          selectedSite: { ...pageState.selectedSite, name: value },
          disableSubmit: false
        });
        break;
      case 'add1':
        setPageState({
          ...pageState,
          selectedSite: {
            ...pageState.selectedSite,
            address: { ...pageState.selectedSite.address, street1: value }
          },
          disableSubmit: false
        });
        break;
      case 'add2':
        setPageState({
          ...pageState,
          selectedSite: {
            ...pageState.selectedSite,
            address: { ...pageState.selectedSite.address, street2: value }
          },
          disableSubmit: false
        });
        break;
      case 'fName':
        setPageState({
          ...pageState,
          selectedSite: {
            ...pageState.selectedSite,
            contact: { ...pageState.selectedSite.contact, fname: value }
          },
          disableSubmit: false
        });
        break;
      case 'lName':
        setPageState({
          ...pageState,
          selectedSite: {
            ...pageState.selectedSite,
            contact: { ...pageState.selectedSite.contact, lname: value }
          },
          disableSubmit: false
        });
        break;
      case 'city':
        setPageState({
          ...pageState,
          selectedSite: {
            ...pageState.selectedSite,
            address: { ...pageState.selectedSite.address, city: value }
          },
          disableSubmit: false
        });
        break;
      case 'number':
        setPageState({
          ...pageState,
          selectedSite: {
            ...pageState.selectedSite,
            contact: { ...pageState.selectedSite.contact, phone: value }
          },
          disableSubmit: false
        });
        break;
      case 'email':
        setPageState({
          ...pageState,
          selectedSite: {
            ...pageState.selectedSite,
            contact: { ...pageState.selectedSite.contact, email: value }
          },
          disableSubmit: false
        });
        break;

      default:
        return;
    }
  };

  const hanldeClearError = (
    type: 'name' | 'add1' | 'fName' | 'lName' | 'city' | 'number' | 'email'
  ): void => {
    switch (type) {
      case 'name':
        setPageErrors({ ...pageErrors, name: '' });
        break;
      case 'add1':
        setPageErrors({ ...pageErrors, add1: '' });
        break;
      case 'fName':
        setPageErrors({ ...pageErrors, fName: '' });
        break;
      case 'lName':
        setPageErrors({ ...pageErrors, lName: '' });
        break;
      case 'city':
        setPageErrors({ ...pageErrors, city: '' });
        break;
      case 'number':
        setPageErrors({ ...pageErrors, number: '' });
        break;
      case 'email':
        setPageErrors({ ...pageErrors, email: '' });
        break;
      default:
        return;
    }
  };

  const handleChangeProvince = (event: SelectChangeEvent) => {
    const province = event.target.value;
    setPageState({
      ...pageState,
      selectedSite: {
        ...pageState.selectedSite,
        address: { ...pageState.selectedSite.address, prov: province }
      },
      disableSubmit: false
    });
  };

  return (
    <ContentContainer header title="Receiving Sites">
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          borderBottom: hasSites ? '1px solid grey' : 'none'
        }}>
        {hasSites ? (
          <Tabs
            value={pageState.selectedTab}
            onChange={handleChangeTab}
            sx={{
              margin: 'auto',
              '& .Mui-selected': {
                backgroundColor: theme.palette.background.paper,
                borderTopRightRadius: '5px',
                borderTopLeftRadius: '5px',
                color: theme.palette.secondary.main
              },
              '& .MuiTabs-indicator': { backgroundColor: theme.palette.secondary.main }
            }}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs">
            {pageState.sites.map((s, i) => (
              <Tab label={s.name} key={s.entityId} value={i} />
            ))}
          </Tabs>
        ) : (
          <Box
            sx={{
              height: '100%',
              width: '100%',
              backgroundColor: 'white',
              padding: 2,
              borderRadius: '15px',
              boxShadow: theme.shadows[1],
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
            <SubTitle>No receiving sites found, please create a receiving site.</SubTitle>
          </Box>
        )}
      </Box>

      <AnimatedInformationContainer style={expandSprings}>
        <InformationContainerAction>
          <SubTitle>{hasSites ? 'Site details' : 'No data'}</SubTitle>
          <div>
            <Button
              variant="text"
              label={pageState.isInfoOpen ? 'Hide Details' : 'Show Details'}
              size="normal"
              color="secondary"
              disabled={!hasSites}
              onClick={() => {
                if (hasSites) {
                  setPageState({ ...pageState, isInfoOpen: !pageState.isInfoOpen });
                }
              }}
            />
            <IconButton
              disabled={!hasSites}
              onClick={() => {
                if (hasSites) {
                  setPageState({ ...pageState, isInfoOpen: !pageState.isInfoOpen });
                }
              }}
              sx={{ height: '50px', width: '50px' }}>
              <AnimatedArrowIcon
                style={{
                  ...arrowSprings,
                  height: 25,
                  width: 25,
                  color: theme.palette.background.default
                }}
              />
            </IconButton>
          </div>
        </InformationContainerAction>
        <div
          style={{
            height: 425,
            width: '100%',
            display: pageState.isInfoOpen ? 'flex' : 'none',
            justifyContent: 'space-evenly',
            padding: ` 0 15px`
          }}>
          <InfoColumn>
            <InputGroup>
              <SubTitle style={{ alignSelf: 'flex-start', margin: 0 }}>Name*</SubTitle>
              <StyledTextField
                label="Receiving Site Name"
                id="filled-size-small"
                required
                disabled={!hasPermission}
                error={pageErrors.name !== ''}
                helperText={pageErrors.name}
                onBlur={(e) => {
                  if (!e.target.value) {
                    setPageErrors({ ...pageErrors, name: 'This field is required.' });
                  }

                  if (
                    coreState.sites
                      .filter((s) => s.entityId !== pageState.selectedSite.entityId)
                      .find((s) => s.name.toLowerCase() === e.target.value.toLowerCase()) !==
                    undefined
                  ) {
                    setPageErrors({ ...pageErrors, name: 'Site name already exists.' });
                  }
                }}
                value={pageState.selectedSite.name}
                variant="filled"
                fullWidth
                size="small"
                onFocus={() => {
                  hanldeClearError('name');
                }}
                onChange={(e) => {
                  handleInputChange('name', e.target.value);
                }}
              />
            </InputGroup>
            <InputGroup
              style={{
                width: '100%',
                height: '80%',
                justifyContent: 'space-evenly',
                display: 'flex'
              }}>
              <SubTitle style={{ alignSelf: 'flex-start', margin: 0 }}>
                Location Information*
              </SubTitle>
              <StyledTextField
                label="Address line 1"
                id="filled-size-small"
                disabled={!hasPermission}
                required
                error={pageErrors.add1 !== ''}
                helperText={pageErrors.add1}
                onBlur={(e) => {
                  if (!e.target.value) {
                    setPageErrors({ ...pageErrors, add1: 'This field is required.' });
                  }
                }}
                value={pageState.selectedSite.address.street1}
                variant="filled"
                fullWidth
                size="small"
                onFocus={() => {
                  hanldeClearError('add1');
                }}
                onChange={(e) => {
                  handleInputChange('add1', e.target.value);
                }}
              />
              <StyledTextField
                label="Address line 2"
                id="filled-size-small"
                disabled={!hasPermission}
                value={pageState.selectedSite.address.street2}
                variant="filled"
                fullWidth
                size="small"
                onChange={(e) => {
                  handleInputChange('add2', e.target.value);
                }}
              />
              <div
                style={{
                  height: '48px',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}>
                <StyledTextField
                  label="City"
                  id="filled-size-small"
                  error={pageErrors.city !== ''}
                  disabled={!hasPermission}
                  helperText={pageErrors.city}
                  required
                  onBlur={(e) => {
                    if (!e.target.value) {
                      setPageErrors({ ...pageErrors, city: 'This field is required.' });
                    }
                  }}
                  value={pageState.selectedSite.address.city}
                  variant="filled"
                  size="small"
                  onFocus={() => {
                    hanldeClearError('city');
                  }}
                  onChange={(e) => {
                    handleInputChange('city', e.target.value);
                  }}
                />
                <FormControl
                  variant="filled"
                  sx={{
                    m: 1,
                    width: '50%',
                    marginRight: 0,
                    '& .MuiFilledInput-root': {
                      backgroundColor: 'rgba(244, 245, 253, 0.6)',
                      height: '48px'
                    }
                  }}>
                  <InputLabel>Province</InputLabel>
                  <Select
                    value={pageState.selectedSite.address.prov}
                    disabled={!hasPermission}
                    onChange={handleChangeProvince}>
                    {provinces.map((p) => (
                      <MenuItem value={p} key={p}>
                        {p}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </InputGroup>
          </InfoColumn>
          <InfoColumn>
            <InputGroup
              style={{
                width: '100%',
                height: '100%',
                justifyContent: 'flex-start',
                display: 'flex'
              }}>
              <SubTitle style={{ alignSelf: 'flex-start', margin: 0 }}>
                Contact Information*
              </SubTitle>
              <div
                style={{
                  height: '60%',
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-evenly',
                  alignItems: 'center'
                }}>
                <StyledTextField
                  disabled={!hasPermission}
                  label="First Name"
                  id="filled-size-small"
                  error={pageErrors.fName !== ''}
                  helperText={pageErrors.fName}
                  required
                  value={pageState.selectedSite.contact.fname}
                  variant="filled"
                  fullWidth
                  size="small"
                  onFocus={() => {
                    hanldeClearError('fName');
                  }}
                  onChange={(e) => {
                    handleInputChange('fName', e.target.value);
                  }}
                />
                <StyledTextField
                  label="Last Name"
                  disabled={!hasPermission}
                  id="filled-size-small"
                  value={pageState.selectedSite.contact.lname}
                  error={pageErrors.lName !== ''}
                  helperText={pageErrors.lName}
                  variant="filled"
                  required
                  fullWidth
                  size="small"
                  onFocus={() => {
                    hanldeClearError('lName');
                  }}
                  onChange={(e) => {
                    handleInputChange('lName', e.target.value);
                  }}
                />
                <StyledTextField
                  label="Phone Number"
                  id="filled-size-small"
                  disabled={!hasPermission}
                  required
                  helperText={pageErrors.number}
                  error={pageErrors.number !== ''}
                  onBlur={(e) => {
                    if (!e.target.value) {
                      setPageErrors({ ...pageErrors, name: 'This field is required.' });
                    }
                  }}
                  value={pageState.selectedSite.contact.phone}
                  variant="filled"
                  fullWidth
                  size="small"
                  onFocus={() => {
                    hanldeClearError('number');
                  }}
                  onChange={(e) => {
                    handleInputChange('number', e.target.value);
                  }}
                />
                <StyledTextField
                  label="Email Address"
                  id="filled-size-small"
                  disabled={!hasPermission}
                  error={!!pageErrors.email}
                  helperText={pageErrors.email}
                  // sx={{ marginTop: theme.spacing(1) }}
                  value={pageState.selectedSite.contact.email ?? ''}
                  variant="filled"
                  fullWidth
                  size="small"
                  type="email"
                  onFocus={() => {
                    hanldeClearError('email');
                  }}
                  onChange={(e) => {
                    handleInputChange('email',e.target.value,);
                  }}
                  onBlur={(e) => {
                    if ((!!e.target.value || e.target.value != '') && !validateEmail(e.target.value)) {
                      setPageErrors({ ...pageErrors, email: 'Invalid email address' });
                    } else {
                      hanldeClearError('email');
                    }
                  }}
                  // required
                />
              </div>
            </InputGroup>
          </InfoColumn>
          <InfoColumn style={{ justifyContent: 'flex-start' }}>
            <SubTitle style={{ marginTop: 25, marginBottom: 10, alignSelf: 'flex-start' }}>
              Projects
            </SubTitle>
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center'
              }}>
              <Button
                variant="text"
                color="secondary"
                size="normal"
                disabled={!hasPermission}
                label={
                  pageState.selectedProjects.length ===
                  coreState.projects.filter((p) => p.isActive).length
                    ? 'Deselect All'
                    : 'Select All'
                }
                onClick={() => {
                  if (
                    pageState.selectedProjects.length ===
                    coreState.projects.filter((p) => p.isActive).length
                  ) {
                    setPageState({ ...pageState, selectedProjects: [], disableSubmit: false });
                  } else {
                    setPageState({
                      ...pageState,
                      selectedProjects: [...coreState.projects.filter((p) => p.isActive)],
                      disableSubmit: false
                    });
                  }
                }}
              />
            </div>
            <Autocomplete
              multiple
              disabled={!hasPermission}
              sx={{
                width: '90%',
                height: '100%',
                overflow: 'auto',
                '& .MuiFormControl-root': { height: '100%' },
                '& .MuiInputBase-root': {
                  backgroundColor: 'rgba(244, 245, 253, 0.6)',
                  minHeight: '50%'
                }
              }}
              options={coreState.projects}
              getOptionLabel={(option) => option.name}
              value={pageState.selectedProjects}
              onChange={(e, v) => {
                setPageState({
                  ...pageState,
                  selectedProjects: [...v],
                  selectedSite: { ...pageState.selectedSite, projects: v.map((i) => i.entityId) },
                  disableSubmit: false
                });
              }}
              renderInput={(params) => (
                <StyledTextField {...params} label="Select project(s)" variant="filled" />
              )}
            />
          </InfoColumn>
        </div>
        <div
          style={{
            width: '100%',
            height: '100px',
            display: pageState.isInfoOpen ? 'flex' : 'none',
            justifyContent: 'flex-end',
            padding: '5px 15px'
          }}>
          <Button
            variant="outlined"
            size="normal"
            label="Cancel"
            color="secondary"
            styles={{ marginRight: '15px' }}
            disabled={pageState.disableSubmit}
            onClick={() => {
              handleCancelEdit();
            }}
          />
          <Button
            variant="contained"
            size="normal"
            label="Submit"
            color="primary"
            disabled={pageState.disableSubmit}
            onClick={() => {
              handleEditSubmit();
            }}
          />
        </div>
      </AnimatedInformationContainer>

      <Box
        sx={{
          height: '100%',
          width: '100%',
          minHeight: '900px',
          backgroundColor: 'white',
          padding: 2,
          borderRadius: '15px',
          marginTop: '15px',
          boxShadow: theme.shadows[1]
        }}>
        <SubTitle>Delivery Log</SubTitle>
        <Header style={{ marginBottom: '16px' }}>
          <div>
            <SubTitle style={{ alignSelf: 'flex-start', margin: 0 }}>Filter By Truck</SubTitle>
            <FormControl
              variant="filled"
              sx={{
                minWidth: 210,
                '& .MuiFilledInput-root': {
                  backgroundColor: 'rgba(244, 245, 253, 0.6)',
                  height: '56px'
                }
              }}>
              <InputLabel>Truck ID</InputLabel>
              <Select value={truckFilter.selectedItem.id} onChange={handleChangeTruckFilter}>
                {truckFilter.filter.map((t) => (
                  <MenuItem value={t.id} key={t.id}>
                    {t.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div>
            <SubTitle style={{ alignSelf: 'flex-start', margin: 0 }}>Choose date</SubTitle>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <div id="start-date-picker" style={{ alignSelf: 'flex-start' }}>
                { <DatePicker
                  disabled={pageState.loadDisplays.length === 0}
                  label="Date Filter"
                  value={dateFilter}
                  disableFuture
                  onChange={(newValue) => {
                    handleDatefilterChange(newValue);
                  }}
                  renderInput={(params) => (
                    <StyledTextField {...params} variant="filled" size="small" />
                  )}
                /> }
                <Button
                  variant="text"
                  color="secondary"
                  size="small"
                  label="Clear"
                  onClick={() => {
                    handleDatefilterChange(null);
                  }}
                />
              </div>
            </LocalizationProvider>
          </div>
          <div>
            <SubTitle style={{ alignSelf: 'flex-start', margin: 0 }}>
              Or Filter View by Date Range
            </SubTitle>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <div id="start-date-picker" style={{ alignSelf: 'flex-start' }}>
                <DatePicker
                  //disabled={pageState.allLoadHistories.length === 0}
                  label="From"
                  maxDate={dateRangeFilterEnd}
                  value={dateRangeFilterStart}
                  disableFuture
                  onChange={(newValue) => {
                    if (newValue?.isValid()) {
                      setDateRangeFilterStart(newValue);
                      handleDateRangeFilterApply(newValue, dateRangeFilterEnd);
                    }
                  }}
                  renderInput={(params) => (
                    <StyledTextField {...params} variant="filled" size="small" />
                  )}
                />
              </div>
            </LocalizationProvider>
          </div>
          <div>
            <SubTitle style={{ alignSelf: 'flex-start', visibility: 'hidden', margin: 0 }}>
              Or Filter View by Date Range
            </SubTitle>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <div id="start-date-picker" style={{ alignSelf: 'flex-start' }}>
                <DatePicker
                  disabled={pageState.filterableLoads.length === 0}
                  label="To"
                  minDate={dateRangeFilterStart}
                  value={dateRangeFilterEnd}
                  disableFuture
                  onChange={(newValue) => {
                    if (newValue?.isValid()) {
                      setDateRangeFilterEnd(newValue);
                      handleDateRangeFilterApply(dateRangeFilterStart, newValue);
                    }
                  }}
                  renderInput={(params) => (
                    <StyledTextField {...params} variant="filled" size="small" />
                  )}
                />
                <Button
                  variant="text"
                  color="secondary"
                  size="small"
                  label="Clear"
                  onClick={() => {
                    setDateRangeFilterStart(null);
                    setDateRangeFilterEnd(null);
                    setDateFilter(null);

                    setTruckFilter({
                      ...truckFilter,
                      selectedItem: {
                        name: 'All',
                        id: '-1',
                        selected: false
                      }
                    });
                    setPageState({ ...pageState, filterableLoads: [...pageState.loadDisplays] });
                  }}
                />
              </div>
            </LocalizationProvider>
          </div>
        </Header>
        <Header>
          <FilterContainer>
            <Caption>Hover on the header of each column for sorting and filtering.</Caption>
          </FilterContainer>
        </Header>
        {pageState.filterableLoads.length === 0 ? (
          <NoData />
        ) : (
          <Box
            sx={{
              height: '100%',
              width: '100%',
              minHeight: '790px',
              '& .data-grid-row-blue': {
                bgcolor: `${lighterBlue}`,
                '&: hover': {
                  '&: .MuiDataGrid-cell': { bgcolor: `rgba(22,153,213,0.3)` },
                  bgcolor: `rgba(22,153,213,0.3)`
                }
              }
            }}>

            <DataGrid
              className={`#export-delivery-log-site-${pageState.selectedSite.name}`} //how we get the file name for export donot delete or change format
              getRowClassName={(p) => {
                if (p.indexRelativeToCurrentPage % 2 === 1) {
                  return `data-grid-row-blue`;
                } else {
                  return `data-grid-row-white`;
                }
              }}
              loading={pageState.isGridLoading}
              columns={columns()}
              rows={pageState.filterableLoads}
              autoHeight
              pageSize={10}
              rowsPerPageOptions={[10]}
              checkboxSelection={false}
              disableSelectionOnClick
              experimentalFeatures={{ newEditingApi: true }}
              components={{ Toolbar: ExportBar }}
              getRowId={(row) => row.entityId}
            />
          </Box>
        )}
      </Box>
      <NotificationPopup
        open={notificationState.open}
        isSuccess={notificationState.success}
        message={notificationState.message}
      />
    </ContentContainer>
  );
};

const InformationContainerAction = styled.div`
  height: 75px;
  width: 100%;
  border-radius: 15px;
  background-color: ${({ theme }) => theme.palette.background.paper};
  display: flex;
  justify-content: space-between;
  padding: 0 15px;
  align-items: center;
  margin-top: 15px;
`;

const InfoColumn = styled.div`
  width: 30%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const InformationContainer = styled.div`
  height: 75px;
  width: 100%;
  border-radius: 15px;
  background-color: ${({ theme }) => theme.palette.background.paper};
`;

const AnimatedInformationContainer = animated(InformationContainer);
const AnimatedArrowIcon = animated(AiOutlineArrowDown);
