import React, { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';

import bg from '../../asset/404.png';
import { Button } from '../../component/atoms/button';
import { SubTitle } from '../../component/atoms/text';

export const Page404 = (): ReactElement => {
  const navigate = useNavigate();
  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
      }}>
      <img src={bg} style={{ width: '40%', height: 'auto' }} />
      <SubTitle>There is no content here.</SubTitle>
      <Button
        variant="contained"
        size="large"
        label="Go Back To Dashboard"
        color="primary"
        onClick={() => {
          navigate('/');
        }}
      />
    </div>
  );
};
