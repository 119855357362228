import React, { ReactElement } from 'react';
import styled, { useTheme } from 'styled-components';
import CircularProgress from '@mui/material/CircularProgress';
import { Caption, SubTitle2 } from './text';
import { m3 } from '../../util';
import TruckIcon from '../../asset/icon/iconTruckXS.svg';
const Container = styled.div`
  height: 165px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
const ProgressContainer = styled.div`
  position: relative;
  height: 100px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

type Props = {
  loadTypeName: string;
  materialName: string;
  current: number;
  total: number;
  type: string;
  loadCount: number;
};

export const CircleProgress = ({
  loadTypeName,
  materialName,
  current,
  total,
  type,
  loadCount
}: Props): ReactElement => {
  const theme = useTheme();

  const getPercentage = (): number => {
    let value = 0;

    if (current > 0 && current >= total) {
      value = 100;
    } else {
      const realValue = Math.round(((current / total) * 0.75 + Number.EPSILON) * 100);
      value = realValue;
    }

    if (total === 0 && current === total) {
      value = 0;
    }

    return value;
  };

  const percentage = getPercentage();

  let charCount = loadCount.toString().length;
  let countFontSize = 25;
  if (charCount > 3) {
    countFontSize = charCount < 6 ? 16 : 14;
  }

  return (
    <Container>
      <ProgressContainer id="progress-container">
        <CircularProgress
          variant="determinate"
          value={100}
          size={100}
          thickness={10}
          sx={{
            color: '#d7d7d7',
            position: 'absolute',
            left: 0,
            transform: 'rotate(90deg) !important'
          }}
        />
        <CircularProgress
          variant="determinate"
          value={percentage}
          size={100}
          thickness={10}
          sx={{
            position: 'absolute',
            left: 0,
            color: theme.palette.secondary.main,
            transform: 'rotate(90deg) !important'
          }}
        />

        <div
          style={{
            position: 'absolute',
            left: 0,
            height: 100,
            width: 100,
            transform: 'rotate(45deg)'
          }}>
          <svg viewBox="22 22 44 44" xmlns="http://www.w3.org/2000/svg">
            <circle
              r="17"
              cx="44"
              cy="44"
              fill="transparent"
              stroke="white"
              strokeWidth="12"
              strokeDasharray={100 * 3.1415926}
              strokeDashoffset={((100 * 3.1415926) / 360) * (360 - 31)}
            />
          </svg>
        </div>

        <div
          id="percentage-container"
          style={{
            width: '50%',
            height: '70%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: '16%',
            zIndex: 2
          }}>
          <Caption
            style={{
              color: '#8c8c8c',
              fontSize: countFontSize,
              margin: 0
            }}>
            {loadCount}
          </Caption>
          <img src={TruckIcon} height={'auto'} width={25} />
        </div>
      </ProgressContainer>
      <SubTitle2 style={{ textTransform: 'capitalize', margin: 0, marginTop: 10 }}>
        {materialName}
      </SubTitle2>
      <SubTitle2 style={{ fontSize: 12, textTransform: 'capitalize', margin: 0, marginTop: 0 }}>
        {loadTypeName}
      </SubTitle2>
      <div
        style={{
          width: '80%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column'
          }}>
          <Caption
            style={{
              color: current > total ? theme.palette.error.main : theme.palette.secondary.main,
              margin: 0,
              textAlign: 'center'
            }}>
            {current}
          </Caption>
          <div
            style={{
              borderWidth: 0.5,
              borderColor: 'grey',
              borderStyle: 'solid',
              height: 1,
              width: '60%'
            }}
          />
          <Caption style={{ margin: 0 }}>{total}</Caption>
        </div>
        <Caption>{type === 'm3' ? m3 : type}</Caption>
      </div>
    </Container>
  );
};
