import React, { useState } from 'react';
import { GenericModal } from './editModal';
import styled from 'styled-components';
import { Button, Spinner, StyledTextField, SubTitle } from '../atoms';
import { Typography } from '@mui/material';
import { useErrors, useService } from '../../hooks';
import { ResGenError, csvFileResponseMapper, responseStatus, scriptOP } from '../../network';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { LocalBackdrop } from '../atoms/LocalBackdrop';

interface SendLoadHistoryModalProps {
  open: boolean;
  handleClose: () => void;
}

export const Content = styled.div`
  position: relative;
  height: calc(100% - 100px);
  width: 100%;
  margin-top: 50px;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
`;

export const Footer = styled.div`
  height: 50px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  padding: 0 20px 0 20px;
`;

const ExportPromptModal = ({ open, handleClose }: SendLoadHistoryModalProps) => {
  const [startDatePrompt, setStartDatePrompt] = useState(
    dayjs(Date.now()).add(-1, 'month').toISOString()
  );
  const [endDatePrompt, setEndDatePrompt] = useState(dayjs(Date.now()).toISOString());
  const [formError, setFormError] = useState<string>();
  const [submitError, setSubmitError] = useState<string>();
  const [loading, setLoading] = useState(false);
  const { service } = useService();
  const { setError } = useErrors();

  const closeModal = () => {
    setFormError(undefined);
    setSubmitError(undefined);
    handleClose();
  };

  const downloadCSV = async () => {
    try {
      const startDate = dayjs(startDatePrompt).startOf('day');
      const endDate = dayjs(endDatePrompt).endOf('day');
      setLoading(true);
      const csvReq = await service.post(scriptOP.exportCSV, { startDate, endDate });
      const status = responseStatus(csvReq);
      if (status.status === 200) {
        const files = csvFileResponseMapper(csvReq, setError);

        if (files)
          files.forEach((file) => {
            const blob = new Blob([file.content], { type: 'text/plain' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = file.name;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(url);
          });
      }
    } catch (e) {
      console.log(`Failed to download export`, e);
      alert('Export is not available');
    } finally {
      setLoading(false);
    }
  };

  return (
    <GenericModal
      open={open}
      title={'Data Export'}
      isLoading={false}
      isSuccess={false}
      isError={false}
      handleClose={handleClose}>
      <>
        <LocalBackdrop open={loading}>
          <Spinner />
        </LocalBackdrop>
        <Content>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <SubTitle style={{ alignSelf: 'flex-start', margin: 0 }}>Start Date</SubTitle>
            <div id="start-date-picker" style={{ alignSelf: 'flex-start' }}>
              <DatePicker
                label="Date*"
                value={dayjs(startDatePrompt).toISOString()}
                onChange={(newValue) => {
                  if (dayjs(newValue).isValid()) {
                    const isoString = dayjs(newValue).toISOString();
                    setStartDatePrompt(isoString);
                  }
                }}
                renderInput={(params) => (
                  <StyledTextField {...params} variant="filled" size="small" />
                )}
              />
            </div>
            <SubTitle style={{ alignSelf: 'flex-start', margin: 0 }}>End Date</SubTitle>
            <div id="start-date-picker" style={{ alignSelf: 'flex-start' }}>
              <DatePicker
                label="Date*"
                value={dayjs(endDatePrompt).toISOString()}
                onChange={(newValue) => {
                  if (dayjs(newValue).isValid()) {
                    const isoString = dayjs(newValue).toISOString();
                    setEndDatePrompt(isoString);
                  }
                }}
                renderInput={(params) => (
                  <StyledTextField {...params} variant="filled" size="small" />
                )}
              />
            </div>
          </LocalizationProvider>

          <Typography variant="caption" color={'red'}>
            {formError}
          </Typography>
          <Typography variant="caption" color={'red'}>
            {submitError}
          </Typography>
        </Content>
        <Footer>
          <Button
            variant="outlined"
            label="Cancel"
            onClick={closeModal}
            styles={{ marginRight: 20 }}
            size="normal"
            color="error"
          />
          <Button
            variant="contained"
            label={'Submit'}
            disabled={loading}
            onClick={() => {
              if (dayjs(startDatePrompt).isBefore(dayjs(endDatePrompt))) {
                console.log(
                  `Getting CVS export for date range ${startDatePrompt} - ${endDatePrompt}`
                );
                try {
                  downloadCSV()
                    .then(() => closeModal())
                    .catch((e: ResGenError) => {
                      console.log(e);
                      setSubmitError((e as ResGenError).response);
                    });
                } catch (e: unknown) {
                  setSubmitError((e as ResGenError).response);
                }
                setFormError(undefined);
              } else {
                setFormError('Invalid date range, end date must be later than start date');
              }
            }}
            size="normal"
            color="primary"
          />
        </Footer>
      </>
    </GenericModal>
  );
};

export default ExportPromptModal;
