import React, { ReactElement, useEffect, useState } from 'react';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import { Caption, SubTitle } from '../atoms/text';
import styled, { useTheme } from 'styled-components';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup
} from '@mui/material';
import { IoClose } from 'react-icons/io5';
import { Button } from '../atoms/button';
import { StyledTextField } from '../atoms/styledTextField';
import { scriptOP, postResHelper, responseErrorHelper } from '../../network';
import { Spinner } from '../atoms/spinner';
import useCoreState from '../../hooks/useCoreState';
import { CoreActionTypes } from '../../context';
import { useService } from '../../hooks';
import { AiFillCheckCircle, AiFillWarning } from 'react-icons/ai';
import { defaultMaterial, DeliveryConfirmation, Material } from '../../types';
import { InputGroup } from './addLoadTypeModal';
import { materialDelivModalHeader } from '../../page';
import { ModalLoadingContainer } from '../containers';

const style = {
  // eslint-disable-next-line @typescript-eslint/prefer-as-const
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  minHeight: '450px',
  height: '40%',
  maxWidth: '950px',
  width: '50%',
  borderRadius: '25px'
};

const initModalState = {
  data: defaultMaterial,
  isLoading: false,
  error: ''
};

export const MaterialDeliverModal = ({
  open,
  label,
  mode,
  data,
  handleClose
}: {
  open: boolean;
  label: string;
  mode: 'New' | 'Edit';
  data?: Material | DeliveryConfirmation;
  handleClose: (entityId?: string) => void;
}): ReactElement => {
  const { coreState, updateCoreState } = useCoreState();

  const [notificationState, setNotifications] = useState<{
    message: string;
    isSuccess: boolean;
    isOpen: boolean;
  }>({
    message: '',
    isSuccess: true,
    isOpen: false
  });

  const theme = useTheme();
  const [modalState, setModalState] = useState<{
    data: {
      entityId: string;
      version: number;
      name: string;
      description: string;
      isActive: boolean;
    };
    error: string;
    isLoading: boolean;
  }>(initModalState);

  const { service } = useService();

  useEffect(() => {
    if (notificationState.isOpen) {
      setTimeout(() => {
        setNotifications({ message: '', isSuccess: true, isOpen: false });
        handleClose(modalState.data.entityId);
      }, 3000);
    }
  }, [notificationState.isOpen]);

  useEffect(() => {
    if (mode === 'New') {
      setModalState({ ...initModalState });
    }

    if (!open) {
      setModalState({ ...initModalState });
    }

    return () => {
      setModalState({ ...initModalState });
    };
  }, [open, mode]);

  useEffect(() => {
    if (mode === 'Edit') {
      setModalState({ ...modalState, isLoading: true });

      if (data) {
        setModalState({ ...modalState, data });
      }
    }
    return () => {
      setModalState({ ...initModalState });
    };
  }, [mode, data]);

  const onClose = (): void => {
    handleClose();
  };

  const handleSubmitCreate = async (): Promise<void> => {
    if (modalState.error !== '') return;
    setModalState({ ...modalState, isLoading: true });

    if (modalState.data.name === '' || modalState.data.name === undefined) {
      setModalState({ ...modalState, error: 'Name is required' });
      return;
    }

    try {
      const entityType =
        label === materialDelivModalHeader.addMaterial ? 'Material' : 'DeliveryConfirmation';

      const dataRes = await service.post(scriptOP.createEntity, {
        dataJson: {
          name: modalState.data.name,
          description: modalState.data.description,
          isActive: modalState.data.isActive
        },
        entityType,
        acl: { other: 2 }
      });

      const entityId = postResHelper(dataRes);
      const error = responseErrorHelper(dataRes);
      if (error) {
        setNotifications({
          message: 'Error! Something went wrong saving the material.',
          isOpen: true,
          isSuccess: false
        });
        return;
      }

      if (entityId) {
        if (entityType === 'Material') {
          const newMaterial: Material = { ...modalState.data, entityId, version: 1 };
          updateCoreState({
            type: CoreActionTypes.SET_MATERIALS,
            payload: { ...coreState, materials: [...coreState.materials, newMaterial] }
          });
        } else {
          const newDelivConf: DeliveryConfirmation = { ...modalState.data, entityId, version: 1 };
          modalState.data.entityId = entityId;
          updateCoreState({
            type: CoreActionTypes.SET_DELIV_DEF,
            payload: { ...coreState, deliveryConf: [...coreState.deliveryConf, newDelivConf] }
          });
        }

        setModalState({ ...modalState, isLoading: false });
        setNotifications({
          message: 'Success! The data has been updated.',
          isOpen: true,
          isSuccess: true
        });
      }
    } catch (e) {
      setNotifications({
        message: 'Something went wrong, please try again.',
        isOpen: true,
        isSuccess: false
      });
    }
  };

  const handleSubmitEdit = async (): Promise<void> => {
    if (modalState.error !== '') return;
    setModalState({ ...modalState, isLoading: true });
    if (modalState.data.name === '' || modalState.data.name === undefined) {
      setModalState({ ...modalState, error: 'Name is required' });
      return;
    }

    try {
      if (data) {
        const entityType =
          label === materialDelivModalHeader.editMaterial ? 'Material' : 'DeliveryConfirmation';

        const dataRes = await service.post(scriptOP.updateEntity, {
          dataJson: {
            name: modalState.data.name,
            description: modalState.data.description,
            isActive: modalState.data.isActive
          },
          entityType,
          acl: { other: 2 },
          entityId: data.entityId,
          version: data.version
        });

        const entityId = postResHelper(dataRes);
        const error = responseErrorHelper(dataRes);
        if (error) {
          setNotifications({
            message: 'Error! Something went wrong saving the material.',
            isOpen: true,
            isSuccess: false
          });
          return;
        }

        if (entityId) {
          if (entityType === 'Material') {
            const updatedMaterials = [
              ...coreState.materials.map((m) => {
                if (m.entityId === data.entityId) {
                  return modalState.data;
                }
                return m;
              })
            ];

            updateCoreState({
              type: CoreActionTypes.SET_MATERIALS,
              payload: { ...coreState, materials: [...updatedMaterials] }
            });
          } else {
            const updatedDelivConfims = [
              ...coreState.deliveryConf.map((dc) => {
                if (dc.entityId === data.entityId) {
                  return modalState.data;
                }
                return dc;
              })
            ];

            updateCoreState({
              type: CoreActionTypes.SET_DELIV_DEF,
              payload: { ...coreState, deliveryConf: [...updatedDelivConfims] }
            });
          }

          setModalState({ ...modalState, isLoading: false });
          setNotifications({
            message: 'Success! The data has been updated.',
            isOpen: true,
            isSuccess: true
          });
        }
      }
    } catch (e) {
      setNotifications({
        message: 'Something went wrong, please try again.',
        isOpen: true,
        isSuccess: false
      });
    }
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={onClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{ backdrop: { timeout: 500 } }}>
      <Fade in={open}>
        <Box sx={style}>
          <Header>
            <SubTitle style={{ color: 'white' }}>{label}</SubTitle>
            <IconButton onClick={onClose} sx={{ height: 45, width: 45 }}>
              <IoClose style={{ height: 40, width: 40, color: 'white' }} />
            </IconButton>
          </Header>
          <Content>
            <div
              style={{
                height: 150,
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}>
              <InputGroup>
                <SubTitle style={{ alignSelf: 'flex-start', margin: 0 }}>Name *</SubTitle>
                <StyledTextField
                  label="Name"
                  id="filled-size-small"
                  value={modalState.data.name}
                  variant="filled"
                  error={modalState.error !== ''}
                  helperText={modalState.error}
                  fullWidth
                  required
                  size="small"
                  onBlur={(e) => {
                    const name = e.target.value;
                    if (mode === 'New' && label === materialDelivModalHeader.addMaterial) {
                      if (coreState.materials.find((m) => m.name === name)) {
                        setModalState({ ...modalState, error: 'Duplicate material name found' });
                        return;
                      }
                    }
                    if (
                      data &&
                      mode === 'Edit' &&
                      label === materialDelivModalHeader.editMaterial
                    ) {
                      if (
                        coreState.materials
                          .filter((m) => m.entityId !== data.entityId)
                          .find((i) => i.name === modalState.data.name)
                      ) {
                        setModalState({ ...modalState, error: 'Duplicate material name found' });
                        return;
                      }
                    }
                  }}
                  onFocus={() => {
                    setModalState({ ...modalState, error: '' });
                  }}
                  onChange={(e) => {
                    setModalState({
                      ...modalState,
                      data: { ...modalState.data, name: e.target.value }
                    });
                  }}
                />
              </InputGroup>
              <FormControl sx={{ width: '100%' }}>
                <FormLabel id="user active lable">Account Status</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="user active group"
                  name="acount status"
                  value={modalState.data.isActive}
                  sx={{
                    width: '100%',
                    '& .Mui-checked': {
                      color: theme.palette.secondary.main
                    }
                  }}
                  onChange={(e, c) => {
                    if (c === 'false') {
                      setModalState({
                        ...modalState,
                        data: { ...modalState.data, isActive: false }
                      });
                    } else {
                      setModalState({
                        ...modalState,
                        data: { ...modalState.data, isActive: true }
                      });
                    }
                  }}>
                  <FormControlLabel value={true} control={<Radio />} label="Available" />
                  <FormControlLabel value={false} control={<Radio />} label="Hidden" />
                </RadioGroup>
              </FormControl>
            </div>
            <div
              style={{
                height: 250,
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}>
              <InputGroup>
                <SubTitle style={{ alignSelf: 'flex-start', margin: 0 }}>Description</SubTitle>
                <StyledTextField
                  id="filled-size-small"
                  value={modalState.data.description}
                  variant="filled"
                  fullWidth
                  multiline
                  rows={4}
                  size="small"
                  onChange={(e) => {
                    setModalState({
                      ...modalState,
                      data: { ...modalState.data, description: e.target.value }
                    });
                  }}
                />
              </InputGroup>
            </div>
          </Content>
          <Footer>
            <Caption>*Required information</Caption>
            <div>
              <Button
                variant="outlined"
                label="Cancel"
                onClick={onClose}
                styles={{ marginRight: 20 }}
                size="normal"
                color="secondary"
              />
              <Button
                variant="contained"
                label="Confirm"
                onClick={() => {
                  mode === 'New' ? handleSubmitCreate() : handleSubmitEdit();
                }}
                size="normal"
                color="secondary"
              />
            </div>
          </Footer>
          {modalState.isLoading && (
            <ModalLoadingContainer>
              <Spinner />
            </ModalLoadingContainer>
          )}

          {notificationState.isOpen && (
            <ModalLoadingContainer>
              <FeedbackContainer>
                {notificationState.isSuccess ? (
                  <AiFillCheckCircle size={50} color={theme.palette.success.main} />
                ) : (
                  <AiFillWarning size={50} color={theme.palette.error.main} />
                )}
                <SubTitle>{notificationState.message}</SubTitle>
              </FeedbackContainer>
            </ModalLoadingContainer>
          )}
        </Box>
      </Fade>
    </Modal>
  );
};

const Header = styled.div`
  height: 50px;
  background-color: ${({ theme }) => theme.palette.primary.main};
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  padding: 0 20px 0 20px;
`;

const Content = styled.div`
  position: relative;
  height: calc(100% - 100px);
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  padding: 0 20px;
`;

const Footer = styled.div`
  height: 50px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  padding: 0 20px 0 20px;
`;

const FeedbackContainer = styled.div`
  height: 125px;
  width: 400px;
  padding: 5px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.background.paper};
`;
