import React, { useState } from 'react';
import { GenericModal } from './editModal';
import { LoadHistory } from '../../types';
import styled from 'styled-components';
import { Button, StyledTextField } from '../atoms';
import { Typography, useTheme } from '@mui/material';
import { validateEmail } from '../../util';
import { useService } from '../../hooks';
import { ResGenError, scriptOP } from '../../network';

interface SendLoadHistoryModalProps {
  load?: LoadHistory;
  handleClose: () => void;
}

export const Content = styled.div`
  position: relative;
  height: calc(100% - 100px);
  width: 100%;
  margin-top: 50px;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
`;

export const Footer = styled.div`
  height: 50px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  padding: 0 20px 0 20px;
`;

const SendLoadHistoryModal = ({ load, handleClose }: SendLoadHistoryModalProps) => {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState<string>();
  const [submitError, setSubmitError] = useState<string>();
  const theme = useTheme();
  const service = useService();

  const closeModal = () => {
    setEmailError(undefined);
    setSubmitError(undefined);
    handleClose();
  };
  return (
    <GenericModal
      open={!!load}
      title={'Send Load detail'}
      // children={emailModalForm()}
      isLoading={false}
      isSuccess={false}
      isError={false}
      handleClose={handleClose}>
      <>
        <Content>
          <StyledTextField
            label="Truck Id"
            id="filled-size-small"
            sx={{ marginTop: theme.spacing(1) }}
            value={load?.truckId}
            type="text"
            variant="filled"
            fullWidth
            size="small"
          />
          <StyledTextField
            label="Ticket"
            id="filled-size-small"
            sx={{ marginTop: theme.spacing(1) }}
            value={load?.ticketId}
            type="text"
            variant="filled"
            fullWidth
            size="small"
          />
          <StyledTextField
            label="Email Address"
            id="filled-size-small"
            sx={{ marginTop: theme.spacing(1) }}
            value={email}
            type="email"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            // onBlur={(e) => {
            //   setEmailError(validateEmail(e.target.value) ? undefined : 'Invalid email address');
            // }}
            // required
            error={!!emailError}
            helperText={emailError}
            variant="filled"
            fullWidth
            size="small"
          />
          <Typography variant="caption" color={'red'}>
            {submitError}
          </Typography>
        </Content>
        <Footer>
          <Button
            variant="outlined"
            label="Cancel"
            onClick={closeModal}
            styles={{ marginRight: 20 }}
            size="normal"
            color="error"
          />
          <Button
            variant="contained"
            label={'Submit'}
            onClick={() => {
              if (validateEmail(email)) {
                console.log(`Send ${load?.entityId} to ${email}`, load);
                try {
                  service.service
                    .post(scriptOP.sendLoadDetail, { email, load })
                    .then(() => closeModal())
                    .catch((e: ResGenError) => {
                      console.log(e);
                      setSubmitError((e as ResGenError).response);
                    });
                } catch (e: unknown) {
                  setSubmitError((e as ResGenError).response);
                }
                setEmailError(undefined);
              } else {
                setEmailError('Invalid email address');
              }
            }}
            size="normal"
            color="primary"
          />
        </Footer>
      </>
    </GenericModal>
  );
};

export default SendLoadHistoryModal;
