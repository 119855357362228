import { CoreActions, CoreActionTypes } from './CoreContext.action';
import { CoreState } from './CoreContext.interface';

export const coreStateReducers = (state: CoreState, action: CoreActions): CoreState => {
  switch (action.type) {
    case CoreActionTypes.SET_USER:
      return { ...state, appUser: action.payload.appUser, permission: action.payload.permission };
    case CoreActionTypes.SET_LOADING:
      return { ...state, isLoading: action.payload.isLoading };
    case CoreActionTypes.SET_COMPANIES:
      return { ...state, companies: action.payload.companies };
    case CoreActionTypes.SET_TRUCKS:
      return { ...state, trucks: [...action.payload.trucks] };
    case CoreActionTypes.SET_LOADTYPES:
      return { ...state, loadTypes: [...action.payload.loadTypes] };
    case CoreActionTypes.SET_PROJECT:
      return { ...state, projects: action.payload.projects };
    case CoreActionTypes.SET_PROJECT_ADDED:
      return {
        ...state,
        projects: action.payload.projects,
        trucks: action.payload.trucks,
        sites: action.payload.sites,
        users: action.payload.users,
        loadTypes: action.payload.loadTypes
      };
    case CoreActionTypes.SET_INIT_LOAD:
      return {
        ...state,
        trucks: action.payload.trucks,
        companies: action.payload.companies,
        loadTypes: action.payload.loadTypes,
        projects: action.payload.projects,
        sites: action.payload.sites,
        materials: action.payload.materials,
        deliveryConf: action.payload.deliveryConf,
        users: action.payload.users,
        summaries: action.payload.summaries,
        isLoading: false
      };
    case CoreActionTypes.ADD_TRUCK: {
      return {
        ...state,
        trucks: action.payload.trucks,
        companies: action.payload.companies
      };
    }
    case CoreActionTypes.ADD_LOADTYPE: {
      return {
        ...state,
        loadTypes: action.payload.loadTypes,

        isLoading: false
      };
    }
    case CoreActionTypes.SET_MATERIALS: {
      return { ...state, materials: action.payload.materials };
    }
    case CoreActionTypes.SET_DELIV_DEF: {
      return { ...state, deliveryConf: action.payload.deliveryConf };
    }
    case CoreActionTypes.SET_RECEIVING_SITES: {
      return { ...state, sites: action.payload.sites, loadTypes: action.payload.loadTypes };
    }
    case CoreActionTypes.SET_MATERIAL_DELIV: {
      return {
        ...state,
        materials: action.payload.materials,
        deliveryConf: action.payload.deliveryConf
      };
    }
    case CoreActionTypes.INIT_RE_SITE: {
      return { ...state, sites: action.payload.sites, projects: action.payload.projects };
    }
    case CoreActionTypes.SET_USERS: {
      return { ...state, users: action.payload.users };
    }

    default:
      return { ...state };
  }
};
