import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { Button } from './button';

const MenuItem = styled.div<{
  disableClick: boolean;
  dividerTop?: boolean;
  dividerBottom?: boolean;
}>`
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  padding: 5px;
  border-bottom: ${(props) => (props.dividerBottom ? '0.5px solid #d7d7d7' : 'none')};
  border-top: ${(props) => (props.dividerTop ? '0.5px solid #d7d7d7' : 'none')};
  &: hover {
    background-color: #d7d7d7;
    > button {
      background-color: #d7d7d7 !important;
    }
  }
`;

export type Props = {
  label: string;
  onClick: () => void;
  disabled: boolean;
  disableClick: boolean;
  dividerTop?: boolean;
  dividerBottom?: boolean;
};

export const AppBarMenuItem = ({
  label,
  onClick,
  disabled,
  dividerTop,
  dividerBottom,
  disableClick
}: Props): ReactElement => {
  return (
    <MenuItem dividerTop={dividerTop} dividerBottom={dividerBottom} disableClick={disableClick}>
      <Button
        styles={{ cursor: disableClick ? 'unset !important' : 'pointer !important' }}
        variant="text"
        size="large"
        onClick={onClick}
        color="primary"
        label={label}
        disabled={disabled}
      />
    </MenuItem>
  );
};
