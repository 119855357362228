import React, { ReactElement, useState, useEffect } from 'react';
import axios, { AxiosInstance } from 'axios';
import { APP_ID, BASE_URL, bearere, storageKey } from './property';
import NetworkService from './service';
const storageToken = localStorage.getItem(storageKey);
export class AxiosIns {
  private static instace: AxiosInstance;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {}

  public static getInstace(): AxiosInstance {
    if (!AxiosIns.instace) {
      AxiosIns.instace = axios.create({
        baseURL: BASE_URL,
        timeout: 1000,
        headers: {
          'X-APPID': APP_ID,
          'content-type': 'application/json',
          'X-PACKETID': 1,
          Authorization: bearere + storageToken
        }
      });
    }

    AxiosIns.instace.interceptors.response.use(
      (res) => {
        return res;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    return AxiosIns.instace;
  }
}

export type AppError = {
  loginError: string;
  networkError: string;
  pageError: string;
};

type NetworkContextType = {
  service: NetworkService;
  token: string;
  error: AppError;
};

export type NetworkContextProps = {
  networkState: NetworkContextType;
  updateToken: (token: string) => void;
  setError: (e: AppError) => void;
};

const NetworkContext = React.createContext<NetworkContextProps>({} as NetworkContextProps);
export const defaultError: AppError = { loginError: '', networkError: '', pageError: '' };

const NetworkContextProvider = (props: { children: JSX.Element | JSX.Element[] }): ReactElement => {
  const [state, setState] = useState<NetworkContextType>({
    service: new NetworkService(),
    error: { ...defaultError },
    token: ''
  });

  const updateToken = (token: string): void => {
    setState({ ...state, token });
    state.service.setToken(token);
    localStorage.setItem(storageKey, token);
  };

  const setError = (e: AppError): void => {
    setState({ ...state, error: e });
  };

  return (
    <NetworkContext.Provider
      value={{
        networkState: state,
        updateToken,
        setError
      }}>
      {props.children}
    </NetworkContext.Provider>
  );
};

export { NetworkContextProvider, NetworkContext };
