import React, { ReactElement } from 'react';
import { Caption } from './text';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
export const CellStatus = ({
  isActive,
  label,
  style
}: {
  isActive: boolean;
  label?: string;
  style?: React.CSSProperties;
}): ReactElement => {
  return (
    <div
      style={{
        height: 25,
        width: 75,
        borderRadius: 5,
        backgroundColor: isActive ? 'rgba(167, 237, 167, 1)' : 'rgba(255, 206, 212, 1)',
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        paddingLeft: 10,
        alignItems: 'center',
        position: 'relative',
        ...style
      }}>
      {isActive ? (
        <CheckIcon
          style={{
            height: 15,
            width: 15,
            color: '#366D36',
            position: 'absolute',
            left: 5,
            bottom: 5,
            top: 5
          }}
        />
      ) : (
        <CloseIcon
          style={{
            height: 15,
            width: 15,
            color: '#BD1414',
            position: 'absolute',
            left: 5,
            bottom: 5,
            top: 5
          }}
        />
      )}
      <Caption
        style={{
          margin: 0,

          color: isActive ? '#366D36' : '#BD1414'
        }}>
        {label ? label : isActive ? 'Active' : 'Disabled'}
      </Caption>
    </div>
  );
};
