import { Tooltip, IconButton, Avatar } from '@mui/material';
import React, { ReactElement, useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { useToken, useUser } from '../../hooks';
import UserIcon from '../../asset/icon/user.png';
import { AppBarMenu, itemData } from '.';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { defaultUser, Project } from '../../types';
import useCoreState from '../../hooks/useCoreState';

const Container = styled.div`
  box-sizing: border-box;
  width: 100vw;
  height: 60px;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  color: ${({ theme }) => theme.palette.primary.contrastText};
  z-index: 1100;
  background-color: ${({ theme }) => theme.palette.primary.main};
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%),
    0px 1px 10px 0px rgb(0 0 0 / 12%);
  align-items: center;
  justify-content: space-between;
`;

const SubContainer = styled.div`
  height: 100%;
  min-width: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  flex-direction: column;
`;

const SubLogo = styled.p`
  font-size: ${({ theme }) => theme.fontSize.caption}px;
  font-weight: ${({ theme }) => theme.fontSize.caption};
  margin-block: unset;
`;

const projectsMenuItems = (navigate: NavigateFunction, projects: Project[]): itemData[] => {
  const menuItems: itemData[] = projects.map((p) => {
    return {
      label: p.name,
      onClick: () => {
        navigate(`/project/${p.name}`, { state: { entityId: p.entityId } });
      }
    };
  });

  menuItems.push({
    label: 'Create a New Project',
    onClick: () => {
      navigate('/newProject');
    },
    divider: true
  });

  return menuItems;
};

const accountMenuItems = (
  navigate: NavigateFunction,
  user: string,
  logout: () => void
): itemData[] => {
  return [
    {
      label: user,

      divider: true
    },
    {
      label: 'My Profile',
      onClick: () => {
        navigate('/users/profile');
      }
    },
    {
      label: 'Logout',
      onClick: () => {
        logout();
      },
      divider: true
    }
  ];
};

export const StyledAppBar = (): ReactElement => {
  const theme = useTheme();
  const { user, setUser } = useUser();
  const { coreState } = useCoreState();
  //const logout = useLogout();
  const [accountMenuOpen, setAccountMenuOpen] = React.useState<boolean>(false);
  const [projectMenuOpen, setProjectMenuOpen] = React.useState<boolean>(false);
  const [menuItems, setMenuItems] = React.useState<itemData[]>([]);
  const [name, setName] = useState<string>('');
  const navigate = useNavigate();
  const { updateToken } = useToken();

  useEffect(() => {
    const projects = coreState.projects.filter((p) => p.isActive);
    if (projects.length !== menuItems.length - 1) {
      setMenuItems(
        projectsMenuItems(
          navigate,
          coreState.projects.filter((p) => p.isActive)
        )
      );
    }
  }, [coreState.projects]);

  useEffect(() => {
    setName(user.name ? user.name : `${user.firstName} ${user.lastName}`);
  }, [user]);

  function logout() {
    setUser(defaultUser);
    updateToken('');
    navigate('/login');
  }

  return (
    <>
      <Container>
        <SubContainer>
          <h5 style={{ marginBlock: 'unset' }}>TRUCK LOG</h5>
          <SubLogo>SITE PREPARATION LTD</SubLogo>
        </SubContainer>
        <SubContainer style={{ flexDirection: 'row' }}>
          <Tooltip title="Account">
            <IconButton
              onClick={() => {
                setAccountMenuOpen(!accountMenuOpen);
                if (projectMenuOpen) {
                  setProjectMenuOpen(false);
                }
              }}
              size="small"
              sx={{ ml: 2 }}
              aria-controls={accountMenuOpen ? 'account-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={accountMenuOpen ? 'true' : undefined}>
              <Avatar sx={{ width: 32, height: 32, color: theme.palette.primary.main }}>
                {name.length > 2 ? (
                  name[0].toUpperCase() + name[1].toUpperCase()
                ) : (
                  <img src={UserIcon} height="20px" alt="user icon" />
                )}
              </Avatar>
            </IconButton>
          </Tooltip>
        </SubContainer>
      </Container>
      <AppBarMenu
        open={accountMenuOpen}
        right={25}
        menuData={accountMenuItems(navigate, name, logout)}
        onClose={() => setAccountMenuOpen(false)}
      />
    </>
  );
};
