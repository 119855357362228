export const BASE_URL = 'https://restportalproxy.braincloudservers.com/v2/';
export const SCRIPT_BASE = 'script/RUN/';
export const APP_ID = process.env.REACT_APP_APP_ID ?? '14329'; // Make App ID env configurable, will be useful for deployments
export const globalEntity = 'globalEntity/';
export const customEntity = 'customEntity/';
export const bearere = 'Bearere ';
export enum GEOP {
  create = 'CREATE',
  createwID = 'CREATE_WITH_INDEXED_ID',
  delete = 'DELETE',
  getList = 'GET_LIST',
  getListByIdx = 'GET_LIST_BY_INDEXED_ID',
  read = 'READ',
  update = 'UPDATE'
}

export enum CEOP {
  getEntityPage = 'GET_ENTITY_PAGE/',
  getEntityPageOffset = 'GET_ENTITY_PAGE_OFFSET/',
  getPage = 'GET_PAGE',
  getRandomEntitiesMatch = 'GET_RANDOM_ENTITIES_MATCHING/',
  getPageByOffset = 'GET_PAGE_BY_OFFSET/',
  readEntity = 'READ_ENTITY/',
  readSingleton = 'READ_SINGLETON/'
}

export const scriptOP = {
  createEntity: `${SCRIPT_BASE}CreateEntity`,
  updateEntity: `${SCRIPT_BASE}UpdateEntity`,
  createLoadType: `${SCRIPT_BASE}CreateLoadType`,
  creatProject: `${SCRIPT_BASE}CreateProject`,
  assignTrucks: `${SCRIPT_BASE}AssignTruck`,
  fetchAll: `${SCRIPT_BASE}AdminStartupData`,
  assignSites: `${SCRIPT_BASE}AssignReceivingSite`,
  assignUsers: `${SCRIPT_BASE}AssignUser`,
  listUsers: `${SCRIPT_BASE}ListUser`,
  createUser: `${SCRIPT_BASE}CreateUser`,
  updateUser: `${SCRIPT_BASE}UpdateUser`,
  resetPsw: `${SCRIPT_BASE}ResetPassword`,
  aboutme: `${SCRIPT_BASE}AboutMe`,
  listActivity: `${SCRIPT_BASE}ListActivity`,
  updateLoadType: `${SCRIPT_BASE}UpdateLoadType`,
  listLoad: `${SCRIPT_BASE}ListLoad`,
  updateLoad: `${SCRIPT_BASE}UpdateLoad`,
  truckNameChecker: `${SCRIPT_BASE}TruckNameChecker`,
  sendLoadDetail: `${SCRIPT_BASE}SendLoadDetail`,
  exportCSV: `${SCRIPT_BASE}ExportCSV`
};

export const forgetPsw = 'authentication/FORGOT';
export const storageKey = 'token';
